import React from "react";
import FileUpload from "../../../components/FileUpload/FileUpload";
import CKEditor from "ckeditor4-react";
import MainContext from "../../../Context/AppContext";
import {
  Form,
  Modal,
  Input,
  Row,
  Checkbox,
  Radio,
  Col,
  Select,
  Button,
  notification,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { storeCategory, getParentCategories } from "../../../network/network";

class AddCategory extends React.Component {
  formRef = React.createRef();
  state = {
    visible: false,
    categoriesArr: [],
    thumbnailImageId: "",
    ogImageId: "",
    bannerImageId: "",
    categoryDescription: "",
    categories: [],
  };
  static contextType = MainContext;

  showModal = () => {
    // var newCategories = this.props?.categories;

    // var parentCategories = newCategories.filter(function (item) {
    //   return item.parent_category === null;
    // });
    getParentCategories(this.context.authDetails.token).then((res) => {
      this.setState({ categoriesArr: res.data, visible: true });
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleUpload = ({ thumbnailImage }) => {
    this.setState({ thumbnailImage });
  };
  onFinish = () => {
    let values = this.formRef.current.getFieldValue();

    const formData = new FormData();
    formData.append("thumbnailImage", this.state.thumbnailImageId);
    formData.append("ogImage", this.state.ogImageId);
    formData.append("bannerImage", this.state.bannerImageId);
    formData.append("name", values.name);
    formData.append("description", this.state.categoryDescription);
    formData.append("order", values.order);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);

    formData.append(
      "parent_category",
      values.parent_category ? values.parent_category : ""
    );
    formData.append("recommended", values.recommended ? true : false);
    formData.append("status", values.status);
    storeCategory(formData, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Category Created",
          description: "The category has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  onFinishFailed = (errorInfo) => {};

  handleThumbnailImageID = (thumbnailImageID) => {
    this.setState({ thumbnailImageId: thumbnailImageID });
  };

  handleOGImageID = (ogImageID) => {
    this.setState({ ogImageId: ogImageID });
  };

  handlebannerImageID = (bannerImageID) => {
    this.setState({ bannerImageId: bannerImageID });
  };

  categoryDescriptionupdate = (evt) => {
    this.setState({
      categoryDescription: evt.editor.getData(),
    });
  };

  render() {
    return (
      <div>
        <Button
          style={{ float: "right", margin: "10px" }}
          type="primary"
          onClick={this.showModal}
          icon={<PlusOutlined />}
          size="large"
        ></Button>
        <Modal
          title="Create Category"
          visible={this.state.visible}
          onOk={this.onFinish}
          onCancel={this.hideModal}
          okText="Create"
          width={2000}
          centered
          cancelText="Cancel"
          footer={[
            <Button
              form="myFormAdd"
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Create
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                layout="vertical"
                id="myFormAdd"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: false,
                      message: "Please input description!",
                    },
                  ]}
                >
                  <CKEditor data="" onChange={this.categoryDescriptionupdate} />
                </Form.Item>

                <Form.Item label="Thumbnail Image">
                  <FileUpload
                    onThumbnailImageId={this.handleThumbnailImageID}
                    imageUploadType="thumbnailImage"
                  />
                </Form.Item>

                <Form.Item label="Banner Image">
                  <FileUpload
                    onBannerImageId={this.handlebannerImageID}
                    imageUploadType="bannerImage"
                  />
                </Form.Item>

                <Form.Item label="OG Image">
                  <FileUpload
                    onOGImageId={this.handleOGImageID}
                    imageUploadType="categoryOGImage"
                  />
                </Form.Item>

                <Form.Item
                  name="parent_category"
                  label="Parent Category"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select>
                    {/* <Select.Option value={""}>No Category</Select.Option> */}
                    {this.state.categoriesArr?.map((item, index) => (
                      <Select.Option value={item._id} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>

                  {/* <Select>
                    {this.props?.categories?.map((item, index) => (
                      <Select.Option value={item._id} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                </Form.Item>

                <Form.Item
                  name="order"
                  label="Ordering"
                  rules={[
                    {
                      required: false,
                      message: "Please input integer value for ordering!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item name="recommended" valuePropName="checked">
                  <Checkbox>Mark as Recommended Category</Checkbox>
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Published</Radio>
                    <Radio value={false}>Unpublished</Radio>
                  </Radio.Group>
                </Form.Item>

                <div
                  className="ant-modal-title"
                  style={{ marginBottom: "20px" }}
                >
                  Course SEO Details
                </div>

                <Form.Item
                  name="seo_title"
                  label="SEO Title"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO title!",
                      whitespace: true,
                      max: 60,
                      min: 30,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="SEO Description"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO description!",
                      whitespace: true,
                      max: 160,
                      min: 110,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="SEO Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input course SEO Keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default AddCategory;
