import React, { Component } from "react";
import Context from "./AppContext";

class ContextWrapper extends Component {
  state = {
    authDetails: {},
    isUserLoggedIn: false,
    orignal_account_authDetails: {},
    orignal_token: "",
  };
  setUserDetails = (userObj) => {
    this.setState({
      authDetails: userObj,
    });
  };

  logoutUser = () => {
    this.setState({
      authDetails: {},
      isUserLoggedIn: false,
      orignal_account_authDetails: {},
      orignal_token: "",
    });
  };
  switchAccount = (userDetails) => {
    if (this.state.authDetails.userType === 1) {
      localStorage.setItem("orignal_key", this.state.authDetails.token);
      this.setState({
        orignal_account_authDetails: this.state.authDetails,
        authDetails: userDetails,
        orignal_token: localStorage.getItem("key"),
      });
      localStorage.setItem("key", userDetails.token);
    } else if (this.state.authDetails.userType === 2) {
      let managerTraineeAccount = { ...this.state.authDetails };
      managerTraineeAccount.orignalUserType = 2;
      managerTraineeAccount.userType = 4;
      this.setState({
        orignal_account_authDetails: this.state.authDetails,
        authDetails: managerTraineeAccount,
      });
    }
  };

  switchBackToOrignalAccount = () => {
    if (
      this.state.orignal_account_authDetails.userType === 1 ||
      localStorage.getItem("orignal_key")
    ) {
      localStorage.setItem("key", localStorage.getItem("orignal_key"));
      this.setState({
        authDetails: this.state.orignal_account_authDetails,
        orignal_account_authDetails: {},
        orignal_token: "",
      });
      localStorage.removeItem("orignal_key");
      return 1; //Admin
    } else if (
      this.state.authDetails &&
      this.state.authDetails.orignalUserType === 2 &&
      this.state.authDetails.userType === 4
    ) {
      this.setState({
        authDetails: this.state.orignal_account_authDetails,
        orignal_account_authDetails: {},
      });
      return 2; //Manager
    }
  };

  setUserLoginStatus = (isLoggedIn) => {
    this.setState({ isUserLoggedIn: isLoggedIn });
  };

  render() {
    return (
      <Context.Provider
        value={{
          authDetails: this.state.authDetails,
          setUserDetails: this.setUserDetails,
          setUserLoginStatus: this.setUserLoginStatus,
          isUserLoggedIn: this.state.isUserLoggedIn,
          orignal_token: this.state.orignal_token,
          orignal_account_authDetails: this.state.orignal_account_authDetails,
          switchAccount: this.switchAccount,
          switchBackToOrignalAccount: this.switchBackToOrignalAccount,
          switchManagerAccount: this.switchAccount,
          logoutUser: this.logoutUser,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default ContextWrapper;
