import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  Button,
  notification,
  message,
  Modal,
} from "antd";
import { saveFaq } from "../../../network/network";
import MainContext from "../../../Context/AppContext";
const { TextArea } = Input;

class AddFaq extends React.Component {
  formRef = React.createRef();
  state = {};
  static contextType = MainContext;
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onFinish = (values) => {
    saveFaq(values, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Faq Created",
          description: "The faq has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch((error) => {
        if (error && error.response.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          let errorMessages = Object.values(error.response.data.message);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      });
  };

  onFinishFailed = (errorInfo) => {};

  render() {
    return (
      <div>
        <Button
          style={{ float: "right", margin: "10px" }}
          type="primary"
          onClick={this.showModal}
          icon={<PlusOutlined />}
          size="large"
        ></Button>

        <Modal
          title="Create Faq"
          visible={this.state.visible}
          // onOk={this.hideModal}
          onCancel={this.hideModal}
          width={2000}
          centered
          footer={[
            <Button
              form={"myFormAdd"}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Store
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id={"myFormAdd"}
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="question"
                  label="Question"
                  // tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your question!",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea placeholder="textarea with clear icon" allowClear />
                </Form.Item>

                <Form.Item
                  name="answer"
                  label="Answer"
                  rules={[
                    {
                      required: true,
                      message: "Please input your answer!",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea placeholder="textarea with clear icon" allowClear />
                </Form.Item>

                <Form.Item
                  name="order"
                  label="Ordering"
                  rules={[
                    {
                      required: true,
                      message: "Please input integer value for ordering!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Published</Radio>
                    <Radio value={false}>Draft</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default AddFaq;
