import React from "react";
import {
  Table,
  Col,
  message,
  Space,
  Button,
  Tooltip,
  Input,
  Row,
  Modal,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EyeOutlined, RedoOutlined } from "@ant-design/icons";
import {
  getManagerTrainees,
  getManagerHierarchy,
  sendTraineeCredentialsNotification,
} from "../../../network/network";
import StudentStats from "../Users/StudentStats";
import AddHierarchy from "../Hierarchy/add";
import AddTrainee from "./add";
import EditTrainee from "./edit";
import MainContext from "../../../Context/AppContext";
import {
  USER_CREDENTIALS_TYPE_EMAIL,
  USER_CREDENTIALS_TYPE_SMS,
} from "../../../constants/constants";

const confirm = Modal.confirm;
const { Search } = Input;
class Trainees extends React.Component {
  state = {
    filters: {
      searchText: "",
      hierarchy: null,
    },
    searchedColumn: "",
    showStatsModal: false,
    course: {},
    data: [],
    hierarchy_data: [],
    hierarchy_filters: [],
    filterTable: null,
    pagination: {
      current: 1,
      pageSize: 20,
    },
    filterPagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
  };
  static contextType = MainContext;
  componentDidMount() {
    this.fetch();
    this.getManagerHierarchyData();
  }

  refreshTraineesData = () => {
    this.setState({
      filterTable: null,
      filters: {
        searchText: "",
        hierarchy: null,
      },
      searchedColumn: "",
      index: this.state.index + 1,
      loading: false,
      data: [],
      pagination: {
        current: 1,
        pageSize: 20,
      },
      filterPagination: {
        current: 1,
        pageSize: 20,
      },
    });
  };

  sendSuccessNotifications = (type, traineeId) => {
    let message_type = type === USER_CREDENTIALS_TYPE_EMAIL ? "Email" : "SMS";
    confirm({
      title: `Trainee Credentials ${message_type}`,
      content: `Are you sure want to send trainee credentials ${message_type}`,
      okText: "OK",
      onOk: () => {
        sendTraineeCredentialsNotification(
          type,
          traineeId,
          this.context.authDetails?.token
        )
          .then((res) => {
            notification.success({
              message: `Manager Success ${message_type}`,
              description: `${res.data.message}`,
              placement: "topRight",
            });
          })
          .catch((err) => {
            notification.error({
              message: `Error`,
              description: `Some error has occurred while sending ${message_type}`,
              placement: "topRight",
            });
          });
      },
    });
  };

  searchValue = (value) => {
    this.setState({ loading: true, filterTable: null });
    value = value.trim();
    const { searchText, hierarchy } = this.state.filters;
    let tableFilters = {};
    if (searchText && searchText != "") {
      tableFilters.keyword = searchText;
    }
    if (hierarchy) {
      tableFilters.hierarchy = hierarchy;
    }
    if (value) {
      this.filterData({ ...tableFilters });
    } else {
      this.setState({
        filters: {
          searchText: "",
          hierarchy: null,
        },
        filterTable: null,
        loading: false,
      });
    }
  };
  makeTextHighlight = (text) => {
    return (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.filters.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  };
  getManagerHierarchyData = () => {
    if (this.context.authDetails.token) {
      getManagerHierarchy(this.context.authDetails.token).then((res) => {
        let hierarchy_filters_data = this.state.hierarchy_filters;

        if (res.data.length > 0) {
          for (let index = 0; index < res.data.length; index++) {
            hierarchy_filters_data.push({
              text: res.data[index].label,
              value: res.data[index]._id,
            });
          }
        }

        this.setState({
          hierarchy_data: res.data,
          hierarchy_filters: hierarchy_filters_data,
        });
      });
    }
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  updateUserTable = () => {
    this.fetch();
  };
  handleTableChange = (tablePagination, filters, sorter) => {
    const { current: pageNumber, pageSize: pagesize } = tablePagination;
    const { searchText } = this.state.filters;
    let tableFilters = {};

    if (searchText && searchText != "") {
      tableFilters.keyword = searchText;
    }
    if (filters && filters.Hierarchy) {
      tableFilters.hierarchy = filters.Hierarchy;
    }
    this.filterData(
      {
        ...tableFilters,
      },
      { pageNumber, pagesize }
    );
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  notDeleteUserMessage = () => {
    message.info("Trainee is safe");
  };

  filterData = (filters = {}, params = { pageNumber: 1, pagesize: 20 }) => {
    this.setState({
      loading: true,
      filterTable: null,
      filters: {
        searchText: "",
        hierarchy: null,
      },
    });

    getManagerTrainees(this.context.authDetails?.token, {
      ...filters,
      ...params,
    })
      .then((res) => {
        this.setState({
          filterTable: res.data.trainees,
          filters: {
            searchText: filters.keyword || "",
            hierarchy: filters.hierarchy || "",
          },
          index: this.state.index++,
          filterPagination: {
            current: params.pageNumber,
            total: res.data.total,
            pageSize: params.pagesize,
          },
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  fetch = (params = { pageNumber: 1, pagesize: 20 }, refreshData = false) => {
    this.setState({
      loading: true,
      filterTable: null,
      filters: {
        searchText: "",
        hierarchy: null,
      },
    });

    setTimeout(() => {
      if (this.context.authDetails?.token) {
        getManagerTrainees(this.context.authDetails.token, params)
          .then((res) => {
            this.setState({
              data: res.data.trainees,
              index: this.state.index++,
              pagination: {
                current: params.pageNumber,
                total: res.data.total,
                pageSize: params.pagesize,
              },
            });
          })
          .catch(function (error) {
            if (error && error.response && error.response.status === 401) {
              message.error(error.response.data.message);
            }
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    }, 1000);
  };

  onChangeHandle = (e) => {
    const { hierarchy } = this.state.filters;
    if (e.target.value === "" && hierarchy) {
      const tableFilters = {
        hierarchy,
      };
      this.filterData({
        ...tableFilters,
      });
    }
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        searchText: e.target.value,
      },
    }));
  };

  render() {
    const { loading, filterTable, data, pagination, showStatsModal, course } =
      this.state;
    const columns = [
      {
        title: "#",
        width: "2%",
        key: "index",
        render: (text, record, index) => {
          return (
            <p>
              {!this.state.filterTable
                ? this.state.pagination.pageSize *
                    (this.state.pagination.current - 1) +
                  (index + 1)
                : this.state.filterPagination.pageSize *
                    (this.state.filterPagination.current - 1) +
                  (index + 1)}
            </p>
          );
        },
      },
      {
        title: "Full Name",
        width: "20%",
        render: (text, record) => {
          let fullName = `${record.firstName} ${record.lastName}`;
          return (
            <>
              <b>{this.makeTextHighlight(fullName)}</b>
              {!record?.is_non_oes_user && !record?.is_blocked && (
                <>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.sendSuccessNotifications(
                        USER_CREDENTIALS_TYPE_EMAIL,
                        record._id
                      )
                    }
                    style={{
                      marginTop: "20px",
                      display: "block",
                    }}
                  >
                    Credentials Email
                  </Button>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.sendSuccessNotifications(
                        USER_CREDENTIALS_TYPE_SMS,
                        record._id
                      )
                    }
                    style={{
                      marginTop: "20px",
                      display: "block",
                    }}
                  >
                    Credentials SMS
                  </Button>
                </>
              )}
            </>
          );
        },

        // filterIcon: <SearchOutlined style={{ color: "#0083B7" }} />,
        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters,
        // }) => (
        //   <div style={{ padding: 8 }}>
        //     <Input
        //       ref={(node) => {
        //         this.searchInput = node;
        //       }}
        //       placeholder={`Search trainee name`}
        //       value={selectedKeys[0]}
        //       onChange={(e) =>
        //         setSelectedKeys(e.target.value ? [e.target.value] : [])
        //       }
        //       onPressEnter={() =>
        //         this.handleSearch(selectedKeys, confirm, "fullname")
        //       }
        //       style={{ marginBottom: 8, display: "block" }}
        //     />
        //     <Space>
        //       <Button
        //         type="primary"
        //         onClick={() =>
        //           this.handleSearch(selectedKeys, confirm, "fullname")
        //         }
        //         icon={<SearchOutlined />}
        //         size="small"
        //         style={{ width: 90 }}
        //       >
        //         Search
        //       </Button>
        //       <Button
        //         onClick={() => this.handleReset(clearFilters)}
        //         size="small"
        //         style={{ width: 90 }}
        //       >
        //         Reset
        //       </Button>
        //     </Space>
        //   </div>
        // ),
      },
      {
        title: "User Credentials",
        width: "20%",
        render: (text, record) => {
          return (
            <>
              {record.userName && (
                <p>
                  <b>Username: </b>
                  {this.makeTextHighlight(record.userName)}
                </p>
              )}
              {record.password && (
                <p>
                  <b>Password: </b> {this.makeTextHighlight(record.password)}
                </p>
              )}
            </>
          );
        },
        // filterIcon: <SearchOutlined style={{ color: "#0083B7" }} />,
        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters,
        // }) => (
        //   <div style={{ padding: 8 }}>
        //     <Input
        //       ref={(node) => {
        //         this.searchInput = node;
        //       }}
        //       placeholder={`Search trainee Credentials`}
        //       value={selectedKeys[0]}
        //       onChange={(e) =>
        //         setSelectedKeys(e.target.value ? [e.target.value] : [])
        //       }
        //       onPressEnter={() =>
        //         this.handleSearch(selectedKeys, confirm, "user_credentials")
        //       }
        //       style={{ marginBottom: 8, display: "block" }}
        //     />
        //     <Space>
        //       <Button
        //         type="primary"
        //         onClick={() =>
        //           this.handleSearch(selectedKeys, confirm, "user_credentials")
        //         }
        //         icon={<SearchOutlined />}
        //         size="small"
        //         style={{ width: 90 }}
        //       >
        //         Search
        //       </Button>
        //       <Button
        //         onClick={() => this.handleReset(clearFilters)}
        //         size="small"
        //         style={{ width: 90 }}
        //       >
        //         Reset
        //       </Button>
        //     </Space>
        //   </div>
        // ),
      },
      {
        title: "Contact Details",
        width: "20%",
        render: (text, record) => {
          return (
            <>
              {record.email && (
                <p>
                  <b>Email: </b>
                  {this.makeTextHighlight(record.email)}
                </p>
              )}

              {record.contactNo && (
                <p>
                  <b>Contact: </b>
                  {this.makeTextHighlight(record.contactNo)}
                </p>
              )}
              {/* <p><b>Address: </b> {record.address}, {record.city} </p> */}
            </>
          );
        },
        // filterIcon: <SearchOutlined style={{ color: "#0083B7" }} />,
        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters,
        // }) => (
        //   <div style={{ padding: 8 }}>
        //     <Input
        //       ref={(node) => {
        //         this.searchInput = node;
        //       }}
        //       placeholder={`Search trainee email`}
        //       value={selectedKeys[0]}
        //       onChange={(e) =>
        //         setSelectedKeys(e.target.value ? [e.target.value] : [])
        //       }
        //       onPressEnter={() =>
        //         this.handleSearch(selectedKeys, confirm, "contact_details")
        //       }
        //       style={{ marginBottom: 8, display: "block" }}
        //     />
        //     <Space>
        //       <Button
        //         type="primary"
        //         onClick={() =>
        //           this.handleSearch(selectedKeys, confirm, "contact_details")
        //         }
        //         icon={<SearchOutlined />}
        //         size="small"
        //         style={{ width: 90 }}
        //       >
        //         Search
        //       </Button>
        //       <Button
        //         onClick={() => this.handleReset(clearFilters)}
        //         size="small"
        //         style={{ width: 90 }}
        //       >
        //         Reset
        //       </Button>
        //     </Space>
        //   </div>
        // ),
      },
      {
        title: "Hierarchy",
        width: "15%",
        align: "center",
        filters: this.state.hierarchy_filters,
        render: (text, record) =>
          record.hierarchy_id && record.hierarchy_id.label
            ? record.hierarchy_id.label
            : "-",
      },
      {
        title: "Assigned Course",
        width: "15%",
        align: "center",
        render: (text, record) => {
          let courses = [];
          record.courses.forEach((element) => {
            // if (element.courseId) {
            //   courses.push({
            //     title: element.courseId.title,
            //     registration_id: element._id,
            //   });
            // }
            if (element.course_assign_status && element.courseId) {
              let status = "";
              if (element.is_failed || element.status === "FAILED") {
                status = "Failed";
              } else if (element.completion === "COMPLETED") {
                status = "COMPELETED";
              } else if (
                (element.time === "0" || element.time === "00:00:00") &&
                element.completion !== "COMPLETED"
              ) {
                status = "NOT STARTED";
              } else if (
                element.time !== "0" &&
                element.time !== "00:00:00" &&
                element.completion !== "COMPLETED"
              ) {
                status = "IN PROGRESS";
              }

              courses.push({
                title: element.courseId.title,
                registration_id: element._id,
                course_progress: status,
              });
            }
          });

          return (
            <>
              {courses.map((obj, i) => (
                <div className="course-report trainee-course" key={i}>
                  <p key={i}>
                    {i + 1}. <b>{obj.title}</b>
                    <span
                      className="link-stats"
                      onClick={() => {
                        this.setState({ course: obj, showStatsModal: true });
                      }}
                    >
                      View Progress
                    </span>
                  </p>
                </div>
              ))}
            </>
          );
        },
      },

      {
        title: "Action",
        align: "center",
        dataIndex: "",
        key: "x",
        render: (text, record) => (
          <Space size="middle">
            <EditTrainee
              hierarchy_data={this.state.hierarchy_data}
              onClick={this.showModal}
              data_id={record._id}
              type={"edit"}
              onChildClick={this.updateUserTable.bind(this)}
            />
            <Tooltip placement="bottom" title={"View Trainees"}>
              <Button type="dashed">
                <Link to={`/user/${record._id}/details`}>
                  <EyeOutlined />
                </Link>
              </Button>
            </Tooltip>
          </Space>
        ),
      },
    ];

    return (
      <>
        {showStatsModal ? (
          <StudentStats
            closeStats={() => this.setState({ showStatsModal: false })}
            course={course}
          />
        ) : (
          ""
        )}

        <div>
          <Row className="mb-10">
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <h2>Recent Trainees</h2>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div
                className="search"
                style={{ display: "inline-flex", alignItems: "center" }}
              >
                <Search
                  placeholder="Search Trainee"
                  allowClear
                  value={this.state.filters.searchText}
                  onChange={this.onChangeHandle}
                  enterButton={<SearchOutlined />}
                  size="large"
                  onSearch={(value) => this.searchValue(value)}
                />
              </div>
              <Button
                style={{ margin: "0 10px 0px" }}
                type="primary"
                icon={<RedoOutlined />}
                size={"large"}
                onClick={() => {
                  this.refreshTraineesData();
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4} align="right">
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <AddHierarchy
                  pageType="trainees"
                  onChildClick={this.getManagerHierarchyData.bind(this)}
                />
                <AddTrainee
                  hierarchy_data={this.state.hierarchy_data}
                  onChildClick={this.updateUserTable.bind(this)}
                />
              </div>
            </Col>
          </Row>
          <Col className="dashboard-charts" span={24}>
            <div className="table-box">
              <Table
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={filterTable ? filterTable : data}
                pagination={
                  filterTable ? this.state.filterPagination : pagination
                }
                loading={loading}
                onChange={this.handleTableChange}
              />
            </div>
          </Col>
        </div>
      </>
    );
  }
}

export default Trainees;
