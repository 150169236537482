import React, { useState, useEffect, useContext } from "react";
import { Spin, Timeline, Col, message } from "antd";
import { CalendarOutlined, LoadingOutlined } from "@ant-design/icons";
import MainContext from "../../Context/AppContext";
import { getManagerActivityLogs } from "../../network/network";
import { formatDateTime } from "../../helpers/helpers";
class ActivityLogs extends React.Component {
  state = {
    loading: false,
    logs: [],
  };
  static contextType = MainContext;
  componentDidMount() {
    this.setState({ loading: true });
    getManagerActivityLogs(this.context.authDetails.token)
      .then((res) => {
        this.setState({ logs: res.data });
      })
      .catch((err) => {
        message.error("Some error has occurred while fetching the stats");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  render() {
    const { loading, logs } = this.state;

    return (
      <>
        <h2 className="manager-box-heading">Activity Logs</h2>
        <div className="table-box">
          {loading ? (
            <div className="processing-loader">
              <Spin indicator={<LoadingOutlined />} />
            </div>
          ) : (
            <>
              <Col span={24}>
                {logs.length > 0 ? (
                  <Timeline className="activityLogs">
                    <>
                      {logs.map((log, i) => (
                        <Timeline.Item
                          key={i}
                          dot={
                            <CalendarOutlined className="timeline-icon timeline-clock-icon " />
                          }
                        >
                          <p className="dateTime">
                            {formatDateTime(log.createdAt)}
                          </p>
                          <p className="smalldesc">
                            {log.currentState.message.replace(
                              new RegExp("<[^>]*>", "g"),
                              ""
                            )}
                          </p>
                        </Timeline.Item>
                      ))}
                    </>
                  </Timeline>
                ) : (
                  <p className="not-found-message">No logs found</p>
                )}
              </Col>
            </>
          )}
        </div>
      </>
    );
  }
}

export default ActivityLogs;
