import React from "react";
import {
  Tag,
  Table,
  // Row,
  Col,
  Button,
  // Popconfirm,
  message,
  Popconfirm,
  // Popconfirm,
  notification,
  Space,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";
import MainContext from "../../../Context/AppContext";

import { getFaqs, deleteFaq } from "../../../network/network";
import AddFaq from "./add";
import EditFaq from "./edit";

class Faqs extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    columns: [
      {
        title: "#",
        width: "2%",
        align: "center",
        key: "index",
        render: (text, record, index) => {
          return (
            <b>
              {this.state.pagination.pageSize *
                (this.state.pagination.current - 1) +
                (index + 1)}
            </b>
          );
        },
      },
      {
        title: "Order",
        width: "5%",
        align: "center",
        dataIndex: "order",
      },
      {
        title: "Question",
        width: "25%",
        align: "left",
        dataIndex: "question",
      },
      {
        title: "Answer",
        width: "50%",
        align: "left",
        dataIndex: "answer",
      },

      {
        title: "Status",
        width: "5%",
        align: "center",
        render: (record) => {
          return (
            <Tag color={record.status ? "green" : "red"}>
              {record.status ? "PUBLISHED" : "DRAFT"}
            </Tag>
          );
        },
      },

      {
        title: "Action",
        width: "5%",
        align: "center",
        dataIndex: "",
        key: "x",
        render: (text, record) => (
          <Space size="middle">
            <EditFaq
              data_id={record._id}
              type={"edit"}
              categories={this.state.categories}
              onChildClick={this.updateFaqTable.bind(this)}
            />

            <Popconfirm
              title="Are you sure to delete this faq?"
              onConfirm={() => {
                this.onDelete(record);
              }}
              onCancel={this.notDeleteUserMessage}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} type="danger" />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  };
  static contextType = MainContext;
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter) => {
    // this.fetch({
    //   sortField: sorter.field,
    //   sortOrder: sorter.order,
    //   pagination,
    //   ...filters,
    // });
    this.setState({
      loading: false,
      pagination: pagination,
    });
  };

  updateFaqTable = () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  onDelete = (record) => {
    this.setState({ loading: true });
    deleteFaq(record._id, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
      });

      notification.success({
        message: "Faq Deleted",
        description: "The faq has been deleted successfully.",
        placement: "topRight",
      });

      const { pagination } = this.state;
      this.fetch({ pagination });
    });
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  notDeleteUserMessage = () => {
    message.info("Faq is safe");
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });

    setTimeout(() => {
      getFaqs(this.context.authDetails?.token)
        .then((res) => {
          this.setState({
            loading: false,
            data: res.data,
            pagination: {
              ...params.pagination,
              total: res.data.totalCount,
            },
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }, 1000);
  };

  render() {
    const { loading } = this.state;
    const { columns } = this.state;
    const { data } = this.state;
    const { pagination } = this.state;

    return (
      <div>
        {/* <Row type="flex" align="middle" justify="space-between">
          <Col>
            <h4>Faqs</h4>
          </Col>
          <Col span={12}>
            <AddFaq
              onChildClick={this.updateFaqTable.bind(this)}
              categories={this.state.categories}
            />
          </Col>
        </Row> */}

        <Col className="dashboard-charts" span={24}>
          <h2>Recent Faqs</h2>
          <AddFaq
            onChildClick={this.updateFaqTable.bind(this)}
            categories={this.state.categories}
          />
          <div className="table-box">
            <Table
              className="faqs-table"
              columns={columns}
              rowKey={(record) => record._id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </div>
        </Col>
      </div>
    );
  }
}

export default Faqs;
