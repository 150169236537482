import React from "react";
import { Button, message, Modal, Col, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MainContext from "../../../Context/AppContext";
import { SecondsToTime } from "../../../helpers/helpers";

import {
  GetRegistrationProgress,
  saveCourseProgress,
} from "../../../network/network";

class StudentStats extends React.Component {
  state = {
    course_progress_details: {},
    loading: false,
    visible: true,
  };
  static contextType = MainContext;
  componentDidMount() {
    this.getCourseStats();
  }

  hideModal = () => {
    this.setState({
      visible: false,
    });
    this.props.closeStats();
  };

  saveCourseProgressStats = (course_progress_scorm, course_registration_id) => {
    if (course_progress_scorm) {
      let data = {
        completion: course_progress_scorm.activityDetails.activityCompletion,
        status: course_progress_scorm.activityDetails.activitySuccess,
        score: course_progress_scorm.activityDetails.score
          ? course_progress_scorm.activityDetails.score.scaled
          : "0",
        time: course_progress_scorm.totalSecondsTracked,
        firstAccessDate: course_progress_scorm.firstAccessDate,
        lastAccessDate: course_progress_scorm.lastAccessDate,
        completedDate: course_progress_scorm.completedDate,
        expiryDate: "",
        is_failed:
          course_progress_scorm.activitySuccess === "FAILED" ? true : false,
      };

      saveCourseProgress(
        course_registration_id,
        data,
        this.context.authDetails.token
      )
        .then((res) => {
          message.success("Stats updated successfully.");
        })
        .catch((err) => {
          message.warning("Course not Found");
          this.hideModal();
        });
    }
  };

  getCourseStats = (params = {}) => {
    this.setState({ loading: true, visible: true });

    if (
      this.context.authDetails?.userType === 1 ||
      this.context.authDetails?.userType === 2
    ) {
      setTimeout(() => {
        GetRegistrationProgress(this.props.course.registration_id)
          .then((res) => {
            this.setState({
              course_progress_details: res.data,
            });
            this.saveCourseProgressStats(
              res.data,
              this.props.course.registration_id
            );
          })
          .catch(() => {
            message.error("Some error has occurred");
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }, 1000);
    }
  };

  render() {
    const { course_progress_details } = this.state;

    return (
      <Modal
        title="View Stats"
        visible={this.state.visible}
        width={2000}
        centered
        onCancel={this.hideModal}
        footer={[
          <Button type="primary" onClick={this.hideModal}>
            Close
          </Button>,
        ]}
      >
        <Col className="dashboard-charts" span={24}>
          <h2>{this.props.course.title}</h2>

          <div className="table-box">
            {Object.keys(course_progress_details).length > 0 ? (
              <>
                <h2>Stats</h2>
                <p>
                  <b>Activity Details: </b>
                  {course_progress_details.activityDetails.activityCompletion}
                </p>
                <p>
                  <b>Activity Success: </b>
                  {course_progress_details.activityDetails.activitySuccess}
                </p>
                <p>
                  <b>Attempts: </b>
                  {course_progress_details.activityDetails.attempts}
                </p>
                <p>
                  <b>Score: </b>
                  {course_progress_details.activityDetails.score
                    ? course_progress_details.activityDetails.score.scaled
                    : ""}
                </p>
                <p>
                  <b>Time: </b>

                  {SecondsToTime(course_progress_details.totalSecondsTracked)}
                </p>
                <p>
                  <b>Created Date: </b>
                  {course_progress_details.createdDate}
                </p>
                <p>
                  <b>First Access Date: </b>
                  {course_progress_details.firstAccessDate}
                </p>
                <p>
                  <b>Completed Date: </b>
                  {course_progress_details.completedDate}
                </p>
                <h2>Learner</h2>
                <p>
                  <b>First Name: </b>
                  {course_progress_details.learner.firstName}
                </p>
                <p>
                  <b>Last Name: </b>
                  {course_progress_details.learner.lastName}
                </p>
                <p>
                  <b>Email: </b>
                  {course_progress_details.learner.email}
                </p>
                <h2>Course</h2>
                <p>
                  <b>Title: </b>
                  {course_progress_details.course.title}
                </p>
              </>
            ) : (
              <div className="text-center">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              </div>
            )}
          </div>
        </Col>
      </Modal>
    );
  }
}

export default StudentStats;
