import React from "react";
import {
  Form,
  Input,
  Image,
  Row,
  Col,
  Button,
  notification,
  Modal,
} from "antd";
import MainContext from "../../../Context/AppContext";
// import CKEditor from "ckeditor4-react";
import { FormOutlined } from "@ant-design/icons";
import {
  // getCategoryByID,
  getPageByID,
  updatePage,
} from "../../../network/network";
const { TextArea } = Input;

class AddUser extends React.Component {
  formRef = React.createRef();
  state = {
    loading: false,
    visible: false,
    page_details: {},
  };
  static contextType = MainContext;

  getPageDetails = (pageID) => {
    getPageByID(pageID, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
        visible: true,
        page_details: res.data,
      });

      if (res.data) {
        this.formRef.current.setFieldsValue({
          ...res.data,
        });
      }
    });
  };

  onFinish = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("slug", values.slug);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);
    if (values.og_image_url) {
      formData.append("og_image_url", values.og_image_url);
    }
    updatePage(this.props.data_id, formData, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Page Updated",
          description: "The page has been updated successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  showModal = () => {
    this.getPageDetails(this.props.data_id);
    this.setState({
      loading: false,
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onFinishFailed = (errorInfo) => {};
  render() {
    const { layout, page_details } = this.state;

    return (
      <div>
        <Button
          onClick={this.showModal}
          type="primary"
          icon={<FormOutlined />}
        />

        <Modal
          title="Edit Page"
          visible={this.state.visible}
          onOk={this.onFinish}
          onCancel={this.hideModal}
          okText="Update"
          width={2000}
          centered
          cancelText="Cancel"
          footer={[
            <Button
              form={"myForm" + page_details._id}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Update
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                {...layout}
                ref={this.formRef}
                name="basic"
                id={"myForm" + page_details._id}
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="title"
                  label="Page Title"
                  rules={[
                    {
                      required: true,
                      message: "Please input page title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="slug"
                  label="Page Slug"
                  tooltip="This will reflect in your page url."
                  rules={[
                    {
                      required: true,
                      message: "Please input page slug!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_title"
                  label="Meta Title"
                  rules={[
                    {
                      required: false,
                      // message: "Please input meta title!",
                      whitespace: true,
                      max: 60,
                      min: 30,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="Meta Description"
                  rules={[
                    {
                      required: false,
                      // message: "Please input meta description!",
                      whitespace: true,
                      max: 160,
                      min: 110,
                    },
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="Meta Keywords"
                  rules={[
                    {
                      required: false,
                      // message: "Please input meta keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="og_image_url"
                  label="OG Image URL"
                  rules={[
                    {
                      required: false,
                      whitespace: false,
                      message: "This field is required.",
                    },
                    {
                      type: "url",
                      message: "This field must be a valid url.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {this.state.page_details.og_image_url && (
                  <Image
                    width={200}
                    src={this.state.page_details.og_image_url}
                  />
                )}
                {/* 
                <Form.Item
                  name="content"
                  label="Content"
                  rules={[
                    {
                      required: true,
                      message: "Please input meta description!",
                      whitespace: true,
                    },
                  ]}
                >
                  <CKEditor data="<p>Hello from CKEditor 4!</p>" />
                </Form.Item> */}

                {/* <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup" defaultValue={"publish"}>
                    <Radio value={"publish"}>Publish</Radio>
                    <Radio value={"draft"}>Draft</Radio>
                  </Radio.Group>
                </Form.Item> */}

                {/* <Form.Item {...tailLayout}>
                  <Button size={"large"} type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item> */}
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default AddUser;
