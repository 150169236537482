import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  // Select,
  Row,
  Col,
  Button,
  notification,
  message,
  Modal,
} from "antd";
import { saveHierarchyData } from "../../../network/network";
import MainContext from "../../../Context/AppContext";
// const { Option } = Select;

class AddHierarchy extends React.Component {
  formRef = React.createRef();
  state = {};
  static contextType = MainContext;
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onFinish = (values) => {
    saveHierarchyData(values, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Hierarchy Created",
          description: "The hierarchy has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          let errorMessages = Object.values(error.response.data.message);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      });
  };

  onFinishFailed = (errorInfo) => {};

  render() {
    return (
      <div>
        {this.props.pageType && this.props.pageType === "trainees" ? (
          <Button
            style={{ float: "right", marginRight: "10px" }}
            type="primary"
            onClick={this.showModal}
            size="large"
          >
            Add Hierarchy
          </Button>
        ) : (
          <Button
            style={{ float: "right", marginRight: "10px" }}
            type="primary"
            onClick={this.showModal}
            icon={<PlusOutlined />}
            size="large"
          ></Button>
        )}
        <Modal
          title="Create Hierarchy"
          visible={this.state.visible}
          onCancel={this.hideModal}
          width={2000}
          centered
          footer={[
            <Button
              form="hierarchyForm"
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Create
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id="hierarchyForm"
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="label"
                  label="Hierarchy Name"
                  tooltip="Please enter your Hierarchy name?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Hierarchy Name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="campus"
                  label="Campus"
                  rules={[
                    {
                      required: false,
                      message: "Please input your campus!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="instructor"
                  label="Instructor"
                  rules={[
                    {
                      required: false,
                      message: "Please input your instructor name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default AddHierarchy;
