import React from "react";
import { getTraineeByID, updateTraineeDetails } from "../../../network/network";
import MainContext from "../../../Context/AppContext";
import {
  Form,
  Input,
  Select,
  Row,
  // Radio,
  Tooltip,
  Col,
  Button,
  notification,
  Modal,
  message,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
// const { Option } = Select;

class EditTrainee extends React.Component {
  formRef = React.createRef();
  state = {
    user_details: {},
  };
  static contextType = MainContext;
  onFinish = (values) => {
    values._id = this.props.data_id;
    updateTraineeDetails(values, this.context.authDetails?.token)
      .then(() => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Trainee Updated",
          description: "The trainee has been updated successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          let errorMessages = Object.values(error.response.data.message);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      });
  };

  getTraineeDetails = (userID) => {
    getTraineeByID(userID, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
        visible: true,
        user_details: res.data,
      });
      if (res.data && this.formRef.current) {
        this.formRef.current.setFieldsValue({
          ...res.data,
        });
      }
    });
  };

  showModal = () => {
    this.getTraineeDetails(this.props.data_id);
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
    this.formRef.current.resetFields();
  };

  onFinishFailed = (errorInfo) => {};
  render() {
    return (
      <div>
        <Tooltip placement="bottom" title={"Edit Trainees"}>
          <Button
            onClick={this.showModal}
            type="primary"
            icon={<FormOutlined />}
          />
        </Tooltip>
        <Modal
          title="Edit Trainee"
          visible={this.state.visible}
          width={2000}
          centered
          onCancel={this.hideModal}
          okText="Create"
          cancelText="Cancel"
          footer={[
            <Button
              form={"myForm" + this.state.user_details._id}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Update
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id={"myForm" + this.state.user_details._id}
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your firstname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your lastname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="userName"
                  label="User Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your user name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {this.props.hierarchy_data &&
                  this.props.hierarchy_data.length > 0 && (
                    <Form.Item
                      name="hierarchy_id"
                      label="Hierarchy"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select placeholder="Select Trainee Hierarchy">
                        <Select.Option value={null}>
                          Not Identified
                        </Select.Option>
                        {this.props.hierarchy_data.map((item, index) => (
                          <Select.Option value={item._id} key={index}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="contactNo"
                  label="Phone Number"
                  rules={[
                    {
                      required: false,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="address"
                  label="Address"
                  tooltip="Provide your complete address?"
                  rules={[
                    {
                      required: false,
                      message: "Please input your address!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: false,
                      message: "Please input your city!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: false,
                      message: "Please input your state!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    {
                      required: false,
                      message: "Please input your country!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="zipCode"
                  label="Zipcode"
                  rules={[
                    {
                      required: false,
                      message: "Please input your zipcode!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="companyName"
                  label="Company"
                  rules={[
                    {
                      required: false,
                      message: "Please input your company!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default EditTrainee;
