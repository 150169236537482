import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Tag, Table, Col, Space, notification, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import MainContext from "../../../Context/AppContext";
import { CanadianTime } from "../../../helpers/helpers";
import { getContacts, deleteContactByID } from "../../../network/network";

class Contacts extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    columns: [
      {
        title: "#",
        width: "1%",
        align: "center",
        key: "index",
        render: (text, record, index) => {
          return (
            <p>
              {this.state.pagination.pageSize *
                (this.state.pagination.current - 1) +
                (index + 1)}
            </p>
          );
        },
      },
      {
        title: "Full Name",
        width: "7%",
        render: (record) => {
          return `${record.name}`;
        },
      },
      {
        title: "Email",
        width: "10%",
        dataIndex: "email",
      },
      {
        title: "Phone Number",
        width: "7%",
        align: "center",
        render: (record) => {
          return `${record.contactNo ? record.contactNo : "-"}`;
        },
      },
      {
        title: "Company",
        width: "10%",
        align: "center",
        render: (record) => {
          return (
            <span className="compData">
              {record.companyName ? record.companyName : "-"}
            </span>
          );
        },
      },
      {
        title: "Message",
        width: "25%",
        render: (record) => {
          return record.message ? (
            record.message.substring(0, 100) + "..."
          ) : (
            <span
              style={{ textAlign: "center", width: "100%", display: "block" }}
            >
              -
            </span>
          );
        },
      },

      {
        title: "Date",
        render: (record) => {
          return <p>{CanadianTime(record.createdAt)}</p>;
        },
      },
      {
        title: "Status",
        align: "center",
        render: (record) => {
          return (
            <Tag color={record.status ? "green" : "red"}>
              {record.status ? "Read" : "Not Read"}
            </Tag>
          );
        },
      },

      {
        title: "Action",
        dataIndex: "",
        align: "center",
        key: "x",
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              title="Are you sure to delete this Contact?"
              onConfirm={() => {
                this.onDelete(record._id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
            <Link to={"/view/contact/" + record._id}>Details</Link>
          </Space>
        ),
      },
    ],
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
  };
  static contextType = MainContext;
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  updateCategoryTable = () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  onDelete = (contactID) => {
    this.setState({ loading: true });
    deleteContactByID(contactID, this.context.authDetails?.token).then(
      (res) => {
        this.setState({
          loading: false,
        });

        notification.success({
          message: "Contact Deleted",
          description: "The contact has been deleted successfully.",
          placement: "topRight",
        });

        const { pagination } = this.state;
        this.fetch({ pagination });
      }
    );
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  fetch = (params = {}) => {
    this.setState({ loading: true });

    setTimeout(() => {
      getContacts(this.context.authDetails?.token).then((res) => {
        this.setState({
          loading: false,
          data: res.data,
          pagination: {
            ...params.pagination,
            total: res.data.totalCount,
          },
        });
      });
    }, 1000);
  };

  render() {
    const { loading } = this.state;
    const { columns } = this.state;
    const { data } = this.state;
    const { pagination } = this.state;

    return (
      <div>
        <Col className="dashboard-charts" span={24}>
          <h2>Recent Contacts</h2>
          <div className="table-box">
            <Table
              columns={columns}
              rowKey={(record) => record._id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              rowClassName={(record) => (record.status ? "" : "row-highlight")}
              onChange={this.handleTableChange}
            />
          </div>
        </Col>
      </div>
    );
  }
}

export default Contacts;
