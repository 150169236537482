import React from "react";
import {
  Button,
  message,
  Row,
  Modal,
  Col,
  notification,
  Select,
  Checkbox,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import ManagerAvailableCourses from "../../../components/Manager/ManagerAvailableCourses";
import ManagerTraineesProgress from "../../../components/Manager/ManagerTraineesProgress";
import ManagerStats from "../../../components/Manager/ManagerStats";
import CourseCertificateGenerate from "../../User/AssignCourses/CertificateLink";
import {
  getCourses,
  assignCourseToUser,
  unAssignCourseToUser,
  managerReAssignCourses,
  getUserByID,
  markCourseRegistrationFailed,
  createRegistration, // Register user in course
} from "../../../network/network";

import MainContext from "../../../Context/AppContext";
const { Option } = Select;
const confirm = Modal.confirm;
class AssignCourses extends React.Component {
  state = {
    allCourses: [],
    manager_available_courses: [],
    user_details: {},
    selectedCourse: [],
    data: [],
    loading: false,
  };
  static contextType = MainContext;
  componentDidMount() {
    if (
      this.context.authDetails &&
      (this.context.authDetails.userType === 1 ||
        this.context.authDetails.userType === 2)
    ) {
      this.getUserCourseDetails();
      this.fetch();
    }
  }

  markCourseAsFailed = (registration) => {
    if (registration.time != "0" && registration.time != "00:00:00") {
      let _this = this;
      markCourseRegistrationFailed(
        {
          registration_id: registration._id,
          user_id: this.props.match.params?.userid,
          failed_status: !registration.is_failed,
        },
        this.context.authDetails?.token
      )
        .then((res) => {
          notification.success({
            message: "Course Status",
            description: "The Course status has been updated successfully.",
            placement: "topRight",
          });
          _this.setState({
            user_details: {},
            data: [],
            manager_available_courses: [],
          });
          _this.getUserCourseDetails();
          _this.updateCourseTable();
        })
        .catch(() => {
          message.error("Some error has occurred ");
        });
    }
  };

  getUserCourseDetails = () => {
    let userType = "";
    if (this.context.authDetails.userType === 1) {
      userType = "admin";
    } else if (this.context.authDetails.userType === 2) {
      userType = "manager";
    } else if (this.context.authDetails.userType === 4) {
      userType = "user";
    }
    getUserByID(
      userType,
      this.props.match.params?.userid,
      this.context.authDetails?.token
    )
      .then((res) => {
        let user_courses = res.data.courses.filter(
          (course) => course.courseId && course.course_assign_status
        );

        this.setState({
          user_details: res.data,
          data: user_courses,
        });
      })
      .catch(() => {
        message.error("Some error has occurred ");
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  unAssingCourse = (courseProgressID) => {
    confirm({
      title: "Course Assignment",
      content: "Are you sure want to unassign that course?",
      okText: "OK",
      onOk: () => {
        this.setState({ loading: true });
        unAssignCourseToUser(
          this.props.match.params?.userid,
          courseProgressID,
          this.context.authDetails?.token
        )
          .then((res) => {
            this.setState({
              loading: false,
              data: [],
            });
            this.getUserCourseDetails();
            this.updateCourseTable();

            notification.success({
              message: "Course Unassigned",
              description: "The Course has been unassigned successfully.",
              placement: "topRight",
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            if (err.response && err.response.status === 404) {
              message.error("Something went wrong");
            }
          });
      },
    });
  };

  updateCourseTable = () => {
    this.setState({
      allCourses: [],
      selectedCourse: [],
    });

    this.fetch();
  };

  managerCoursesAssignToTrainee = (courses) => {
    if (courses.length === 0) {
      message.error("Please select courses.");
      return false;
    } else {
      confirm({
        title: "Course Assignment",
        content: "Are you sure want to assign that course?",
        okText: "OK",
        onOk: () => {
          this.setState({ loading: true });
          let _this = this;
          managerReAssignCourses(
            { course_id: courses, trainee_id: this.props.match.params?.userid },
            this.context.authDetails?.token
          )
            .then((res) => {
              if (res.data && res.data.assigned_course) {
                createRegistration(
                  this.state.user_details, //user id
                  res.data.assigned_course.courseId.scorm_course_id, //course id
                  res.data.assigned_course._id //registration id
                )
                  .then((res) => {
                    _this.getUserCourseDetails();
                    _this.updateCourseTable();
                    notification.success({
                      message: "Course Assigned",
                      description:
                        "The Course has been assigned to trainee successfully.",
                      placement: "topRight",
                    });
                  })
                  .catch((err) => {
                    message.error(err.response.data.error);
                  })
                  .finally(() => {
                    this.setState({
                      loading: false,
                    });
                  });
              }
            })
            .catch((err) => {
              message.error(
                "Some error has occurred while assigning courses to trainees."
              );
            });
        },
      });
    }
  };

  coursesAssignToUser = (courses) => {
    if (courses.length === 0) {
      message.error("Please select courses.");
      return false;
    }
    confirm({
      title: "Course Assignment",
      content: "Are you sure want to assign that course?",
      okText: "OK",
      onOk: () => {
        this.setState({ loading: true });
        let allcourses = this.state.allCourses;
        let user_details = this.state.user_details;

        let courseDetails = {};
        let _this = this;
        assignCourseToUser(
          this.props.match.params?.userid,
          courses,
          this.context.authDetails?.token
        ).then((res) => {
          let userCourseArr = res.data;
          userCourseArr.forEach((userCourse) => {
            courseDetails = allcourses.find(
              (course) => course._id === userCourse.courseId
            );

            if (courseDetails && courseDetails.scorm_course_id !== "") {
              createRegistration(
                user_details, //user id
                courseDetails.scorm_course_id, //course id
                userCourse._id //registration id
              )
                .then((res) => {})
                .catch((err) => {
                  message.error(err.response.data.error);
                });
            }
          });
          _this.getUserCourseDetails();
          _this.updateCourseTable();
          notification.success({
            message: "Course Assigned",
            description: "The Course has been assigned successfully.",
            placement: "topRight",
          });
          this.setState({
            loading: false,
          });
        });
      },
    });
  };

  courseAssign = () => {
    let courses = this.state.selectedCourse;
    let _this = this;
    if (courses.length === 0) {
      message.error("No courses has been selected");
    } else {
      // if admin is assigning course
      if (this.context.authDetails.userType === 1) {
        _this.coursesAssignToUser(courses);

        // if manager is assigning course
      } else if (this.context.authDetails.userType === 2) {
        this.managerCoursesAssignToTrainee(courses);
      }
    }
  };

  fetch = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      let userType = "";
      if (this.context.authDetails.userType === 1) {
        userType = "admin";
      } else if (this.context.authDetails.userType === 2) {
        userType = "manager";
      } else if (this.context.authDetails.userType === 4) {
        userType = "user";
      }

      getCourses(userType, this.context.authDetails?.token).then((res) => {
        if (userType === "manager") {
          let manager_available_courses = [];
          let manager_courses = [...res.data];

          manager_courses.map((obj) => {
            if (
              obj.courseId &&
              obj.quantity > obj.assignCount &&
              obj.courseId.portal_type == 1
            ) {
              manager_available_courses.push({
                title: obj.courseId.title,
                _id: obj.courseId._id,
                scorm_course_id: obj.courseId.scorm_course_id,
                sku: obj.courseId.sku,
                slug: obj.courseId.slug,
                portal_type: obj.courseId.portal_type,
              });
            }
          });

          this.setState({
            loading: false,
            manager_available_courses: manager_available_courses,
          });
        } else if (userType === "admin") {
          this.setState({
            loading: false,
            allCourses: res.data,
          });
        }
      });
    }, 1000);
  };

  searchCourseChange = (value) => {
    this.setState({
      selectedCourse: value,
    });
  };
  copyCodeToClipboard = (val) => {
    const tempInput = document.createElement("input");
    tempInput.value = val;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    message.success("Copied!");
  };
  render() {
    const { allCourses, manager_available_courses } = this.state;

    return (
      <div>
        {this.state.user_details && this.state.user_details.userType === 2 && (
          <ManagerStats manager_id={this.state.user_details._id} />
        )}

        <div className="user-profile">
          <Row>
            <Col md={10} sm={24} className="user-profile-details ind-dashboard">
              <Row>
                <Col span="22">
                  {this.state.user_details &&
                    this.state.user_details.userType === 4 && (
                      <h2 className="card-heading">Trainee Profile</h2>
                    )}
                  {this.state.user_details &&
                    this.state.user_details.userType === 2 && (
                      <h2 className="card-heading">Manager Profile</h2>
                    )}
                  {this.state.user_details &&
                    this.state.user_details.userType === 1 && (
                      <h2 className="card-heading">Admin Profile</h2>
                    )}
                </Col>
                <Col span="2">
                  {/* <Button className="edit-profile-btn">
                  <FormOutlined />
                </Button> */}
                </Col>
              </Row>
              <div style={{ width: "100%" }}>
                {this.state.user_details?.firstName &&
                  this.state.user_details?.lastName && (
                    <div className="field-wrapper">
                      <label className="label">Full Name</label>
                      <p className="details">
                        {this.state.user_details?.firstName}{" "}
                        {this.state.user_details?.lastName}
                      </p>
                    </div>
                  )}
                {this.state.user_details?.email && (
                  <div className="field-wrapper">
                    <label className="label">Email Address</label>
                    <p className="details">{this.state.user_details?.email}</p>
                  </div>
                )}
                {this.state.user_details?.contactNo && (
                  <div className="field-wrapper">
                    <label className="label">Phone Number</label>
                    <p className="details">
                      {this.state.user_details?.contactNo}
                    </p>
                  </div>
                )}
                {this.state.user_details?.companyName && (
                  <div className="field-wrapper">
                    <label className="label">Company Name</label>
                    <p className="details">
                      {this.state.user_details?.companyName}
                    </p>
                  </div>
                )}
                {this.state.user_details?.userName && (
                  <div className="field-wrapper">
                    <label className="label">UserName</label>
                    <div className="p-wrapper">
                      <p className="details">
                        {this.state.user_details?.userName}
                      </p>
                      <span
                        onClick={() =>
                          this.copyCodeToClipboard(
                            this.state.user_details?.userName
                          )
                        }
                        className="ctc-btn"
                      >
                        Click To Copy
                      </span>
                    </div>
                  </div>
                )}
                {this.state.user_details?.password && (
                  <div className="field-wrapper">
                    <label className="label">Password</label>
                    <div className="p-wrapper">
                      <p className="details">
                        {this.state.user_details?.password}
                      </p>
                      <span
                        onClick={() =>
                          this.copyCodeToClipboard(
                            this.state.user_details?.password
                          )
                        }
                        className="ctc-btn"
                      >
                        Click To Copy
                      </span>
                    </div>
                  </div>
                )}
                {this.state.user_details?.address && (
                  <div className="field-wrapper">
                    <label className="label">Complete Address</label>
                    <p className="address-field details">
                      {this.state.user_details?.address},{" "}
                      {this.state.user_details?.city},{" "}
                      {this.state.user_details?.state},{" "}
                      {this.state.user_details?.country} -{" "}
                      {this.state.user_details?.zipCode}
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col
              offset="1"
              md={13}
              sm={24}
              className="user-course-details ind-dashboard"
            >
              <h2 className="card-heading">Assigned Course(s) </h2>

              {this.context.authDetails &&
                this.context.authDetails.userType === 1 && (
                  <>
                    <div className="admin-userpage-search">
                      <h3>Search By Course Name</h3>
                      <div className="search-form-wrapper">
                        <Select
                          showSearch
                          value={this.state.selectedCourse}
                          placeholder="Search by Course name"
                          optionFilterProp="children"
                          mode="multiple"
                          onChange={this.searchCourseChange}
                        >
                          {this.context.authDetails.userType === 1 &&
                            allCourses &&
                            allCourses.map((obj) => (
                              <Option value={obj._id} key={obj._id}>
                                {obj.title}
                              </Option>
                            ))}
                        </Select>
                        <Button type="primary" onClick={this.courseAssign}>
                          Assign Course
                        </Button>
                      </div>
                    </div>
                  </>
                )}

              {/* Availabe courses for manager dropdown HTML */}
              {this.context.authDetails &&
                this.context.authDetails.userType === 2 &&
                manager_available_courses.length > 0 && (
                  <>
                    <div className="admin-userpage-search">
                      <h3>Search By Course Name</h3>
                      <div className="search-form-wrapper">
                        <Select
                          showSearch
                          value={this.state.selectedCourse}
                          placeholder="Search by Course name"
                          optionFilterProp="children"
                          mode="single"
                          onChange={this.searchCourseChange}
                        >
                          {manager_available_courses.map((obj) => (
                            <Option value={obj._id} key={obj._id}>
                              {obj.title}
                            </Option>
                          ))}
                        </Select>
                        <Button type="primary" onClick={this.courseAssign}>
                          Assign Course
                        </Button>
                      </div>
                    </div>
                  </>
                )}

              {/*End of Availabe courses for manager dropdown HTML */}

              <div
                className={`courses-wrapper ${
                  Object.keys(this.state.user_details).length > 0 &&
                  this.state.data &&
                  this.state.data.length > 0
                    ? ""
                    : "no-courses"
                } `}
              >
                {Object.keys(this.state.user_details).length > 0 &&
                this.state.data &&
                this.state.data.length > 0 ? (
                  <>
                    {this.state.data.map((obj, i) => {
                      return (
                        <div className="course-box" key={i}>
                          <Row>
                            <Col span={24} xl={8}>
                              <div className="course-img">
                                <img
                                  src={`${obj.courseId.thumbnailImage.path}`}
                                  alt={obj.courseId.title}
                                />
                              </div>
                            </Col>
                            <Col span={24} xl={16}>
                              <div className="course-desc">
                                <Row>
                                  <Col span={24} md={18}>
                                    <h2 className="course-title">
                                      {obj.courseId.title}
                                    </h2>
                                    <Row className="course-points">
                                      {/* Progress(%) */}
                                      <Col span={12} md={10}>
                                        <label>Progress: </label>
                                      </Col>
                                      <Col span={12} md={14}>
                                        {obj.is_failed && (
                                          <p
                                            style={{
                                              color: "red",
                                              fontWeight: "600",
                                            }}
                                          >
                                            FAILED
                                          </p>
                                        )}
                                        {!obj.is_failed &&
                                          obj.completion === "COMPLETED" && (
                                            <p
                                              style={{
                                                color: "green",
                                                fontWeight: "600",
                                              }}
                                            >
                                              COMPLETED
                                            </p>
                                          )}
                                        {!obj.is_failed &&
                                          (obj.time === "0" ||
                                            obj.time === "00:00:00") && (
                                            <p
                                              style={{
                                                color: "#82868e",
                                                fontWeight: "600",
                                              }}
                                            >
                                              NOT STARTED
                                            </p>
                                          )}
                                        {!obj.is_failed &&
                                          obj.time !== "0" &&
                                          obj.time !== "00:00:00" &&
                                          obj.completion !== "COMPLETED" && (
                                            <p
                                              style={{
                                                color: "orange",
                                                fontWeight: "600",
                                              }}
                                            >
                                              IN PROGRESS
                                            </p>
                                          )}
                                      </Col>
                                      {/* Start Date */}
                                      <Col span={12} md={10}>
                                        <label>Start Date:</label>
                                      </Col>
                                      <Col span={12} md={14}>
                                        {obj.firstAccessDate === "" ? (
                                          <p>-</p>
                                        ) : (
                                          <p>{obj.firstAccessDate}</p>
                                        )}
                                      </Col>
                                      {/* Completion Date */}
                                      <Col span={12} md={10}>
                                        <label>Completion Date:</label>
                                      </Col>
                                      <Col span={12} md={14}>
                                        {obj.completion === "COMPLETED" ? (
                                          <p>{obj.lastAccessDate}</p>
                                        ) : (
                                          <p>-</p>
                                        )}
                                      </Col>
                                      {/* Time Elapsed */}
                                      <Col span={12} md={10}>
                                        <label>Time Elapsed:</label>
                                      </Col>
                                      <Col span={12} md={14}>
                                        <p>
                                          {obj.time !== "00:00:00"
                                            ? new Date(obj.time * 1000)
                                                .toISOString()
                                                .substr(11, 8)
                                            : "00:00:00"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  {obj.certificate_link && (
                                    <Col
                                      span={24}
                                      style={{ textAlign: "right" }}
                                    >
                                      <a
                                        href={obj.certificate_link}
                                        className="download-certificate"
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        Download Certificate
                                      </a>
                                    </Col>
                                  )}
                                  {this.context.authDetails &&
                                    this.context.authDetails.userType === 1 && (
                                      <Col span={24} md={6}>
                                        <Button
                                          className="remove-course"
                                          onClick={() => {
                                            this.unAssingCourse(obj._id);
                                          }}
                                        >
                                          <DeleteOutlined />
                                        </Button>

                                        <CourseCertificateGenerate
                                          data_id={obj._id}
                                          user_id={this.state.user_details._id}
                                          certificate_link={
                                            obj.certificate_link
                                          }
                                          onChildClick={this.updateCourseTable}
                                        />
                                      </Col>
                                    )}
                                  {this.context.authDetails.userType === 1 && (
                                    <Col span={24} md={24} align="right">
                                      <div className="checkboxWrapper">
                                        <Checkbox
                                          checked={obj.is_failed}
                                          onChange={() =>
                                            this.markCourseAsFailed(obj)
                                          }
                                        >
                                          <b>Mark as Failed</b>
                                        </Checkbox>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <p>No Courses</p>
                )}
              </div>
            </Col>

            {this.state.user_details && this.state.user_details.userType === 2 && (
              <Col
                className="dashboard-charts"
                xs={24}
                md={10}
                sm={24}
                lg={10}
                xl={10}
              >
                <ManagerAvailableCourses
                  manager_id={this.state.user_details._id}
                />
              </Col>
            )}

            {this.state.user_details && this.state.user_details.userType === 2 && (
              <Col
                className="dashboard-charts"
                xs={24}
                offset="1"
                md={13}
                sm={24}
                lg={13}
                xl={13}
              >
                <ManagerTraineesProgress
                  manager_id={this.state.user_details._id}
                />
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  }
}

export default AssignCourses;
