import React from "react";
import {
  Table,
  Button,
  InputNumber,
  Row,
  Input,
  Col,
  Modal,
  notification,
  // Select,
  Space,
  message,
} from "antd";
import Highlighter from "react-highlight-words";
// import {

//   DownloadOutlined,
//   DeleteOutlined
// } from "@ant-design/icons";

// import CourseCertificateGenerate from "../../User/AssignCourses/CertificateLink";
import { SearchOutlined } from "@ant-design/icons";

import {
  getCourses,
  // assignCourseToUser,
  addManagerCourses,
  // unAssignCourseToUser,
  getUserByID,
  // createRegistration, // Register user in course
} from "../../../network/network";

import MainContext from "../../../Context/AppContext";
// const { Option } = Select;
const confirm = Modal.confirm;
class AssignCourses extends React.Component {
  state = {
    selectedRowKeys: [],
    user_details: {},
    searchText: "",
    searchedColumn: "",
    selectedCourses: [],
    courses: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },

    loading: false,

    selected_courses_table_columns: [
      {
        title: "#",
        key: "index",
        // width: '5%',
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Course Name",
        // width: '15%',
        render: (record) => {
          return <p>{record.title || "-"}</p>;
        },
      },
      {
        title: "Quantity",
        // width: '15%',
        render: (record) => {
          return (
            <InputNumber
              size={"large"}
              type="number"
              min={1}
              defaultValue={record.quantity}
              onChange={(e) => (record.quantity = e)}
            />
          );
        },
      },

      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="danger"
              onClick={() => {
                this.removeSelectedCourse(record);
              }}
            >
              REMOVE
            </Button>
          </Space>
        ),
      },
    ],
  };
  static contextType = MainContext;
  componentDidMount() {
    const { pagination } = this.state;
    if (this.context.authDetails && this.context.authDetails.userType === 1) {
      this.getUserCourseDetails();
      this.getAllCourses({ pagination });
    }
  }
  addCourseToManager = () => {
    confirm({
      title: "Course Assignment",
      content: `Are you sure want to assign these ${this.state.selectedCourses.length} courses?`,
      okText: "OK",
      onOk: () => {
        this.setState({ loading: true });
        addManagerCourses(
          this.props.match.params?.userid,
          this.state.selectedCourses,
          this.context.authDetails?.token
        )
          .then((res) => {
            this.setState({ selectedCourses: [] });
            notification.success({
              message: ` Course Assigned`,
              description: "The Course has been assigned successfully.",
              placement: "topRight",
            });
          })
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              message.error(err.response.data.message);
            } else {
              message.error("Some Error has occurred");
            }
          })
          .finally((err) => {
            this.setState({ loading: false });
          });
      },
    });
  };

  removeSelectedCourse = (courseData) => {
    let courses = [...this.state.selectedCourses];
    let newCourses = courses.filter(
      (item) => item.course_id !== courseData.course_id
    );
    this.setState({ selectedCourses: newCourses });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    selectedKeys[0] = selectedKeys[0].trim();
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  moveCourseToSelectedCourse = (course) => {
    let courses = [...this.state.selectedCourses];
    let itemIndex = courses.findIndex((item) => item.course_id === course._id);
    if (itemIndex !== -1) {
      courses[itemIndex].quantity++;
    } else {
      courses.push({
        title: course.title,
        quantity: 1,
        course_id: course._id,
      });
    }
    this.setState({ selectedCourses: courses });
  };

  getUserCourseDetails = () => {
    let userType = "";
    if (this.context.authDetails.userType === 1) {
      userType = "admin";
    } else if (this.context.authDetails.userType === 2) {
      userType = "manager";
    } else if (this.context.authDetails.userType === 4) {
      userType = "user";
    }
    getUserByID(
      userType,
      this.props.match.params?.userid,
      this.context.authDetails?.token
    )
      .then((res) => {
        let user_courses = res.data.courses.filter(
          (course) => course.courseId && course.course_assign_status
        );

        this.setState({
          selectedRowKeys: res.data.courses,
          user_details: res.data,
          data: user_courses,
        });
      })
      .catch(() => {
        message.error("Some error has occurred ");
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getAllCourses = (params = {}) => {
    this.setState({ loading: true });
    setTimeout(() => {
      let userType = "";
      if (this.context.authDetails.userType === 1) {
        userType = "admin";
      } else if (this.context.authDetails.userType === 2) {
        userType = "manager";
      } else if (this.context.authDetails.userType === 4) {
        userType = "user";
      }

      getCourses(userType, this.context.authDetails?.token).then((res) => {
        this.setState({
          loading: false,
          courses: res.data,
          pagination: {
            ...params.pagination,
            total: res.data.totalCount,
          },
        });
      });
    }, 1000);
  };
  copyCodeToClipboard = (val) => {
    const tempInput = document.createElement("input");
    tempInput.value = val;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    message.success("Copied!");
  };
  render() {
    const {
      loading,
      courses,
      selectedCourses,
      selected_courses_table_columns,
    } = this.state;

    const courses_table_columns = [
      {
        title: "#",
        key: "index",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Course Name",
        dataIndex: "title",
        key: "title",
        ...this.getColumnSearchProps("title"),
      },

      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => {
                this.moveCourseToSelectedCourse(record);
              }}
            >
              ADD
            </Button>
          </Space>
        ),
      },
    ];

    return (
      <div className="user-profile">
        <Row style={{ margin: "0px 0" }} gutter={30}>
          <Col md={24} sm={24} className="user-profile-details ind-dashboard">
            <Row>
              <Col span="22">
                <h2 className="card-heading">Manager Profile</h2>
              </Col>
              <Col span="2">
                {/* <Button className="edit-profile-btn">
                  <FormOutlined />
                </Button> */}
              </Col>
            </Row>
            <div style={{ width: "100%" }}>
              <Row gutter={30}>
                <>
                  {this.state.user_details?.firstName &&
                    this.state.user_details?.lastName && (
                      <Col span={12}>
                        <div className="field-wrapper">
                          <label className="label">Full Name</label>
                          <p className="details">
                            {this.state.user_details?.firstName}{" "}
                            {this.state.user_details?.lastName}
                          </p>
                        </div>
                      </Col>
                    )}

                  {this.state.user_details?.userName && (
                    <Col span={12}>
                      <div className="field-wrapper">
                        <label className="label">UserName</label>
                        <div className="p-wrapper">
                          <p className="details">
                            {this.state.user_details?.userName}
                          </p>
                          <span
                            onClick={() =>
                              this.copyCodeToClipboard(
                                this.state.user_details?.userName
                              )
                            }
                            className="ctc-btn"
                          >
                            Click To Copy
                          </span>
                        </div>
                      </div>
                    </Col>
                  )}
                  {this.state.user_details?.email && (
                    <Col span={12}>
                      <div className="field-wrapper">
                        <label className="label">Email Address</label>
                        <p className="details">
                          {this.state.user_details?.email}
                        </p>
                      </div>
                    </Col>
                  )}
                  {this.state.user_details?.password && (
                    <Col span={12}>
                      <div className="field-wrapper">
                        <label className="label">Password</label>
                        <div className="p-wrapper">
                          <p className="details">
                            {this.state.user_details?.password}
                          </p>
                          <span
                            onClick={() =>
                              this.copyCodeToClipboard(
                                this.state.user_details?.password
                              )
                            }
                            className="ctc-btn"
                          >
                            Click To Copy
                          </span>
                        </div>
                      </div>
                    </Col>
                  )}
                </>
              </Row>
            </div>
          </Col>

          <Col className="dashboard-charts ind-dashboard" span={12}>
            <h2>Available Courses</h2>
            <div className="table-box">
              <Table
                columns={courses_table_columns}
                rowKey={(record) => record._id}
                dataSource={courses}
                loading={loading}
              />
            </div>
          </Col>
          <Col className="dashboard-charts ind-dashboard" span={12}>
            <h2>Selected Courses</h2>
            <div className="table-box">
              <Table
                columns={selected_courses_table_columns}
                rowKey={(record) => record._id}
                dataSource={selectedCourses}
                loading={loading}
                pagination={false}
              />
              <div
                className="button-wrapper"
                style={{ display: "block", margin: "30px 0" }}
              >
                <Button
                  disabled={this.state.selectedCourses.length === 0}
                  onClick={() => this.addCourseToManager()}
                >
                  Assign Courses
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AssignCourses;
