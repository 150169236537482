import React from "react";
import {
  Table,
  notification,
  Col,
  Row,
  Space,
  Tag,
  // Popconfirm,
  Button,
} from "antd";
import {
  getBlogCategories,
  deleteBlogCategory,
} from "../../../network/network";
import { EyeOutlined } from "@ant-design/icons";
import MainContext from "../../../Context/AppContext";
import AddBlogCategory from "./add";
import EditBlogCategory from "./edit";

class BlogCategories extends React.Component {
  state = {
    categories: [],
    columns: [
      {
        title: "#",
        key: "index",
        width: "5%",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Title",
        width: "20%",
        render: (record) => {
          return <b>{record?.name || "-"}</b>;
        },
      },

      {
        title: "Slug",
        render: (record) => {
          return (
            <a
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_BASE_URL}/articles/categories/${record.slug}`}
            >
              {record?.slug}
            </a>
          );
        },
      },

      {
        title: "Status",

        render: (record) => {
          return (
            <Tag color={record.status === 1 || record.status ? "green" : "red"}>
              {record.status || record.status === 1 ? "Published" : "Draft"}
            </Tag>
          );
        },
      },

      {
        title: "Action",
        render: (text, record) => (
          <div className="">
            <Space size="middle">
              <EditBlogCategory
                data_id={record._id}
                type={"edit"}
                categories={this.state.categories}
                onChildClick={this.updateBlogsTable.bind(this)}
              />

              {/* <Popconfirm
                title="Are you sure to delete this blog?"
                onConfirm={() => {
                  this.onDelete(record);
                }}
                onCancel={this.notDeleteUserMessage}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />} type="danger" />
              </Popconfirm> */}

              <Button type="dashed">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_BASE_URL}/articles/categories/${record.slug}`}
                >
                  <EyeOutlined />
                </a>
              </Button>
            </Space>
          </div>
        ),
      },
    ],
    blogs: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  };

  static contextType = MainContext;

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  onDelete = (record) => {
    this.setState({ loading: true });
    deleteBlogCategory(record._id, this.context.authDetails?.token).then(
      (res) => {
        this.setState({
          loading: false,
        });

        notification.success({
          message: "Blog Category Deleted",
          description: "The blog Category has been deleted successfully.",
          placement: "topRight",
        });

        const { pagination } = this.state;
        this.fetch({ pagination });
      }
    );
  };
  updateBlogsTable = () => {
    notification.success({
      message: "Blog Category Updated",
      description: "The blog Category has been updated successfully.",
      placement: "topRight",
    });
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });

    getBlogCategories(this.context.authDetails?.token)
      .then((res) => {
        this.setState({
          blogs: res.data,
          pagination: {
            ...params.pagination,
            total: res.data.totalCount,
          },
        });
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { blogs, columns, loading } = this.state;

    return (
      <>
        <Row gutter={30}>
          <Col className="dashboard-charts ind-dashboard" span={24}>
            <h2>My Blog Categories</h2>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ padding: 0 }}>
              <AddBlogCategory
                onChildClick={this.updateBlogsTable.bind(this)}
                categories={this.state.categories}
              />
            </Col>
            <div className="table-box">
              <Table
                className="blogcat-table"
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={blogs ? blogs : []}
                loading={loading}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

// };
export default BlogCategories;
// export default withRouter(BlogCategories)
