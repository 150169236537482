import React from "react";
import FileUpload from "../../../components/FileUpload/FileUpload";
import CKEditor from "ckeditor4-react";
import {
  getCourseByID,
  updateCourse,
  getChildCategories,
} from "../../../network/network";
import { LoadingOutlined, FormOutlined } from "@ant-design/icons";
import MainContext from "../../../Context/AppContext";
import {
  Spin,
  Form,
  Input,
  Select,
  Row,
  Col,
  Radio,
  Button,
  Checkbox,
  // message,
  Image,
  // notification,
  Modal,
  message,
} from "antd";
const { TextArea } = Input;
const { Option } = Select;

class EditCourse extends React.Component {
  formRef = React.createRef();
  state = {
    loading: false,
    visible: false,
    // layout: {
    //   labelCol: {
    //     span: 8,
    //   },
    //   wrapperCol: {
    //     span: 16,
    //   },
    // },
    // tailLayout: {
    //   wrapperCol: {
    //     offset:3,
    //     span: 24,
    //   },
    // },
    courseScormId: "",
    course_details: {},
    thumbnailCourse: "",
    faqInputList: [{ question: "", answer: "" }],
    courseFieldObj: {},
    categories: [],
    thumbnailImageId: "",
    thumbnailImagePath: "",

    ogImageId: "",
    ogImagePath: "",

    bannerImageId: "",

    courseDescription: "",
    courseOutlines: "",
    courseObjectives: "",
    courseFeatures: "",
    courseAudience: "",
    durationValidation: false,
  };
  static contextType = MainContext;

  handleThumbnailImageID = (thumbnailImageID, thumbnailImagePath) => {
    this.setState({
      thumbnailImageId: thumbnailImageID,
      thumbnailImagePath: thumbnailImagePath,
    });
  };

  handleCourseOGImageID = (courseOGid, courseOGpath) => {
    this.setState({
      ogImageId: courseOGid,
      ogImagePath: courseOGpath,
    });
  };

  // handle input change
  handleFaqInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.faqInputList];
    list[index][name] = value;
    this.setState({ faqInputList: list });
  };

  // handle click event of the Remove button
  handleFaqRemoveClick = (index) => {
    const list = [...this.state.faqInputList];
    list.splice(index, 1);
    this.setState({ faqInputList: list });
  };

  // handleFaq click event of the Add button
  handleFaqAddClick = () => {
    this.setState({
      faqInputList: [...this.state.faqInputList, { question: "", answer: "" }],
    });
  };

  generateSlug = (e) => {
    let title = e.target.value
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");

    this.formRef.current.setFieldsValue({
      // ...res.data,
      slug: title,
    });

    // return title
    //
  };

  courseAudienceUpdate = (evt) => {
    this.setState({
      courseAudience: evt.editor.getData(),
    });
  };

  courseDescriptionUpdate = (evt) => {
    this.setState({
      courseDescription: evt.editor.getData(),
    });
  };

  courseObjectivesUpdate = (evt) => {
    this.setState({
      courseObjectives: evt.editor.getData(),
    });
  };

  courseOutlineUpdate = (evt) => {
    this.setState({
      courseOutlines: evt.editor.getData(),
    });
  };
  courseFeaturesUpdate = (evt) => {
    this.setState({
      courseFeatures: evt.editor.getData(),
    });
  };

  onFinish = (values) => {
    if (values.minutes > 0 || values.hours > 0) {
      this.setState({
        durationValidation: false,
      });
    } else {
      return this.setState({
        durationValidation: true,
      });
    }
    // if (this.state.courseScormId === "") {
    //     message.error("Upload Course File.");
    //     return false;
    //   }

    var faqs = this.state.faqInputList.filter(
      (faq) => faq.question !== "" && faq.answer !== ""
    );

    // Mold Remediation & Restoration For Supervisors And Managers For Construction

    let _this = this;
    const formData = new FormData();

    formData.append("thumbnailImage", this.state.thumbnailImageId);
    formData.append("ogImage", this.state.ogImageId);
    formData.append("courseType", "SCORM");
    if (this.state.courseScormId !== "") {
      formData.append("scorm_course_id", this.state.courseScormId);
    } else {
      formData.append(
        "scorm_course_id",
        this.state.course_details.scorm_course_id
      );
    }
    formData.append("category", values.category);
    formData.append("title", values.title);
    formData.append("search_terms", values.search_terms);
    formData.append("slug", values.slug);
    // formData.append("description",  this.state.courseDescription);
    formData.append("sibling_course_slug", values.sibling_course_slug);
    formData.append("includes_text", values.includes_text);
    formData.append("order", values.order);
    formData.append("price", values.price);
    formData.append("discounted_price", values.discounted_price);
    formData.append("hours", values.hours);
    formData.append("minutes", values.minutes || "0");
    formData.append("status", values.status);
    formData.append("portal_type", values.portal_type);
    formData.append("recommended", values.recommended ? true : false);
    formData.append("new_course", values.new_course ? true : false);
    formData.append("popular", values.popular ? true : false);
    formData.append("language", values.language);
    formData.append("level", values.level);
    formData.append(
      "ogImage",
      this.state.courseOGid ? this.state.courseOGid : ""
    );
    // formData.append("objectives", values.objectives);
    // formData.append("features", values.features);
    // formData.append("audience", values.audience);
    // formData.append("outline", values.outline);
    formData.append("objectives", this.state.courseObjectives);
    formData.append("outline", this.state.courseOutlines);
    formData.append("features", this.state.courseFeatures);
    formData.append("audience", this.state.courseAudience);
    formData.append("description", this.state.courseDescription);
    formData.append("excerpt", values.excerpt);

    formData.append("faqs", JSON.stringify(faqs));
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);
    this.setState({ loading: true });
    updateCourse(
      _this.state.course_details._id,
      formData,
      _this.context.authDetails?.token
    )
      .then((res) => {
        _this.hideModal();
        _this.props.onChildClick(res.data);
        // notification.success({
        //   message: "Course Updated",
        //   description: "The course has been updated successfully.",
        //   placement: "topRight",
        // });
        if (_this.formRef.current) {
          _this.formRef.current.resetFields();
        }
        values = "";
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getCourseDetails = (courseID) => {
    getChildCategories(this.context.authDetails?.token).then((res) => {
      this.setState({
        categories: res.data,
      });
    });

    getCourseByID(courseID, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
        visible: true,

        courseDescription: res.data.description,
        courseOutlines: res.data.outline,
        courseObjectives: res.data.objectives,
        courseFeatures: res.data.features,
        courseAudience: res.data.audience,
        // categories: this.props.categories,
        faqInputList:
          res.data.faqs.length > 0
            ? res.data.faqs
            : [{ question: "", answer: "" }],
        course_details: res.data,
        thumbnailImageId: res.data.thumbnailImage
          ? res.data.thumbnailImage._id
          : "",
        thumbnailImagePath: res.data.thumbnailImage
          ? res.data.thumbnailImage.path
          : "",
        ogImageId: res.data.ogImage ? res.data.ogImage._id : "",
        ogImagePath: res.data.ogImage ? res.data.ogImage.path : "",
        bannerImageId: res.data.bannerImage ? res.data.bannerImage._id : "",
      });

      if (res.data.category) {
        res.data.category = res.data.category._id;
      }

      if (res.data && this.formRef.current) {
        this.formRef.current.setFieldsValue({
          ...res.data,
        });
      }

      // }
    });
  };
  showModal = () => {
    this.getCourseDetails(this.props.data_id);
  };
  handleCourseFileObj = (courseObj, courseID) => {
    this.setState({ courseFieldObj: courseObj, courseScormId: courseID });
  };
  hideModal = () => {
    this.setState({
      visible: false,
    });
    this.formRef.current.resetFields();
  };

  onFinishFailed = (errorInfo) => {
    message.error("Please fill the required fields");
  };
  render() {
    const { course_details, faqInputList, durationValidation } = this.state;
    return (
      <div>
        <Button
          onClick={this.showModal}
          type="primary"
          icon={<FormOutlined />}
        />

        <Modal
          title="Edit Course"
          visible={this.state.visible}
          onOk={this.onFinish}
          width={2000}
          centered
          onCancel={this.hideModal}
          okText="Update"
          cancelText="Cancel"
          footer={[
            this.state.loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} />} />
            ) : (
              <Button
                form={"myForm" + course_details._id}
                type="primary"
                key="submit"
                htmlType="submit"
              >
                Update
              </Button>
            ),
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id={"myForm" + course_details._id}
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="title"
                  label="Title"
                  onChange={this.generateSlug}
                  rules={[
                    {
                      required: true,
                      message: "Please input course title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="slug"
                  label="Slug"
                  rules={[
                    {
                      required: true,
                      message: "Please input slug!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="search_terms"
                  label="Search terms"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="sibling_course_slug"
                  label="Alternate Course Slug"
                  rules={[
                    {
                      required: false,
                      message: "Please input alternate course slug!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="includes_text"
                  label="Course Include"
                  rules={[
                    {
                      required: false,
                      message: "Please input alternate course slug!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input course description",
                    },
                  ]}
                >
                  <CKEditor
                    data={this.state.course_details.description}
                    onChange={this.courseDescriptionUpdate}
                  />
                </Form.Item>
                <Form.Item
                  name="excerpt"
                  label="Excerpt"
                  rules={[
                    {
                      required: true,
                      max: 150,
                    },
                  ]}
                >
                  <Input type="text" max="150" />
                </Form.Item>

                <Form.Item
                  name="level"
                  label="Levels"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={1} key={1}>
                      Introductory
                    </Select.Option>
                    <Select.Option value={2} key={2}>
                      Intermediate
                    </Select.Option>
                    <Select.Option value={3} key={3}>
                      Advance
                    </Select.Option>
                    <Select.Option value={4} key={4}>
                      Certificate
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="language"
                  label="Language"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={1} key={1}>
                      English
                    </Select.Option>
                    <Select.Option value={2} key={2}>
                      Spanish
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="objectives"
                  label="Objectives"
                  rules={[
                    {
                      required: false,
                      message: "Please input course objectives!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <CKEditor
                    data={this.state.course_details.objectives}
                    onChange={this.courseObjectivesUpdate}
                  />
                </Form.Item>

                <Form.Item
                  name="features"
                  label="Features & Benefits"
                  rules={[
                    {
                      required: false,
                      message: "Please input course Features and benefits!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <CKEditor
                    data={this.state.course_details.features}
                    onChange={this.courseFeaturesUpdate}
                  />
                </Form.Item>

                <Form.Item
                  name="audience"
                  label="Audience"
                  rules={[
                    {
                      required: false,
                      message: "Please input course audience!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <CKEditor
                    data={this.state.course_details.audience}
                    onChange={this.courseAudienceUpdate}
                  />
                </Form.Item>

                <Form.Item
                  name="outline"
                  label="Outline"
                  rules={[
                    {
                      required: false,
                      message: "Please input Course outline!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <CKEditor
                    data={this.state.course_details.outline}
                    onChange={this.courseOutlineUpdate}
                  />
                </Form.Item>

                <div
                  className="ant-modal-title"
                  style={{ marginBottom: "20px" }}
                >
                  Course Duration
                </div>
                <div className="course-druation">
                  <div>
                    <Form.Item
                      name="hours"
                      label="Hours"
                      rules={[
                        {
                          required: true,
                          message: "Please input course hours!",
                          // whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          border: `${
                            durationValidation ? "1px solid red" : ""
                          }`,
                        }}
                        onChange={() =>
                          this.setState({ durationValidation: false })
                        }
                        type="number"
                        min="0"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      name="minutes"
                      label="Minutes"
                      rules={[
                        {
                          required: true,
                          message: "Please input course minutes!",
                          // whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          border: `${
                            durationValidation ? "1px solid red" : ""
                          }`,
                        }}
                        onChange={() =>
                          this.setState({ durationValidation: false })
                        }
                        type="number"
                        min="0"
                        max="60"
                      />
                    </Form.Item>
                  </div>
                </div>

                {/* 
                <Form.Item
                  name="duration"
                  label="Duration"
                  rules={[
                    {
                      required: true,
                      message: "Please input course duration!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item> */}

                <Form.Item
                  name="order"
                  label="Ordering"
                  rules={[
                    {
                      required: true,
                      message: "Please input integer value for ordering!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    {
                      required: true,
                      message: "Please input for price!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" step="0.01" />
                </Form.Item>

                <Form.Item
                  name="discounted_price"
                  label="Discounted Price"
                  rules={[
                    {
                      required: true,
                      message: "Please input for discounted price!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" step="0.01" />
                </Form.Item>

                <Form.Item label="Thumbnail Image">
                  {this.state.thumbnailImageId ? (
                    <Image width={200} src={this.state.thumbnailImagePath} />
                  ) : (
                    <Image
                      width={200}
                      src={
                        this.state.course_details &&
                        this.state.course_details.thumbnailImage
                          ? this.state.course_details.thumbnailImage["path"]
                          : "/no-image.png"
                      }
                    />
                  )}
                  <FileUpload
                    onCourseThumbnailID={this.handleThumbnailImageID}
                    imageUploadType="courseThumbnail"
                    type="image"
                  />
                </Form.Item>

                <Form.Item label="OG Image">
                  {this.state.ogImage ? (
                    <Image width={200} src={this.state.ogImagePath} />
                  ) : (
                    <Image
                      width={200}
                      src={
                        this.state.course_details &&
                        this.state.course_details.ogImage
                          ? this.state.course_details.ogImage["path"]
                          : "/no-image.png"
                      }
                    />
                  )}
                  <FileUpload
                    onCourseOgID={this.handleCourseOGImageID}
                    imageUploadType="courseOGImage"
                    type="image"
                  />
                </Form.Item>

                <Form.Item label="Course File">
                  {this.state.course_details &&
                  (this.state.course_details.scorm_course_id ==
                    this.state.course_details.sku ||
                    !this.state.course_details.scorm_course_id) ? (
                    <>
                      <b>No Course File Uploaded</b>
                      <FileUpload
                        onCourseFileObj={this.handleCourseFileObj}
                        imageUploadType="courseFile"
                        type="courseFile"
                      />
                    </>
                  ) : (
                    <>
                      <b>{this.state.course_details.scorm_course_id}</b>
                      <FileUpload
                        onCourseFileObj={this.handleCourseFileObj}
                        imageUploadType="courseFile"
                        type="courseFile"
                      />
                    </>
                  )}
                </Form.Item>

                <Form.Item
                  label="Categories"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please input Category!",
                      // whitespace: true,
                    },
                  ]}
                >
                  {/* <Select defaultValue="lucy" style={{ width: 200 }}>
                    <OptGroup label="Manager">
                      <Option value="jack">Jack</Option>
                      <Option value="lucy">Lucy</Option>
                    </OptGroup>
                    <OptGroup label="Engineer">
                      <Option value="Yiminghe">yiminghe</Option>
                    </OptGroup>
                  </Select> */}

                  <Select>
                    {this.state.categories.map((item, index) => (
                      <Option value={item._id} key={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="recommended" valuePropName="checked">
                  <Checkbox>Mark as Recommended Course</Checkbox>
                </Form.Item>

                <Form.Item name="new_course" valuePropName="checked">
                  <Checkbox>Mark as New Course</Checkbox>
                </Form.Item>

                <Form.Item name="popular" valuePropName="checked">
                  <Checkbox>Mark as Popular Course</Checkbox>
                </Form.Item>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Course status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup">
                    <Radio value={true}>Published</Radio>
                    <Radio value={false}>Unpublished</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="portal_type"
                  label="Portal type"
                  rules={[
                    {
                      required: true,
                      message: "Course portal type is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup">
                    <Radio value={1}>LMS</Radio>
                    <Radio value={2}>NON-LMS</Radio>
                  </Radio.Group>
                </Form.Item>

                <div
                  className="ant-modal-title"
                  style={{ marginBottom: "20px" }}
                >
                  Course SEO Details
                </div>

                <Form.Item
                  name="seo_title"
                  label="SEO Title"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO title!",
                      whitespace: true,
                      max: 120,
                      min: 30,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="SEO Description"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO description!",
                      whitespace: true,
                      max: 160,
                      min: 60,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="SEO Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input course SEO Keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>

              <div className="ant-modal-title" style={{ marginBottom: "20px" }}>
                Faq Section
              </div>
              {faqInputList.map((x, i) => {
                return (
                  <div className="box" key={i}>
                    <label>Question</label>
                    <TextArea
                      style={{ margin: "10px 0px" }}
                      placeholder="Enter Faq Question"
                      name="question"
                      onChange={(e) => this.handleFaqInputChange(e, i)}
                      value={x.question}
                    />
                    {/* <input
                    
                      name="question"
                      placeholder="Enter First Name"
                      value={x.question}
                      onChange={(e) => this.handleFaqInputChange(e, i)}
                    /> */}
                    <label>Answer</label>
                    <TextArea
                      style={{ margin: "10px 0px" }}
                      className="ml10"
                      name="answer"
                      placeholder="Enter Faq Answer"
                      value={x.answer}
                      onChange={(e) => this.handleFaqInputChange(e, i)}
                    />
                    <div className="btn-box">
                      {faqInputList.length !== 1 && (
                        <Button
                          style={{ margin: "10px 10px 10px 0" }}
                          type="primary"
                          danger
                          className="mr10"
                          onClick={() => this.handleFaqRemoveClick(i)}
                        >
                          Remove
                        </Button>
                      )}
                      {faqInputList.length - 1 === i && (
                        <Button
                          style={{ margin: "10px 0px" }}
                          type="primary"
                          onClick={this.handleFaqAddClick}
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default EditCourse;
