import React from "react";
import { Spin, Col, message, Row } from "antd";
import CourseAssignment from "../../components/CourseAssignment/CourseAssignment";

import MainContext from "../../Context/AppContext";
import { getStatistics, getManagerHierarchy } from "../../network/network";
import { LoadingOutlined } from "@ant-design/icons";
import AddTrainee from "../../screens/User/Trainees/add";
class ManagerStats extends React.Component {
  state = {
    loading: false,
    showModal: false,
    manager_stats: {
      courses_not_started_count: 0,
      courses_in_progress_count: 0,
      courses_available: 0,
      courses_completed: 0,
    },
  };

  static contextType = MainContext;
  componentDidMount() {
    if (
      this.context.authDetails.userType === 2 ||
      this.context.authDetails.userType === 1
    ) {
      this.getManagerStats();
      this.getManagerHierarchyData();
    }
  }

  getManagerHierarchyData = () => {
    getManagerHierarchy(this.context.authDetails.token).then((res) => {
      this.setState({
        hierarchy_data: res.data,
      });
    });
  };

  closeModal = () => {
    this.getManagerStats();
  };

  assignCourseToTrainees = (_id) => {
    this.setState({
      showModal: true,
      active_course: _id,
    });
  };

  getManagerStats = () => {
    this.setState({
      loading: true,
    });
    getStatistics(
      this.context.authDetails.token,
      this.context.authDetails.userType,
      this.props.manager_id ? this.props.manager_id : ""
    )
      .then((res) => {
        let purchased_courses = res.data.course_data;
        this.setState({
          manager_stats: res.data.manager_stats,
          course_data: purchased_courses,
          trainees_data: res.data.trainees_data,
        });
      })
      .catch((err) => {
        message.error("Some error has occured while fetching manager stats!");
      })
      .finally(() => {
        this.setState({
          loading: false,
          showModal: false,
          active_course: {},
        });
      });
  };

  render() {
    const { active_course, showModal, manager_stats } = this.state;
    return (
      <>
        {showModal && (
          <CourseAssignment course={active_course} callBack={this.closeModal} />
        )}

        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={this.state.loading}
        >
          <div className="main-manager-stats-wrapper">
            <Row gutter={16} style={{ justifyContent: "space-evenly" }}>
              <Col xs={24} sm={24} md={24} lg={12} xl={5}>
                <div className="manager-stats-wrapper">
                  <div className="manager-stats heading">
                    <h4>Trainings</h4>
                    <h3>In-Progress</h3>
                  </div>
                  <div className="manager-stats response">
                    <h2>
                      {manager_stats.courses_in_progress_count
                        ? manager_stats.courses_in_progress_count
                        : 0}
                    </h2>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={5}>
                <div className="manager-stats-wrapper">
                  <div className="manager-stats heading">
                    <h4>Trainings</h4>
                    <h3>Not-Started</h3>
                  </div>
                  <div className="manager-stats response">
                    <h2>
                      {manager_stats.courses_not_started_count
                        ? manager_stats.courses_not_started_count
                        : 0}
                    </h2>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={5}>
                <div className="manager-stats-wrapper">
                  <div className="manager-stats heading">
                    <h4>Trainings</h4>
                    <h3>Not-Assigned</h3>
                  </div>
                  <div className="manager-stats response">
                    <h2>
                      {manager_stats.courses_available
                        ? manager_stats.courses_available
                        : 0}
                    </h2>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={5}>
                <div className="manager-stats-wrapper">
                  <div className="manager-stats heading">
                    <h4>Trainings</h4>
                    <h3>Completed</h3>
                  </div>
                  <div className="manager-stats response">
                    <h2>
                      {manager_stats.courses_completed
                        ? manager_stats.courses_completed
                        : 0}
                    </h2>
                  </div>
                </div>
              </Col>
              {!this.props.manager_id && (
                <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                  <div className="m-btn-wrapper">
                    <AddTrainee
                      hierarchy_data={this.state.hierarchy_data}
                      type="manager_dashboard"
                      onChildClick={() => this.getManagerStats()}
                    />

                    <a
                      className="m-blue-btn"
                      href={`${process.env.REACT_APP_BASE_URL}/all-courses`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Buy More Courses
                    </a>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Spin>
      </>
    );
  }
}

export default ManagerStats;
