import React from "react";
import { Col, Row, Button } from "antd";
import {
  getCourseByID,
  getCoursePreviewURL,
  GetRegistrationProgress,
  BuildRegistrationLaunchLink,
  getRegistrationDetailsByRegID,
  saveCourseProgress,
  // DeleteRegistrations,
} from "../../../network/network";
import { Progress } from "antd";
import MainContext from "../../../Context/AppContext";
class CourseDetails extends React.Component {
  state = {
    courseCompletion: 0,
    courseStatus: "incompelete",
    courseScore: 0,
    courseTime: "0:00:00",
    course_details: {},
    course_progress_details: {},
  };
  static contextType = MainContext;
  componentDidMount() {
    if (
      this.context.authDetails.userType === 1 ||
      this.context.authDetails.userType === 5
    ) {
      this.getCourseDetails();
    }
    if (this.context.authDetails.userType === 4) {
      this.getRegCourseDetailsByRegId();
      this.courseStatsByRegId();
    }
  }

  // deleteScormCloudRegistrations = () => {
  //   DeleteRegistrations("60b7950d7669d4cd484b55d2")
  //     .then((res) => {})
  //     .catch((err) => {});
  // };

  saveCourseProgressStats = () => {
    if (this.state.course_progress_details.activityDetails) {
      let data = {
        completion:
          this.state.course_progress_details.activityDetails.activityCompletion,
        status:
          this.state.course_progress_details.activityDetails.activitySuccess,
        score: this.state.course_progress_details.activityDetails.score
          ? this.state.course_progress_details.activityDetails.score.scaled
          : "0",
        time: this.state.course_progress_details.totalSecondsTracked,
        firstAccessDate: this.state.course_progress_details.firstAccessDate,
        lastAccessDate: this.state.course_progress_details.lastAccessDate,
        completedDate: this.state.course_progress_details.completedDate,
        expiryDate: "",
        is_failed:
          this.state.course_progress_details.activitySuccess === "FAILED"
            ? true
            : false,
        // (this.state.course_progress_details.registrationCompletion ===
        //   "INCOMPLETE" &&
        //   this.state.course_progress_details.score)
      };

      if (this.context.authDetails) {
        saveCourseProgress(
          this.props.match.params.courseID,
          data,
          this.context.authDetails.token
        )
          .then((res) => {})
          .catch((err) => {});
      }
    }
  };

  getRegCourseDetailsByRegId = () => {
    let reg_id = this.props.match.params.courseID;

    getRegistrationDetailsByRegID(this.context.authDetails?.token, reg_id)
      .then((res) => {
        //
        this.setState({
          course_details: res.data,
        });
      })
      .catch((err) => {});
  };

  courseStatsByRegId = () => {
    let _this = this;
    GetRegistrationProgress(this.props.match.params.courseID)
      .then((res) => {
        this.setState({
          course_progress_details: res.data,
        });

        const query = new URLSearchParams(this.props.location.search);
        const course_progress = query.get("course_progress");
        if (course_progress) {
          _this.saveCourseProgressStats();
        }
      })
      .catch((err) => {});
  };

  launchCourse(course_portal_type) {
    if (course_portal_type == process.env.REACT_APP_COURSE_NOT_IN_LMS) {
      window.location.href = "https://auth.clicksafety.com/login";
      return false;
    } else if (
      this.context.authDetails.userType === 1 &&
      course_portal_type == process.env.REACT_APP_COURSE_IN_LMS
    ) {
      let redirectOnExitUrl = window.location.href;
      let courseScormID = this.state.course_details.courseId
        ? this.state.course_details.courseId.scorm_course_id
        : this.state.course_details.scorm_course_id;

      getCoursePreviewURL(courseScormID, redirectOnExitUrl)
        .then((res) => {
          window.location.href = res.data.launchLink;
        })
        .catch((err) => {});
    } else if (
      this.context.authDetails.userType === 4 &&
      course_portal_type == process.env.REACT_APP_COURSE_IN_LMS
    ) {
      let redirectOnExitUrl = window.location.href + "?course_progress=true";
      let reg_id = this.props.match.params.courseID;

      BuildRegistrationLaunchLink(reg_id, redirectOnExitUrl)
        .then((res) => {
          window.location.href = res.data.launchLink;
        })
        .catch((err) => {});
    }
  }

  getCourseDetails = (params = {}) => {
    this.setState({ loading: true });
    if (this.context.authDetails) {
      getCourseByID(
        this.props.match.params.courseID,
        this.context.authDetails.token
      )
        .then((res) => {
          this.setState({
            course_details: res.data,
            loading: false,
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
  };

  render() {
    const { course_details } = this.state;

    return (
      <>
        {(this.context.authDetails.userType === 1 ||
          this.context.authDetails.userType === 5) && (
          <>
            <Col className="ind-dashboard dashboard-charts" span={24}>
              <h2>Course</h2>
              <div className="chart-box">
                <Row gutter={30}>
                  <Col
                    className="admin-image-wrapper"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={10}
                    xl={10}
                  >
                    <img
                      alt={course_details?.title}
                      src={
                        course_details.thumbnailImage &&
                        course_details.thumbnailImage.path
                          ? `${course_details.thumbnailImage.path}`
                          : "/no-image.png"
                      }
                    />
                    <div className="desc-wrapper-listing">
                      <ul>
                        <li>
                          <p>
                            <label>Language:</label>
                            {course_details?.language === 1
                              ? "English"
                              : "Spanish"}
                          </p>
                        </li>
                        <li>
                          <p>
                            <label>Duration:</label>
                            {`${course_details?.hours} Hours and ${course_details?.minutes} Minutes`}
                          </p>
                        </li>
                        <li>
                          <p>
                            <label>Level:</label>
                            {course_details?.language === 1 && "Introductory"}
                            {course_details?.language === 2 && "Intermediate"}
                            {course_details?.language === 3 && "Advance"}
                            {course_details?.language === 4 && "Certificate"}
                          </p>
                        </li>
                        <li>
                          <p>
                            <label>Include:</label>
                            Certificate of Completion
                          </p>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col
                    className="desc-wrapper course-desc-wrapper"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={14}
                    xl={14}
                  >
                    <span>{course_details?.category?.name}</span>
                    <h3> {course_details?.title}</h3>

                    {course_details.description && (
                      <>
                        <div style={{ display: "block", marginTop: "30px" }}>
                          <label>
                            <b> Description </b> :{" "}
                          </label>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: course_details.description,
                            }}
                          />
                        </div>
                      </>
                    )}
                    {course_details.objectives && (
                      <>
                        <div style={{ display: "block", marginTop: "30px" }}>
                          <label>
                            <b> Objectives</b> :{" "}
                          </label>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: course_details.objectives,
                            }}
                          />
                        </div>
                      </>
                    )}
                    {course_details.audience && (
                      <>
                        <div style={{ display: "block", marginTop: "30px" }}>
                          <label>
                            <b> Audience</b> :{" "}
                          </label>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: course_details.audience,
                            }}
                          />
                        </div>
                      </>
                    )}
                    {course_details.portal_type === 1 && (
                      <div
                        className="button-wrapper"
                        style={{ display: "block", marginTop: "30px" }}
                      >
                        <Button
                          onClick={() =>
                            this.launchCourse(course_details.portal_type)
                          }
                        >
                          Start
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        )}
        {this.context.authDetails.userType === 4 && course_details.courseId && (
          <>
            <Col className="ind-dashboard dashboard-charts" span={24}>
              <h2>Course</h2>
              <div className="chart-box">
                <Row gutter={30}>
                  <Col
                    className="image-wrapper"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={10}
                    xl={10}
                  >
                    <img
                      alt={course_details.courseId.title}
                      src={
                        course_details.courseId.thumbnailImage &&
                        course_details.courseId.thumbnailImage.path
                          ? `${course_details.courseId.thumbnailImage.path}`
                          : "/no-image.png"
                      }
                    />
                  </Col>
                  <Col
                    className="desc-wrapper"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={14}
                    xl={14}
                  >
                    {course_details.courseId.category &&
                      course_details.courseId.category.name && (
                        <span>{course_details.courseId.category.name}</span>
                      )}
                    {course_details.courseId.title && (
                      <h3> {course_details.courseId.title}</h3>
                    )}
                    {course_details.completion === "COMPLETED" && (
                      <Progress strokeLinecap="square" percent={100} />
                    )}

                    {course_details.courseId.description && (
                      <p>
                        <b> Description</b>:{" "}
                        {course_details.courseId.description.replace(
                          /<[^>]*>?/gm,
                          ""
                        )}
                      </p>
                    )}
                    {course_details.courseId.objectives && (
                      <p>
                        <b> Objectives</b>:{" "}
                        {course_details.courseId.objectives.replace(
                          /<[^>]*>?/gm,
                          ""
                        )}
                      </p>
                    )}
                    {course_details.courseId.audience && (
                      <p>
                        <b> Audience</b>:{" "}
                        {course_details.courseId.audience.replace(
                          /<[^>]*>?/gm,
                          ""
                        )}
                      </p>
                    )}
                    {course_details.courseId.portal_type === 1 &&
                      !course_details.is_failed && (
                        <div className="button-wrapper">
                          <Button
                            onClick={() =>
                              this.launchCourse(
                                course_details.courseId.portal_type
                              )
                            }
                          >
                            Start
                          </Button>
                        </div>
                      )}
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        )}
      </>
    );
  }
}

export default CourseDetails;
