import React from "react";
import MainContext from "../../Context/AppContext";

class ManagerReports extends React.Component {
  state = {};

  static contextType = MainContext;

  render() {
    return (
      <>
        <h1>Manager Reports</h1>
      </>
    );
  }
}

export default ManagerReports;
