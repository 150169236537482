// import moment from "moment";
import moment from "moment-timezone";

export const CanadianTime = (inputTime, formatted = true) => {
  let hrDiff = (moment().tz("America/Toronto").utcOffset() / 60) * -1;

  let dateObj = moment(inputTime).subtract(hrDiff, "h").utc();

  if (formatted) {
    return dateObj.format("DD MMM YYYY, HH:mm:ss") + " EST";
  }
  return dateObj;
};

export const getCurrentDateTime = (inputTime, formatted = true) => {
  var d = new Date();
  var myTimezone = "America/New_York";
  var myDatetimeFormat = "DD MMM YYYY, HH:mm";
  var myDatetimeString = moment(d).tz(myTimezone).format(myDatetimeFormat);
  return myDatetimeString + " EST";
};

export const SecondsToTime = (d) => {
  if (d && d !== "00:00:00" && d != "0") {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "H " : "H ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "M " : "M ") : "";

    return hDisplay + mDisplay;
  } else {
    return "0H";
  }
};

export const formatDateTime = (d) => {
  var myDatetimeFormat = "DD MMM YYYY, HH:mm";
  var myDatetimeString = moment(d).format(myDatetimeFormat);
  return myDatetimeString;
};
