import React from "react";
import { message, Table } from "antd";

import MainContext from "../../Context/AppContext";
import { getStatistics } from "../../network/network";

class ManagerDashboard extends React.Component {
  state = {
    loading: false,
    trainees_data: [],

    course_trainees_columns: [
      {
        title: "#",
        key: "index",
        width: "5%",
        align: "center",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Trainee Name",
        width: "15%",
        render: (record) => {
          return <p>{`${record.firstName} ${record.lastName}`}</p>;
        },
      },
      {
        title: "Course",
        width: "15%",
        render: (text, record) => {
          let courses = [];
          record.courses.forEach((element) => {
            if (element.courseId) {
              courses.push({
                title: element.courseId.title,
              });
            }
          });

          return (
            <div className="progress-course-list">
              {courses.map((obj, i) => (
                <p>
                  <b>{i + 1}. </b>
                  {obj.title}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        title: "Assigned On",
        width: "15%",
        align: "center",
        render: (text, record) => {
          let courses = [];
          record.courses.forEach((element) => {
            if (element.courseId) {
              courses.push({
                createdAt: new Date(element.createdAt).toLocaleDateString(
                  "en-GB",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  }
                ),
              });
            }
          });

          return (
            <div className="progress-course-list">
              {courses.map((obj) => (
                <p>{obj.createdAt}</p>
              ))}
            </div>
          );
        },
      },
      {
        title: "Status",
        width: "15%",
        align: "center",
        render: (text, record) => {
          let courses = [];
          record.courses.forEach((element) => {
            if (element.courseId) {
              courses.push({
                completion: element.completion,
                time: element.time,
                status: element.status,
                score: element.score,
                is_failed: element.is_failed,
              });
            }
          });

          return (
            <div className="progress-course-list">
              {courses.map((obj) => {
                if (obj.is_failed) {
                  return (
                    <p style={{ color: "red", fontWeight: "600" }}>FAILED</p>
                  );
                } else if (obj.completion === "COMPLETED") {
                  return (
                    <p style={{ color: "green", fontWeight: "600" }}>
                      COMPLETED
                    </p>
                  );
                } else if (obj.time === "0" || obj.time === "00:00:00") {
                  return (
                    <p style={{ color: "#82868e", fontWeight: "600" }}>
                      NOT STARTED
                    </p>
                  );
                } else if (obj.time !== "0" && obj.time !== "00:00:00") {
                  return (
                    <p style={{ color: "orange", fontWeight: "600" }}>
                      IN PROGRESS
                    </p>
                  );
                } else {
                  return false;
                }
              })}
            </div>
          );
        },
      },
      // {
      //   title: 'Action',
      //   width: '10%',
      //   align: 'center',
      //   render: (record) => {
      //     return (
      //       <Button className="green-btn" style={{ width: "100%" }} >View</Button>
      //     )
      //   }
      // },
    ],
  };

  static contextType = MainContext;
  componentDidMount() {
    if (
      this.context.authDetails.userType === 2 ||
      this.context.authDetails.userType === 1
    ) {
      this.getManagerStats();
    }
  }

  getManagerStats = () => {
    this.setState({
      loading: true,
    });
    getStatistics(
      this.context.authDetails.token,
      this.context.authDetails.userType,
      this.props.manager_id ? this.props.manager_id : ""
    )
      .then((res) => {
        this.setState({
          trainees_data: res.data.trainees_data,
        });
      })
      .catch((err) => {
        message.error("Some error has occured while fetching manager stats!");
      })
      .finally(() => {
        this.setState({
          loading: false,
          showModal: false,
          active_course: {},
        });
      });
  };

  render() {
    const { loading, course_trainees_columns, trainees_data } = this.state;
    return (
      <>
        <h2 className="manager-box-heading">Progress</h2>
        <div className="table-box">
          <Table
            className="recent-courses-progress"
            scroll={{ y: 500 }}
            columns={course_trainees_columns}
            rowKey={(record) => record._id}
            dataSource={trainees_data}
            loading={loading}
            pagination={false}
          />
        </div>
      </>
    );
  }
}

export default ManagerDashboard;
