import React from "react";
import {
  Modal,
  Button,
  // Space,
  Form,
  Input,
  Radio,
  // Select,
  Row,
  notification,
  Col,
  Image,
  // notification,
} from "antd";
// import CKEditor from "ckeditor4-react";
import FileUpload from "../../../components/FileUpload/FileUpload";
import MainContext from "../../../Context/AppContext";
import { PlusOutlined } from "@ant-design/icons";
import { storeBlogCategories } from "../../../network/network";
// import CKEditor from "ckeditor4-react";
// const { TextArea } = Input;
// import { ExclamationCircleOutlined } from '@ant-design/icons';

class AddBlogCategory extends React.Component {
  formRef = React.createRef();
  state = {
    detailed_content: "",
    categories: [],
    visible: false,
    thumbnailImageId: "",
    thumbnailImagePath: "",
    bannerImagePath: "",
    bannerImageId: "",
    layout: {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    },
    tailLayout: {
      wrapperCol: {
        offset: 3,
        span: 24,
      },
    },
  };
  static contextType = MainContext;
  showModal = () => {
    this.setState({
      categories: this.props.categories,
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onEditorChange = (evt) => {
    this.setState({
      detailed_content: evt.editor.getData(),
    });
  };

  onFinish = (values) => {
    const formData = new FormData();

    // formData.append("description", this.state.detailed_content);
    // formData.append("shortDescription", values.shortDescription);
    formData.append("name", values.name);
    formData.append("thumbnailImage", this.state.thumbnailImageId);
    formData.append("bannerImage", this.state.bannerImageId);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);
    // formData.append("seo_title", values.seo_title);
    // formData.append("authorsName", values.authors_name);
    // formData.append("seo_description", values.seo_description);
    // formData.append("seo_keyword", values.seo_keyword);
    // formData.append("category",values.category);
    formData.append("status", values.status);

    storeBlogCategories(formData, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Blog Category Created",
          description: "The blog category has been created successfully.",
          placement: "topRight",
        });
        this.setState({
          thumbnailImagePath: "",
          bannerImagePath: "",
        });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  handleThumbnailImageID = (thumbnailImageID, thumbnailImagePath) => {
    this.setState({
      thumbnailImageId: thumbnailImageID,
      thumbnailImagePath: thumbnailImagePath,
    });
  };

  handlebannerImageID = (bannerImageID, bannerImagePath) => {
    this.setState({
      bannerImageId: bannerImageID,
      bannerImagePath: bannerImagePath,
    });
  };

  render() {
    return (
      <>
        <Button
          style={{ float: "right", margin: "10px 0" }}
          type="primary"
          onClick={this.showModal}
          icon={<PlusOutlined />}
          size="large"
        ></Button>

        <Modal
          width={2000}
          centered
          title="Create Blog"
          visible={this.state.visible}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText="Create"
          cancelText="Cancel"
          footer={[
            <Button
              form="myFormAdd"
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Store
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                layout="vertical"
                name="basic"
                id="myFormAdd"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                // onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="name"
                  label="Category Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input blog category name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                {/* <Form.Item
                  name="shortDescription"
                  label="Short Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input short description!",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>



                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: false,
                      message: "Please input description!",
                      // whitespace: true,
                    },
                  ]}
                >
                   <CKEditor data={"Here is the post content"}   onChange={this.onEditorChange} />
             
                </Form.Item> */}

                <Form.Item
                  name="seo_title"
                  label="SEO Title"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO title!",
                      whitespace: true,
                      // max:60,
                      // min:30
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="SEO Description"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO description!",
                      whitespace: true,
                      // max:160,
                      // min:110,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="SEO Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input course SEO Keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Thumbnail Image">
                  <Image
                    width={200}
                    src={
                      this.state.thumbnailImagePath.startsWith("https")
                        ? this.state.thumbnailImagePath
                        : !this.state.thumbnailImagePath
                        ? "/no-image.png"
                        : process.env.REACT_APP_API_URL +
                          this.state.thumbnailImagePath
                    }
                  />
                  <FileUpload
                    onThumbnailImageId={this.handleThumbnailImageID}
                    imageUploadType="blogCategoryThumbnailImage"
                  />
                </Form.Item>

                <Form.Item label="Banner Image">
                  <Image
                    width={200}
                    src={
                      this.state.bannerImagePath.startsWith("https")
                        ? this.state.bannerImagePath
                        : !this.state.bannerImagePath
                        ? "/no-image.png"
                        : process.env.REACT_APP_API_URL +
                          this.state.bannerImagePath
                    }
                  />
                  <FileUpload
                    onBannerImageId={this.handlebannerImageID}
                    imageUploadType="blogCategoryBannerImage"
                  />
                </Form.Item>

                {/* <Form.Item label="Thumbnail Image">
                  <FileUpload
                    onThumbnailImageId={this.handleThumbnailImageID}
                    imageUploadType="thumbnailImage"
                  />
                </Form.Item>

                <Form.Item label="Banner Image">
                  <FileUpload
                    onBannerImageId={this.handlebannerImageID}
                    imageUploadType="bannerImage"
                  />
                </Form.Item> */}

                {/* 
                <Form.Item
                  name="authors_name"
                  label="Authors Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input blog category authors_name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>


{this.state.categories.length > 0 && 
                <Form.Item
                    name="category"
                    label="Categories"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select>
                     
                      {this.state.categories?.map((item, index) => (
                        <Select.Option value={item._id} key={index}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>

                  </Form.Item>
  }

                <div className="ant-modal-title" style={{ marginBottom: "20px" }}>
                Course SEO Details
              </div>


                <Form.Item
                  name="seo_title"
                  label="SEO Title"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO title!",
                      whitespace: true,
                      // max:60,
                      // min:30
                    },
                  ]}
                >
                  <Input />
                </Form.Item>



                <Form.Item
                  name="seo_description"
                  label="SEO Description"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO description!",
                      whitespace: true,
                      // max:160,
                      // min:110,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>



                <Form.Item
                  name="seo_keyword"
                  label="SEO Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input course SEO Keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item> */}

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={1}>Published</Radio>
                    <Radio value={0}>Unpublished</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default AddBlogCategory;
