import React from "react";
import Highlighter from "react-highlight-words";
import { downloadCertificate } from "../../network/network";
import { Table, Input, Button, Row, Col, notification, Space } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { SecondsToTime, formatDateTime } from "../../helpers/helpers";

import { Link } from "react-router-dom";

import { getCourses } from "../../network/network";

import MainContext from "../../Context/AppContext";

class StudentCourses extends React.Component {
  state = {
    categories: [],
    searchText: "",
    searchedColumn: "",
    columns: [
      {
        title: "#",
        key: "index",
        width: "2%",
        align: "center",
        render: (text, record, index) => {
          return (
            <p>
              {this.state.pagination.pageSize *
                (this.state.pagination.current - 1) +
                (index + 1)}
            </p>
          );
        },
      },

      {
        title: "Course Name",
        width: "20%",
        align: "left",
        render: (record) => {
          return <p>{record?.courseId?.title || "-"}</p>;
        },
      },
      {
        title: "Status",
        width: "10%",
        align: "left",
        render: (record) => {
          if (record.is_failed || record.status === "FAILED") {
            return (
              <>
                <p style={{ color: "red", fontWeight: "600" }}>FAILED</p>
                {record.score && record.score > 0 && (
                  <p>
                    <b>Score: </b>
                    {record.score}
                  </p>
                )}
              </>
            );
          } else if (record.completion === "COMPLETED") {
            return (
              <>
                <p style={{ color: "green", fontWeight: "600" }}>COMPLETED</p>
                {record.score && parseInt(record.score) > 0 && (
                  <p>
                    <b>Score: </b>
                    {record.score}
                  </p>
                )}
              </>
            );
          } else if (
            (record.time === "0" || record.time === "00:00:00") &&
            record.completion !== "COMPLETED"
          ) {
            return (
              <p style={{ color: "#82868e", fontWeight: "600" }}>NOT STARTED</p>
            );
          } else if (
            record.time !== "0" &&
            record.time !== "00:00:00" &&
            record.completion !== "COMPLETED"
          ) {
            return (
              <p style={{ color: "orange", fontWeight: "600" }}>IN PROGRESS</p>
            );
          }
        },
      },
      {
        title: "Date ",
        width: "20%",
        align: "left",
        render: (record) => {
          return (
            <>
              {record.createdAt && (
                <p>
                  <b>Assigned Date: </b>
                  {formatDateTime(record.createdAt)}
                </p>
              )}
              {record.firstAccessDate && (
                <p>
                  <b>Start Date: </b>
                  {formatDateTime(record.firstAccessDate)}
                </p>
              )}

              {record.lastAccessDate && (
                <p>
                  <b>Last Access Date: </b>
                  {formatDateTime(record.lastAccessDate)}
                </p>
              )}
            </>
          );
        },
      },
      {
        title: "Compelete Date",
        width: "10%",
        align: "center",
        render: (record) => {
          return (
            <p>
              {record.completedDate
                ? formatDateTime(record.completedDate)
                : "-"}
            </p>
          );
        },
      },
      {
        title: "Expiry Date",
        width: "10%",
        align: "center",
        render: (record) => {
          return (
            <p>{record.expiryDate ? formatDateTime(record.expiryDate) : "-"}</p>
          );
        },
      },
      {
        title: "Time Elapsed",
        width: "10%",
        align: "center",
        render: (record) => {
          if (record?.time) {
            return <p>{SecondsToTime(record.time)}</p>;
          }
        },
      },
      {
        title: "Certificate",
        width: "10%",
        align: "center",
        render: (record) => {
          if (
            record.completion === "COMPLETED" &&
            record.completedDate !== ""
          ) {
            return (
              <Col className="button-wrapper" style={{ textAlign: "center" }}>
                <a
                  onClick={() => this.generatePDF(record._id)}
                  rel="noreferrer"
                  target="_blank"
                  style={{ fontSize: 18 }}
                  className="ant-btn-primary"
                >
                  <DownloadOutlined />
                </a>
              </Col>
            );
          } else {
            return <p>-</p>;
          }
        },
      },
      {
        title: "Action",
        width: "5%",
        align: "center",
        render: (record) => {
          if (!record.is_failed && record.status !== "FAILED") {
            if (record?.completion === "COMPLETED") {
              return (
                <Col className="button-wrapper">
                  <Link to={"/view/course/" + record._id}>View</Link>
                </Col>
              );
            }
            if (record?.completion !== "COMPLETED") {
              return (
                <Col className="button-wrapper-start">
                  <Link to={"/view/course/" + record._id}>
                    {" "}
                    {record.time === "0" || record.time === "00:00:00"
                      ? "Start"
                      : "Resume"}
                  </Link>
                </Col>
              );
            }
          }
        },
      },
    ],
    courses: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  };
  static contextType = MainContext;

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  generatePDF(registration_id) {
    this.setState({ assignLoading: true });
    downloadCertificate(registration_id, this.context.authDetails?.token)
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `certificate.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({ assignLoading: false });
      });
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    selectedKeys[0] = selectedKeys[0].trim();
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  updateCourseTable = () => {
    notification.success({
      message: "Course Updated",
      description: "The Course has been updated successfully.",
      placement: "topRight",
    });
    // const { pagination } = this.state;
    // this.fetch({ pagination });
  };
  handleTableChange = (pagination, filters, sorter) => {
    // this.fetch({
    //   sortField: sorter.field,
    //   sortOrder: sorter.order,
    //   pagination,
    //   ...filters,
    // });
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  fetch = (params = {}) => {
    this.setState({ loading: true });
    getCourses("user", this.context.authDetails?.token)
      .then((res) => {
        // var courses = [];
        // var tempcourses =  res.data;
        // tempcourses.forEach(element => {
        //   courses.push({
        //       "title": element.courseId.title,
        //       "_id": element._id
        //    })
        //  });

        this.setState({
          courses: res.data,
          pagination: {
            ...params.pagination,
            total: res.data.totalCount,
          },
          loading: false,
        });
      })
      .catch((err) => {
        //   this.setState({
        //     loading: false,
        //   });
        //   if (err.response && err.response.status === 404) {
        //     message.error("Something went wrong");
        //   }
        //   if (err.response && err.response.status === 401) {
        //     message.error(err.response.data.message);
        //   }
        // }, 1000);
      });
  };

  render() {
    const { loading, courses, columns } = this.state;

    return (
      <>
        {/* <div className="dashboard-charts" >
        <h2>Recent Courses</h2> 
      
        <div className="table-box">
          
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data}
       
          loading={loading}
       
        />
      </div>
      </div> */}

        <Row gutter={30}>
          <Col className="dashboard-charts ind-dashboard" span={24}>
            <h2>My Courses</h2>
            <div className="table-box">
              <Table
                className="stdmycours-table"
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={courses ? courses : []}
                loading={loading}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default StudentCourses;
