import React from "react";
import Highlighter from "react-highlight-words";
import {
  Tag,
  Table,
  Col,
  Input,
  Button,
  message,
  notification,
  Space,
} from "antd";

import MainContext from "../../../Context/AppContext";
import { PicCenterOutlined, SearchOutlined } from "@ant-design/icons";
import { getCategories, deleteCategory } from "../../../network/network";
import AddCategory from "./add";
import EditCategory from "./edit";

class Categories extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",

    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
  };
  static contextType = MainContext;
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    selectedKeys[0] = selectedKeys[0].trim();
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  updateCategoryTable = () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  onDelete = (record) => {
    this.setState({ loading: true });
    deleteCategory(record._id, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
      });

      notification.success({
        message: "Category Deleted",
        description: "The category has been deleted successfully.",
        placement: "topRight",
      });

      const { pagination } = this.state;
      this.fetch({ pagination });
    });
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  notDeleteUserMessage = () => {
    message.info("Category is safe");
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });

    setTimeout(() => {
      getCategories(this.context.authDetails?.token)
        .then((res) => {
          this.setState({
            loading: false,
            data: res.data,
            categories: res.data,
            pagination: {
              ...params.pagination,
              total: res.data.totalCount,
            },
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }, 1000);
  };

  render() {
    const { loading } = this.state;
    // const { columns } = this.state;
    const { data } = this.state;
    // const { pagination } = this.state;

    const columns = [
      {
        title: "#",
        width: "0%",
        key: "index",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Image",
        width: "10%",
        dataIndex: "", // this is the value that is parsed from the DB / server side
        render: (text, record) => (
          <img
            height="70"
            width="auto"
            alt={record.name}
            src={
              record.thumbnailImage
                ? `${record.thumbnailImage.path}`
                : "/no-image.png"
            }
          />
        ),
      },
      {
        title: "Type",
        width: "10%",
        align: "center",
        dataIndex: "",
        render: (record) => {
          return (
            <>
              <Tag color={!record.parent_category ? "black" : "blue"}>
                {!record.parent_category ? "Parent" : "Child"}
              </Tag>
            </>
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        width: "45%",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Slug",
        width: "25%",
        dataIndex: "slug",
      },

      {
        title: "Status",
        width: "10%",
        align: "center",
        dataIndex: "status",
        sorter: true,
        render: (status) => {
          return (
            <Tag color={status ? "green" : "red"}>
              {status ? "Published" : "Draft"}
            </Tag>
          );
        },
      },

      {
        title: "Action",
        dataIndex: "",
        width: "5%",
        algin: "center",
        key: "x",
        render: (text, record) => (
          <div className="text-center">
            <Space size="middle">
              <EditCategory
                data_id={record._id}
                type={"edit"}
                categories={this.state.categories}
                onChildClick={this.updateCategoryTable.bind(this)}
              />

              {/* <Popconfirm
              title="Are you sure to delete this category?"
              onConfirm={() => {
                this.onDelete(record);
              }}
              onCancel={this.notDeleteUserMessage}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm> */}
            </Space>
          </div>
        ),
      },
    ];

    return (
      <div>
        {/* <Row type="flex" align="middle" justify="space-between">
          <Col>
            <h4>Categories</h4>
          </Col>
          <Col span={12}>
            <AddCategory
              onChildClick={this.updateCategoryTable.bind(this)}
              categories={this.state.categories}
            />
          </Col>
        </Row> */}

        <Col className="dashboard-charts" span={24}>
          <h2>Recent Categories</h2>

          <AddCategory
            onChildClick={this.updateCategoryTable.bind(this)}
            categories={this.state.categories}
          />
          <div className="table-box">
            <Table
              columns={columns}
              rowKey={(record) => record._id}
              dataSource={data}
              pagination={false}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </div>
        </Col>
      </div>
    );
  }
}

export default Categories;
