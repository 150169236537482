import React from "react";
import "./App.scss";
import ContextWrapper from "./Context/ContextWrapper";
import MainApp from "./screens/User/Main/Main";
class App extends React.Component {
  state = {};

  render() {
    return (
      <ContextWrapper>
        <MainApp />
      </ContextWrapper>
    );
  }
}

export default App;
