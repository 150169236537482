import React from "react";
import { Col, Row } from "antd";
import ManagerAvailableCourses from "../Manager/ManagerAvailableCourses";
import ManagerTraineesProgress from "../../components/Manager/ManagerTraineesProgress";
import ManagerStats from "../../components/Manager/ManagerStats";
import ActivityLogs from "../ActivityLogs/ActivityLogs";
class ManagerDashboard extends React.Component {
  render() {
    return (
      <>
        <ManagerStats />

        <Row gutter={[15, 15]}>
          <Col
            className="dashboard-charts"
            xs={24}
            md={10}
            sm={24}
            lg={10}
            xl={10}
          >
            <ManagerAvailableCourses />
          </Col>
          <Col
            className="dashboard-charts"
            xs={24}
            offset="1"
            md={13}
            sm={24}
            lg={13}
            xl={13}
          >
            <ManagerTraineesProgress />
          </Col>

          <Col
            className="dashboard-charts"
            xs={24}
            md={24}
            sm={24}
            lg={24}
            xl={24}
          >
            <ActivityLogs />
          </Col>
        </Row>
      </>
    );
  }
}

export default ManagerDashboard;
