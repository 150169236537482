import React from "react";
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  Button,
  // notification,
  Select,
  // Image,
  Modal,
} from "antd";
import MainContext from "../../../Context/AppContext";
// import CKEditor from "ckeditor4-react";
// import FileUpload from "../../../components/FileUpload/FileUpload";
// import CKEditor from "ckeditor4-react";
import { FormOutlined } from "@ant-design/icons";
import {
  // getCategoryByID,
  getPromoCodeByID,
  updatePromoCode,
} from "../../../network/network";
// const { TextArea } = Input;

class EditPromoCode extends React.Component {
  formRef = React.createRef();
  state = {
    promoCode_details: {},
    loading: false,
    visible: false,
  };
  static contextType = MainContext;

  getPromoCodeDetails = (promoCodeId) => {
    getPromoCodeByID(promoCodeId, this.context.authDetails?.token).then(
      (res) => {
        this.setState({
          loading: false,
          visible: true,
          promoCode_details: res.data,
        });

        res.data.categoryName = res.data.category?._id;

        if (res.data) {
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
        }
      }
    );
  };

  onFinish = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("code", values.code);
    formData.append("type", values.type);
    formData.append("discount", values.discount);
    formData.append("limit", values.limit);
    formData.append("status", values.status);

    updatePromoCode(
      this.props.data_id,
      formData,
      this.context.authDetails?.token
    )
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        // notification.success({
        //   message: "Blog Updated",
        //   description: "The Blog has been updated successfully.",
        //   placement: "topRight",
        // });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  showModal = () => {
    this.getPromoCodeDetails(this.props.data_id);

    this.setState({
      loading: false,
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  //   handleChange( changeEvent ) {
  //     this.setState( {
  //         data: changeEvent.target.value
  //     } );
  // }

  onFinishFailed = (errorInfo) => {};

  render() {
    const { layout, promoCode_details } = this.state;

    return (
      <div>
        <Button
          onClick={this.showModal}
          type="primary"
          icon={<FormOutlined />}
        />

        <Modal
          title="Edit Promo Code"
          visible={this.state.visible}
          onOk={this.onFinish}
          onCancel={this.hideModal}
          okText="Update"
          width={2000}
          centered
          cancelText="Cancel"
          footer={[
            <Button
              form={"myForm" + promoCode_details._id}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Update
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                {...layout}
                ref={this.formRef}
                name="basic"
                id={"myForm" + promoCode_details._id}
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="name"
                  label="Promotion Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input promotion title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="code"
                  label="Promotion Code"
                  rules={[
                    {
                      required: true,
                      message: "Please input promotion title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="type"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please input promotion type!",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={1}>Percentage</Select.Option>
                    <Select.Option value={2}>Amount</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="discount"
                  label="Discount"
                  rules={[
                    {
                      required: true,
                      message: "Please input promo discount!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item
                  name="limit"
                  label="Limit"
                  rules={[
                    {
                      required: true,
                      message: "Please input promo limit!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Published</Radio>
                    <Radio value={false}>Draft</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default EditPromoCode;
