import React from "react";
import {
  Button,
  Spin,
  Form,
  Input,
  Select,
  Row,
  notification,
  Col,
  Popconfirm,
} from "antd";

import FileUpload from "../../../components/FileUpload/FileUpload";
import { generateEmails } from "../../../network/network";
import MainContext from "../../../Context/AppContext";
import { DeleteOutlined } from "@ant-design/icons";
import { validateEmail } from "../../../utils";

const { Option } = Select;
class SendEmail extends React.Component {
  formRef = React.createRef();
  static contextType = MainContext;

  state = {
    fileObj: false,
    loading: false,
    fileName: "",
    selectedFile: true,
    browse: true,
    buttonDisabled: true,
  };

  handleCSV = (csvFile) => {
    this.setState({
      fileObj: csvFile,
      fileName: csvFile.name,
      selectedFile: true,
    });
  };

  onFinish = (values) => {
    const formData = new FormData();

    if (!this.state.fileObj) {
      notification.error({
        message: "Error",
        description: "Please upload a file",
        placement: "topRight",
      });
    }

    if (values.emails_list === undefined) {
      values.emails_list = this.state.fileObj;
    }

    if (values.cc_lists) {
      values.cc_lists = values.cc_lists.toString();
    }

    formData.append("template_id", values.template_id);
    formData.append("emails_list", values.emails_list);
    formData.append("cc_lists", values.cc_lists);
    formData.append("reply_to_name", values.reply_to_name);
    formData.append("reply_to_email", values.reply_to_email);
    formData.append("platform_name", values.platform_name);
    formData.append("sender_name", values.sender_name);
    formData.append("sender_email", values.sender_email);
    formData.append("unsubscribe_group", values.unsubscribe_group);

    generateEmails(formData, this.context.authDetails?.token)
      .then((res) => {
        notification.success({
          message: "Success",
          description: res.data.message,
          placement: "topRight",
        });

        this.setState({
          loading: true,
          fileObj: false,
        });
        this?.formRef?.current.resetFields();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  removeFile = () => {
    this.setState({
      fileObj: "",
      selectedFile: false,
    });
  };

  render() {
    const emailList = [
      "support@oshaoutreachcourses.com",
      "support@oshaeducationschool.com",
      "support@oshaonlinecenter.com",
      "farhan@oshaoutreachcourses.com",
      "hammad@elearningavenue.com",
      "talha@elearningavenue.com",
      "ali@elearningavenue.com",
    ];

    const { fileName, fileObj } = this.state;

    return (
      <>
        <div className="grid_title">
          <h1>Email Marketing</h1>
        </div>

        <Form
          ref={this.formRef}
          layout="vertical"
          name="basic"
          id="myFormAddss"
          initialValues={{
            remember: true,
          }}
          onFinish={this.onFinish}
          className="send_grid_emails"
          // onFinishFailed={this.onFinishFailed}
        >
          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="sender_name"
                label="Sender Name"
                placeholder="Enter sender name"
                rules={[
                  {
                    required: true,
                    message: "Please input sender name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter sender name" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="sender_email"
                label="Sender Email"
                rules={[
                  {
                    required: true,
                    message: "Please input sender Email!",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter sender email" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="reply_to_name"
                label="Reply-To Name"
                rules={[
                  {
                    required: true,
                    message: "Please input reply name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter reply email" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="reply_to_email"
                label="Reply-To Email"
                rules={[
                  {
                    required: true,
                    message: "Please input reply email!",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter reply email address" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="platform_name"
                label="Website Name"
                rules={[
                  {
                    required: true,
                    message: "Please input platform name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter website name" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="cc_lists"
                rules={[
                  {
                    required: false,
                  },
                  {
                    validator: (val1, val2) => {
                      if (validateEmail(val2.at(-1)) || val2.length == 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "please select valid email address"
                        );
                      }
                    },
                  },
                ]}
                label="Receive campaign result on:"
              >
                <Select
                  mode="tags"
                  placeholder="Enter email address"
                  tokenSeparators={[","]}
                >
                  {emailList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="unsubscribe_group"
                label="Unsubscribe Group From Send Grid"
                rules={[
                  {
                    required: true,
                    message: "Please input unsubscribe group!",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter unsubscribe group" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="template_id"
                label="Template ID From Send Grid"
                rules={[
                  {
                    required: true,
                    message: "Please input template id!",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter template id" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    paddingLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontFamily: "SimSun, sans-serif",
                    }}
                  >
                    *
                  </span>{" "}
                  Select Email List
                </div>
                <Form.Item name="emails_list">
                  <FileUpload
                    handleCSV={this.handleCSV}
                    imageUploadType="emailList"
                    browse={this.state.browse}
                    selectedFile={this.state.selectedFile}
                  />
                </Form.Item>
              </div>
              {fileObj ? (
                <div
                  style={{
                    paddingLeft: "20px",
                  }}
                >
                  <span>{fileName}</span>
                  {"  "}
                  <span>
                    <DeleteOutlined onClick={this.removeFile} />
                  </span>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <div className="send__btn">
            <Popconfirm
              title="Are you sure to send emails"
              placement="topLeft"
              okButtonProps={{
                htmlType: "submit",
                form: "myFormAddss",
              }}
            >
              <Button
                style={{ margin: "10px 0px" }}
                type="primary"
                key="submit"
              >
                Send
              </Button>
            </Popconfirm>
          </div>
        </Form>
      </>
    );
  }
}

export default SendEmail;
