import React from "react";
import MainContext from "../../../Context/AppContext";
import AdminReports from "../../../components/Reports/AdminReports";
import ManagerReports from "../../../components/Reports/ManagerReports";

class Reports extends React.Component {
  state = {};

  static contextType = MainContext;
  componentDidMount() {}

  render() {
    return (
      <>
        {this.context.authDetails.userType === 1 && <AdminReports />}
        {this.context.authDetails.userType === 2 && <ManagerReports />}
      </>
    );
  }
}

export default Reports;
