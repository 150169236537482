import React from "react";
import { Col, Row, Button, Spin } from "antd";
import MainContext from "../../Context/AppContext";
import {
  getStatistics,
  GetRegistrationProgress,
  BuildRegistrationLaunchLink,
  saveCourseProgress,
  downloadCertificate,
} from "../../network/network";
import { LoadingOutlined } from "@ant-design/icons";
import { SecondsToTime, formatDateTime } from "../../helpers/helpers";
import { Progress } from "antd";

class StudentDashboard extends React.Component {
  state = {
    loading: false,
    courses: [],
    assignLoading: false,
  };

  static contextType = MainContext;
  componentDidMount() {
    if (this.context.authDetails.userType === 4) {
      this.getStudentDashboardCourses();

      var url_string = new URL(window.location.href);
      var course_registration_id = url_string.searchParams.get(
        "recent_course_registration_id"
      );
      if (course_registration_id) {
        this.getCourseStatsByRegId(course_registration_id);
      }
    }
  }

  getCourseStatsByRegId = (course_registration_id) => {
    let _this = this;

    setTimeout(() => {
      if (course_registration_id) {
        GetRegistrationProgress(course_registration_id)
          .then((res) => {
            _this.saveCourseProgressStats(res.data, course_registration_id);
          })
          .catch((err) => {});
      }
    }, 2000);
  };

  saveCourseProgressStats = (course_progress_scorm, course_registration_id) => {
    if (course_progress_scorm) {
      let data = {
        completion: course_progress_scorm.activityDetails.activityCompletion,
        status: course_progress_scorm.activityDetails.activitySuccess,
        score: course_progress_scorm.activityDetails.score
          ? course_progress_scorm.activityDetails.score.scaled
          : "0",
        time: course_progress_scorm.totalSecondsTracked,
        firstAccessDate: course_progress_scorm.firstAccessDate,
        lastAccessDate: course_progress_scorm.lastAccessDate,
        completedDate: course_progress_scorm.completedDate,
        expiryDate: "",
        is_failed:
          course_progress_scorm.activitySuccess === "FAILED" ? true : false,
      };

      saveCourseProgress(
        course_registration_id,
        data,
        this.context.authDetails.token
      )
        .then((res) => {
          //
        })
        .catch((err) => {
          //
        });
    }
  };

  getStudentDashboardCourses = () => {
    this.setState({
      loading: true,
    });

    getStatistics(
      this.context.authDetails.token,
      this.context.authDetails.userType
    )
      .then((res) => {
        this.setState({
          courses: res.data.my_courses,
        });
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  launchCourse(course) {
    if (this.context.authDetails.userType === 4) {
      let reg_id = course._id;
      let redirectOnExitUrl =
        window.location.href + `?recent_course_registration_id=${reg_id}`;

      BuildRegistrationLaunchLink(reg_id, redirectOnExitUrl)
        .then((res) => {
          window.location.href = res.data.launchLink;
        })
        .catch((err) => {});
    }
  }
  generatePDF(registration_id) {
    this.setState({ assignLoading: true });
    downloadCertificate(registration_id, this.context.authDetails?.token)
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `certificate.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({ assignLoading: false });
      });
  }

  render() {
    const { courses, loading } = this.state;

    return (
      <>
        <Row style={{ margin: "30px 0" }} gutter={30}>
          <Col className="dashboard-charts ind-dashboard" span={24}>
            <h2>My Courses</h2>
          </Col>
          {loading ? (
            <Col
              span={24}
              type="flex"
              align="middle"
              justify="center"
              style={{ padding: "50px 0" }}
            >
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} />} />
            </Col>
          ) : (
            <>
              {courses &&
                courses.map((obj, i) => (
                  <Col
                    className="dashboard-charts ind-dashboard"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={12}
                    key={i}
                    style={{ marginBottom: "30px" }}
                  >
                    {obj.courses && obj.courses.course_details[0] && (
                      <div className="chart-box">
                        <Row gutter={30}>
                          <Col
                            className="image-wrapper"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={10}
                            xl={10}
                          >
                            <img
                              src={
                                obj.courses.course_details[0].thumbnailImage &&
                                obj.courses.course_details[0].thumbnailImage
                                  .path
                                  ? `${obj.courses.course_details[0].thumbnailImage.path}`
                                  : "/no-image.png"
                              }
                              className=""
                            />
                          </Col>
                          <Col
                            className="desc-wrapper"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={14}
                            xl={14}
                          >
                            <span>
                              {obj.courses.course_details[0].category?.name}
                            </span>
                            <h3> {obj.courses.course_details[0].title}</h3>

                            {obj.courses.course_details[0].completion ===
                              "COMPLETED" && (
                              <Progress strokeLinecap="square" percent={100} />
                            )}

                            {obj.courses.course_details[0].completion !==
                              "COMPLETED" &&
                              obj.courses.course_details[0].time ===
                                "00:00:00" && (
                                <Progress strokeLinecap="square" percent={0} />
                              )}
                            <Row gutter={30} style={{ alignItems: "flex-end" }}>
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                className="desc-wrapper-listing"
                              >
                                <ul>
                                  <li>
                                    <label>Language:</label>
                                    <p>
                                      {obj.courses.course_details[0]
                                        .language === 1
                                        ? "English"
                                        : "Spanish"}
                                    </p>
                                  </li>
                                  <li>
                                    <label>Time Elapsed:</label>
                                    <p>{SecondsToTime(obj.courses.time)}</p>
                                  </li>

                                  {obj.courses.createdAt && (
                                    <li>
                                      <label>Assigned Date:</label>
                                      <p>
                                        {formatDateTime(obj.courses.createdAt)}
                                      </p>
                                    </li>
                                  )}

                                  {(obj.courses.is_failed ||
                                    obj.courses.status === "FAILED") && (
                                    <li>
                                      <label>Progress:</label>
                                      <p
                                        className="st"
                                        style={{
                                          color: "red",
                                          fontWeight: "600",
                                        }}
                                      >
                                        FAILED
                                      </p>
                                    </li>
                                  )}
                                  {!obj.courses.is_failed &&
                                    obj.courses.status !== "FAILED" &&
                                    obj.courses.completion === "COMPLETED" && (
                                      <li>
                                        <label>Progress:</label>
                                        <p
                                          className="st"
                                          style={{
                                            color: "lightgreen",
                                            fontWeight: "600",
                                          }}
                                        >
                                          COMPELETED
                                        </p>
                                      </li>
                                    )}
                                  {!obj.courses.is_failed &&
                                    obj.courses.status !== "FAILED" &&
                                    (obj.courses.time === "0" ||
                                      obj.courses.time === "00:00:00") &&
                                    obj.courses.completion !== "COMPLETED" && (
                                      <li>
                                        <label>Progress:</label>
                                        <p
                                          className="st"
                                          style={{
                                            color: "grey",
                                            fontWeight: "600",
                                          }}
                                        >
                                          NOT STARTED
                                        </p>
                                      </li>
                                    )}

                                  {!obj.courses.is_failed &&
                                    obj.courses.status !== "FAILED" &&
                                    obj.courses.time !== "0" &&
                                    obj.courses.time !== "00:00:00" &&
                                    obj.courses.completion !== "COMPLETED" && (
                                      <li>
                                        <label>Progress:</label>
                                        <p
                                          className="st"
                                          style={{
                                            color: "orange",
                                            fontWeight: "600",
                                          }}
                                        >
                                          IN PROGRESS
                                        </p>
                                      </li>
                                    )}
                                </ul>
                              </Col>
                              {obj.courses.completion === "COMPLETED" &&
                                obj.courses.completedDate !== "" &&
                                obj.courses.course_details[0].portal_type ===
                                  1 && (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    className="button-wrapper std"
                                  >
                                    <a
                                      href="#"
                                      className=""
                                      rel="noreferrer"
                                      // target="_blank"
                                      onClick={() =>
                                        this.generatePDF(obj.courses._id)
                                      }
                                      style={{
                                        padding: 0,
                                        background: "transparent",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <Button
                                        className="ant-btn-primary"
                                        loading={this.state.assignLoading}
                                      >
                                        Download Certificate
                                      </Button>
                                    </a>
                                    {obj.courses.completion === "COMPLETED" &&
                                      obj.courses.certificate_link && (
                                        <Button
                                          onClick={() =>
                                            this.launchCourse(obj.courses)
                                          }
                                        >
                                          View
                                        </Button>
                                      )}
                                  </Col>
                                )}
                              {obj.courses.completion !== "COMPLETED" && (
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  className="button-wrapper std"
                                >
                                  {obj.courses.completion !== "COMPLETED" && (
                                    <Button
                                      onClick={() =>
                                        this.launchCourse(obj.courses)
                                      }
                                    >
                                      {obj.courses.time === "0" ||
                                      obj.courses.time === "00:00:00"
                                        ? "Start"
                                        : "Resume"}
                                    </Button>
                                  )}
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                ))}
            </>
          )}
        </Row>
      </>
    );
  }
}

export default StudentDashboard;
