import React from "react";
import { Form, Input, Button, Col, Spin, notification } from "antd";
import { UpdateCourseDiscount } from "../../../network/network";
import { LoadingOutlined } from "@ant-design/icons";
import MainContext from "../../../Context/AppContext";

class Discounts extends React.Component {
  state = {
    loading: false,

    layout: {
      labelCol: { span: 24 },
      wrapperCol: { span: 16 },
    },
  };

  static contextType = MainContext;

  onFinish = (values) => {
    this.ApplyDiscountOnCourses(1, values);
  };

  ApplyDiscountOnCourses = (value, discount) => {
    this.setState({ loading: true });
    UpdateCourseDiscount(value, discount, this.context.authDetails.token)
      .then((res) => {
        notification.success({
          message: "Discount Applied",
          description: "The Course prices updated successfully.",
          placement: "topRight",
        });
      })

      .catch((err) => {})

      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { layout, loading } = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <>
        <Col className="dashboard-charts" span={24}>
          <h2>Apply Global Discount</h2>
          <div className="chart-box">
            {!loading ? (
              <Form {...layout} name="basicForm" onFinish={this.onFinish}>
                <Form.Item
                  name="discount_percent"
                  label="Discount (%)"
                  rules={[
                    { required: true, message: "Please input course discount" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                  Apply
                </Button>

                <Button
                  type="danger"
                  onClick={() => this.ApplyDiscountOnCourses(2, "")}
                >
                  Restore Original Prices
                </Button>
              </Form>
            ) : (
              <Spin tip="Loading..." indicator={antIcon} />
            )}
          </div>
        </Col>
      </>
    );
  }
}

// };
export default Discounts;
