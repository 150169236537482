import React from "react";
import { Modal, Button, Input, message } from "antd";
import MainContext from "../../../Context/AppContext";
import { generateCertificateLink } from "../../../network/network";
import { CopyOutlined } from "@ant-design/icons";

const { TextArea } = Input;

class CertificateLink extends React.Component {
  state = {
    isModalVisible: false,
    certificate_link: "",
  };
  static contextType = MainContext;

  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };
  componentDidMount() {
    if (this.props.certificate_link) {
      this.setState({
        certificate_link: this.props.certificate_link,
      });
    }
  }

  onChange = (e) => {
    //
    this.setState({
      certificate_link: e.target.value,
    });
  };

  handleOk = () => {
    var data = {
      user_id: this.props.user_id,
      certificate_link: this.state.certificate_link,
    };

    generateCertificateLink(
      data,
      this.props.data_id,
      this.context.authDetails?.token
    ).then((res) => {
      this.props.onChildClick();
      this.setState({
        isModalVisible: false,
      });
      message.success("Certificate link generated successfully");
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  render() {
    return (
      <>
        <Button
          title="Generate Certificate "
          type="primary"
          onClick={this.showModal}
          className="course-certificate"
        >
          <CopyOutlined />
        </Button>

        <Modal
          title="Generate Certificate Link"
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="Generate Link"
        >
          <TextArea
            placeholder="Enter Certificate Link"
            onChange={this.onChange}
            autoSize
            defaultValue={this.props.certificate_link}
          />
        </Modal>
      </>
    );
  }
}

export default CertificateLink;
