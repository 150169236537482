import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Radio,
  Button,
  notification,
  message,
  Modal,
} from "antd";
import { saveUserData } from "../../../network/network";
import MainContext from "../../../Context/AppContext";
const { Option } = Select;
const confirm = Modal.confirm;
class AddUser extends React.Component {
  formRef = React.createRef();
  state = {
    isBDMSelected: false,
  };
  static contextType = MainContext;
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  addNewUser = (values) => {
    saveUserData(values, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "User Created",
          description: "The user has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch((error) => {
        if (error && error.response.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          let errorMessages = Object.values(error.response.data.message);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      });
  };

  onFinish = (values) => {
    let selectedRole = "";
    if (values.userType == 1) {
      selectedRole = "ADMIN";
    } else if (values.userType == 2) {
      selectedRole = "MANAGER";
    } else if (values.userType == 4) {
      selectedRole = "USER";
    } else if (values.userType == 3) {
      selectedRole = "SALES";
    }

    if (selectedRole !== "USER") {
      confirm({
        title: "User Role",
        content: (
          <p>
            You have Selected role as <b>{selectedRole}</b>, Are you sure?
          </p>
        ),
        okText: "OK",
        onOk: () => {
          this.addNewUser(values);
        },
      });
    } else {
      this.addNewUser(values);
    }
  };

  onFinishFailed = (errorInfo) => {};

  render() {
    return (
      <div>
        <Button
          style={{ float: "right" }}
          type="default"
          onClick={this.showModal}
          icon={<PlusOutlined />}
          size="large"
        >
          Create User
        </Button>

        <Modal
          title="Create User"
          visible={this.state.visible}
          // onOk={this.hideModal}
          onCancel={this.hideModal}
          width={2000}
          centered
          footer={[
            <Button
              form="myFormAdd"
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Create
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id="myFormAdd"
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your firstname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your lastname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="userName"
                  label="User Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your user name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  getValueFromEvent={(val) => {
                    if (val == 3) {
                      this.setState({ isBDMSelected: true });
                    } else {
                      this.setState({ isBDMSelected: false });
                    }
                    return val;
                  }}
                  name="userType"
                  label="Role"
                  initialValue={"4"}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select User role" allowClear>
                    <Option value="1">Admin</Option>
                    <Option value="2">Manager</Option>
                    <Option value="3">Sales</Option>
                    <Option value="4">User</Option>
                  </Select>
                </Form.Item>

                {this.state.isBDMSelected && (
                  <Form.Item
                    name="bdm_no"
                    label="BDM No"
                    rules={[
                      {
                        required: true,
                        message: "Please input BDM No!",
                      },
                    ]}
                  >
                    <Input type="number" min="1" />
                  </Form.Item>
                )}

                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="contactNo"
                  label="Phone Number"
                  rules={[
                    {
                      required: false,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    addonBefore={
                      <Form.Item name="prefix" noStyle>
                        <Select
                          style={{
                            width: 70,
                          }}
                        >
                          <Option value="86">+86</Option>
                          <Option value="87">+87</Option>
                        </Select>
                      </Form.Item>
                    }
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="address"
                  label="Address"
                  tooltip="Provide your complete address?"
                  rules={[
                    {
                      required: false,
                      message: "Please input your address!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: false,
                      message: "Please input your city!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: false,
                      message: "Please input your state!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    {
                      required: false,
                      message: "Please input your country!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="zipCode"
                  label="Zipcode"
                  rules={[
                    {
                      required: false,
                      message: "Please input your zipcode!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="companyName"
                  label="Company"
                  rules={[
                    {
                      required: false,
                      message: "Please input your company!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="is_blocked"
                  label="User Account"
                  rules={[
                    {
                      required: true,
                      message: "User Account is required",
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup">
                    <Radio value={false}>Active</Radio>
                    <Radio value={true}>Disabled</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default AddUser;
