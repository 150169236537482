import React from "react";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import CKEditor from "ckeditor4-react";
import {
  Spin,
  Form,
  Modal,
  Image,
  Input,
  Row,
  Radio,
  message,
  Col,
  Button,
  Checkbox,
  notification,
  Select,
} from "antd";
import MainContext from "../../../Context/AppContext";

import { storeCourse, getParentCategories } from "../../../network/network";
const { TextArea } = Input;

class AddCourse extends React.Component {
  formRef = React.createRef();
  state = {
    visible: false,
    courseScormId: "",
    thumbnailCourse: "",
    courseOGid: "",
    courseThumbnailPath: "",
    courseOGpath: "",
    courseFieldObj: {},
    categories: [],
    faqInputList: [{ question: "", answer: "" }],
    courseDescription: "",
    courseOutlines: "",
    courseObjectives: "",
    courseFeatures: "",
    courseAudience: "",
    durationValidation: false,
  };
  static contextType = MainContext;

  showModal = () => {
    getParentCategories(this.context.authDetails?.token).then((res) => {
      this.setState({
        categories: res.data,
        visible: true,
      });
    });
  };

  hideModal = (close) => {
    this.setState({
      visible: false,
    });
  };

  handleUpload = ({ thumbnailImage }) => {
    this.setState({ thumbnailImage });
  };
  onFinish = (values) => {
    if (values.minutes > 0 || values.hours > 0) {
      this.setState({
        durationValidation: false,
      });
    } else {
      return this.setState({
        durationValidation: true,
      });
    }
    // if (this.state.courseScormId === "") {
    //   message.error("Upload Course File.");
    //   return false;
    // }

    var faqs = this.state.faqInputList.filter(
      (faq) => faq.question !== "" && faq.answer !== ""
    );

    const formData = new FormData();
    formData.append("thumbnailImage", this.state.thumbnailCourse);
    formData.append("ogImage", this.state.courseOGid);
    formData.append("courseType", "SCORM");
    formData.append("search_terms", values.search_terms);
    formData.append("scorm_course_id", this.state.courseScormId);
    formData.append("title", values.title);
    formData.append("description", this.state.courseDescription);
    if (values.includes_text !== "") {
      formData.append("includes_text", values.includes_text);
    }

    formData.append("excerpt", values.Excerpt);
    formData.append("order", values.order);
    formData.append("hours", values.hours);
    formData.append("minutes", values.minutes);
    formData.append("category", values.category);
    formData.append("price", values.price);
    formData.append("status", values.status);
    formData.append("portal_type", values.portal_type);
    formData.append("discounted_price", values.discounted_price);
    formData.append("language", values.language);
    formData.append("level", values.level);
    formData.append("objectives", this.state.courseObjectives);
    formData.append("outline", this.state.courseOutlines);
    formData.append("features", this.state.courseFeatures);
    formData.append("audience", this.state.courseAudience);
    formData.append("recommended", values.recommended ? true : false);
    formData.append("new_course", values.new_course ? true : false);
    formData.append("popular", values.popular ? true : false);
    formData.append("faqs", JSON.stringify(faqs));
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);
    this.setState({ loading: true });
    storeCourse(formData, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Course Created",
          description: "The Course has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch(function (error) {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onFinishFailed = (errorInfo) => {
    let errorMessages = errorInfo.errorFields;
    errorMessages.forEach(function (key, value) {
      let temp = Object.values(key);
      message.error(temp[1]);
    });
  };

  handleCourseThumbnailID = (courseThumbnailID, courseThumbnailPath) => {
    this.setState({
      thumbnailCourse: courseThumbnailID,
      courseThumbnailPath: courseThumbnailPath,
    });
  };
  handleCourseOGImageID = (courseOGid, courseOGpath) => {
    this.setState({
      courseOGid: courseOGid,
      courseOGPath: courseOGpath,
    });
  };

  // handle input change
  handleFaqInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.faqInputList];
    list[index][name] = value;
    this.setState({ faqInputList: list });
  };

  // handle click event of the Remove button
  handleFaqRemoveClick = (index) => {
    const list = [...this.state.faqInputList];
    list.splice(index, 1);
    this.setState({ faqInputList: list });
  };

  // handleFaq click event of the Add button
  handleFaqAddClick = () => {
    this.setState({
      faqInputList: [...this.state.faqInputList, { question: "", answer: "" }],
    });
  };

  handleCourseFileObj = (courseObj, courseID) => {
    this.setState({ courseFieldObj: courseObj, courseScormId: courseID });
  };

  handlebannerImageID = (bannerImageID) => {
    this.setState({ bannerImageId: bannerImageID });
  };

  courseAudienceUpdate = (evt) => {
    this.setState({
      courseAudience: evt.editor.getData(),
    });
  };

  courseDescriptionUpdate = (evt) => {
    this.setState({
      courseDescription: evt.editor.getData(),
    });
  };

  courseObjectivesUpdate = (evt) => {
    this.setState({
      courseObjectives: evt.editor.getData(),
    });
  };

  courseOutlineUpdate = (evt) => {
    this.setState({
      courseOutlines: evt.editor.getData(),
    });
  };
  courseFeaturesUpdate = (evt) => {
    this.setState({
      courseFeatures: evt.editor.getData(),
    });
  };

  render() {
    const { faqInputList, durationValidation } = this.state;

    const childCategories = this.props.categories.filter((val) => {
      return val.name !== "Other";
    });

    return (
      <div>
        <Button
          style={{ float: "right", margin: "10px" }}
          type="primary"
          onClick={this.showModal}
          icon={<PlusOutlined />}
          size="large"
        ></Button>

        <Modal
          title="Create Course"
          visible={this.state.visible}
          onOk={this.onFinish}
          width={2000}
          centered
          maskClosable="false"
          onCancel={this.hideModal}
          okText="Create"
          cancelText="Cancel"
          footer={[
            this.state.loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} />} />
            ) : (
              <Button
                form="myFormAdd"
                type="primary"
                key="submit"
                htmlType="submit"
              >
                Store
              </Button>
            ),
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                layout="vertical"
                name="basic"
                id="myFormAdd"
                initialValues={{
                  remember: true,
                  minutes: 0,
                  hours: 0,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please input course title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="search_terms"
                  label="Search terms"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="includes_text"
                  label="Course Include"
                  rules={[
                    {
                      required: false,
                      message: "Please input alternate course slug!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input course description",
                    },
                  ]}
                >
                  <CKEditor data="" onChange={this.courseDescriptionUpdate} />
                </Form.Item>
                <Form.Item
                  name="Excerpt"
                  label="Excerpt"
                  rules={[
                    {
                      required: true,
                      max: 150,
                    },
                  ]}
                >
                  {/* <input data="" onChange={this.courseExcerptUpdate} /> */}
                  <Input type="text" max="150" />
                </Form.Item>

                <div
                  className="ant-modal-title"
                  style={{ marginBottom: "20px" }}
                >
                  Course Duration
                </div>
                <div className="course-druation">
                  <div>
                    <Form.Item
                      name="hours"
                      label="Hours"
                      rules={[
                        {
                          required: true,
                          message: "Please input course hours!",
                          // whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          border: `${
                            durationValidation ? "1px solid red" : ""
                          }`,
                        }}
                        type="number"
                        onChange={() =>
                          this.setState({ durationValidation: false })
                        }
                        min="0"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      name="minutes"
                      label="Minutes"
                      rules={[
                        {
                          required: true,
                          message: "Please input course minutes!",
                          // whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          border: `${
                            durationValidation ? "1px solid red" : ""
                          }`,
                        }}
                        type="number"
                        onChange={() =>
                          this.setState({ durationValidation: false })
                        }
                        min="0"
                        max="60"
                      />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item
                  name="level"
                  label="Levels"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={1} key={1}>
                      Introductory
                    </Select.Option>
                    <Select.Option value={2} key={2}>
                      Intermediate
                    </Select.Option>
                    <Select.Option value={3} key={3}>
                      Advance
                    </Select.Option>
                    <Select.Option value={4} key={4}>
                      Certificate
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="language"
                  label="Language"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={1} key={1}>
                      English
                    </Select.Option>
                    <Select.Option value={2} key={2}>
                      Spanish
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="objectives"
                  label="Objectives"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <CKEditor data="" onChange={this.courseObjectivesUpdate} />
                </Form.Item>

                <Form.Item
                  name="outline"
                  label="Outline"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <CKEditor data="" onChange={this.courseOutlineUpdate} />
                </Form.Item>

                <Form.Item
                  name="features"
                  label="Features & Benefits"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <CKEditor data="" onChange={this.courseFeaturesUpdate} />
                </Form.Item>

                <Form.Item
                  name="audience"
                  label="Audience"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <CKEditor data="" onChange={this.courseAudienceUpdate} />
                </Form.Item>

                <Form.Item
                  name="order"
                  label="Order"
                  rules={[
                    {
                      required: true,
                      message: "Please input integer value for ordering!",
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    {
                      required: true,
                      message: "Please input for price!",
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item
                  name="discounted_price"
                  label="Discounted Price"
                  rules={[
                    {
                      required: true,
                      message: "Please input for discounted price!",
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item label="Thumbnail Image">
                  {this.state.courseThumbnailPath && (
                    <Image width={200} src={this.state.courseThumbnailPath} />
                  )}

                  <FileUpload
                    onCourseThumbnailID={this.handleCourseThumbnailID}
                    imageUploadType="courseThumbnail"
                    type="image"
                  />
                </Form.Item>

                <Form.Item label="OG Image">
                  {this.state.courseOGpath && (
                    <Image width={200} src={this.state.courseOGpath} />
                  )}

                  <FileUpload
                    onCourseOgID={this.handleCourseOGImageID}
                    imageUploadType="courseOGImage"
                    type="image"
                  />
                </Form.Item>

                <Form.Item label="Course File">
                  <FileUpload
                    onCourseFileObj={this.handleCourseFileObj}
                    imageUploadType="courseFile"
                    type="courseFile"
                  />
                </Form.Item>

                <Form.Item
                  label="Categories"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please input Category!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select>
                    {childCategories.map((item, index) => (
                      <Select.Option value={item._id} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="recommended" valuePropName="checked">
                  <Checkbox>Mark as Recommended Course</Checkbox>
                </Form.Item>
                <Form.Item name="new_course" valuePropName="checked">
                  <Checkbox>Mark as New Course</Checkbox>
                </Form.Item>

                <Form.Item name="popular" valuePropName="checked">
                  <Checkbox>Mark as Popular Course</Checkbox>
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Course status is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup">
                    <Radio value={"1"}>Published</Radio>
                    <Radio value={"0"}>Unpublished</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="portal_type"
                  label="Portal type"
                  rules={[
                    {
                      required: true,
                      message: "Course status is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup">
                    <Radio value={"1"}>LMS</Radio>
                    <Radio value={"2"}>NON-LMS</Radio>
                  </Radio.Group>
                </Form.Item>

                <div
                  className="ant-modal-title"
                  style={{ marginBottom: "20px" }}
                >
                  Course SEO Details
                </div>

                <Form.Item
                  name="seo_title"
                  label="SEO Title"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO title!",
                      whitespace: true,
                      max: 120,
                      min: 30,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="SEO Description"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO description!",
                      whitespace: true,
                      max: 160,
                      min: 110,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="SEO Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input course SEO Keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>

              <div className="ant-modal-title" style={{ marginBottom: "20px" }}>
                Course Faq's
              </div>

              {faqInputList.map((x, i) => {
                return (
                  <div className="box" key={i}>
                    <label>Question</label>
                    <TextArea
                      style={{ margin: "10px 0px" }}
                      placeholder="Enter Faq Question"
                      name="question"
                      onChange={(e) => this.handleFaqInputChange(e, i)}
                      value={x.question}
                    />
                    {/* <input
                    
                      name="question"
                      placeholder="Enter First Name"
                      value={x.question}
                      onChange={(e) => this.handleFaqInputChange(e, i)}
                    /> */}
                    <label>Answer</label>
                    <TextArea
                      style={{ margin: "10px 0px" }}
                      className="ml10"
                      name="answer"
                      placeholder="Enter Faq Answer"
                      value={x.answer}
                      onChange={(e) => this.handleFaqInputChange(e, i)}
                    />
                    <div className="btn-box">
                      {faqInputList.length !== 1 && (
                        <Button
                          style={{ margin: "10px 10px 10px 0" }}
                          type="primary"
                          danger
                          className="mr10"
                          onClick={() => this.handleFaqRemoveClick(i)}
                        >
                          Remove
                        </Button>
                      )}

                      {faqInputList.length - 1 === i && (
                        <Button
                          style={{ margin: "10px 0px" }}
                          type="primary"
                          onClick={this.handleFaqAddClick}
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default AddCourse;
