import React from "react";
import AdminCourses from "../../../components/Courses/AdminCourses";
import StudentCourses from "../../../components/Courses/StudentCourses";
import ManagerCourses from "../../../components/Courses/ManagerCourses";
import MainContext from "../../../Context/AppContext";
class Courses extends React.Component {
  state = {};
  static contextType = MainContext;

  render() {
    return (
      <>
        {(this.context.authDetails.userType === 1 ||
          this.context.authDetails.userType === 5) && <AdminCourses />}
        {this.context.authDetails.userType === 4 && <StudentCourses />}
        {this.context.authDetails.userType === 2 && <ManagerCourses />}
      </>
    );
  }
}

export default Courses;
