import React from "react";
import { Link } from "react-router-dom";
import { CanadianTime, getCurrentDateTime } from "../../helpers/helpers";
import { Col, Row, Table } from "antd";
import { Column } from "@ant-design/charts";
import MainContext from "../../Context/AppContext";
import moment from "moment";
import { getStatistics } from "../../network/network";

class AdminDashboard extends React.Component {
  state = {
    loading: false,
    currentTime: getCurrentDateTime(),
    selling_courses_data: [],
    selling_courses_columns: [
      {
        title: "#",
        width: "10%",
        align: "center",
        render: (text, record, index) => {
          return index + 1;
        },
      },
      {
        title: "Courses (s)",
        width: "50%",
        align: "left",
        render: (record) => {
          return <p>{record.courseName}</p>;
        },
      },
      {
        title: "No. of Sales  ",
        width: "20%",
        align: "center",
        render: (record) => {
          return record.totalQty;
        },
      },
      {
        title: "Amount",
        width: "20%",
        align: "right",
        render: (record) => {
          return "$" + record.totalSales.toFixed(2);
        },
      },
    ],
    recent_orders_data: [],
    recent_orders_columns: [
      {
        title: "Order-ID",
        render: (record) => {
          return (
            <Link to={`/orders?order_id=${record._id}`}>
              {record.buying_for === "Group" &&
                `${process.env.REACT_APP_COMPANY_SHORT_NAME}-G-${record.order_id
                  .toString()
                  .padStart(5, "0")}`}
              {(!record.buying_for || record.buying_for === "Yourself") &&
                `${process.env.REACT_APP_COMPANY_SHORT_NAME}-I-${record.order_id
                  .toString()
                  .padStart(5, "0")}`}
              {record.buying_for === "Someone" &&
                `${process.env.REACT_APP_COMPANY_SHORT_NAME}-S-${record.order_id
                  .toString()
                  .padStart(5, "0")}`}
            </Link>
          );
        },
      },
      {
        title: "Date / Time",
        render: (record) => {
          return <p>{CanadianTime(record.updatedAt)}</p>;
        },
      },
      {
        title: "Contact Details",
        dataIndex: "users",
        render: (text, record) => {
          let order_details = record.user ? record.user : record;

          return (
            <>
              {order_details.firstName && order_details.lastName && (
                <p>
                  <b>Full Name: </b> {order_details.firstName}{" "}
                  {order_details.lastName}{" "}
                </p>
              )}
              {order_details.email && (
                <p>
                  <b>Email: </b> {order_details.email}{" "}
                </p>
              )}
              {order_details.contactNo && (
                <p>
                  <b>Contact: </b> {order_details.contactNo}
                </p>
              )}
              {order_details.userName && (
                <p>
                  <b>Username: </b> {order_details.userName}
                </p>
              )}
              {order_details.password && (
                <p>
                  <b>Password: </b> {order_details.password}
                </p>
              )}
              {(order_details.address ||
                order_details.city ||
                order_details.state ||
                order_details.country ||
                order_details.zipCode) && (
                <p>
                  <b>Address: </b>

                  {order_details.address && `${order_details.address}, `}
                  {order_details.city && `${order_details.city}, `}
                  {order_details.state && `${order_details.state}, `}
                  {order_details.country && order_details.country}
                  {order_details.zipCode && ` - ${order_details.zipCode}`}
                </p>
              )}
            </>
          );
        },
      },

      {
        title: "Courses (s)",
        render: (text, record) => {
          let courses = [];
          record.items.forEach((element) => {
            courses.push({
              title: element.title,
              quantity: element.quantity,
              price: element.price,
            });
          });

          return (
            <>
              {courses.map((obj, i) => (
                <div key={i}>
                  <p>{obj.title}</p>
                  <p>
                    {" "}
                    <b>Qty: </b> {obj.quantity}, <b>Price/Unit: </b> $
                    {obj.price.toFixed(2)}{" "}
                  </p>
                </div>
              ))}
            </>
          );
        },
      },
      {
        title: "Amount",
        render: (record) => {
          return "$" + record.total.toFixed(2);
        },
      },
      {
        title: "Status",
        filters: [
          {
            text: "Paid",
            value: "Paid",
          },
          {
            text: "UnPaid",
            value: "UnPaid",
          },
          {
            text: "Refunded",
            value: "Refunded",
          },
        ],
        onFilter: (value, record) => {
          if (value === "Paid") {
            return record.isPaid && !record.is_refunded;
          } else if (value === "UnPaid") {
            return !record.isPaid;
          } else if (value === "Refunded") {
            return record.is_refunded;
          }
        },
        render: (record) => {
          if (record.is_refunded) {
            return <p style={{ color: "red", fontWeight: "600" }}>REFUNDED</p>;
          } else if (record.isPaid) {
            return <p style={{ color: "#42a400", fontWeight: "600" }}>PAID</p>;
          } else if (!record.isPaid) {
            return (
              <p style={{ color: "#c48600", fontWeight: "600" }}>UNPAID</p>
            );
          }
        },
      },
      // {
      //   title: "Action",
      //   dataIndex: "",
      //   key: "x",
      //   render: (text, record) => (
      //     <Space size="middle">
      //       <Link to={"/view/order/" + record._id}>View Invoice</Link>
      //     </Space>
      //   ),
      // }
    ],

    monthWiseSales: {
      data: [],
      xField: "type",
      yField: "sales",
      label: {
        position: "middle",
        style: {
          fill: "#FFFFFF",
          opacity: 0.6,
        },
      },
      meta: {
        type: { alias: "Column" },
        sales: { alias: "Order Payment ($)" },
      },
    },

    monthWiseOrders: {
      data: [],
      xField: "type",
      yField: "sales",
      label: {
        position: "middle",
        style: {
          fill: "#FFFFFF",
          opacity: 0.6,
        },
      },
      meta: {
        type: { alias: "Column" },
        sales: { alias: "Order Count " },
      },
    },

    total_paid_orders_this_month: {},
    total_paid_orders_this_week: {},
    total_paid_orders_today: {},

    dailySales: {
      data: [],
      xField: "type",
      yField: "sales",
      label: {
        position: "middle",
        style: {
          fill: "#FFFFFF",
          opacity: 0.6,
        },
      },
      meta: {
        type: { alias: "Column" },
        sales: { alias: "Order Payment ($)" },
      },
    },

    weeklySales: {
      data: [],
      xField: "type",
      yField: "sales",
      label: {
        position: "middle",
        style: {
          fill: "#FFFFFF",
          opacity: 0.6,
        },
      },
      meta: {
        type: { alias: "Column" },
        sales: { alias: "Order Payment ($)" },
      },
    },
  };

  static contextType = MainContext;
  componentDidMount() {
    if (
      this.context.authDetails.userType === 1 ||
      this.context.authDetails.userType === 3
    ) {
      this.getStats();
    }

    let _this = this;
    let intervalId = setInterval(() => {
      _this.setState({ currentTime: getCurrentDateTime() });
    }, 5000);
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getStats = () => {
    this.setState({
      loading: true,
    });

    getStatistics(
      this.context.authDetails.token,
      this.context.authDetails.userType
    )
      .then((res) => {
        var orders_today = res.data.total_paid_orders_today;

        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // let recentOrders = res.data.recent_orders;
        // var order_date = "";

        // recentOrders.forEach((element) => {
        //   if (element.isPaid) {
        //     order_date = new Date(element.updatedAt).toLocaleString("en-US", { timeZone: 'Canada/Eastern' }).split(",")[0];

        //     if (order_date === currentDate) {

        //       orders_today.order_count = orders_today.order_count + 1;
        //       orders_today.paymentSum = orders_today.paymentSum + element.total;
        //     }

        //   }
        // });

        let paidOrderSumArr = this.state.monthWiseSales;
        let paidOrderCountArr = this.state.monthWiseOrders;
        let tempData = [...res.data.total_sales_count_by_month];
        let daily_sales = res.data.total_sales_count_by_daily;
        let weekly_sales = res.data.total_sales_count_by_week;

        tempData.forEach((element) => {
          paidOrderSumArr.data.push({
            type: `${months[element._id.month - 1]} ${element._id.year}`,
            sales: element.totalSaleAmount,
          });

          paidOrderCountArr.data.push({
            type: `${months[element._id.month - 1]}  ${element._id.year}`,
            sales: element.count,
          });
        });

        Date.prototype.addDays = function (days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };

        let dailysalesChartData = this.state.dailySales;
        let weeklySalesChartData = this.state.weeklySales;

        daily_sales.forEach((element) => {
          dailysalesChartData.data.push({
            type: `${element._id.day}/${element._id.month}`,
            sales: element.totalSaleAmount,
          });
        });

        weekly_sales.forEach((element) => {
          var start_day = moment()
            .year(element._id.year)
            .startOf("isoWeek")
            .isoWeek(element._id.week)
            .format("D/M");
          var last_day = moment()
            .year(element._id.year)
            .endOf("isoWeek")
            .isoWeek(element._id.week)
            .format("D/M");

          weeklySalesChartData.data.push({
            type: ` ${start_day} - ${last_day}`,
            sales: element.totalSaleAmount,
          });
        });

        this.setState({
          total_paid_orders_today: orders_today,
          recent_orders_data: res.data.recent_orders,
          selling_courses_data: res.data.top_selling_courses,
          total_paid_orders_this_month: res.data.total_paid_orders_this_month,
          total_paid_orders_this_week: res.data.total_paid_orders_this_week,
          monthWiseSales: paidOrderSumArr,
          monthWiseOrders: paidOrderCountArr,
          dailySales: dailysalesChartData,
          weeklySales: weeklySalesChartData,
        });
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const {
      loading,
      monthWiseSales,
      dailySales,
      total_paid_orders_this_month,
      total_paid_orders_this_week,
      total_paid_orders_today,
      monthWiseOrders,
      weeklySales,
    } = this.state;

    return (
      <>
        <Row
          style={{ marginBottom: 16 }}
          gutter={16}
          style={{ justifyContent: "flex-end", textAlign: "right" }}
        >
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <p className="datetime">
              <b>Current Time:</b> {this.state.currentTime}
            </p>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <div className="stats">
              <div className="stats-heading " style={{ background: "#0083B7" }}>
                <h2>Today</h2>
              </div>
              <div className="stats-heading">
                <h3>
                  {Object.keys(total_paid_orders_today).length > 0 &&
                  total_paid_orders_today.constructor === Object
                    ? total_paid_orders_today.count
                    : 0}
                </h3>
                <p>Orders Received</p>
              </div>

              <div className="stats-heading">
                <h3>
                  {Object.keys(total_paid_orders_today).length > 0 &&
                  total_paid_orders_today.constructor === Object
                    ? "$" +
                      parseFloat(
                        total_paid_orders_today.totalSaleAmount
                      ).toFixed(2)
                    : 0}
                </h3>
                <p>Payments Received</p>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <div className="stats">
              <div className="stats-heading" style={{ background: "#006f9b" }}>
                <h2>This Week</h2>
              </div>
              <div className="stats-heading">
                <h3>
                  {Object.keys(total_paid_orders_this_week).length > 0 &&
                  total_paid_orders_this_week.constructor === Object
                    ? total_paid_orders_this_week.count
                    : 0}
                </h3>

                <p>Orders Received</p>
              </div>
              <div className="stats-heading">
                <h3>
                  {Object.keys(total_paid_orders_this_week).length > 0 &&
                  total_paid_orders_this_week.constructor === Object
                    ? "$" +
                      parseFloat(
                        total_paid_orders_this_week.totalSaleAmount
                      ).toFixed(2)
                    : 0}
                </h3>
                <p>Payments Received</p>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <div className="stats">
              <div className="stats-heading" style={{ background: "#00638b" }}>
                <h2>This Month</h2>
              </div>
              <div className="stats-heading">
                <h3>
                  {Object.keys(total_paid_orders_this_month).length > 0 &&
                  total_paid_orders_this_month.constructor === Object
                    ? total_paid_orders_this_month.count
                    : 0}
                </h3>
                <p>Orders Received</p>
              </div>
              <div className="stats-heading">
                <h3>
                  {Object.keys(total_paid_orders_this_month).length > 0 &&
                  total_paid_orders_this_month.constructor === Object
                    ? "$" +
                      parseFloat(
                        total_paid_orders_this_month.totalSaleAmount
                      ).toFixed(2)
                    : 0}
                </h3>
                <p>Payments Received</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ margin: "30px 0" }} gutter={30}>
          <Col
            className="dashboard-charts"
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
          >
            <h2>Recent Orders</h2>
            <div className="table-box">
              <Table
                className="recent-orders admin-recent-orders"
                columns={this.state.recent_orders_columns}
                rowKey={(record) => record._id}
                dataSource={
                  this.state.recent_orders_data
                    ? this.state.recent_orders_data
                    : []
                }
                loading={loading}
                pagination={false}
              />
            </div>
          </Col>

          <Col
            className="dashboard-charts db-charts-main"
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
          >
            <Row gutter={30}>
              <Col
                style={{ marginBottom: "20px" }}
                className="dashboard-charts"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <h2>Daily Sales</h2>
                <div className="chart-box">
                  {dailySales.data && dailySales.data.length > 0 && (
                    <Column {...dailySales} />
                  )}
                </div>
              </Col>

              <Col
                style={{ marginBottom: "20px" }}
                className="dashboard-charts"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <h2>Weekly Sales</h2>
                <div className="chart-box">
                  {weeklySales.data && weeklySales.data.length > 0 && (
                    <Column {...weeklySales} />
                  )}
                </div>
              </Col>

              <Col
                style={{ marginBottom: "20px" }}
                className="dashboard-charts"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <h2>Monthly Sales</h2>
                <div className="chart-box">
                  {monthWiseSales.data && monthWiseSales.data.length > 0 && (
                    <Column {...monthWiseSales} />
                  )}
                </div>
              </Col>

              <Col
                style={{ marginBottom: "20px" }}
                className="dashboard-charts"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <h2>Top Selling Courses (All Time)</h2>
                <div className="table-box top-selling-courses">
                  <Table
                    className="recent-orders"
                    scroll={{ y: 468, x: 0 }}
                    columns={this.state.selling_courses_columns}
                    rowKey={(record) => record._id}
                    dataSource={
                      this.state.selling_courses_data
                        ? this.state.selling_courses_data
                        : []
                    }
                    loading={loading}
                    pagination={false}
                  />
                </div>
              </Col>

              <Col
                className="dashboard-charts"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <h2>Month-Wise Orders</h2>
                <div className="chart-box">
                  {monthWiseOrders.data && monthWiseOrders.data.length > 0 && (
                    <Column {...monthWiseOrders} />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Col span={6}>
   <div className="stats">
     <div className="stats-heading" style={{background:"#0083B7"}}>
       <h2>Active Users</h2>
     </div>
     <div className="stats-heading">
     <h3>{ (Object.keys(total_paid_orders_month).length > 0 && total_paid_orders_month.constructor === Object) ? total_paid_orders_month.count :  0}</h3>
          <p>Orders Received</p>
     </div>
     <div className="stats-heading">
        <h3>{ (Object.keys(total_paid_orders_today).length > 0 && total_paid_orders_today.constructor === Object) ? 15 :  0}</h3>
       <p>Payments Received</p>
     </div>
   </div>
</Col> */}
        {/* <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Orders"
                valueStyle={{ color: "#000" }}
                value={data.total_orders}
                prefix={<BarsOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Payments"
                value={data.total_sum_paid_orders ? parseFloat(data.total_sum_paid_orders).toFixed(2) : "0.00"}
                valueStyle={{ color: "#1890ff" }}
                prefix={<DollarCircleOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Courses"
                value={data.total_courses}
                valueStyle={{ color: "#ff1414" }}
                prefix={<CopyOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Categories"
                value={data.total_categories}
                valueStyle={{ color: "#3f8600" }}
                prefix={<AppstoreOutlined />}
              />
            </Card>
          </Col>
        </Row>

        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={data.latest_users ? data.latest_users : []}
          loading={loading}
        />

        <Row style={{ marginBottom: 16 }} gutter={30}>

          {monthWiseSales.data.length > 0 && (
            <>
              <Col span={24}>
              <h2>Order Payment Amount (Last 30 days)</h2>
                <div className="chart-box">
                  <Column {...monthWiseSales} />
                </div>
              </Col>
            </>
          )}


          {monthWiseSales.data.length > 0 && (
            <>
              <Col span={24}>
              <h2>Order Payment Count (Last 30 days)</h2>
                <div className="chart-box">
                  <Column {...monthWiseOrders} />
                </div>
              </Col>
            </>
          )}


        </Row>
  */}
      </>
    );
  }
}

export default AdminDashboard;
