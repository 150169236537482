import React from "react";
import { message, notification, Button, Modal, AutoComplete } from "antd";
import {
  addTraineesToManager,
  getCanBeTraineesData,
} from "../../../network/network";
import MainContext from "../../../Context/AppContext";
import { DeleteTwoTone } from "@ant-design/icons";

class AddTrainees extends React.Component {
  formRef = React.createRef();
  state = {
    visible: true,
    canBeTraineesData: [],
    traineesManager: false,
    addAsTraineesData: [],
    autocompleteValue: "",
  };
  static contextType = MainContext;

  componentDidMount() {
    if (this.props.traineesManager) {
      this.setState({ traineesManager: this.props.traineesManager });
      this.getAllUsersCanBeTrainees();
    }
  }
  getAllUsersCanBeTrainees = () => {
    // Get All Users Data that can be Trainees (Have No Manager ID)
    getCanBeTraineesData(this.context.authDetails.token)
      .then((res) => {
        this.setState({
          canBeTraineesData: res.data,
        });
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          message.error(error.response.data.message);
        }
      });
  };
  handleAddTrainees = () => {
    addTraineesToManager(
      {
        _id: this.state.traineesManager._id,
        trainees: this.state.addAsTraineesData,
      },
      this.context.authDetails.token
    )
      .then((res) => {
        this.setState({
          addAsTraineesData: [],
        });

        this.getAllUsersCanBeTrainees();

        notification.success({
          message: "Trainees Added to Manager",
          description: "Trainees Added to Manager Successfully!",
          placement: "topRight",
        });
        this.props.closeTrainees();
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          message.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.setState({
          visible: false,
        });
      });
  };

  handleRemoveTrainee = (traineeId) => {
    this.setState({
      addAsTraineesData: this.state.addAsTraineesData.filter(
        (trainee) => trainee._id !== traineeId
      ),
    });
  };

  render() {
    return (
      <Modal
        title="Add Trainees"
        visible={this.state.visible}
        onOk={this.handleAddTrainees}
        onCancel={() => {
          this.setState({ visible: false });
          this.props.closeTrainees();
        }}
      >
        <strong>Manager's Name:</strong> {this.state.traineesManager.firstName}{" "}
        {this.state.traineesManager.lastName}
        <br />
        <br />
        {this.state.traineesManager && this.state.traineesManager.companyName && (
          <>
            <p>
              <strong>Manager's Company:</strong>{" "}
              {this.state.traineesManager.companyName}
            </p>
            <br />
            <br />
          </>
        )}
        <label>Search Trainees:</label>
        <AutoComplete
          style={{ width: "100%" }}
          onChange={(value) => {
            this.setState({
              autocompleteValue: value,
            });
          }}
          value={this.state.autocompleteValue}
          options={this.state.canBeTraineesData
            .filter((user) => {
              if (
                this.state.addAsTraineesData.findIndex(
                  (trainee) => trainee._id === user._id
                ) === -1
              ) {
                return true;
              }
              return false;
            })
            .map((user) => ({
              _id: user._id,
              value: `${user.firstName} ${user.lastName} - ${user.email}`,
            }))}
          placeholder="Search User"
          filterOption={(inputValue, option) => {
            return (
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1 &&
              this.state.addAsTraineesData.findIndex(
                (trainee) => trainee._id === option._id
              ) === -1
            );
          }}
          onSelect={(selected, option) => {
            this.setState({
              autocompleteValue: "",
              addAsTraineesData: [...this.state.addAsTraineesData, option],
            });
          }}
        />
        {this.state.visible && this.state.addAsTraineesData.length > 0 && (
          <>
            <h2>Will be Added as Trainees:</h2>
            <ul style={{ padding: 0, listStyleType: "none" }}>
              {this.state.addAsTraineesData.map((trainee) => {
                return (
                  <li key={trainee._id}>
                    <Button
                      type="link"
                      style={{ padding: 0, marginRight: "10px" }}
                      onClick={() => this.handleRemoveTrainee(trainee._id)}
                    >
                      <DeleteTwoTone twoToneColor="#ff0000" />
                    </Button>
                    {trainee.value}
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </Modal>
    );
  }
}

export default AddTrainees;
