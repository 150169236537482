import React from "react";
import Highlighter from "react-highlight-words";
import {
  Tag,
  Table,
  Input,
  Button,
  // Popconfirm,
  message,
  notification,
  Space,
} from "antd";
import {
  SearchOutlined,
  EyeOutlined,
  // DeleteOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  deleteCourseByID,
  getCourses,
  getCategories,
} from "../../network/network";
import AddCourse from "../../screens/User/Courses/add";
import EditCourse from "../../screens/User/Courses/edit";
import MainContext from "../../Context/AppContext";

class AdminCourses extends React.Component {
  state = {
    categories: [],
    searchText: "",
    searchedColumn: "",

    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  };
  static contextType = MainContext;

  componentDidMount() {
    setTimeout(() => {
      if (
        this.context.authDetails?.userType === 1 ||
        this.context.authDetails?.userType === 5
      )
        getCategories(this.context.authDetails?.token).then((res) => {
          this.setState({
            categories: res.data,
          });
        });
    }, 1000);
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    selectedKeys[0] = selectedKeys[0].trim();
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  updateCourseTable = (data = null) => {
    if (data) {
      this.setState({
        loading: true,
      });
      var newData = [...this.state.data];
      const index = newData.findIndex((item) => item._id === data._id);
      newData[index] = data;

      this.setState({
        data: newData,
        loading: false,
      });
    } else {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }

    notification.success({
      message: "Course Updated",
      description: "The Course has been updated successfully.",
      placement: "topRight",
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  onDelete = (courseID) => {
    this.setState({ loading: true });

    deleteCourseByID(courseID, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
      });

      notification.success({
        message: "Course Deleted",
        description: "The Course has been deleted successfully.",
        placement: "topRight",
      });

      const { pagination } = this.state;
      this.fetch({ pagination });
    });
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  notDeleteUserMessage = () => {
    message.info("Course is safe");
  };

  // handleRowUpdate = key => {

  //   // this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
  // };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    // setTimeout(() => {
    let userType =
      this.context.authDetails.userType === 1 ||
      this.context.authDetails.userType === 5
        ? "admin"
        : "user";
    getCourses(userType, this.context.authDetails?.token)
      .then((res) => {
        // var courses = res.data.courses
        //   ? res.data.courses.filter((course) => course.courseId)
        //   : res.data;
        var courses = [];
        if (userType === "admin") {
          courses = res.data;
        } else {
          var tempcourses = res.data;
          tempcourses.forEach((element) => {
            courses.push({
              title: element.courseId.title,
              _id: element._id,
            });
          });
        }
        this.setState({
          data: courses,
          pagination: {
            ...params.pagination,
            total: res.data.totalCount,
          },
          loading: false,
        });
      })
      .catch((err) => {
        //   this.setState({
        //     loading: false,
        //   });
        //   if (err.response && err.response.status === 404) {
        //     message.error("Something went wrong");
        //   }
        //   if (err.response && err.response.status === 401) {
        //     message.error(err.response.data.message);
        //   }
        // }, 1000);
      });
  };

  render() {
    const { loading } = this.state;
    const { data } = this.state;
    const columns = [
      {
        title: "#",
        key: "index",
        width: "0%",
        align: "center",
        render: (text, record, index) => {
          return (
            <p>
              {this.state.pagination.pageSize *
                (this.state.pagination.current - 1) +
                (index + 1)}
            </p>
          );
        },
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: "30%",
        ...this.getColumnSearchProps("title"),
      },
      {
        title: "Slug",
        // dataIndex: "slug",
        width: "30%",
        render: (course) => {
          return (
            <a
              href={`${process.env.REACT_APP_BASE_URL}/${course.slug}`}
              target="_blank"
              rel="noreferrer"
            >
              {course.slug || ""}
            </a>
          );
        },
      },
      {
        title: "Category",
        width: "15%",
        align: "center",
        // sorter: true,
        // dataIndex: "category",
        // ...this.getColumnSearchProps('category'),
        render: (course) => {
          return <b>{course.category?.name || ""}</b>;
        },
      },
      {
        title:
          this.context.authDetails?.userType === 1 ||
          this.context.authDetails?.userType === 5
            ? "Price"
            : "",
        // sorter: true,
        width: "10%",
        align: "center",
        render: (course) => {
          if (
            this.context.authDetails?.userType === 1 ||
            this.context.authDetails?.userType === 5
          ) {
            return (
              <>
                <b style={{ color: "limegreen", display: "block" }}>
                  {" "}
                  {"$" +
                    parseFloat(
                      course.courseId
                        ? course.courseId.discounted_price
                        : course.discounted_price
                    ).toFixed(2) || "0.00"}
                </b>

                <b style={{ color: "red", textDecoration: "line-through" }}>
                  {" "}
                  {"$" +
                    parseFloat(
                      course.courseId ? course.courseId.price : course.price
                    ).toFixed(2) || "0.00"}
                </b>
              </>
            );
          }
        },
      },
      {
        title:
          this.context.authDetails?.userType === 1 ||
          this.context.authDetails?.userType === 5
            ? "Status"
            : "",
        // dataIndex: "sta/tus",
        width: "15%",
        // sorter: true,
        align: "center",
        render: (course) => {
          if (
            this.context.authDetails?.userType === 1 ||
            this.context.authDetails?.userType === 5
          ) {
            let status = course.courseId
              ? course.courseId.status
              : course.status;
            return (
              <div className="course-status">
                {status ? (
                  <Tag color={"green"} key={status}>
                    PUBLISHED
                  </Tag>
                ) : (
                  <Tag color={"red"} key={status}>
                    DRAFT
                  </Tag>
                )}

                {course.scorm_course_id &&
                course.scorm_course_id != course.sku ? (
                  <Tag color={"black"}> UPLOADED </Tag>
                ) : (
                  <Tag color={"red"}> NOT UPLOADED </Tag>
                )}
                {course.popular && <Tag color={"grey"}>POPULAR</Tag>}
                {course.recommended && <Tag color={"blue"}>RECOMMENDED</Tag>}

                {course.portal_type === 1 ? (
                  <Tag color={"black"}>LMS</Tag>
                ) : (
                  <Tag color={"red"}>Non-LMS</Tag>
                )}
              </div>
            );
          }
        },
      },

      {
        title: "Action",
        width: "10%",
        dataIndex: "",
        align: "center",
        key: "x",
        render: (text, record) => {
          var courseID = record.courseId ? record.courseId._id : record._id;
          // var regID = record.courseId ? record._id : record._id;
          return (
            <Space size="middle">
              {(this.context.authDetails?.userType === 1 ||
                this.context.authDetails?.userType === 5) && (
                <>
                  <EditCourse
                    categories={this.state.categories}
                    data_id={courseID}
                    type={"edit"}
                    // onUpdateCourse={this.courseRowUpdate}
                    onChildClick={this.updateCourseTable.bind(this)}
                  />

                  {/* <Popconfirm
                    title="Are you sure to delete this Course?"
                    onConfirm={() => {
                      this.onDelete(courseID);
                    }}
                    onCancel={this.notDeleteUserMessage}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined />
                  </Popconfirm> */}
                  <Button type="dashed">
                    <Link to={"/view/course/" + record._id}>
                      <EyeOutlined />
                    </Link>
                  </Button>
                </>
              )}
            </Space>
          );
        },
      },
    ];

    return (
      <div>
        {/* <Row  type="flex" align="middle" justify="space-between">
          <Col>
            <h4>Courses</h4>
          </Col>

          {this.context.authDetails?.userType === 1 && (
            <Col span={12}>
              <AddCourse
                onChildClick={this.updateCourseTable.bind(this)}
                categories={this.state.categories}
              />
            </Col>
          )}
        </Row> */}

        <div className="dashboard-charts">
          <h2>Recent Courses</h2>
          <div className="w-full">
            <AddCourse
              onChildClick={this.updateCourseTable.bind(this)}
              categories={this.state.categories}
            />
          </div>
          <div className="table-box">
            <Table
              columns={columns}
              rowKey={(record) => record._id}
              dataSource={data}
              // rowSelection={rowSelection}
              // pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AdminCourses;
