import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  notification,
  message,
  Modal,
  Checkbox,
} from "antd";
import { saveTraineeData } from "../../../network/network";
import MainContext from "../../../Context/AppContext";

class AddTrainee extends React.Component {
  formRef = React.createRef();
  state = {
    notify_user: false,
  };
  static contextType = MainContext;
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  doNotifyUser = (e) => {
    this.setState({ notify_user: e.target.checked });
  };

  onFinish = (values) => {
    values.notify_user = this.state.notify_user;
    saveTraineeData(values, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Trainee Created",
          description: "The trainee has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch((error) => {
        if (error && error.response.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          let errorMessages = Object.values(error.response.data.message);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      });
  };

  onFinishFailed = (errorInfo) => {};

  render() {
    return (
      <div>
        {this.props.type && this.props.type === "manager_dashboard" ? (
          <Button onClick={this.showModal} className="m-green-btn">
            <PlusOutlined /> Add New Trainee
          </Button>
        ) : (
          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={this.showModal}
            icon={<PlusOutlined />}
            size="large"
          ></Button>
        )}

        <Modal
          title="Create Trainee"
          visible={this.state.visible}
          onCancel={this.hideModal}
          centered
          footer={[
            <Row className="assignment-wrapper">
              <Col span="12">
                <Checkbox
                  checked={this.state.notify_user}
                  onChange={this.doNotifyUser}
                >
                  <b>Send login details to trainee</b>
                </Checkbox>
              </Col>
              <Col span="12">
                <Button
                  form="myFormAdd"
                  type="primary"
                  key="submit"
                  htmlType="submit"
                >
                  Create
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id="myFormAdd"
                name="basic"
                // layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your firstname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your lastname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="userName"
                  label="User Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your user name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {this.props.hierarchy_data &&
                  this.props.hierarchy_data.length > 0 && (
                    <Form.Item
                      name="hierarchy_id"
                      label="Hierarchy"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select placeholder="Select Trainee Hierarchy">
                        {this.props.hierarchy_data.map((item, index) => (
                          <Select.Option value={item._id} key={index}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                <Form.Item
                  name="contactNo"
                  label="Phone Number"
                  rules={[
                    {
                      required: false,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="address"
                  label="Address"
                  tooltip="Provide your complete address?"
                  rules={[
                    {
                      required: false,
                      message: "Please input your address!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: false,
                      message: "Please input your city!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: false,
                      message: "Please input your state!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    {
                      required: false,
                      message: "Please input your country!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="zipCode"
                  label="Zipcode"
                  rules={[
                    {
                      required: false,
                      message: "Please input your zipcode!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="companyName"
                  label="Company"
                  rules={[
                    {
                      required: false,
                      message: "Please input your company!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                {/* <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item> */}
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default AddTrainee;
