import React from "react";
import AddPage from "./add";
import EditPage from "./edit";
import { getPage } from "../../../network/network";
import { Table, message, notification, Space } from "antd";
import MainContext from "../../../Context/AppContext";

class Users extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    columns: [
      {
        title: "#",
        width: "0%",
        align: "center",
        key: "index",
        render: (text, record, index) => {
          return (
            <p>
              {this.state.pagination.pageSize *
                (this.state.pagination.current - 1) +
                (index + 1)}
            </p>
          );
        },
      },
      {
        title: "Title",
        width: "15%",
        dataIndex: "title",
      },

      {
        title: "Slug",
        width: "15%",
        dataIndex: "slug",
      },

      {
        title: "SEO Title",
        width: "20%",
        dataIndex: "seo_title",
      },

      {
        title: "SEO Description",
        width: "40%",
        dataIndex: "seo_description",
      },

      {
        title: "SEO Keywords",
        width: "15%",
        dataIndex: "seo_keyword",
      },

      {
        title: "Action",
        dataIndex: "",
        width: "5%",
        align: "center",
        key: "x",
        render: (text, record) => (
          <Space size="middle">
            <EditPage
              pages={this.state.pages}
              data_id={record._id}
              type={"edit"}
              onChildClick={this.updatePageTable.bind(this)}
            />
            {/* 
            <Popconfirm
              title="Are you sure to delete this page?"
              onConfirm={() => {
                this.onDelete(record);
              }}
              onCancel={this.notDeleteUserMessage}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm> */}
          </Space>
        ),
      },
    ],
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
  };
  static contextType = MainContext;
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter) => {
    // this.fetch({
    //   sortField: sorter.field,
    //   sortOrder: sorter.order,
    //   pagination,
    //   ...filters,
    // });
    this.setState({
      loading: false,
      pagination: pagination,
    });
  };

  onDelete = (record) => {
    notification.success({
      message: "Page Deleted",
      description: "The page has been deleted successfully.",
      placement: "topRight",
    });
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  notDeleteUserMessage = () => {
    message.info("Page is safe");
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });

    setTimeout(() => {
      getPage(this.context.authDetails?.token)
        .then((res) => {
          this.setState({
            loading: false,
            data: res.data,
            categories: res.data,
            pagination: {
              ...params.pagination,
              total: res.data.totalCount,
            },
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }, 1000);
  };

  updatePageTable = () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  render() {
    const { loading } = this.state;
    const { columns } = this.state;
    const { data } = this.state;
    const { pagination } = this.state;

    return (
      <div>
        <div className="dashboard-charts">
          <h2>Pages</h2>
          <div className="w-full">
            <AddPage
              onChildClick={this.updatePageTable.bind(this)}
              pages={this.state.pages}
            />
          </div>
          <div className="table-box">
            <Table
              className="pages-table"
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
