import React from "react";
import { Upload, Button, message } from "antd";
// import { UploadOutlined } from '@ant-decsign/icons';
// import reqwest from 'reqwest';
import { saveImage, importAndUploadCourse } from "../../network/network";
import MainContext from "../../Context/AppContext";
class FileUpload extends React.Component {
  state = {
    fileList: [],
    uploading: false,
    loading: false,
    // courseScormId: "",
    courseFileName: "",
    courseThumbnailImageName: "",
  };
  static contextType = MainContext;
  handleUpload = () => {
    const { fileList } = this.state;
    // return false;

    const formData = new FormData();
    // alert(this.props.imageUploadType === "courseThumbnail");
    // For Category

    if (
      (this.props.imageUploadType === "thumbnailImage" ||
        this.props.imageUploadType === "courseOGImage" ||
        this.props.imageUploadType === "blogOGImage" ||
        this.props.imageUploadType === "categoryOGImage" ||
        this.props.imageUploadType === "bannerImage" ||
        this.props.imageUploadType === "blogCategoryThumbnailImage" ||
        this.props.imageUploadType === "blogCategoryBannerImage" ||
        this.props.imageUploadType === "courseThumbnail") &&
      fileList[0] &&
      (fileList[0].type === "image/png" ||
        fileList[0].type === "image/jpg" ||
        fileList[0].type === "image/webp" ||
        fileList[0].type === "image/jpeg")
    ) {
      if (this.props.imageUploadType === "thumbnailImage") {
        formData.append("upload", fileList[0]);
        formData.append("type", "category_thumbnail");
      } else if (this.props.imageUploadType === "bannerImage") {
        formData.append("upload", fileList[0]);
        formData.append("type", "category_banner");
      } else if (this.props.imageUploadType === "courseThumbnail") {
        formData.append("upload", fileList[0]);
        formData.append("type", "course_thumbnail");
      } else if (this.props.imageUploadType === "blogOGImage") {
        formData.append("upload", fileList[0]);
        formData.append("type", "blog_og_image");
      } else if (this.props.imageUploadType === "courseOGImage") {
        formData.append("upload", fileList[0]);
        formData.append("type", "course_og_image");
      } else if (this.props.imageUploadType === "categoryOGImage") {
        formData.append("upload", fileList[0]);
        formData.append("type", "category_og_image");
      } else if (this.props.imageUploadType === "blogCategoryThumbnailImage") {
        formData.append("upload", fileList[0]);
        formData.append("type", "blog_category_thumbnail");
      } else if (this.props.imageUploadType === "blogCategoryBannerImage") {
        formData.append("upload", fileList[0]);
        formData.append("type", "blog_category_banner");
      } else {
        fileList.forEach((file) => {
          formData.append("files[]", file);
        });
      }
      this.setState({
        loading: true,
        uploading: true,
      });

      saveImage(formData, this.context.authDetails?.token)
        .then((res) => {
          if (this.props.imageUploadType === "thumbnailImage") {
            this.props.onThumbnailImageId(res.data._id, res.data.path);
          }
          if (this.props.imageUploadType === "bannerImage") {
            this.props.onBannerImageId(res.data._id, res.data.path);
          }
          if (this.props.imageUploadType === "courseThumbnail") {
            this.props.onCourseThumbnailID(res.data._id, res.data.path);
          }
          if (this.props.imageUploadType === "courseOGImage") {
            this.props.onCourseOgID(res.data._id, res.data.path);
          }
          if (this.props.imageUploadType === "categoryOGImage") {
            this.props.onOGImageId(res.data._id, res.data.path);
          }
          if (this.props.imageUploadType === "blogCategoryThumbnailImage") {
            this.props.onThumbnailImageId(res.data._id, res.data.path);
          }
          if (this.props.imageUploadType === "blogCategoryBannerImage") {
            this.props.onBannerImageId(res.data._id, res.data.path);
          }
          if (this.props.imageUploadType === "blogOGImage") {
            this.props.onBlogOgID(res.data._id, res.data.path);
          }
          message.info("File Uploaded Successfully");
          this.setState({
            uploading: false,
            loading: false,
            fileList: [],
          });
        })
        .catch((err) => {
          message.error("File not Uploaded, Some error has occurred");
          this.setState({
            uploading: false,
            loading: false,
            fileList: [],
          });
        });
    }

    //For Course Module
    else if (
      fileList &&
      this.props.imageUploadType === "courseFile" &&
      ["application/x-zip-compressed", "application/zip"].includes(
        fileList[0].type
      )
    ) {
      let random = Math.floor(Math.random() * 1000) + 111;
      let scorm_course_id = "course_" + random + Date.now();

      formData.append("file", fileList[0]);
      importAndUploadCourse(scorm_course_id, formData)
        .then((res) => {
          this.props.onCourseFileObj(res.data, scorm_course_id);
          message.info("File Uploaded Successfully");
          this.setState({
            uploading: false,
            loading: false,
            courseFileName: fileList[0].name,
            // courseScormId: scorm_course_id,
          });
        })
        .catch((err) => {
          message.error("Course not uploaded, Some error has occurred");
          this.setState({
            uploading: false,
            loading: false,
          });
        });

      // this.state.fileList = [];
      // alert(fileList[0].type)
      // formData.append("courseFile", fileList[0]);
      // uploadCourse(formData, this.context.authDetails?.token)
      //   .then((res) => {
      //     this.props.onCourseFileObj(res.data);

      //   })
      //   .catch((err) => {
      //     if (err.response.status === 400) {
      //       message.error(
      //         err.response.data.message
      //           ? err.response.data.message[0].courseFile
      //           : "Some error has occurred"
      //       );
      //     } else {
      //       message.error("File not Uploaded, Some error has occurred");
      //     }
      //     this.setState({
      //       uploading: false,
      //       loading: false,
      //     });
      //   });
    } else if (
      fileList &&
      this.props.imageUploadType === "emailList" &&
      ["text/csv"].includes(fileList[0].type)
    ) {
      this.props.handleCSV(fileList[0]);
      message.info("File Uploaded Successfully");
    } else {
      alert("File format(s) are inappropriate!");
      this.setState({
        fileList: [],
      });
      return false;
    }
  };

  // componentDidMount() {
  //   // alert(this.props.imageUploadType);

  //   if(this.props.imageUploadType == "thumbnailImage" ){

  //    let newObj = ... this.props.courseThumbnailImage.thumbnailImage;
  //    uid: "2",
  //    //   name: "yyy.png",
  //    //   status: "done",
  //    //   url: "http://www.baidu.com/yyy.png",
  //     this.setState({

  //           fileList : [...this.state.fileList, this.props.courseThumbnailImage.thumbnailImage]

  //     })

  //   }
  // }

  render() {
    let { fileList, courseFileName } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },

      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },

      // onChange : () => { this.handleUpload},
      fileList: [
        // {
        //   uid: "2",
        //   name: "yyy.png",
        //   status: "done",
        //   url: "http://www.baidu.com/yyy.png",
        // },
      ],
    };

    return (
      <div>
        <Upload
          {...props}
          onChange={this.handleUpload}
          accept={
            this.props.type === "courseFile" || this.props.type === "emailList"
              ? "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,text/csv"
              : ".png, .jpg, .jpeg, webp, csv"
          }
        >
          <b>
            {courseFileName} <br />
          </b>
          {!this.props.browse ? (
            <Button type="primary" disabled={fileList.length === 1}>
              Select File
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={this.props.selectedFile && fileList.length === 1}
            >
              Browse
            </Button>
          )}
        </Upload>

        {/* <Button
          type="primary"
          // onClick={this.handleUpload}
          disabled={fileList.length === 1}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? "Uploading" : ""}
        </Button> */}
      </div>
    );
  }
}

export default FileUpload;
