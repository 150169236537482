import React from "react";
import moment from "moment-timezone";
import { Table, Row, Col, message, Button, Input, DatePicker } from "antd";
import Highlighter from "react-highlight-words";
import {
  getAllUsersStatistics,
  getManagerHierarchyStatistics,
  getManagerTraineesStatistics,
  exportCourseProgressSheet,
} from "../../../network/network";
import { CanadianTime, SecondsToTime } from "../../../helpers/helpers";
import {
  SearchOutlined,
  ReloadOutlined,
  CalendarOutlined,
  ExportOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import MainContext from "../../../Context/AppContext";
const { Search } = Input;
const { RangePicker } = DatePicker;
class CoursesProgress extends React.Component {
  state = {
    loading: false,
    searchText: "",
    filter_start_date: "",
    filter_end_date: "",
    course_progress_status: [],
    index: 0,
    searchedColumn: "",
    filterTable: null,
    courses_progress: [],
    completed_courses_count: 0,
    failed_courses_count: 0,
    inprogress_courses_count: 0,
    notstarted_courses_count: 0,
    course_progress_columns: [
      {
        title: "#",
        key: "index",
        width: "2%",
        align: "center",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Trainee Name",
        width: "20%",
        render: (record) => {
          var fullName = `${record.firstName} ${record.lastName}`;
          return (
            <>
              <p>{this.makeTextHighlight(fullName)}</p>
              <p>
                <b>Email: </b>
                {this.makeTextHighlight(record.email)}
              </p>
            </>
          );
        },
      },
      {
        title: "Course",
        width: "20%",
        render: (record) => {
          if (
            record.courses &&
            record.courses.course_details &&
            record.courses.course_details.title
          ) {
            return (
              <p>
                {this.makeTextHighlight(record.courses.course_details.title)}
              </p>
            );
          }
        },
      },
      {
        title: "Time Elapsed",
        width: "10%",
        algin: "center",
        render: (record) => {
          return <p>{SecondsToTime(record.courses.time)}</p>;
        },
      },
      {
        title: "Date",
        width: "20%",
        filterIcon: (filtered) => <CalendarOutlined />,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div className="custom-date-dropdown">
            <RangePicker
              onChange={(date) => {
                this.setState({
                  filter_start_date: date && date[0] ? date[0]._d : "",
                  filter_end_date: date && date[1] ? date[1]._d : "",
                });
              }}
            />
            <Button
              type="primary"
              onClick={() =>
                this.dateRangeFilterProgress(selectedKeys, confirm)
              }
            >
              Search
            </Button>
          </div>
        ),

        onFilter: (value, record) =>
          record["a"].toString().toLowerCase().includes(value.toLowerCase()),

        render: (record) => {
          return (
            <>
              {record.courses.firstAccessDate && (
                <p>
                  <b>First Access Date: </b>
                  {CanadianTime(record.courses.firstAccessDate)}
                </p>
              )}

              {record.courses.lastAccessDate && (
                <p>
                  <b>Last Access Date: </b>
                  {CanadianTime(record.courses.lastAccessDate)}
                </p>
              )}
              {record.courses.createdAt && (
                <p>
                  <b>Assigned Date: </b>
                  {CanadianTime(record.courses.createdAt)}
                </p>
              )}
            </>
          );
        },
      },
      {
        title: "Status",
        width: "10%",
        align: "center",

        filterIcon: <FilterOutlined style={{ color: "#0083B7" }} />,
        filters: [
          {
            text: "Not Started",
            value: "not_started",
          },
          {
            text: "Completed",
            value: "completed",
          },
          {
            text: "Failed",
            value: "failed",
          },
          {
            text: "In Progress",
            value: "in_progress",
          },
        ],
        onFilter: (value, record) => {
          if (value === "failed") {
            return record.courses.is_failed;
          } else if (value === "in_progress") {
            return (
              !record.courses.is_failed &&
              record.courses.time !== "0" &&
              record.courses.time !== "00:00:00" &&
              record.courses.completion !== "COMPLETED"
            );
          } else if (value === "completed") {
            return record.courses.completion === "COMPLETED";
          } else if (value === "not_started") {
            return (
              (record.courses.time === "0" ||
                record.courses.time === "00:00:00") &&
              record.courses.completion !== "COMPLETED"
            );
          }
        },
        render: (record) => {
          if (record.courses.is_failed) {
            return (
              <>
                <p style={{ color: "red", fontWeight: "600" }}>FAILED</p>
                {record.courses.score && record.courses.score > 0 && (
                  <p>
                    <b>Score: </b>
                    {record.courses.score}
                  </p>
                )}
              </>
            );
          } else if (record.courses.completion === "COMPLETED") {
            return (
              <>
                <p style={{ color: "green", fontWeight: "600" }}>COMPLETED</p>
                {record.courses.score && parseInt(record.courses.score) > 0 && (
                  <p>
                    <b>Score: </b>
                    {record.courses.score}
                  </p>
                )}
              </>
            );
          } else if (
            (record.courses.time === "0" ||
              record.courses.time === "00:00:00") &&
            record.courses.completion !== "COMPLETED"
          ) {
            return (
              <p style={{ color: "#82868e", fontWeight: "600" }}>NOT STARTED</p>
            );
          } else if (
            record.courses.time !== "0" &&
            record.courses.time !== "00:00:00" &&
            record.courses.completion !== "COMPLETED"
          ) {
            return (
              <p style={{ color: "orange", fontWeight: "600" }}>IN PROGRESS</p>
            );
          }
        },
      },
      // },
      {
        title: "Action",
        width: "10%",
        align: "center",
        render: (record) => {
          return (
            record.courses.certificate_link && (
              <a
                href={record.courses.certificate_link}
                className="green-btn download-certificate"
                rel="noreferrer"
                target="_blank"
              >
                Download Certificate
              </a>
            )
          );
        },
      },
    ],
  };
  static contextType = MainContext;

  componentDidMount() {
    this.fetch();
  }

  makeTextHighlight = (text) => {
    return (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  };

  dateRangeFilterProgress = () => {
    this.setState({ loading: true, filterTable: null });
    let courses_progress = this.state.courses_progress;
    let startDate = this.state.filter_start_date;
    let endDate = this.state.filter_end_date;
    let filterTable = [];
    let myTimezone = "America/New_York";
    let myDatetimeFormat = "DD MMM YYYY";
    let assigned_date = "";
    courses_progress.map((progress) => {
      assigned_date = moment(progress.courses.createdAt)
        .tz(myTimezone)
        .format(myDatetimeFormat);
      var start_date = moment(startDate)
        .tz(myTimezone)
        .format(myDatetimeFormat);
      var end_date = moment(endDate).tz(myTimezone).format(myDatetimeFormat);

      if (moment(assigned_date).isBetween(start_date, end_date)) {
        filterTable.push(progress);
      }
    });

    this.setState({
      filterTable: filterTable,
      loading: false,
    });
  };

  refreshProgressData = () => {
    this.setState({
      filterTable: null,
      searchText: "",
      searchedColumn: "",
      index: this.state.index + 1,
      loading: false,
      data: [],
    });

    this.fetch();
  };

  exportCourseProgressCSV = () => {
    exportCourseProgressSheet(
      {
        filter_start_date: this.state.filter_start_date
          ? moment(this.state.filter_start_date).format("YYYY-MM-DD")
          : "",
        filter_end_date: this.state.filter_end_date
          ? moment(this.state.filter_end_date).format("YYYY-MM-DD")
          : "",
        course_progress_status: JSON.stringify(
          this.state.course_progress_status
        ),
      },
      this.context.authDetails.token
    )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${new Date().toISOString()}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        message.error(
          "Some eror has occured while exporting Course Progress CSV"
        );
      });
  };

  searchValue = (value) => {
    this.setState({ loading: true, filterTable: null });
    var courses_progress = this.state.courses_progress;
    value = value.trim();
    var filterTable = [];
    if (value) {
      courses_progress.map((o) => {
        var fullname =
          o.firstName.toLowerCase() + " " + o.lastName.toLowerCase();
        if (
          fullname.includes(value.toLowerCase()) ||
          o.firstName.toLowerCase().includes(value.toLowerCase()) ||
          o.lastName.toLowerCase().includes(value.toLowerCase()) ||
          o.email.toLowerCase().includes(value.toLowerCase()) ||
          (o.courses &&
            o.courses.course_details &&
            o.courses.course_details.title &&
            o.courses.course_details.title
              .toLowerCase()
              .includes(value.toLowerCase()))
        ) {
          filterTable.push(o);
        }
      });
      this.setState({
        filterTable: filterTable,
        searchText: value,
        loading: false,
      });
    } else {
      this.setState({
        filterTable: null,
        searchText: "",
        loading: false,
      });
    }
  };

  fetch = () => {
    if (this.context.authDetails && this.context.authDetails.token) {
      if (this.context.authDetails.userType === 1) {
        this.setState({ loading: true });
        getAllUsersStatistics(this.context.authDetails.token)
          .then((res) => {
            let completed_courses_count = 0;
            let failed_courses_count = 0;
            let inprogress_courses_count = 0;
            let notstarted_courses_count = 0;

            let course_progress = res.data;

            if (course_progress.length > 0) {
              course_progress.map((progress) => {
                if (progress.courses.is_failed) {
                  failed_courses_count++;
                } else if (progress.courses.completion === "COMPLETED") {
                  completed_courses_count++;
                } else if (
                  (progress.courses.time === "0" ||
                    progress.courses.time === "00:00:00") &&
                  progress.courses.completion !== "COMPLETED"
                ) {
                  notstarted_courses_count++;
                } else if (
                  progress.courses.time !== "0" &&
                  progress.courses.time !== "00:00:00" &&
                  progress.courses.completion !== "COMPLETED"
                ) {
                  inprogress_courses_count++;
                }
              });
            }

            this.setState({
              courses_progress: res.data,
              completed_courses_count: completed_courses_count,
              failed_courses_count: failed_courses_count,
              inprogress_courses_count: inprogress_courses_count,
              notstarted_courses_count: notstarted_courses_count,
            });
          })
          .catch((err) => {
            message.error(
              "Some error has occured while fetching users progress!"
            );
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      } else if (
        this.context.authDetails.userType === 2 &&
        this.props?.match?.params?.hierarchyID
      ) {
        this.setState({ loading: true });
        getManagerHierarchyStatistics(
          this.props.match.params.hierarchyID,
          this.context.authDetails.token
        )
          .then((res) => {
            this.setState({ courses_progress: res.data });
          })
          .catch((err) => {
            message.error(
              "Some error has occured while fetching manager hierarchy trainees progress!"
            );
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      } else if (
        this.context.authDetails.userType === 2 &&
        !this.props?.match?.params?.hierarchyID
      ) {
        this.setState({ loading: true });
        getManagerTraineesStatistics(this.context.authDetails.token)
          .then((res) => {
            this.setState({ courses_progress: res.data });
          })
          .catch((err) => {
            message.error(
              "Some error has occured while fetching manager hierarchy trainees progress!"
            );
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }
    }
  };
  onChange = (pagination, filters, sorter, extra) => {
    if (filters[5]) {
      this.setState({ course_progress_status: filters[5] });
    }
  };
  render() {
    const { loading, course_progress_columns, courses_progress, filterTable } =
      this.state;

    return (
      <div>
        {this.context.authDetails.userType === 1 && (
          <Row style={{ marginBottom: 20 }} gutter={16}>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="stats">
                <Row style={{ width: "100%" }}>
                  <Col span={12}>
                    <div
                      className="stats-heading "
                      style={{ background: "#0083b7" }}
                    >
                      <h2>COMPLETED</h2>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="stats-heading">
                      <h3>{this.state.completed_courses_count}</h3>
                      <p>Courses Completed</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="stats">
                <Row style={{ width: "100%" }}>
                  <Col span={12}>
                    <div
                      className="stats-heading "
                      style={{ background: "#006f9b" }}
                    >
                      <h2>IN PROGRESS</h2>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="stats-heading">
                      <h3>{this.state.inprogress_courses_count}</h3>
                      <p>Courses In Progress</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="stats">
                <Row style={{ width: "100%" }}>
                  <Col span={12}>
                    <div
                      className="stats-heading "
                      style={{ background: "#00638b" }}
                    >
                      <h2>FAILED</h2>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="stats-heading">
                      <h3>{this.state.failed_courses_count}</h3>
                      <p>Courses Failed</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="stats">
                <Row style={{ width: "100%" }}>
                  <Col span={12}>
                    <div
                      className="stats-heading "
                      style={{ background: "#015374" }}
                    >
                      <h2>NOT STARTED</h2>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="stats-heading">
                      <h3>{this.state.notstarted_courses_count}</h3>
                      <p>Courses Not Started</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        <Col className="users-recent" span={24}>
          <Row type="flex" justify="center" align="middle" className="mb-20">
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <h2>Progress</h2>
            </Col>

            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
              <Row type="flex" justify="space-between" align="middle">
                <div className="search">
                  <Search
                    placeholder="Search by Trainee name, email and courses"
                    value={this.state.searchText}
                    onChange={(e) =>
                      this.setState({ searchText: e.target.value })
                    }
                    allowClear
                    enterButton={<SearchOutlined />}
                    size="large"
                    onSearch={(value) => this.searchValue(value)}
                  />
                </div>
                <Button
                  type="primary"
                  icon={<ReloadOutlined />}
                  size="large"
                  onClick={() => {
                    this.refreshProgressData();
                  }}
                ></Button>
                {this.context.authDetails.userType === 1 && (
                  <Button
                    type="primary"
                    icon={<ExportOutlined />}
                    size="large"
                    onClick={() => {
                      this.exportCourseProgressCSV();
                    }}
                  ></Button>
                )}
              </Row>
            </Col>
          </Row>
          <div className="table-box">
            <Table
              key={this.state.index}
              columns={course_progress_columns}
              rowKey={(record) => record._id}
              loading={loading}
              dataSource={filterTable === null ? courses_progress : filterTable}
              pagination={false}
              onChange={this.onChange}
            />
          </div>
        </Col>
      </div>
    );
  }
}

export default CoursesProgress;
