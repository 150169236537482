import React from "react";
import {
  Modal,
  Button,
  // Space,
  Form,
  Input,
  Radio,
  Select,
  Row,
  Image,
  notification,
  Col,
  // notification,
} from "antd";
import CKEditor from "ckeditor4-react";
import FileUpload from "../../../components/FileUpload/FileUpload";
import MainContext from "../../../Context/AppContext";
import { PlusOutlined } from "@ant-design/icons";
import { storeBlog } from "../../../network/network";
// import CKEditor from "ckeditor4-react";
const { TextArea } = Input;
// import { ExclamationCircleOutlined } from '@ant-design/icons';

class AddBlog extends React.Component {
  formRef = React.createRef();
  state = {
    detailed_content: "",
    bannerImagePath: "",
    thumbnailImagePath: "",
    blogOGid: "",
    blogOGpath: "",
    categories: [],
    visible: false,
    thumbnailImageId: "",
    bannerImageId: "",
    layout: {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    },
    tailLayout: {
      wrapperCol: {
        offset: 3,
        span: 24,
      },
    },
  };
  static contextType = MainContext;
  showModal = () => {
    this.setState({
      categories: this.props.categories,
      visible: true,
    });

    setTimeout(() => {
      if (this.formRef.current)
        this.formRef.current.setFieldsValue({
          authors_name: `${this.context.authDetails.firstName} ${this.context.authDetails.lastName}`,
        });
    }, 2000);
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onEditorChange = (evt) => {
    this.setState({
      detailed_content: evt.editor.getData(),
    });
  };

  handleBlogOGImageID = (blogOGid, blogOGpath) => {
    this.setState({
      blogOGid: blogOGid,
      blogOGPath: blogOGpath,
    });
  };
  onFinish = (values) => {
    const formData = new FormData();

    formData.append("description", this.state.detailed_content);
    formData.append("shortDescription", values.shortDescription);
    formData.append("ogImage", this.state.blogOGid);
    formData.append("title", values.title);
    formData.append("thumbnailImage", this.state.thumbnailImageId);
    formData.append("bannerImage", this.state.bannerImageId);
    formData.append("seo_title", values.seo_title);
    formData.append("authorsName", values.authors_name);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);
    formData.append("category", values.category);
    formData.append("featured", values.status);

    storeBlog(formData, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Blog Created",
          description: "The blog has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  handleThumbnailImageID = (thumbnailImageID, thumbnailImagePath) => {
    this.setState({
      thumbnailImageId: thumbnailImageID,
      thumbnailImagePath: thumbnailImagePath,
    });
  };

  handlebannerImageID = (bannerImageID, bannerImagePath) => {
    this.setState({
      bannerImageId: bannerImageID,
      bannerImagePath: bannerImagePath,
    });
  };

  render() {
    return (
      <>
        <Button
          style={{ float: "right", margin: "10px" }}
          type="primary"
          onClick={this.showModal}
          icon={<PlusOutlined />}
          size="large"
        ></Button>

        <Modal
          width={2000}
          centered
          title="Create Blog"
          visible={this.state.visible}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText="Create"
          cancelText="Cancel"
          footer={[
            <Button
              form="myFormAdd"
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Store
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                layout="vertical"
                name="basic"
                id="myFormAdd"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                // onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="title"
                  label="Blog Title"
                  rules={[
                    {
                      required: true,
                      message: "Please input blog title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="shortDescription"
                  label="Short Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input short description!",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: false,
                      message: "Please input description!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <CKEditor
                    data={"Here is the post content"}
                    onChange={this.onEditorChange}
                  />
                  {/* <TextArea rows={4} /> */}
                </Form.Item>

                {this.state.thumbnailImageId === "" ? (
                  <Form.Item label="Thumbnail Image">
                    <FileUpload
                      onThumbnailImageId={this.handleThumbnailImageID}
                      imageUploadType="thumbnailImage"
                    />
                  </Form.Item>
                ) : (
                  <Image
                    width={200}
                    src={
                      this.state.thumbnailImagePath &&
                      this.state.thumbnailImagePath
                        ? `${this.state.thumbnailImagePath}`
                        : "/no-image.png"
                    }
                  />
                )}

                {this.state.bannerImageId === "" ? (
                  <Form.Item label="Banner Image">
                    <FileUpload
                      onBannerImageId={this.handlebannerImageID}
                      imageUploadType="bannerImage"
                    />
                  </Form.Item>
                ) : (
                  <Image
                    width={200}
                    src={
                      this.state.bannerImagePath && this.state.bannerImagePath
                        ? `${this.state.bannerImagePath}`
                        : "/no-image.png"
                    }
                  />
                )}

                <Form.Item label="OG Image">
                  {this.state.blogOGpath && (
                    <Image width={200} src={this.state.blogOGpath} />
                  )}

                  <FileUpload
                    onBlogOgID={this.handleBlogOGImageID}
                    imageUploadType="blogOGImage"
                    type="image"
                  />
                </Form.Item>

                <Form.Item
                  name="authors_name"
                  label="Authors Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input blog authors_name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>

                {this.state.categories.length > 0 && (
                  <Form.Item
                    name="category"
                    label="Categories"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select>
                      {this.state.categories?.map((item, index) => (
                        <Select.Option value={item._id} key={index}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <div
                  className="ant-modal-title"
                  style={{ marginBottom: "20px" }}
                >
                  Course SEO Details
                </div>

                <Form.Item
                  name="seo_title"
                  label="SEO Title"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO title!",
                      whitespace: true,
                      // max:60,
                      // min:30
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="SEO Description"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO description!",
                      whitespace: true,
                      // max:160,
                      // min:110,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="SEO Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input course SEO Keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={1}>Published</Radio>
                    <Radio value={0}>Unpublished</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default AddBlog;
