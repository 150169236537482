import React from "react";
import Routes from "../../../routes";
import {
  Spin,
  Layout,
  Breadcrumb,
  Button,
  Badge,
  Menu,
  Tooltip,
  message,
  Row,
  Col,
  Avatar,
} from "antd";
import { Link } from "react-router-dom";
import MainContext from "../../../Context/AppContext";
import { getCookie } from "../../../utils";
import { getProfileMe, getContactNotification } from "../../../network/network";
import {
  DesktopOutlined,
  PieChartOutlined,
  ApartmentOutlined,
  DownOutlined,
  UserSwitchOutlined,
  FileOutlined,
  BarChartOutlined,
  DollarCircleOutlined,
  CreditCardOutlined,
  LoadingOutlined,
  UserOutlined,
  QuestionOutlined,
  AppstoreOutlined,
  MailOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;
const { Header } = Layout;
const { SubMenu } = Menu;
const text = <span>Click to Copy</span>;

const { Content } = Layout;

class Main extends React.Component {
  static contextType = MainContext;

  state = {
    collapsed: false,
    loading: false,
    contactNotificationCount: 0,
  };
  getContactNotificationCount = (token) => {
    getContactNotification(token)
      .then((response) => {
        this.setState({ contactNotificationCount: response.data.count });
      })
      .catch((error) => {
        message.error(
          "Some error has occured while fetching contact notification count."
        );
      });
  };
  componentDidMount() {
    const token = getCookie("worksafetycourses.com");
    const userProfileToken = localStorage.getItem("key");
    if (token && token !== undefined) {
      this.setState({
        loading: true,
      });

      getProfileMe(
        localStorage.getItem("orignal_key") && userProfileToken
          ? userProfileToken
          : token
      )
        .then((response) => {
          response.data.token =
            localStorage.getItem("orignal_key") && userProfileToken
              ? userProfileToken
              : token;
          this.context.setUserDetails(response.data);
          this.context.setUserLoginStatus(true);
          if (response.data.userType === 1) {
            this.getContactNotificationCount(token);
          }
        })
        .catch((error) => {
          message.error("Some error has occured.");
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      localStorage.removeItem("key");
      message.error("Your token is expired, Please log in");
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_BASE_URL}/login`;
      }, 2000);
    }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  toggleCollapsed = () => {
    if (window.innerWidth < 768) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
    }
  };

  copyCodeToClipboard = (val) => {
    const tempInput = document.createElement("input");
    tempInput.value = val;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    message.success("Copied!");
  };

  backToOrignalAdminAccount = async () => {
    const userType = await this.context.switchBackToOrignalAccount();

    if (userType === 1) {
      window.location.href = "/users";
    }
  };
  swtichToLearnerAccount = async () => {
    await this.context.switchManagerAccount();
    message.info("Account Switched successfully");
  };

  logoutUser = async () => {
    document.cookie = `worksafetycourses.com=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.${process.env.REACT_APP_DOMAIN};path=/`;
    localStorage.removeItem("key");
    localStorage.removeItem("orignal_key");
    await this.context.logoutUser();
    message.info("Logout successfully.");
    let redirectLink = process.env.REACT_APP_BASE_URL;
    window.location.href = redirectLink;
  };

  render() {
    const { collapsed, loading, contactNotificationCount } = this.state;

    return (
      <>
        {loading ? (
          <div className="main-loader">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} />} />
          </div>
        ) : (
          <Layout style={{ minHeight: "100vh" }}>
            <Sider
              collapsible
              defaultCollapsed={true}
              collapsed={collapsed}
              collapsedWidth={window.innerWidth > 767 ? 80 : 0}
              onCollapse={this.onCollapse}
              breakpoint={"md"}
              style={{
                height: "100vh",
                position: "fixed",
                left: 0,
              }}
            >
              <div className={`logo ${!collapsed ? "" : "collapsed"} `}>
                <a href={process.env.REACT_APP_BASE_URL}>
                  {!collapsed ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 320 186.1"
                    >
                      <g>
                        <g>
                          <path
                            fill="#FFFFFF"
                            d="M69.8,54.9L55.3,2.3h12.6L77,39.1L87.1,2.3h9.8L107,39.1l9.1-36.8h12.6l-14.5,52.6h-13.7l-8.6-31l-8.6,31    H69.8z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M176.2,48c-5.3,5.3-11.8,7.9-19.4,7.9s-14.1-2.6-19.4-7.9c-5.3-5.3-8-11.7-8-19.4s2.7-14.2,8-19.4    c5.3-5.3,11.8-7.9,19.4-7.9s14.1,2.6,19.4,7.9c5.3,5.3,8,11.7,8,19.4S181.5,42.8,176.2,48z M145.9,39.9c2.9,2.9,6.6,4.4,10.9,4.4    s8-1.4,10.9-4.4c2.9-2.9,4.4-6.7,4.4-11.3c0-4.6-1.5-8.4-4.4-11.3c-2.9-2.9-6.6-4.4-10.9-4.4s-8,1.5-10.9,4.4    c-2.9,2.9-4.4,6.7-4.4,11.3C141.5,33.2,142.9,36.9,145.9,39.9z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M218.5,54.9l-9.9-17.3h-5.7v17.3h-12V2.3h21c5,0,9.3,1.8,12.8,5.3c3.5,3.5,5.3,7.8,5.3,12.8    c0,3.2-0.9,6.2-2.7,8.9c-1.8,2.7-4.1,4.8-7,6.3l11.2,19.3H218.5z M202.9,13.6v13.5h9c1.7,0,3.1-0.6,4.2-2c1.2-1.3,1.8-2.9,1.8-4.8    s-0.6-3.5-1.8-4.8s-2.6-2-4.2-2H202.9z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M279.5,54.9h-13.7l-16.8-24.3v24.3h-12V2.3h12v22.8l16.1-22.8h13.7l-17.7,25.6L279.5,54.9z"
                          />
                        </g>
                      </g>
                      <polygon
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#FFD243"
                        points="234.9,154.9 219.9,128.8 189.7,128.8 219.9,181 233.9,181   250,181 280.2,128.8 315.1,68.3 298.9,68.3 284.9,68.3 250,128.8 "
                      />
                      <path
                        fill="#FFFFFF"
                        d="M311.7,66.4c1.1-1.8,3.4-2.5,5.2-1.4c1.8,1.1,2.5,3.4,1.4,5.2l-65,112.7c-1.5,2.7-5.4,2.5-6.7-0.2  l-28.9-50.1h-21.3l25.7,44.5h11.8c2.1,0,3.8,1.7,3.8,3.8c0,2.1-1.7,3.9-3.8,3.9l-14,0c-1.3,0-2.6-0.7-3.3-1.9l-30-52  c-1.7-2.5,0.1-6,3.2-6l30.2,0c1.3,0,2.6,0.7,3.3,1.9l26.8,46.5L311.7,66.4z M246.3,142.8c-1.1,1.8-3.4,2.5-5.2,1.4  c-1.8-1.1-2.5-3.4-1.4-5.2l41.9-72.6c0.7-1.2,2-1.9,3.3-1.9l14,0c2.1,0,3.9,1.7,3.9,3.8c0,2.1-1.7,3.9-3.9,3.9h-11.8L246.3,142.8z"
                      />
                      <g>
                        <g>
                          <path
                            fill="#FFFFFF"
                            d="M22.1,117.1c-5.4,0-9.8-1.2-13.4-3.5c-3.6-2.3-6.1-5.5-7.6-9.5l10.4-6c2.1,4.9,5.8,7.3,11,7.3    c4.8,0,7.1-1.4,7.1-4.3c0-1.5-0.8-2.8-2.3-3.6c-1.5-0.9-4.4-1.9-8.6-3c-2.2-0.6-4.1-1.3-5.8-2c-1.7-0.8-3.3-1.7-4.8-3    c-1.6-1.2-2.8-2.8-3.6-4.6c-0.8-1.9-1.2-4-1.2-6.4c0-4.9,1.7-8.7,5.2-11.6c3.5-2.9,7.6-4.4,12.4-4.4c4.3,0,8.1,1,11.4,3    c3.3,2,5.9,5,7.8,8.8L30,80.3c-1-2-2.1-3.5-3.5-4.5c-1.4-1-3.3-1.5-5.6-1.5c-1.8,0-3.2,0.4-4.2,1.2c-1,0.8-1.5,1.7-1.5,2.8    c0,1.3,0.6,2.4,1.8,3.4s3.8,2,7.6,3.2c2.1,0.6,3.7,1.2,4.9,1.6c1.2,0.4,2.6,1.1,4.4,2c1.8,0.9,3.1,1.9,4.1,2.9    c0.9,1,1.8,2.4,2.5,4.1c0.7,1.7,1.1,3.6,1.1,5.7c0,5-1.8,8.9-5.4,11.8C32.6,115.7,27.9,117.1,22.1,117.1z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M80.3,116.1l-2.6-8.3H58.1l-2.6,8.3H42.4l17.9-52.6h15.3l17.9,52.6H80.3z M61.8,96.6h12.3l-6.2-19.3    L61.8,96.6z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M129.9,75.1H110v10.1h19.5v11.6H110v19.3h-12V63.5h31.9V75.1z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M149,104.5h21v11.6h-33V63.5h32.7v11.6H149v8.7h18.8v11.4H149V104.5z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M212.1,63.5v11.6h-13.5v41h-12v-41H173V63.5H212.1z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M260.1,63.5l-17.6,32.4v20.2h-12V95.9l-17.6-32.4h13.7l10,20.3l10-20.3H260.1z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            fill="#FFD243"
                            d="M10.7,143.5c-2.7,0-5-0.9-6.8-2.7c-1.8-1.8-2.7-4.1-2.7-6.7c0-2.7,0.9-4.9,2.7-6.7c1.8-1.8,4-2.7,6.8-2.7    c1.6,0,3.1,0.4,4.5,1.1c1.4,0.8,2.5,1.8,3.3,3.1l-3.6,2.1c-0.4-0.7-1-1.3-1.7-1.6c-0.7-0.4-1.6-0.6-2.5-0.6    c-1.6,0-2.9,0.5-3.9,1.5c-1,1-1.4,2.3-1.4,3.9s0.5,2.9,1.4,3.9c1,1,2.2,1.5,3.9,1.5c0.9,0,1.8-0.2,2.5-0.6    c0.8-0.4,1.3-0.9,1.7-1.6l3.6,2.1c-0.8,1.3-1.9,2.3-3.2,3.1C13.9,143.1,12.4,143.5,10.7,143.5z"
                          />
                          <path
                            fill="#FFD243"
                            d="M45.9,140.8c-1.8,1.8-4.1,2.7-6.7,2.7c-2.6,0-4.9-0.9-6.7-2.7c-1.8-1.8-2.7-4.1-2.7-6.7    c0-2.7,0.9-4.9,2.7-6.7c1.8-1.8,4.1-2.7,6.7-2.7c2.6,0,4.9,0.9,6.7,2.7c1.8,1.8,2.8,4.1,2.8,6.7C48.7,136.7,47.8,139,45.9,140.8z     M35.5,138c1,1,2.3,1.5,3.8,1.5c1.5,0,2.8-0.5,3.8-1.5c1-1,1.5-2.3,1.5-3.9c0-1.6-0.5-2.9-1.5-3.9c-1-1-2.3-1.5-3.8-1.5    c-1.5,0-2.8,0.5-3.8,1.5c-1,1-1.5,2.3-1.5,3.9C34,135.7,34.5,137,35.5,138z"
                          />
                          <path
                            fill="#FFD243"
                            d="M73.2,141.8c-1.3,1.1-3,1.7-5,1.7c-2,0-3.7-0.6-5-1.7c-1.3-1.1-2-2.6-2-4.5V125h4.2v11.9    c0,1.7,1,2.5,2.9,2.5s2.9-0.8,2.9-2.5V125h4.2v12.3C75.2,139.2,74.5,140.7,73.2,141.8z"
                          />
                          <path
                            fill="#FFD243"
                            d="M98.1,143.1l-3.4-6h-2v6h-4.2V125h7.3c1.7,0,3.2,0.6,4.4,1.8c1.2,1.2,1.8,2.7,1.8,4.4    c0,1.1-0.3,2.1-0.9,3.1c-0.6,0.9-1.4,1.7-2.4,2.2l3.9,6.7H98.1z M92.7,128.9v4.7h3.1c0.6,0,1.1-0.2,1.5-0.7c0.4-0.5,0.6-1,0.6-1.7    c0-0.7-0.2-1.2-0.6-1.7c-0.4-0.5-0.9-0.7-1.5-0.7H92.7z"
                          />
                          <path
                            fill="#FFD243"
                            d="M121,143.5c-1.9,0-3.4-0.4-4.6-1.2c-1.2-0.8-2.1-1.9-2.6-3.3l3.6-2.1c0.7,1.7,2,2.5,3.8,2.5    c1.6,0,2.5-0.5,2.5-1.5c0-0.5-0.3-1-0.8-1.3c-0.5-0.3-1.5-0.7-3-1.1c-0.8-0.2-1.4-0.4-2-0.7c-0.6-0.3-1.1-0.6-1.7-1    c-0.5-0.4-1-1-1.2-1.6c-0.3-0.6-0.4-1.4-0.4-2.2c0-1.7,0.6-3,1.8-4c1.2-1,2.6-1.5,4.3-1.5c1.5,0,2.8,0.3,3.9,1    c1.1,0.7,2,1.7,2.7,3.1l-3.5,2c-0.3-0.7-0.7-1.2-1.2-1.6c-0.5-0.4-1.1-0.5-1.9-0.5c-0.6,0-1.1,0.1-1.4,0.4c-0.3,0.3-0.5,0.6-0.5,1    c0,0.4,0.2,0.8,0.6,1.2c0.4,0.3,1.3,0.7,2.6,1.1c0.7,0.2,1.3,0.4,1.7,0.5s0.9,0.4,1.5,0.7c0.6,0.3,1.1,0.7,1.4,1    c0.3,0.4,0.6,0.8,0.9,1.4s0.4,1.2,0.4,2c0,1.7-0.6,3.1-1.9,4.1C124.6,143,123,143.5,121,143.5z"
                          />
                          <path
                            fill="#FFD243"
                            d="M144.2,139.2h7.3v4H140V125h11.3v4h-7.1v3h6.5v3.9h-6.5V139.2z"
                          />
                          <path
                            fill="#FFD243"
                            d="M170.5,143.5c-1.9,0-3.4-0.4-4.6-1.2c-1.2-0.8-2.1-1.9-2.6-3.3l3.6-2.1c0.7,1.7,2,2.5,3.8,2.5    c1.6,0,2.5-0.5,2.5-1.5c0-0.5-0.3-1-0.8-1.3c-0.5-0.3-1.5-0.7-3-1.1c-0.8-0.2-1.4-0.4-2-0.7c-0.6-0.3-1.1-0.6-1.7-1    c-0.5-0.4-1-1-1.2-1.6c-0.3-0.6-0.4-1.4-0.4-2.2c0-1.7,0.6-3,1.8-4c1.2-1,2.6-1.5,4.3-1.5c1.5,0,2.8,0.3,3.9,1s2,1.7,2.7,3.1    l-3.5,2c-0.3-0.7-0.7-1.2-1.2-1.6c-0.5-0.4-1.1-0.5-1.9-0.5c-0.6,0-1.1,0.1-1.4,0.4c-0.3,0.3-0.5,0.6-0.5,1c0,0.4,0.2,0.8,0.6,1.2    c0.4,0.3,1.3,0.7,2.6,1.1c0.7,0.2,1.3,0.4,1.7,0.5s0.9,0.4,1.5,0.7c0.6,0.3,1.1,0.7,1.4,1c0.3,0.4,0.6,0.8,0.9,1.4s0.4,1.2,0.4,2    c0,1.7-0.6,3.1-1.9,4.1C174.2,143,172.5,143.5,170.5,143.5z"
                          />
                        </g>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      style={{ width: "50px" }}
                      viewBox="0 0 300 300"
                    >
                      <g>
                        <g>
                          <polygon
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="#FFD243"
                            points="167.6,194 145.6,156 101.7,156 145.6,232.1 166,232.1 189.5,232.1 233.5,156 284.3,67.9 260.7,67.9 240.3,67.9 189.5,156 		"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M279.4,65.1c1.5-2.7,5-3.6,7.6-2c2.7,1.5,3.6,5,2,7.6l-94.7,164.1c-2.2,3.9-7.9,3.6-9.8-0.3l-42.1-72.9 h-31l37.4,64.9H166c3.1,0,5.6,2.5,5.6,5.6c0,3.1-2.5,5.6-5.6,5.6l-20.4,0c-1.9,0-3.8-1-4.8-2.8l-43.7-75.7 c-2.5-3.7,0.1-8.7,4.6-8.7l43.9,0c1.9,0,3.8,1,4.8,2.8l39.1,67.7L279.4,65.1z M184.2,176.4c-1.5,2.7-5,3.6-7.6,2 c-2.7-1.5-3.6-5-2-7.6l61-105.7c1-1.8,2.9-2.8,4.8-2.8l20.4,0c3.1,0,5.6,2.5,5.6,5.6c0,3.1-2.5,5.6-5.6,5.6h-17.2L184.2,176.4z"
                          />
                        </g>
                        <g>
                          <path
                            fill="#FFFFFF"
                            d="M26,131.2L10.2,73.7H24l9.9,40.3L45,73.7h10.7l11.1,40.3l9.9-40.3h13.8l-15.9,57.5h-15l-9.4-33.9L41,131.2 WH26z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M113.9,132c-5.9,0-10.8-1.3-14.7-3.8c-3.9-2.5-6.7-6-8.3-10.4l11.3-6.6c2.3,5.3,6.3,8,12.1,8 c5.2,0,7.8-1.6,7.8-4.7c0-1.7-0.8-3-2.5-4c-1.7-1-4.8-2.1-9.4-3.3c-2.4-0.7-4.5-1.4-6.3-2.2c-1.8-0.8-3.6-1.9-5.3-3.2 s-3-3-3.9-5.1c-0.9-2-1.4-4.4-1.4-7c0-5.3,1.9-9.6,5.7-12.7c3.8-3.2,8.3-4.8,13.6-4.8c4.7,0,8.9,1.1,12.5,3.3 c3.6,2.2,6.5,5.4,8.5,9.7l-11.1,6.5c-1-2.2-2.3-3.8-3.9-5c-1.5-1.1-3.6-1.7-6.1-1.7c-2,0-3.5,0.4-4.6,1.3 c-1.1,0.9-1.6,1.9-1.6,3.1c0,1.4,0.7,2.7,2,3.7c1.3,1,4.1,2.2,8.3,3.5c2.3,0.7,4.1,1.3,5.3,1.7c1.3,0.4,2.9,1.2,4.8,2.2 c1.9,1,3.4,2.1,4.4,3.2s1.9,2.6,2.7,4.4c0.8,1.8,1.2,3.9,1.2,6.3c0,5.5-2,9.8-5.9,12.9C125.4,130.5,120.3,132,113.9,132z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M166.9,131.1c-8.2,0-15.1-2.7-20.5-8.2c-5.4-5.5-8.1-12.3-8.1-20.4c0-8.1,2.7-15,8.1-20.4 c5.4-5.5,12.3-8.2,20.5-8.2c4.9,0,9.5,1.1,13.7,3.4c4.2,2.3,7.5,5.4,9.9,9.3l-10.9,6.3c-1.2-2.2-2.9-3.8-5.2-5s-4.8-1.8-7.6-1.8 c-4.9,0-8.8,1.5-11.7,4.5c-2.9,3-4.4,7-4.4,11.9c0,4.9,1.5,8.9,4.4,11.9c2.9,3,6.8,4.5,11.7,4.5c2.8,0,5.3-0.6,7.6-1.8 s4-2.9,5.2-5l10.9,6.3c-2.4,3.9-5.6,7.1-9.8,9.3C176.5,129.9,171.9,131.1,166.9,131.1z"
                          />
                        </g>
                      </g>
                    </svg>
                  )}
                </a>
              </div>

              {this.context.authDetails?.userType === 7 && (
                <Menu
                  className="sidebar"
                  style={{ background: "#0083b7", color: "#fff" }}
                  defaultSelectedKeys={window?.location?.pathname}
                  mode="inline"
                >
                  <Menu.Item key="/users" icon={<UserOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/users">
                      Users
                    </Link>
                  </Menu.Item>
                </Menu>
              )}

              {/* ADMIN ROUTES */}
              {this.context.authDetails?.userType === 1 && (
                <Menu
                  className="sidebar"
                  style={{
                    background: "#0083B7",
                    color: "#fff",
                    border: "none",
                  }}
                  defaultSelectedKeys={window?.location?.pathname}
                  mode="inline"
                >
                  <Menu.Item key="/" icon={<PieChartOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/">
                      Dashboard
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/users" icon={<UserOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/users">
                      Users
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/courses" icon={<DesktopOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/courses">
                      Courses
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/pages" icon={<FileOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/pages">
                      Pages
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/categories" icon={<AppstoreOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/categories">
                      Categories
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/sendgridemails" icon={<MailOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/send-grid-email">
                      Email Marketing
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/orders" icon={<DollarCircleOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/orders">
                      Orders
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/BDMOrders" icon={<DollarCircleOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/BDMOrders">
                      Bdm 1 Orders
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/contacts" icon={<CreditCardOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/contacts">
                      Contacts
                      {contactNotificationCount > 0 && (
                        <Badge
                          style={{ marginLeft: 15 }}
                          count={contactNotificationCount}
                        />
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/faqs" icon={<QuestionOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/faqs">
                      Faq's
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="9" icon={<DollarCircleOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/discounts">Discounts</Link>
                  </Menu.Item> */}

                  <Menu.Item
                    key="/courses/progress"
                    icon={<BarChartOutlined />}
                  >
                    <Link onClick={this.toggleCollapsed} to="/courses/progress">
                      Courses Progress
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key="/blogs"
                    icon={<FileOutlined />}
                    title="BLOG"
                    className="blogsmenuwrapper"
                  >
                    <Menu.Item icon={<FileOutlined />} key="/blogs">
                      {" "}
                      <Link onClick={this.toggleCollapsed} to="/blogs">
                        Manage Blogs
                      </Link>
                    </Menu.Item>
                    <Menu.Item icon={<FileOutlined />} key="/blogs/categories">
                      <Link
                        onClick={this.toggleCollapsed}
                        to="/blogs/categories"
                      >
                        Blog Categories
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  {/* <Menu.Item key="12" icon={<DollarCircleOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/promo-codes">Promo Codes</Link>
                  </Menu.Item> */}
                </Menu>
              )}

              {/* BDM Sale ROUTES */}
              {this.context.authDetails?.userType === 3 && (
                <Menu
                  className="sidebar"
                  style={{
                    background: "#0083B7",
                    color: "#fff",
                    border: "none",
                  }}
                  defaultSelectedKeys={window?.location?.pathname}
                  mode="inline"
                >
                  <Menu.Item key="/" icon={<PieChartOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/">
                      Dashboard
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/users" icon={<UserOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/users">
                      Users
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/courses" icon={<DesktopOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/orders">
                      Orders
                    </Link>
                  </Menu.Item>
                </Menu>
              )}

              {/* USER ROUTES */}
              {this.context.authDetails?.userType === 4 && (
                <Menu
                  className="sidebar"
                  style={{
                    background: "#0083B7",
                    border: "none",
                    color: "#fff",
                  }}
                  defaultSelectedKeys={window?.location?.pathname}
                  mode="inline"
                >
                  <Menu.Item key="/" icon={<PieChartOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/">
                      Dashboard
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/courses" icon={<DesktopOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/courses">
                      Courses
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/profile" icon={<UserOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/profile">
                      Profile
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/orders" icon={<DollarCircleOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/orders">
                      Orders
                    </Link>
                  </Menu.Item>
                </Menu>
              )}

              {/* Manager ROUTES */}
              {this.context.authDetails.userType === 2 && (
                <Menu
                  className="sidebar"
                  style={{
                    background: "#0083B7",
                    border: "none",
                    color: "#fff",
                  }}
                  defaultSelectedKeys={window?.location?.pathname}
                  mode="inline"
                >
                  <Menu.Item key="/" icon={<PieChartOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/">
                      Dashboard
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/courses" icon={<DesktopOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/courses">
                      Courses
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/trainees" icon={<UserOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/trainees">
                      Trainees
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/orders" icon={<DollarCircleOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/orders">
                      Orders
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/hierarchy" icon={<ApartmentOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/hierarchy">
                      Hierarchy
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    key="/courses/progress"
                    icon={<BarChartOutlined />}
                  >
                    <Link onClick={this.toggleCollapsed} to="/courses/progress">
                      Courses Progress
                    </Link>
                  </Menu.Item>
                </Menu>
              )}

              {/* USER ROUTES */}
              {this.context.authDetails?.userType === 6 && (
                <SubMenu key="/blogs" icon={<FileOutlined />} title="BLOG">
                  <Menu.Item icon={<FileOutlined />} key="/blogs">
                    {" "}
                    <Link onClick={this.toggleCollapsed} to="/blogs">
                      Manage Blogs
                    </Link>
                  </Menu.Item>
                  <Menu.Item icon={<FileOutlined />} key="/blogs/categories">
                    <Link onClick={this.toggleCollapsed} to="/blogs/categories">
                      Blog Categories
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}

              {this.context.authDetails?.userType === 5 && (
                <Menu
                  className="sidebar"
                  style={{
                    background: "#0083B7",
                    border: "none",
                    color: "#fff",
                  }}
                  defaultSelectedKeys={window?.location?.pathname}
                  mode="inline"
                >
                  <Menu.Item key="/" icon={<PieChartOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/">
                      Dashboard
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/courses" icon={<DesktopOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/courses">
                      Courses
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/pages" icon={<FileOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/pages">
                      Pages
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/categories" icon={<AppstoreOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/categories">
                      Categories
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="/profile" icon={<UserOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/profile">
                      Profile
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="faqs" icon={<QuestionOutlined />}>
                    <Link onClick={this.toggleCollapsed} to="/faqs">
                      Faq's
                    </Link>
                  </Menu.Item>

                  <SubMenu key="/blogs" icon={<FileOutlined />} title="BLOG">
                    <Menu.Item icon={<FileOutlined />} key="/blogs">
                      {" "}
                      <Link onClick={this.toggleCollapsed} to="/blogs">
                        Manage Blogs
                      </Link>
                    </Menu.Item>
                    <Menu.Item icon={<FileOutlined />} key="/blogs/categories">
                      <Link
                        onClick={this.toggleCollapsed}
                        to="/blogs/categories"
                      >
                        Blog Categories
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              )}
            </Sider>
            {/*  } */}
            <Layout
              className={`site-layout main-wrapper ${
                !collapsed ? "menu-open" : ""
              }`}
            >
              {this.context.authDetails &&
                Object.keys(this.context.authDetails).length > 0 && (
                  <>
                    <Header
                      className="site-layout-background desktop-view"
                      style={{ padding: 0, background: "#fff" }}
                    >
                      <Row>
                        <div className="logo-mob">
                          <a href={process.env.REACT_APP_BASE_URL}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              style={{ width: "80px" }}
                              viewBox="0 0 500 322.7"
                            >
                              <g>
                                <polygon
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  fill="#FFD243"
                                  points="279.8,238.4 241.3,171.8 164.5,171.8 241.3,305 277,305 
318.2,305 395.1,171.8 484,17.7 442.7,17.7 407.1,17.7 318.2,171.8 	"
                                />
                                <g>
                                  <linearGradient
                                    id="SVGID2_1_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="13.4133"
                                    y1="78.3824"
                                    x2="325.4776"
                                    y2="78.3824"
                                  >
                                    <stop
                                      offset="0"
                                      style={{ stopColor: "#008AB5" }}
                                    />
                                    <stop
                                      offset="0.3087"
                                      style={{ stopColor: "#008BB2" }}
                                    />
                                    <stop
                                      offset="0.4931"
                                      style={{ stopColor: "#008DAA" }}
                                    />
                                    <stop
                                      offset="0.6449"
                                      style={{ stopColor: "#00919C" }}
                                    />
                                    <stop
                                      offset="0.7789"
                                      style={{ stopColor: "#009788" }}
                                    />
                                    <stop
                                      offset="0.9"
                                      style={{ stopColor: "#009E6E" }}
                                    />
                                    <stop
                                      offset="1"
                                      style={{ stopColor: "#00A652" }}
                                    />
                                  </linearGradient>
                                  <path
                                    fill="url(#SVGID2_1_)"
                                    d="M33.9,128.4L6.3,28.4h24l17.3,70l19.3-70h18.6l19.3,70l17.3-70h24l-27.6,100.1h-26l-16.3-59
l-16.3,59H33.9z"
                                  />
                                  <linearGradient
                                    id="SVGID2_2_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="13.4133"
                                    y1="77.8426"
                                    x2="325.4776"
                                    y2="77.8426"
                                  >
                                    <stop
                                      offset="0"
                                      style={{ stopColor: "#008AB5" }}
                                    />
                                    <stop
                                      offset="0.3087"
                                      style={{ stopColor: "#008BB2" }}
                                    />
                                    <stop
                                      offset="0.4931"
                                      style={{ stopColor: "#008DAA" }}
                                    />
                                    <stop
                                      offset="0.6449"
                                      style={{ stopColor: "#00919C" }}
                                    />
                                    <stop
                                      offset="0.7789"
                                      style={{ stopColor: "#009788" }}
                                    />
                                    <stop
                                      offset="0.9"
                                      style={{ stopColor: "#009E6E" }}
                                    />
                                    <stop
                                      offset="1"
                                      style={{ stopColor: "#00A652" }}
                                    />
                                  </linearGradient>
                                  <path
                                    fill="url(#SVGID2_2_)"
                                    d="M186.8,129.9c-10.2,0-18.7-2.2-25.5-6.6c-6.8-4.4-11.6-10.5-14.5-18.1l19.7-11.4
c4,9.2,11,13.9,21,13.9c9,0,13.6-2.7,13.6-8.1c0-2.9-1.5-5.3-4.4-6.9c-2.9-1.7-8.4-3.6-16.4-5.8c-4.2-1.1-7.9-2.4-11-3.9
c-3.1-1.4-6.2-3.3-9.2-5.6c-3-2.3-5.3-5.3-6.9-8.8s-2.4-7.6-2.4-12.2c0-9.2,3.3-16.6,9.9-22.2c6.6-5.5,14.5-8.3,23.7-8.3
c8.2,0,15.4,1.9,21.7,5.8c6.3,3.9,11.2,9.5,14.9,16.8l-19.3,11.3c-1.8-3.8-4.1-6.7-6.7-8.6c-2.7-1.9-6.2-2.9-10.6-2.9
c-3.4,0-6.1,0.7-7.9,2.2c-1.9,1.5-2.8,3.3-2.8,5.4c0,2.5,1.2,4.6,3.5,6.4s7.2,3.8,14.5,6c4,1.2,7.1,2.2,9.3,3c2.2,0.8,5,2,8.4,3.8
c3.4,1.8,6,3.6,7.7,5.6c1.8,2,3.3,4.5,4.7,7.7c1.4,3.2,2.1,6.8,2.1,10.9c0,9.5-3.4,17-10.3,22.5
C206.8,127.1,197.9,129.9,186.8,129.9z"
                                  />
                                  <linearGradient
                                    id="SVGID2_3_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="13.4133"
                                    y1="78.3336"
                                    x2="325.4776"
                                    y2="78.3336"
                                  >
                                    <stop
                                      offset="0"
                                      style={{ stopColor: "#008AB5" }}
                                    />
                                    <stop
                                      offset="0.3087"
                                      style={{ stopColor: "#008BB2" }}
                                    />
                                    <stop
                                      offset="0.4931"
                                      style={{ stopColor: "#008DAA" }}
                                    />
                                    <stop
                                      offset="0.6449"
                                      style={{ stopColor: "#00919C" }}
                                    />
                                    <stop
                                      offset="0.7789"
                                      style={{ stopColor: "#009788" }}
                                    />
                                    <stop
                                      offset="0.9"
                                      style={{ stopColor: "#009E6E" }}
                                    />
                                    <stop
                                      offset="1"
                                      style={{ stopColor: "#00A652" }}
                                    />
                                  </linearGradient>
                                  <path
                                    fill="url(#SVGID2_3_)"
                                    d="M278.9,128.2c-14.3,0-26.2-4.8-35.7-14.3c-9.4-9.6-14.2-21.4-14.2-35.6c0-14.2,4.7-26,14.2-35.6
c9.5-9.5,21.4-14.3,35.7-14.3c8.6,0,16.5,2,23.8,6c7.3,4,13.1,9.4,17.3,16.2l-18.9,11c-2.1-3.8-5.1-6.7-9-8.7
c-3.9-2.1-8.3-3.1-13.2-3.1c-8.5,0-15.3,2.6-20.3,7.8c-5.1,5.2-7.6,12.1-7.6,20.7s2.5,15.5,7.6,20.7c5.1,5.2,11.9,7.8,20.3,7.8
c4.8,0,9.2-1,13.2-3.1c4-2.1,7-5,9-8.6l18.9,11c-4.1,6.8-9.8,12.3-17.1,16.2C295.5,126.2,287.6,128.2,278.9,128.2z"
                                  />
                                </g>
                                <linearGradient
                                  id="SVGID2_4_"
                                  gradientUnits="userSpaceOnUse"
                                  x1="206.9826"
                                  y1="247.4061"
                                  x2="504.2544"
                                  y2="83.0323"
                                >
                                  <stop
                                    offset="0"
                                    style={{ stopColor: "#008AB5" }}
                                  />
                                  <stop
                                    offset="1"
                                    style={{ stopColor: "#00A652" }}
                                  />
                                </linearGradient>
                                <path
                                  fill="url(#SVGID2_4_)"
                                  d="M475.5,12.8c2.7-4.7,8.7-6.3,13.4-3.6c4.7,2.7,6.3,8.7,3.6,13.4L326.6,309.9
c-3.9,6.8-13.8,6.3-17.2-0.6l-73.7-127.7h-54.3L247,295.2h30c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8l-35.7,0
c-3.4,0-6.6-1.8-8.5-4.9l-76.5-132.6c-4.3-6.4,0.2-15.3,8.1-15.3l76.9,0c3.4,0,6.7,1.8,8.5,4.9l68.4,118.5L475.5,12.8z
M308.8,207.6c-2.7,4.7-8.7,6.3-13.4,3.6c-4.7-2.7-6.3-8.7-3.6-13.4l106.7-185c1.8-3.1,5.1-4.9,8.5-4.9l35.7,0
c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8h-30L308.8,207.6z"
                                />
                              </g>
                            </svg>
                          </a>
                        </div>
                        <Col
                          className="welcome-note"
                          span={`${
                            this.context.authDetails.userType === 1 ||
                            this.context.authDetails.userType === 2
                              ? 6
                              : 12
                          }`}
                        >
                          <p
                            style={{
                              background: "#fff",
                              border: "none",
                              paddingLeft: "20px",
                              margin: "0",
                            }}
                          >
                            <span style={{ color: "#999", marginRight: 4 }}>
                              Welcome,{" "}
                            </span>
                            <b style={{ color: "#0083B7" }}>
                              {this.context.authDetails?.firstName}{" "}
                              {this.context.authDetails?.lastName}
                            </b>
                          </p>
                        </Col>
                        {(this.context.authDetails.userType === 1 ||
                          this.context.authDetails.userType === 2) && (
                          <>
                            <Col
                              className="header-email-col"
                              span={6}
                              style={{ textAlign: "center" }}
                            >
                              <Tooltip placement="bottom" title={text}>
                                <button
                                  onClick={() =>
                                    this.copyCodeToClipboard(
                                      process.env.REACT_APP_EMAIL_ADDRESS
                                    )
                                  }
                                  style={{
                                    height: "50px",
                                    background: "#fff",
                                    border: "none",
                                    padding: "0",
                                    cursor: "pointer",
                                  }}
                                >
                                  <b style={{ color: "#0083B7" }}>
                                    {process.env.REACT_APP_EMAIL_ADDRESS}
                                  </b>
                                </button>
                              </Tooltip>
                            </Col>
                            <Col
                              className="header-email-col"
                              span={6}
                              style={{ textAlign: "center" }}
                            >
                              <Tooltip placement="bottom" title={text}>
                                <button
                                  onClick={() =>
                                    this.copyCodeToClipboard(
                                      process.env.REACT_APP_PHONE_NO
                                    )
                                  }
                                  style={{
                                    height: "50px",
                                    background: "#fff",
                                    border: "none",
                                    padding: "0",
                                    cursor: "pointer",
                                  }}
                                >
                                  <b style={{ color: "#0083B7" }}>
                                    {process.env.REACT_APP_PHONE_NO}
                                  </b>
                                </button>
                              </Tooltip>
                            </Col>
                          </>
                        )}

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                          className="header-menu-wrapper"
                          span={`${
                            this.context.authDetails.userType === 1 ||
                            this.context.authDetails.userType === 2
                              ? 6
                              : 12
                          }`}
                        >
                          {((this.context.orignal_account_authDetails &&
                            this.context.orignal_account_authDetails
                              .userType === 1 &&
                            Object.keys(
                              this.context.orignal_account_authDetails
                            ).length > 0) ||
                            localStorage.getItem("orignal_key")) && (
                            <Tooltip
                              placement="bottom"
                              title={"Switch Back To Admin Account"}
                            >
                              <Button
                                type="primary"
                                onClick={this.backToOrignalAdminAccount}
                              >
                                Switch <UserSwitchOutlined />
                              </Button>
                            </Tooltip>
                          )}

                          {this.context.authDetails.userType === 2 &&
                            !this.context.authDetails.orignalUserType &&
                            !localStorage.getItem("orignal_key") && (
                              <Tooltip
                                placement="bottom"
                                title={"Switch to Learner Account"}
                              >
                                <Button
                                  type="primary"
                                  onClick={this.swtichToLearnerAccount}
                                >
                                  Switch <UserSwitchOutlined />
                                </Button>
                              </Tooltip>
                            )}
                          {this.context.authDetails.userType === 4 &&
                            this.context.authDetails.orignalUserType &&
                            this.context.authDetails.orignalUserType === 2 && (
                              <Button
                                type="primary"
                                onClick={this.backToOrignalAdminAccount}
                              >
                                Switch Back To Manager Account
                                <UserSwitchOutlined />
                              </Button>
                            )}

                          <Menu
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              minWidth: "50%",
                              // textAlign: "right",
                            }}
                            key="user"
                            mode="horizontal"
                          >
                            <SubMenu
                              title={
                                <>
                                  <Avatar
                                    style={{
                                      backgroundColor: "#0083B7",
                                      margin: "0 8px",
                                    }}
                                  >
                                    {this.context.authDetails?.firstName[0]}
                                  </Avatar>
                                  <span
                                    style={{ color: "#999", marginRight: 4 }}
                                  >
                                    Hi,
                                  </span>
                                  <span>
                                    {this.context.authDetails?.firstName}
                                  </span>

                                  <DownOutlined style={{ marginLeft: 8 }} />
                                </>
                              }
                              key={"auth"}
                            >
                              <Menu.Item key="Profile">
                                <Link to="/profile">Profile</Link>
                              </Menu.Item>

                              <Menu.Item
                                key="SignOut"
                                onClick={this.logoutUser}
                              >
                                Sign out
                              </Menu.Item>
                            </SubMenu>
                          </Menu>
                        </Col>
                      </Row>
                    </Header>

                    <Header
                      className="site-layout-background mobile-view"
                      style={{ padding: 0, background: "#fff" }}
                    >
                      <div className="logo-mob">
                        <a href={process.env.REACT_APP_BASE_URL}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            style={{ width: "80px" }}
                            viewBox="0 0 500 322.7"
                          >
                            <g>
                              <polygon
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                fill="#FFD243"
                                points="279.8,238.4 241.3,171.8 164.5,171.8 241.3,305 277,305 318.2,305 395.1,171.8 484,17.7 442.7,17.7 407.1,17.7 318.2,171.8 	"
                              />
                              <g>
                                <linearGradient
                                  id="SVGID_1_"
                                  gradientUnits="userSpaceOnUse"
                                  x1="13.4133"
                                  y1="78.3824"
                                  x2="325.4776"
                                  y2="78.3824"
                                >
                                  <stop
                                    offset="0"
                                    style={{ stopColor: "#008AB5" }}
                                  />
                                  <stop
                                    offset="0.3087"
                                    style={{ stopColor: "#008BB2" }}
                                  />
                                  <stop
                                    offset="0.4931"
                                    style={{ stopColor: "#008DAA" }}
                                  />
                                  <stop
                                    offset="0.6449"
                                    style={{ stopColor: "#00919C" }}
                                  />
                                  <stop
                                    offset="0.7789"
                                    style={{ stopColor: "#009788" }}
                                  />
                                  <stop
                                    offset="0.9"
                                    style={{ stopColor: "#009E6E" }}
                                  />
                                  <stop
                                    offset="1"
                                    style={{ stopColor: "#00A652" }}
                                  />
                                </linearGradient>
                                <path
                                  fill="url(#SVGID_1_)"
                                  d="M33.9,128.4L6.3,28.4h24l17.3,70l19.3-70h18.6l19.3,70l17.3-70h24l-27.6,100.1h-26l-16.3-59 l-16.3,59H33.9z"
                                />
                                <linearGradient
                                  id="SVGID_2_"
                                  gradientUnits="userSpaceOnUse"
                                  x1="13.4133"
                                  y1="77.8426"
                                  x2="325.4776"
                                  y2="77.8426"
                                >
                                  <stop
                                    offset="0"
                                    style={{ stopColor: "#008AB5" }}
                                  />
                                  <stop
                                    offset="0.3087"
                                    style={{ stopColor: "#008BB2" }}
                                  />
                                  <stop
                                    offset="0.4931"
                                    style={{ stopColor: "#008DAA" }}
                                  />
                                  <stop
                                    offset="0.6449"
                                    style={{ stopColor: "#00919C" }}
                                  />
                                  <stop
                                    offset="0.7789"
                                    style={{ stopColor: "#009788" }}
                                  />
                                  <stop
                                    offset="0.9"
                                    style={{ stopColor: "#009E6E" }}
                                  />
                                  <stop
                                    offset="1"
                                    style={{ stopColor: "#00A652" }}
                                  />
                                </linearGradient>
                                <path
                                  fill="url(#SVGID_2_)"
                                  d="M186.8,129.9c-10.2,0-18.7-2.2-25.5-6.6c-6.8-4.4-11.6-10.5-14.5-18.1l19.7-11.4 c4,9.2,11,13.9,21,13.9c9,0,13.6-2.7,13.6-8.1c0-2.9-1.5-5.3-4.4-6.9c-2.9-1.7-8.4-3.6-16.4-5.8c-4.2-1.1-7.9-2.4-11-3.9 c-3.1-1.4-6.2-3.3-9.2-5.6c-3-2.3-5.3-5.3-6.9-8.8s-2.4-7.6-2.4-12.2c0-9.2,3.3-16.6,9.9-22.2c6.6-5.5,14.5-8.3,23.7-8.3 c8.2,0,15.4,1.9,21.7,5.8c6.3,3.9,11.2,9.5,14.9,16.8l-19.3,11.3c-1.8-3.8-4.1-6.7-6.7-8.6c-2.7-1.9-6.2-2.9-10.6-2.9 c-3.4,0-6.1,0.7-7.9,2.2c-1.9,1.5-2.8,3.3-2.8,5.4c0,2.5,1.2,4.6,3.5,6.4s7.2,3.8,14.5,6c4,1.2,7.1,2.2,9.3,3c2.2,0.8,5,2,8.4,3.8 c3.4,1.8,6,3.6,7.7,5.6c1.8,2,3.3,4.5,4.7,7.7c1.4,3.2,2.1,6.8,2.1,10.9c0,9.5-3.4,17-10.3,22.5 C206.8,127.1,197.9,129.9,186.8,129.9z"
                                />
                                <linearGradient
                                  id="SVGID_3_"
                                  gradientUnits="userSpaceOnUse"
                                  x1="13.4133"
                                  y1="78.3336"
                                  x2="325.4776"
                                  y2="78.3336"
                                >
                                  <stop
                                    offset="0"
                                    style={{ stopColor: "#008AB5" }}
                                  />
                                  <stop
                                    offset="0.3087"
                                    style={{ stopColor: "#008BB2" }}
                                  />
                                  <stop
                                    offset="0.4931"
                                    style={{ stopColor: "#008DAA" }}
                                  />
                                  <stop
                                    offset="0.6449"
                                    style={{ stopColor: "#00919C" }}
                                  />
                                  <stop
                                    offset="0.7789"
                                    style={{ stopColor: "#009788" }}
                                  />
                                  <stop
                                    offset="0.9"
                                    style={{ stopColor: "#009E6E" }}
                                  />
                                  <stop
                                    offset="1"
                                    style={{ stopColor: "#00A652" }}
                                  />
                                </linearGradient>
                                <path
                                  fill="url(#SVGID_3_)"
                                  d="M278.9,128.2c-14.3,0-26.2-4.8-35.7-14.3c-9.4-9.6-14.2-21.4-14.2-35.6c0-14.2,4.7-26,14.2-35.6 c9.5-9.5,21.4-14.3,35.7-14.3c8.6,0,16.5,2,23.8,6c7.3,4,13.1,9.4,17.3,16.2l-18.9,11c-2.1-3.8-5.1-6.7-9-8.7 c-3.9-2.1-8.3-3.1-13.2-3.1c-8.5,0-15.3,2.6-20.3,7.8c-5.1,5.2-7.6,12.1-7.6,20.7s2.5,15.5,7.6,20.7c5.1,5.2,11.9,7.8,20.3,7.8 c4.8,0,9.2-1,13.2-3.1c4-2.1,7-5,9-8.6l18.9,11c-4.1,6.8-9.8,12.3-17.1,16.2C295.5,126.2,287.6,128.2,278.9,128.2z"
                                />
                              </g>
                              <linearGradient
                                id="SVGID_4_"
                                gradientUnits="userSpaceOnUse"
                                x1="206.9826"
                                y1="247.4061"
                                x2="504.2544"
                                y2="83.0323"
                              >
                                <stop
                                  offset="0"
                                  style={{ stopColor: "#008AB5" }}
                                />
                                <stop
                                  offset="1"
                                  style={{ stopColor: "#00A652" }}
                                />
                              </linearGradient>
                              <path
                                fill="url(#SVGID_4_)"
                                d="M475.5,12.8c2.7-4.7,8.7-6.3,13.4-3.6c4.7,2.7,6.3,8.7,3.6,13.4L326.6,309.9 c-3.9,6.8-13.8,6.3-17.2-0.6l-73.7-127.7h-54.3L247,295.2h30c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8l-35.7,0 c-3.4,0-6.6-1.8-8.5-4.9l-76.5-132.6c-4.3-6.4,0.2-15.3,8.1-15.3l76.9,0c3.4,0,6.7,1.8,8.5,4.9l68.4,118.5L475.5,12.8z M308.8,207.6c-2.7,4.7-8.7,6.3-13.4,3.6c-4.7-2.7-6.3-8.7-3.6-13.4l106.7-185c1.8-3.1,5.1-4.9,8.5-4.9l35.7,0 c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8h-30L308.8,207.6z"
                              />
                            </g>
                          </svg>
                        </a>
                      </div>

                      <p
                        className="welcome-note"
                        style={{
                          width: "50%",
                          float: "left",
                          background: "#fff",
                          border: "none",
                          paddingLeft: "20px",
                        }}
                      >
                        <span style={{ color: "#999", marginRight: 4 }}>
                          Welcome,
                        </span>{" "}
                        <b style={{ color: "#0083B7" }}>
                          {this.context.authDetails?.firstName}{" "}
                          {this.context.authDetails?.lastName}
                        </b>
                      </p>

                      <Menu
                        style={{
                          width: "50%",
                          float: "right",
                          height: "0",
                          textAlign: "right",
                          display: "flex",
                          justifyContent: "flex-end",
                          minWidth: "50%",
                        }}
                        key="user"
                        mode="horizontal"
                      >
                        <SubMenu
                          title={
                            <>
                              <Avatar
                                style={{
                                  backgroundColor: "#0083B7",
                                  margin: "0 8px",
                                }}
                              >
                                {this.context.authDetails?.firstName[0]}
                              </Avatar>
                              <span style={{ color: "#999", marginRight: 4 }}>
                                Hi,
                              </span>
                              <span>{this.context.authDetails?.firstName}</span>

                              <DownOutlined style={{ marginLeft: 8 }} />
                            </>
                          }
                          key="subauth"
                        >
                          <Menu.Item key="User" className="lghide">
                            <span style={{ color: "#999", marginRight: 4 }}>
                              Hi,
                            </span>
                            <span>
                              {this.context.authDetails?.firstName}{" "}
                              {this.context.authDetails?.lastName}
                            </span>
                          </Menu.Item>
                          <Menu.Item key="Profile">
                            <Link to="/profile">Profile</Link>
                          </Menu.Item>

                          <Menu.Item key="SignOut" onClick={this.logoutUser}>
                            Sign out
                          </Menu.Item>
                          {((this.context.orignal_account_authDetails &&
                            this.context.orignal_account_authDetails
                              .userType === 1 &&
                            Object.keys(
                              this.context.orignal_account_authDetails
                            ).length > 0) ||
                            localStorage.getItem("orignal_key")) && (
                            <Menu.Item key="switch">
                              <Tooltip
                                placement="bottom"
                                title={"Switch Back To Admin Account"}
                              >
                                <Button
                                  type="primary"
                                  onClick={this.backToOrignalAdminAccount}
                                >
                                  Switch <UserSwitchOutlined />
                                </Button>
                              </Tooltip>
                            </Menu.Item>
                          )}

                          {this.context.authDetails.userType === 2 &&
                            !this.context.authDetails.orignalUserType &&
                            !localStorage.getItem("orignal_key") && (
                              <Menu.Item key="switch">
                                <Tooltip
                                  placement="bottom"
                                  title={"Switch to Learner Account"}
                                >
                                  <Button
                                    type="primary"
                                    style={{ width: "100%" }}
                                    onClick={this.swtichToLearnerAccount}
                                  >
                                    Switch <UserSwitchOutlined />
                                  </Button>
                                </Tooltip>
                              </Menu.Item>
                            )}
                          {this.context.authDetails.userType === 4 &&
                            this.context.authDetails.orignalUserType &&
                            this.context.authDetails.orignalUserType === 2 && (
                              <Menu.Item key="switch">
                                <Button
                                  type="primary"
                                  onClick={this.backToOrignalAdminAccount}
                                >
                                  Switch Back To Manager Account
                                  <UserSwitchOutlined />
                                </Button>
                              </Menu.Item>
                            )}
                        </SubMenu>
                      </Menu>
                    </Header>
                  </>
                )}

              <Content style={{ margin: "0 16px" }}>
                <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>

                <div
                  className="site-layout-background"
                  style={{ padding: 24, minHeight: 360 }}
                >
                  {/* { !this.context.authDetails.is_non_oes_user  && */}
                  <Routes />
                  {/* } */}
                </div>
              </Content>
            </Layout>
          </Layout>
        )}
      </>
    );
  }
}

export default Main;
