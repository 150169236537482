/* 
   define all your routes here and it will run automatically
 */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../screens/User/Dashboard/Dashboard";
import CourseDetails from "../screens/User/CourseDetails/CourseDetails";
import OrderDetails from "../screens/User/Orders/OrderDetails";
import Discounts from "../screens/User/Discounts/Discounts";
import BlogCategories from "../screens/User/BlogCategories/BlogCategories";
import Blogs from "../screens/User/Blogs/Blogs";
import PromoCodes from "../screens/User/PromoCodes/PromoCodes";
import Profile from "../screens/User/Users/profile";
import Users from "../screens/User/Users/Users";
import Reports from "../screens/User/Reports/Reports";
import Trainees from "../screens/User/Trainees/Trainees";
import Pages from "../screens/User/Pages/pages";
import AssignCourses from "../screens/User/AssignCourses/AssignCourses";
import UserDetails from "../screens/User/Users/Details";
import Courses from "../screens/User/Courses/Courses";
import Hierarchy from "../screens/User/Hierarchy/Hierarchy";
import CourseProgress from "../screens/User/Courses/Progress";
import Categories from "../screens/User/Categories/Categories";
import Orders from "../screens/User/Orders/Orders";
import Contacts from "../screens/User/Contacts/Contacts";
import ContactDetails from "../screens/User/Contacts/ContactDetails";
import Faqs from "../screens/User/Faqs/Faqs";
import SendEmail from "../screens/User/SendgridEmail/SendgridEmail";
import MainContext from "../Context/AppContext";
class NavRoutes extends React.Component {
  state = {
    loader: true,
    isValid: false,
  };
  static contextType = MainContext;
  render() {
    const { userType } = this.context.authDetails;
    return (
      <Switch>
        <Route
          path="/courses/progress"
          name="course_progress"
          component={CourseProgress}
        />
        <Route
          path="/report/hierarchy/:hierarchyID"
          name="course_hierarchy_progress"
          component={CourseProgress}
        />
        <Route path="/courses" name="courses" component={Courses} />

        <Route path="/faqs" name="faqs" component={Faqs} />
        <Route path="/contacts" name="contacts" component={Contacts} />
        <Route path="/send-grid-email" name="SendEmail" component={SendEmail} />

        <Route
          path="/blogs/categories"
          name="blogs/Categories"
          component={BlogCategories}
        />

        <Route path="/blogs" name="blogs" component={Blogs} />

        <Route path="/discounts" name="discounts" component={Discounts} />

        <Route path="/promo-codes" name="promo-codes" component={PromoCodes} />

        <Route
          path="/view/contact/:contactID"
          name="contactdetails"
          component={ContactDetails}
        />

        <Route
          path="/user/:userid/courses"
          name="assignCourses"
          component={AssignCourses}
        />
        <Route
          path="/user/:userid/details"
          name="userDetails"
          component={UserDetails}
        />
        <Route
          path="/view/course/:courseID"
          name="courseDetails"
          component={CourseDetails}
        />
        <Route
          path="/view/order/:orderID"
          name="orderDetails"
          component={OrderDetails}
        />
        <Route
          path="/view/course/:regID"
          name="courseDetails"
          component={CourseDetails}
        />
        <Route path="/hierarchy" name="hierarchy" component={Hierarchy} />
        <Route path="/Categories" name="categories" component={Categories} />
        <Route
          path="/orders"
          name="orders"
          component={(props) => <Orders {...props} BDMNumber={null} />}
        />
        <Route
          path="/BDMOrders"
          name="BDMOrders"
          component={(props) => <Orders {...props} BDMNumber={1} />}
        />
        <Route path="/users" name="users" component={Users} />
        <Route path="/reports" name="reports" component={Reports} />
        <Route path="/trainees" name="trainees" component={Trainees} />
        <Route path="/profile" name="profile" component={Profile} />
        <Route path="/pages" name="users" component={Pages} />
        {userType == 7 ? (
          <Redirect to="/users" />
        ) : (
          <Route exact path="/" name="home" component={Dashboard} />
        )}
        <Redirect to="/" />
      </Switch>
    );
  }
}
// export default NavRoutes;
export default NavRoutes;
