import React from "react";
import {
  getHierarchyByID,
  updateHierarchyDetails,
} from "../../../network/network";
import MainContext from "../../../Context/AppContext";
import {
  Form,
  Input,
  // Select,
  Row,
  // Radio,
  Col,
  Button,
  notification,
  Modal,
  message,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
// const { Option } = Select;

class EditHierarchy extends React.Component {
  formRef = React.createRef();
  state = {
    hierarchy_details: {},
    loading: false,
    visible: false,
  };
  static contextType = MainContext;
  onFinish = (values) => {
    values._id = this.props.data_id;
    updateHierarchyDetails(values, this.context.authDetails?.token)
      .then(() => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Hierarchy Updated",
          description: "The hierarchy has been updated successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          message.error(error.response.data.message);
          // let errorMessages = Object.values(error.response.data.message);
          // errorMessages.forEach(function (key, value) {
          //   let temp = Object.values(key);
          //   message.error(temp[0]);
          // });
        }
      });
  };

  getHierarchyDetails = (hierarchyID) => {
    getHierarchyByID(hierarchyID, this.context.authDetails?.token).then(
      (res) => {
        this.setState({
          loading: false,
          visible: true,
          hierarchy_details: res.data,
        });

        if (res.data && this.formRef.current) {
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
        }
      }
    );
  };

  showModal = () => {
    this.getHierarchyDetails(this.props.data_id);
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
    this.formRef.current.resetFields();
  };

  onFinishFailed = (errorInfo) => {};
  render() {
    return (
      <div>
        <Button
          onClick={this.showModal}
          type="primary"
          icon={<FormOutlined />}
        />

        <Modal
          title="Edit Hierarchy"
          visible={this.state.visible}
          width={2000}
          centered
          onCancel={this.hideModal}
          okText="Create"
          cancelText="Cancel"
          footer={[
            <Button
              form={"myForm" + this.state.hierarchy_details._id}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Update
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id={"myForm" + this.state.hierarchy_details._id}
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="label"
                  label="Hierarchy Name"
                  tooltip="Please enter your Hierarchy name?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Hierarchy Name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="campus"
                  label="Campus"
                  rules={[
                    {
                      required: false,
                      message: "Please input your campus!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="instructor"
                  label="Instructor"
                  rules={[
                    {
                      required: false,
                      message: "Please input your instructor name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default EditHierarchy;
