import React from "react";

import { Col, Row, Table } from "antd";
import { getOrderByID, getUserOrderByID } from "../../../network/network";
import MainContext from "../../../Context/AppContext";
class OrderDetails extends React.Component {
  state = {
    order_details: {},
    courses: [],
    columns: [
      {
        title: "Course Name",
        width: "30%",
        render: (record) => {
          return <p>{record.title || "-"}</p>;
        },
      },
      {
        title: "Quantity",
        width: "15%",
        render: (record) => {
          return <p>{record.quantity || "-"}</p>;
        },
      },
      {
        title: "Price",
        width: "15%",
        render: (record) => {
          return <p>${parseFloat(record.price).toFixed(2) || "-"}</p>;
        },
      },
      {
        title: "Total",
        width: "25%",
        render: (record) => {
          return (
            <p>
              ${parseFloat(record.price * record.quantity).toFixed(2) || "-"}
            </p>
          );
        },
      },
    ],
  };
  static contextType = MainContext;
  componentDidMount() {
    if (
      this.context.authDetails.userType === 4 ||
      this.context.authDetails.userType === 2
    ) {
      this.getUserOrderDetails();
    } else if (this.context.authDetails.userType === 1) {
      this.getOrderDetails();
    }
  }

  getOrderDetails = () => {
    this.setState({ loading: true });
    getOrderByID(
      this.props.match.params.orderID,
      this.context.authDetails.token
    )
      .then((res) => {
        this.setState({
          order_details: res.data,
          loading: false,
          courses: res.data.items,
        });
      })
      .finally((err) => {
        this.setState({
          loading: false,
        });
      });
  };
  getUserOrderDetails = () => {
    this.setState({ loading: true });
    getUserOrderByID(
      this.props.match.params.orderID,
      this.context.authDetails.token
    )
      .then((res) => {
        this.setState({
          order_details: res.data,
          loading: false,
          courses: res.data.items,
        });
      })
      .finally((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { order_details, courses, columns, loading } = this.state;
    return (
      <>
        <Row gutter={30}>
          <Col lg={14} md={18} style={{ margin: "auto" }}>
            <div className="dashboard-charts invoice-wrapper">
              <div className="chart-box">
                <Row className="invoice-header">
                  <Col span={12}>
                    <h3>Invoice</h3>
                  </Col>
                  <Col span={12}>
                    <Col lg={10} style={{ marginLeft: "auto" }}>
                      <div className="invoice-logo">
                        <img
                          src="https://res.cloudinary.com/elearning-avenue24/image/upload/v1629970736/assets/images/oes-logo_smaks3_hajzuu.png"
                          width="100%"
                          alt="logo"
                        />
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row className="user-details">
                  <Col lg={12} md={24}>
                    <ul>
                      {order_details.user && (
                        <>
                          <li>
                            <p>
                              <b>
                                {`${order_details.user?.firstName} ${order_details.user?.lastName} `}
                              </b>
                            </p>
                          </li>
                          <li>
                            <p>{order_details.user?.email}</p>
                          </li>
                        </>
                      )}
                      <li>
                        <p>{order_details.contactNo}</p>
                      </li>
                      <li>
                        <p>
                          {`${order_details.address}, ${order_details.city},   ${order_details.country}, ${order_details.zipCode} `}
                        </p>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row className="order-details">
                  {order_details.order_id && (
                    <Col lg={12} span={24}>
                      <ul>
                        <li>
                          <p>
                            <b>Invoice #</b>
                            <span>{`${
                              process.env.REACT_APP_COMPANY_SHORT_NAME
                            }-I-${order_details.order_id
                              .toString()
                              .padStart(5, "0")}`}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Order #</b>
                            <span>{`${
                              process.env.REACT_APP_COMPANY_SHORT_NAME
                            }-${order_details.order_id
                              .toString()
                              .padStart(5, "0")}`}</span>
                          </p>
                        </li>
                      </ul>
                    </Col>
                  )}
                  <Col lg={12} span={24}>
                    <ul className="text-right">
                      <li>
                        <p>
                          <b>Invoice Date:</b>
                          <span>
                            {" "}
                            {order_details.updatedAt
                              ? new Date(order_details.updatedAt).toDateString()
                              : "-"}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Order Date:</b>
                          <span>
                            {" "}
                            {order_details.createdAt
                              ? new Date(order_details.createdAt).toDateString()
                              : "-"}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Table
                  columns={columns}
                  rowKey={(record) => record._id}
                  dataSource={courses ? courses : []}
                  loading={loading}
                  pagination={false}
                  style={{ minHeight: "300px" }}
                />
                <Row className="invoice-total">
                  <Col lg={9} span={24} style={{ marginLeft: "auto" }}>
                    <Row style={{ padding: "0 15px" }}>
                      <Col span="12">
                        <p>Subtotal</p>
                      </Col>
                      <Col span="12" style={{ textAlign: "right" }}>
                        <p>
                          {`$ ${parseFloat(order_details?.total).toFixed(2)}`}
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        borderTop: "1px solid #000",
                        borderBottom: "1px solid #000",
                        padding: "0 15px",
                      }}
                    >
                      <Col span="12">
                        <h3>Total</h3>
                      </Col>
                      <Col span="12" style={{ textAlign: "right" }}>
                        <h3>
                          {`$ ${parseFloat(order_details?.total).toFixed(2)}`}
                        </h3>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {order_details.isPaid && (
                  <h3
                    style={{
                      color: "red",
                      fontWeight: "700",
                      marginTop: "100px",
                    }}
                  >
                    Paid via Credit Card, on{" "}
                    {order_details.updatedAt
                      ? new Date(order_details.updatedAt).toDateString()
                      : "-"}
                  </h3>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

// export default withRouter(CourseDetails)
export default OrderDetails;
