import React from "react";
import { getFaqByID, updateFaq } from "../../../network/network";
import MainContext from "../../../Context/AppContext";
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  Button,
  notification,
  Modal,
  message,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
const { TextArea } = Input;
class EditFaq extends React.Component {
  formRef = React.createRef();
  state = {
    faq_details: {},
  };
  static contextType = MainContext;
  onFinish = (values) => {
    values._id = this.props.data_id;
    updateFaq(values, this.context.authDetails?.token)
      .then(() => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Faq Updated",
          description: "The Faq has been updated successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          let errorMessages = Object.values(error.response.data.message);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      });
  };

  getUserDetails = (userID) => {
    getFaqByID(userID, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
        visible: true,
        faq_details: res.data,
      });

      if (res.data && this.formRef.current) {
        this.formRef.current.setFieldsValue({
          ...res.data,
        });
      }
    });
  };

  showModal = () => {
    this.getUserDetails(this.props.data_id);
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
    this.formRef.current.resetFields();
  };

  // handleFormChange = (e) => {
  //   alert(2343);
  // };
  onFinishFailed = (errorInfo) => {};
  render() {
    return (
      <div>
        <Button
          onClick={this.showModal}
          type="primary"
          icon={<FormOutlined />}
        />

        <Modal
          title="Edit Faq"
          visible={this.state.visible}
          //  onOk={this.onFinish}
          //  maskClosable="false"
          onCancel={this.hideModal}
          okText="Create"
          width={2000}
          centered
          cancelText="Cancel"
          footer={[
            <Button
              form={"myForm" + this.state.faq_details?._id}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Update
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id={"myForm" + this.state.faq_details?._id}
                name="basic"
                layout="vertical"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="question"
                  label="Question"
                  rules={[
                    {
                      required: true,
                      message: "Please input your question!",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea placeholder="textarea with clear icon" allowClear />
                </Form.Item>

                <Form.Item
                  name="answer"
                  label="Answer"
                  rules={[
                    {
                      required: true,
                      message: "Please input your answer!",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea placeholder="textarea with clear icon" allowClear />
                </Form.Item>

                <Form.Item
                  name="order"
                  label="Ordering"
                  rules={[
                    {
                      required: true,
                      message: "Please input integer value for ordering!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Published</Radio>
                    <Radio value={false}>Draft</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default EditFaq;
