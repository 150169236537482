import React from "react";
import { getUserByID, updateUserDetails } from "../../../network/network";
import MainContext from "../../../Context/AppContext";
import {
  Form,
  Input,
  Select,
  Row,
  Radio,
  Col,
  Button,
  notification,
  Modal,
  message,
  Tooltip,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
const { Option } = Select;

const confirm = Modal.confirm;
class EditUser extends React.Component {
  formRef = React.createRef();
  state = {
    user_details: {},
    isBDMSelected: false,
  };
  static contextType = MainContext;

  updateUser = (values) => {
    values._id = this.props.data_id;
    updateUserDetails(values, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick(res.data);
        notification.success({
          message: "User Updated",
          description: "The user has been updated successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          let errorMessages = Object.values(error.response.data.message);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      });
  };

  onFinish = (values) => {
    var selectedRole = "";
    if (values.userType === 1) {
      selectedRole = "ADMIN";
    } else if (values.userType === 2) {
      selectedRole = "MANAGER";
    } else if (values.userType === 4) {
      selectedRole = "USER";
    } else if (values.userType === 3) {
      selectedRole = "SALES";
    }

    if (selectedRole !== "USER") {
      confirm({
        title: "User Role",
        content: (
          <p>
            You have Selected role as <b>{selectedRole}</b>, Are you sure?
          </p>
        ),
        okText: "OK",
        onOk: () => {
          this.updateUser(values);
        },
      });
    } else {
      this.updateUser(values);
    }
  };

  getUserDetails = (userID) => {
    let userType = "";
    if (this.context.authDetails.userType === 1) {
      userType = "admin";
    } else if (this.context.authDetails.userType === 2) {
      userType = "manager";
    }
    getUserByID(userType, userID, this.context.authDetails.token).then(
      (res) => {
        this.setState({
          loading: false,
          visible: true,
          isBDMSelected: res.data.userType == 3,
          user_details: res.data,
        });

        if (res.data && this.formRef.current) {
          // res.data.password = "";
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
        }
      }
    );
  };

  showModal = () => {
    this.getUserDetails(this.props.data_id);
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
    this.formRef.current.resetFields();
  };

  // handleFormChange = (e) => {
  //   alert(2343);
  // };
  onFinishFailed = (errorInfo) => {};
  render() {
    return (
      <div>
        <Tooltip placement="bottom" title={"Edit Account Details"}>
          <Button
            onClick={this.showModal}
            type="primary"
            icon={<FormOutlined />}
          />
        </Tooltip>

        <Modal
          title="Edit User"
          visible={this.state.visible}
          //  onOk={this.onFinish}
          //  maskClosable="false"
          width={2000}
          centered
          onCancel={this.hideModal}
          okText="Create"
          cancelText="Cancel"
          footer={[
            <Button
              form={"myForm" + this.state.user_details._id}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Update
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                id={"myForm" + this.state.user_details._id}
                // {...layout}
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your firstname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your lastname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="userName"
                  label="User Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your user name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="userType"
                  label="Role"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select User role" allowClear>
                    <Option index={1} value={1}>
                      Admin
                    </Option>
                    <Option index={2} value={2}>
                      Manager
                    </Option>
                    <Option index={3} value={3}>
                      Sales
                    </Option>

                    <Option index={4} value={4}>
                      User
                    </Option>
                  </Select>
                </Form.Item>

                {this.state.isBDMSelected && (
                  <Form.Item
                    name="bdm_no"
                    label="BDM No"
                    rules={[
                      {
                        required: true,
                        message: "Please input BDM No!",
                      },
                    ]}
                  >
                    <Input type="number" min="1" disabled />
                  </Form.Item>
                )}

                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="contactNo"
                  label="Phone Number"
                  rules={[
                    {
                      required: false,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    // addonBefore={
                    //   <Form.Item name="prefix" noStyle>
                    //     <Select
                    //       style={{
                    //         width: 70,
                    //       }}
                    //     >
                    //       <Option value="86">+86</Option>
                    //       <Option value="87">+87</Option>
                    //     </Select>
                    //   </Form.Item>
                    // }
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="address"
                  label="Address"
                  tooltip="Provide your complete address?"
                  rules={[
                    {
                      required: false,
                      message: "Please input your address!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: false,
                      message: "Please input your city!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: false,
                      message: "Please input your state!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    {
                      required: false,
                      message: "Please input your country!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="zipCode"
                  label="Zipcode"
                  rules={[
                    {
                      required: false,
                      message: "Please input your zipcode!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="companyName"
                  label="Company"
                  rules={[
                    {
                      required: false,
                      message: "Please input your company!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="is_non_oes_user"
                  label="User Type"
                  rules={[
                    {
                      required: true,
                      message: "User type is required",
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup">
                    <Radio value={false}>LMS</Radio>
                    <Radio value={true}>NON-LMS</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="is_blocked"
                  label="User Account"
                  rules={[
                    {
                      required: true,
                      message: "User Account is required",
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup">
                    <Radio value={false}>Active</Radio>
                    <Radio value={true}>Disabled</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default EditUser;
