import React from "react";
import {
  Table,
  Modal,
  message,
  notification,
  Button,
  Input,
  Row,
  Col,
  Checkbox,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  getManagerTrainees,
  assignCourseToYourselfManager,
  managerAssignCourses,
  createRegistration,
} from "../../network/network";
import MainContext from "../../Context/AppContext";
const { Search } = Input;
const confirm = Modal.confirm;
class CourseAssignment extends React.Component {
  state = {
    notify_user: true,
    searchText: "",
    traineesArr: [],
    searchedColumn: "",
    assignLoading: false,
    columns: [
      {
        title: "#",

        key: "index",
        render: (text, record, index) => {
          return (
            <p>
              {!this.state.filterTable
                ? this.state.pagination.pageSize *
                    (this.state.pagination.current - 1) +
                  (index + 1)
                : this.state.filterPagination.pageSize *
                    (this.state.filterPagination.current - 1) +
                  (index + 1)}
            </p>
          );
        },
      },
      {
        title: "Full Name",
        render: (record) => {
          let fullName = `${record.firstName} ${record.lastName}`;
          return <b>{this.makeTextHighlight(fullName)}</b>;
        },
        width: "20%",
      },
      {
        title: "User Credentials",

        width: "20%",
        render: (text, record) => {
          return (
            <>
              <p>
                <b>Username: </b> {this.makeTextHighlight(record.userName)}
              </p>
              <p>
                <b>Password: </b> {record.password}
              </p>
            </>
          );
        },
      },
      {
        title: "Contact Details",
        render: (text, record) => {
          return (
            <>
              <p>
                <b>Email: </b>
                {this.makeTextHighlight(record.email)}
              </p>
              <p>
                <b>Contact: </b>
                {this.makeTextHighlight(record.contactNo)}
              </p>
              {/* <p><b>Address: </b> {record.address}, {record.city} </p> */}
            </>
          );
        },
      },
      {
        title: "Assigned Course",
        render: (text, record) => {
          let courses = [];
          record.courses.forEach((element) => {
            if (element.courseId) {
              courses.push({
                title: element.courseId.title,
              });
            }
          });

          return (
            <>
              {courses.map((obj, i) => (
                <div key={i}>
                  <p>
                    <b>{i + 1}</b>. {obj.title}
                  </p>
                </div>
              ))}
            </>
          );
        },
      },
    ],
    data: [],
    filterTable: null,
    isCourseAssignedToManager: false,
    pagination: {
      current: 1,
      pageSize: 20,
    },
    filterPagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    visible: true,
  };

  doNotifyUser = (e) => {
    this.setState({ notify_user: e.target.checked });
  };
  static contextType = MainContext;

  componentDidMount() {
    var manager_courses = this.context.authDetails.courses;
    var found = manager_courses.find(
      (course) => course.courseId === this.props.course.courseId._id
    );
    if (found && Object.keys(found).length > 0) {
      this.setState({
        isCourseAssignedToManager: true,
      });
    }
    this.fetch();
  }
  searchValue = (value) => {
    this.setState({ loading: true, filterTable: null });
    value = value.trim();
    const { searchText } = this.state;
    let tableFilters = {};
    if (searchText && searchText != "") {
      tableFilters.keyword = searchText.trim();
    }
    if (value) {
      this.filterData({ ...tableFilters });
    } else {
      this.setState({
        searchText: "",
        filterTable: null,
        loading: false,
      });
    }
  };
  makeTextHighlight = (text) => {
    return (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  };
  hideModal = () => {
    this.setState({
      visible: false,
    });
    this.props.callBack();
  };

  managerAssignCourse = () => {
    confirm({
      title: "Course Assignment",
      content: "Once course to you, you wont be able to unassign the course",
      okText: "OK",
      onOk: () => {
        this.setState({ assignLoading: true });
        assignCourseToYourselfManager(
          { course_id: this.props.course.courseId._id },
          this.context.authDetails?.token
        )
          .then((res) => {
            let registrationCompleted = new Promise((resolve, reject) => {
              if (
                this.props.course.courseId.scorm_course_id &&
                this.props.course.courseId.scorm_course_id !== "undefined" &&
                this.props.course.courseId.sku !=
                  this.props.course.courseId.scorm_course_id
              ) {
                createRegistration(
                  this.context.authDetails,
                  this.props.course.courseId.scorm_course_id,
                  res.data.course_registration_id
                )
                  .then((res) => {
                    resolve();
                  })
                  .catch((err) => {});
              } else {
                resolve();
              }
            });
            registrationCompleted.then(() => {
              this.setState({ visible: false });
              notification.success({
                message: ` Course Assigned`,
                description:
                  "The Course has been assigned to you successfully.",
                placement: "topRight",
              });
              this.setState({ assignLoading: false });
              this.props.callBack(1);
            });
          })
          .catch(function (error) {
            message.error("Some error has occurred.");
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      },
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "", filterTable: null });
  };

  updateUserTable = () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
  };
  handleTableChange = (tablePagination, filters, sorter) => {
    const { current: pageNumber, pageSize: pagesize } = tablePagination;
    let tableFilters = {};
    this.filterData(
      {
        ...tableFilters,
      },
      { pageNumber, pagesize }
    );
  };
  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  filterData = (filters = {}, params = { pageNumber: 1, pagesize: 20 }) => {
    this.setState({
      loading: true,
      filterTable: null,
    });

    getManagerTrainees(this.context.authDetails?.token, {
      ...filters,
      ...params,
    })
      .then((res) => {
        this.setState({
          filterTable: res.data.trainees,
          index: this.state.index++,
          filterPagination: {
            current: params.pageNumber,
            total: res.data.total,
            pageSize: params.pagesize,
          },
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };
  fetch = (params = { pageNumber: 1, pagesize: 20 }) => {
    this.setState({ loading: true });

    setTimeout(() => {
      getManagerTrainees(this.context.authDetails?.token, params)
        .then((res) => {
          this.setState({
            data: res.data.trainees,
            index: this.state.index++,
            pagination: {
              current: params.pageNumber,
              total: res.data.total,
              pageSize: params.pagesize,
            },
          });
        })
        .catch(function (error) {
          if (error && error.response && error.response.status === 401) {
            message.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }, 1000);
  };
  onChangeHandle = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };
  // assignCourse = (trainees) => {

  // }

  assignCoursesToTrainees = async () => {
    if (this.state.traineesArr.length === 0) {
      message.error("Please select the trainees to assign the course.");
      this.setState({ assignLoading: false });
    } else if (!this.props.course.courseId._id) {
      message.error("No course found.");
      this.setState({ assignLoading: false });
    } else {
      confirm({
        title: "Course Assignment",
        content:
          "Once course assigned, you wont be able to unassign the course",
        okText: "OK",
        onOk: () => {
          this.setState({ assignLoading: true });
          managerAssignCourses(
            {
              trainees: this.state.traineesArr,
              course_id: this.props.course.courseId._id,
              notify_user: this.state.notify_user,
            },
            this.context.authDetails?.token
          )
            .then((response) => {
              let registrationCompleted = new Promise((resolve, reject) => {
                if (
                  this.props.course.courseId.scorm_course_id &&
                  this.props.course.courseId.scorm_course_id !== "undefined" &&
                  this.props.course.courseId.sku !=
                    this.props.course.courseId.scorm_course_id
                ) {
                  let trainees = response.data.userDetails;

                  if (trainees.length > 0) {
                    trainees.map((trainee, i) => {
                      createRegistration(
                        trainee,
                        this.props.course.courseId.scorm_course_id,
                        trainee.course_registration_id
                      )
                        .then((res) => {
                          if (i === trainees.length - 1) {
                            resolve();
                          }
                        })
                        .catch((err) => {});
                    });
                  } else {
                    resolve();
                  }
                } else {
                  resolve();
                }
              });
              registrationCompleted.then(() => {
                this.setState({ visible: false });
                notification.success({
                  message: `${response.data.userDetails.length} Course(s) Assigned`,
                  description: "The Course has been assigned successfully.",
                  placement: "topRight",
                });
                this.setState({ assignLoading: false });
                this.props.callBack();
              });
            })
            .catch((error) => {
              if (error && error.response && error.response.status === 404) {
                message.error(error.response.data.message);
              }
              this.setState({ assignLoading: false });
            })
            .finally(() => {});
        },
      });
      return false;
    }
  };

  onSelectChange = (traineesArr) => {
    this.setState({ traineesArr });
  };

  render() {
    const {
      loading,
      filterTable,
      columns,
      filterPagination,
      data,
      pagination,
    } = this.state;

    const paginationProperties = {
      pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
    };

    return (
      <Modal
        title={[<>{this.props.course.courseId.title}</>]}
        visible={this.state.visible}
        onCancel={this.hideModal}
        centered
        width={2000}
        footer={[
          <Row className="assignment-wrapper">
            <Col span="12">
              <Checkbox
                checked={this.state.notify_user}
                onChange={this.doNotifyUser}
              >
                <b>Send an e-mail notification</b>
              </Checkbox>
            </Col>
            <Col span="12">
              <div
                className="button-wrapper"
                style={{
                  marginLeft: "auto",
                  width: "fit-content",
                  paddingRight: "10px",
                }}
              >
                <Button
                  loading={this.state.assignLoading}
                  className="green-btn"
                  onClick={this.assignCoursesToTrainees}
                >
                  Assign
                </Button>
              </div>
            </Col>
          </Row>,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Search
              placeholder="Search Trainee"
              allowClear
              value={this.state.searchText}
              onChange={this.onChangeHandle}
              enterButton={<SearchOutlined />}
              size="large"
              onSearch={(value) => this.searchValue(value)}
            />
          </Col>
        </div>
        <div className="table-box assign-course">
          <Table
            columns={columns}
            rowSelection={{
              traineesArr: this.state.traineesArr,
              onChange: this.onSelectChange,
              getCheckboxProps: (record) => ({
                disabled: record.courses.find(
                  (course) =>
                    course.courseId._id === this.props.course.courseId._id
                ),
              }),
            }}
            rowKey={(record) => record._id}
            dataSource={filterTable === null ? data : filterTable}
            pagination={
              filterTable
                ? { ...filterPagination, ...paginationProperties }
                : { ...pagination, ...paginationProperties }
            }
            loading={loading}
            onChange={this.handleTableChange}
          />
        </div>
      </Modal>
    );
  }
}

export default CourseAssignment;
