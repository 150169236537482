import React from "react";
import MainContext from "../../../Context/AppContext";
import AdminDashboard from "../../../components/Dashboard/AdminDashboard";
import ManagerDashboard from "../../../components/Dashboard/ManagerDashboard";
import StudentDashboard from "../../../components/Dashboard/StudentDashboard";

class Dashboard extends React.Component {
  state = {};

  static contextType = MainContext;
  componentDidMount() {}

  render() {
    return (
      <>
        {(this.context.authDetails.userType === 1 ||
          this.context.authDetails.userType === 3) && <AdminDashboard />}
        {this.context.authDetails.userType === 4 && <StudentDashboard />}
        {this.context.authDetails.userType === 2 && <ManagerDashboard />}
      </>
    );
  }
}

export default Dashboard;
