import React from "react";
import { Input, Row, Col, Button, Form, notification, message } from "antd";
import {
  updateProfileInformation,
  getProfileInformation,
} from "../../../network/network";
import MainContext from "../../../Context/AppContext";

class Profile extends React.Component {
  formRef = React.createRef();
  state = {
    visible: true,
    loading: true,
  };
  static contextType = MainContext;

  componentDidMount() {
    this.getProfile();
  }

  getProfile = () => {
    getProfileInformation(this.context.authDetails?.token)
      .then((res) => {
        if (res.data && this.formRef.current) {
          res.data.password = "";
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
        }
        this.setState({
          loading: true,
          visible: true,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          message.error("Something went wrong");
        }
        if (err.response && err.response.status === 401) {
          message.error(err.response.data.message);
        }
      });
  };

  onFinish = (values) => {
    updateProfileInformation(values, this.context.authDetails?.token)
      .then((res) => {
        notification.success({
          message: "Profile Updated",
          description: "The Profile has been updated successfully.",
          placement: "topRight",
        });
      })
      .catch((error) => {
        if (error && error.response?.status === 409) {
          message.error(error.response.data.message);
        }
        if (error.response && error.response.status === 400) {
          let errorMessages = Object.values(error.response.data.message);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      });
  };

  onFinishFailed = (errorInfo) => {};

  render() {
    const buttonItemLayout = { wrapperCol: { span: 14, offset: 4 } };

    return (
      <>
        {this.state.visible && this.state.loading && (
          <Row>
            <Col style={{ background: "#fff" }} span={24} offset={0}>
              <h1 style={{ margin: "15px 15px" }}>Update Profile</h1>
              <Form
                ref={this.formRef}
                id="myFormAdd"
                name="basic"
                style={{ padding: " 0 15px" }}
                initialValues={{
                  remember: true,
                }}
                labelCol={{
                  xl: 4,
                  lg: 4,
                  md: 24,
                  sm: 24,
                  xs: 24,
                }}
                wrapperCol={{
                  xl: 14,
                  lg: 14,
                  md: 24,
                  sm: 24,
                  xs: 24,
                }}
                layout="horizontal"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="firstName"
                  label="First Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your firstname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  tooltip="What do you want others to call you?"
                  rules={[
                    {
                      required: true,
                      message: "Please input your lastname!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="E-mail"
                  disabled
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  name="contactNo"
                  label="Phone Number"
                  rules={[
                    {
                      required: false,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="address"
                  label="Address"
                  tooltip="Provide your complete address?"
                  rules={[
                    {
                      required: false,
                      message: "Please input your address!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: false,
                      message: "Please input your city!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: false,
                      message: "Please input your state!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    {
                      required: false,
                      message: "Please input your country!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="zipCode"
                  label="Zipcode"
                  rules={[
                    {
                      required: false,
                      message: "Please input your zipcode!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="companyName"
                  label="Company"
                  rules={[
                    {
                      required: false,
                      message: "Please input your company!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item {...buttonItemLayout}>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default Profile;
