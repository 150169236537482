import React from "react";
import {
  Table,
  notification,
  Col,
  Row,
  Space,
  Tag,
  Popconfirm,
  Button,
} from "antd";
import { getPromoCodes, deletePromoCode } from "../../../network/network";
// import { Link } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import MainContext from "../../../Context/AppContext";
import AddPromoCode from "./add";

import EditPromoCode from "./edit";
// import { Link } from "react-router-dom";

class promo_codes extends React.Component {
  state = {
    categories: [],
    columns: [
      {
        title: "#",
        key: "index",
        width: "5%",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Promotion",
        width: "20%",
        render: (record) => {
          return <p>{record?.name || "-"}</p>;
        },
      },

      {
        title: "Code",
        render: (record) => {
          return <p>{record?.code || "-"}</p>;
        },
      },
      {
        title: "Promo Type",
        render: (record) => {
          return <b>{record?.type === 1 ? "Percentage" : "Amount"}</b>;
        },
      },

      {
        title: "Discount",
        render: (record) => {
          if (record.type === 1) {
            return <p>{`${record?.discount}%` || "-"}</p>;
          }
          if (record.type === 2) {
            return (
              <p>{`$${parseFloat(record?.discount).toFixed(2)}` || "-"}</p>
            );
          }
        },
      },
      {
        title: "Limit",
        render: (record) => {
          return <p>{record?.limit || "-"}</p>;
        },
      },

      {
        title: "Status",

        render: (record) => {
          return (
            <Tag color={record.status === 1 || record.status ? "green" : "red"}>
              {record.status || record.status === 1 ? "Published" : "Draft"}
            </Tag>
          );
        },
      },

      {
        title: "Action",
        render: (text, record) => (
          <div className="text-center">
            <Space size="middle">
              <EditPromoCode
                data_id={record._id}
                type={"edit"}
                onChildClick={this.updatePromoCodeTable.bind(this)}
              />

              <Popconfirm
                title="Are you sure to delete this PromoCode?"
                onConfirm={() => {
                  this.onDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />} type="danger" />
              </Popconfirm>
            </Space>
          </div>
        ),
      },
    ],
    promo_codes: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  };

  static contextType = MainContext;

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  onDelete = (record) => {
    this.setState({ loading: true });
    deletePromoCode(record._id, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
      });

      notification.success({
        message: "Blog Deleted",
        description: "The blog has been deleted successfully.",
        placement: "topRight",
      });

      const { pagination } = this.state;
      this.fetch({ pagination });
    });
  };
  updatePromoCodeTable = () => {
    notification.success({
      message: "Blog Updated",
      description: "The blog has been updated successfully.",
      placement: "topRight",
    });
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });

    getPromoCodes(this.context.authDetails?.token)
      .then((res) => {
        this.setState({
          promo_codes: res.data,
          pagination: {
            ...params.pagination,
            total: res.data.totalCount,
          },
        });
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { promo_codes, columns, loading } = this.state;

    return (
      <>
        <Row gutter={30}>
          <Col className="dashboard-charts ind-dashboard" span={24}>
            <h2>My Promo Codes</h2>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ padding: 0 }}>
              <AddPromoCode
                onChildClick={this.updatePromoCodeTable.bind(this)}
                categories={this.state.categories}
              />
            </Col>
            <div className="table-box">
              <Table
                className="promocode-table"
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={promo_codes ? promo_codes : []}
                loading={loading}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

// };
export default promo_codes;
