import React from "react";
import {
  Row,
  Table,
  Col,
  Button,
  Checkbox,
  notification,
  message,
  Input,
  Badge,
  Spin,
  Space,
  Modal,
  Tag,
  Pagination,
} from "antd";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { CanadianTime } from "../../../helpers/helpers";
import {
  ORDER_SUCCESS_TYPE_SMS,
  ORDER_SUCCESS_TYPE_EMAIL,
  ORDER_REMINDER_TYPE_SMS,
  ORDER_REMINDER_TYPE_EMAIL,
  USER_TYPE_ADMIN,
  USER_TYPE_SALES,
  USER_TYPE_USER,
  USER_TYPE_MANAGER,
} from "../../../constants/constants";
import MainContext from "../../../Context/AppContext";
import {
  SearchOutlined,
  FilterOutlined,
  //  DeleteOutlined,
  RedoOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  getOrders,
  getUserOrders,
  sendOrderSuccessNotification,
  sendEmailToUnPaidOrders,
  sendSMSToUnPaidOrders,
  removeOrderDuplication,
  // deleteOrderByID,
} from "../../../network/network";
const { Search } = Input;
const confirm = Modal.confirm;
class Orders extends React.Component {
  state = {
    filters: {
      payment_status: null,
      bdm: null,
      searchText: "",
    },
    index: 0,
    selectedUnpaidRowKeys: [],
    order_reminder_channel: [],
    columns: [
      {
        title: "#",
        width: "0%",
        align: "center",
        key: "index",
        render: (text, record, index) => {
          return (
            <p>
              {!this.state.filterTable
                ? this.state.pagination.pageSize *
                    (this.state.pagination.current - 1) +
                  (index + 1)
                : this.state.filterPagination.pageSize *
                    (this.state.filterPagination.current - 1) +
                  (index + 1)}
            </p>
          );
        },
      },
      {
        title: "Date",
        width: "10%",
        render: (record) => {
          return <p>{CanadianTime(record.updatedAt)}</p>;
        },
      },
      {
        title: "Order-ID",
        width: "15%",
        dataIndex: "bdm",
        filterIcon: !this.props.BDMNumber && (
          <FilterOutlined style={{ color: "#012d83" }} />
        ),
        filters: !this.props.BDMNumber && [
          {
            text: "BDM",
            value: true,
          },
        ],
        render: (text, record) => {
          return (
            <>
              {record.isPaid ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_BASE_URL}/thankyou/${record._id}`}
                >
                  <b>{this.makeTextHighlight(record._id)}</b>
                </a>
              ) : (
                <b>{this.makeTextHighlight(record._id)}</b>
              )}

              <p>{this.makeTextHighlight(record.order_id)}</p>
              {record.isPaid &&
                (this.context.authDetails.userType === USER_TYPE_ADMIN ||
                  this.context.authDetails.userType === USER_TYPE_SALES) && (
                  <>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.sendSuccessMessage(
                          ORDER_SUCCESS_TYPE_EMAIL,
                          record._id
                        )
                      }
                      className="mb-10"
                    >
                      Send Success Email
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.sendSuccessMessage(
                          ORDER_SUCCESS_TYPE_SMS,
                          record._id
                        )
                      }
                      className="mb-10"
                    >
                      Send Success SMS
                    </Button>
                  </>
                )}
              {record.bdm && (
                <Tag color="purple">{`BDM ${record.bdm.bdm_no}: ${record.bdm.firstName} ${record.bdm.lastName}`}</Tag>
              )}
            </>
          );
        },
      },
      {
        title: "User",
        width: "20%",
        dataIndex: "users",
        render: (text, record) => {
          let order_details = record.user ? record.user : record;
          let fullName = `${record.firstName} ${record.lastName}`;
          return (
            <>
              {order_details.firstName && order_details.lastName && (
                <p>
                  <b>Full Name: </b>
                  {this.makeTextHighlight(fullName)}
                </p>
              )}
              {order_details.email && (
                <p>
                  <b>Email: </b> {this.makeTextHighlight(order_details.email)}
                </p>
              )}
              {order_details.contactNo && (
                <p>
                  <b>Contact: </b>
                  {this.makeTextHighlight(order_details.contactNo)}
                </p>
              )}
              {order_details.userName && (
                <p>
                  <b>Username: </b>
                  {this.makeTextHighlight(order_details.userName)}
                </p>
              )}
              {order_details.password && (
                <p>
                  <b>Password: </b>
                  {this.makeTextHighlight(order_details.password)}
                </p>
              )}
              {(order_details.address ||
                order_details.city ||
                order_details.state ||
                order_details.country ||
                order_details.zipCode) && (
                <p>
                  <b>Address: </b>
                  {order_details.address &&
                    this.makeTextHighlight(order_details.address)}
                  {order_details.city && `, `}
                  {this.makeTextHighlight(order_details.city)}
                  {order_details.state && `, `}
                  {this.makeTextHighlight(order_details.state)}
                  {order_details.country && `, `}
                  {this.makeTextHighlight(order_details.country)}
                  {order_details.zipCode && ` - `}
                  {this.makeTextHighlight(order_details.zipCode)}
                </p>
              )}
            </>
          );
        },
      },
      {
        title: "Courses (s)",
        width: "15%",
        dataIndex: "courses",
        render: (text, record) => {
          let courses = [];
          record.items.forEach((element) => {
            courses.push({
              title: element.title,
              quantity: element.quantity,
              price: element.price,
            });
          });

          return (
            <>
              {courses.map((obj, i) => (
                <div className="order-course-list">
                  <b>{i + 1}.</b> {this.makeTextHighlight(obj.title)}
                  <p>
                    <b>Qty: </b> {obj.quantity}, <b>Price/Unit: </b> $
                    {obj.price.toFixed(2)}
                  </p>
                </div>
              ))}
            </>
          );
        },
      },
      {
        title: "Total Amount",
        width: "10%",
        align: "center",

        render: (record) => {
          return "$" + record.total.toFixed(2);
        },
      },

      {
        title: "Payment Status",
        dataIndex: "payment_status",
        width: "10%",
        align: "center",
        filterIcon: <FilterOutlined style={{ color: "#012d83" }} />,
        filters: [
          {
            text: "Paid",
            value: "paid",
          },
          {
            text: "UnPaid",
            value: "unpaid",
          },
          {
            text: "Refunded",
            value: "is_refunded",
          },
        ],
        render: (text, record) => {
          if (record.is_refunded) {
            return <p style={{ color: "red", fontWeight: "600" }}>REFUNDED</p>;
          } else if (record.isPaid) {
            return <p style={{ color: "#42a400", fontWeight: "600" }}>PAID</p>;
          } else if (!record.isPaid) {
            return (
              <p style={{ color: "#c48600", fontWeight: "600" }}>UNPAID</p>
            );
          }
        },
      },

      {
        title: "Transaction ID",
        dataIndex: "transaction_id",
        width: "10%",
        align: "center",
        render: (text, record) => {
          return (
            <>
              {this.context.authDetails.userType === USER_TYPE_ADMIN &&
              record.paymentResult ? (
                <p>{this.makeTextHighlight(record.paymentResult.id)}</p>
              ) : (
                "-"
              )}
            </>
          );
        },
      },

      {
        title: "Action",
        dataIndex: "",
        width: "10%",
        align: "center",
        key: "x",
        render: (record) => {
          return (
            <>
              {record.isPaid && (
                <Space
                  size="middle"
                  style={{
                    marginBottom: 8,
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <Link to={"/view/order/" + record._id}>View Invoice </Link>
                </Space>
              )}
              {/* {this.context.authDetails?.userType === 1 &&
                <Popconfirm
                  title="Are you sure to delete this order?"
                  onConfirm={() => {
                    this.onDelete(record._id);
                  }}
                  onCancel={this.notDeleteOrderMessage}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} type="danger" />
                </Popconfirm>
              } */}
            </>
          );
        },
      },
    ],
    data: [],
    filterTable: null,
    filtered: false,
    pagination: {
      current: 1,
      pageSize: 20,
    },
    filterPagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    queryParamOrderID: "",
  };

  static contextType = MainContext;

  componentDidMount() {
    this.setState({
      queryParamOrderID: new URLSearchParams(this.props.location["search"]).get(
        "order_id"
      ),
    });

    this.fetch();
  }
  refreshOrdersData = () => {
    this.setState({
      filterTable: null,
      filters: {
        payment_status: null,
        bdm: null,
        searchText: "",
      },
      index: this.state.index + 1,
      loading: false,
      data: [],
      filterPagination: {
        current: 1,
        pageSize: 20,
      },
    });
    this.fetch();
  };
  sendSuccessMessage = (type, order_id) => {
    let message_type = type == 1 ? "Email" : "SMS";

    confirm({
      title: `Order Success ${message_type}`,
      content: `Are you sure want to send an order success ${message_type}`,
      okText: "OK",
      onOk: () => {
        sendOrderSuccessNotification(
          type,
          order_id,
          this.context.authDetails?.token
        )
          .then((res) => {
            notification.success({
              message: `Order Success ${message_type}`,
              description: `Are you sure want to send an order success ${message_type}`,
              placement: "topRight",
            });
          })
          .catch((err) => {
            notification.error({
              message: `Error`,
              description: `Some error has occurred while sending ${message_type}`,
              placement: "topRight",
            });
          });
      },
    });
  };
  makeTextHighlight = (text) => {
    return (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.filters.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      filters: {
        payment_status: null,
        bdm: null,
        searchText: "",
      },
      filterTable: null,
    });
  };
  searchValue = (value) => {
    this.setState({ loading: true, filterTable: null });
    value = value.trim();

    const { searchText, bdm, payment_status } = this.state.filters;

    let tableFilters = {};

    if (searchText && searchText != "") {
      tableFilters.keyword = searchText;
    }

    if (payment_status) {
      tableFilters.payment_status = payment_status;
    }

    if (bdm) {
      tableFilters.bdm = bdm;
    }

    if (value) {
      this.filterData({ ...tableFilters });
    } else {
      this.setState({
        filterTable: null,
        filters: {
          payment_status: null,
          bdm: null,
          searchText: "",
        },
        loading: false,
      });
    }
  };

  // onDelete = (orderID) => {
  //   // this.setState({ loading: true });

  //   deleteOrderByID(orderID, this.context.authDetails?.token).then((res) => {

  //     // this.setState({
  //     //   loading: false,
  //     // });

  //     notification.success({
  //       message: "Order Deleted",
  //       description: "The order has been deleted successfully.",
  //       placement: "topRight",
  //     });

  //     const { pagination } = this.state;
  //     this.fetch({ pagination });
  //   });
  // };

  handleTableChange = (tablePagination, filters, sorter) => {
    const { current: pageNumber, pageSize: pagesize } = tablePagination;
    const { searchText } = this.state.filters;

    let tableFilters = {};

    if (searchText && searchText != "") {
      tableFilters.keyword = searchText;
    }

    if (filters.payment_status) {
      tableFilters.payment_status = filters.payment_status;
    }

    if (filters.bdm) {
      tableFilters.bdm = filters.bdm;
    }

    this.filterData(
      {
        ...tableFilters,
      },
      { pageNumber, pagesize }
    );
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      filters: {
        payment_status: null,
        bdm: null,
        searchText: "",
      },
      filterTable: null,
    });
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  sendPurchaseEmail = () => {
    confirm({
      title: "Send Abandoned Cart Reminder",
      content: (
        <>
          <p>
            Are you sure you want to send the reminder to the selected
            customers?"
          </p>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(checkedValues) =>
              this.setState({ order_reminder_channel: checkedValues })
            }
          >
            <Row>
              <Col span={8}>
                <Checkbox value={ORDER_REMINDER_TYPE_SMS}>SMS</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value={ORDER_REMINDER_TYPE_EMAIL}>Email</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </>
      ),

      okText: "OK",
      onOk: () => {
        if (
          this.state.order_reminder_channel.includes(ORDER_REMINDER_TYPE_EMAIL)
        ) {
          this.sendOrderReminderViaEmail();
        }
        if (
          this.state.order_reminder_channel.includes(ORDER_REMINDER_TYPE_SMS)
        ) {
          this.sendOrderReminderViaSMS();
        }
      },
    });
  };

  sendOrderReminderViaEmail = () => {
    sendEmailToUnPaidOrders(
      { orderIds: this.state.selectedUnpaidRowKeys },
      this.context.authDetails?.token
    )
      .then((res) => {
        notification.success({
          message: `Purchase Email sent successfully`,
          placement: "topRight",
        });
      })
      .catch((err) => {
        message.error("Some error has occurred while sending emails");
      });
  };
  removeDuplicateOrders = () => {
    confirm({
      title: "Remove Duplicate Orders",
      content: "Are you sure, want to delete duplicate orders? ",
      okText: "OK",
      onOk: () => {
        removeOrderDuplication(this.context.authDetails?.token)
          .then((res) => {
            let modifiedOrdersCount =
              res.data && res.data.deletedData
                ? res.data.deletedData.nModified
                : 0;
            notification.success({
              message: (
                <p>
                  <b>{modifiedOrdersCount} </b> duplicate unpaid orders deleted
                  successfully.
                </p>
              ),
              placement: "topRight",
            });

            this.refreshOrdersData();
          })
          .catch((err) => {
            message.error(
              "Some error has occurred while removing duplicate orders"
            );
          });
      },
    });
  };
  sendOrderReminderViaSMS = () => {
    sendSMSToUnPaidOrders(
      { orderIds: this.state.selectedUnpaidRowKeys },
      this.context.authDetails?.token
    )
      .then((res) => {
        notification.success({
          message: `Purchase SMS sent successfully`,
          placement: "topRight",
        });
      })
      .catch((err) => {
        message.error("Some error has occurred while sending sms");
      });
  };
  notDeleteOrderMessage = () => {
    message.info("Order is safe");
  };

  formatOrderId = (order_id, buying_for) => {
    let formattedOrderId;
    if (buying_for === "Group") {
      formattedOrderId = `${
        process.env.REACT_APP_COMPANY_SHORT_NAME
      }-G-${order_id.toString().padStart(5, "0")}`;
    } else if (!buying_for || buying_for === "Yourself") {
      formattedOrderId = `${
        process.env.REACT_APP_COMPANY_SHORT_NAME
      }-I-${order_id.toString().padStart(5, "0")}`;
    } else if (buying_for === "Someone") {
      formattedOrderId = `${
        process.env.REACT_APP_COMPANY_SHORT_NAME
      }-S-${order_id.toString().padStart(5, "0")}`;
    }

    return formattedOrderId;
  };

  filterData = (filters = {}, params = { pageNumber: 1, pagesize: 20 }) => {
    this.setState({
      loading: true,
      filterTable: null,
      filters: {
        payment_status: null,
        bdm: null,
        searchText: "",
      },
    });
    if (this.props.BDMNumber) {
      params.bdm_no = 1;
    }
    getOrders(
      this.context.authDetails?.token,
      { ...filters, ...params },
      this.context.authDetails?.all_data,
      this.context.authDetails?.bdm_no
    )
      .then((res) => {
        let filteredOrders = res.data.orders;
        filteredOrders.map((o) => {
          o.order_id = this.formatOrderId(o.order_id, o.buying_for);
        });

        this.setState({
          filterTable: filteredOrders,
          filters: {
            payment_status: filters.payment_status || null,
            bdm: filters.bdm || null,
            searchText: filters.keyword || "",
          },
          filterPagination: {
            current: params.pageNumber,
            total: res.data.total,
            pageSize: params.pagesize,
          },
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  fetch = (params = { pageNumber: 1, pagesize: 20 }) => {
    this.setState({
      loading: true,
      filterTable: null,
      filters: {
        payment_status: null,
        bdm: null,
        searchText: "",
      },
    });

    if (
      this.context.authDetails?.userType === USER_TYPE_ADMIN ||
      this.context.authDetails?.userType === USER_TYPE_SALES
    ) {
      if (this.props.BDMNumber) {
        params.bdm_no = 1;
      }
      setTimeout(() => {
        getOrders(
          this.context.authDetails?.token,
          params,
          this.context.authDetails?.all_data,
          this.context.authDetails?.bdm_no
        )
          .then((res) => {
            let orders = res.data.orders;
            orders.map((o) => {
              o.order_id = this.formatOrderId(o.order_id, o.buying_for);
            });

            this.setState({
              data: orders,
              categories: res.data,
              pagination: {
                current: params.pageNumber,
                total: res.data.total,
                pageSize: params.pagesize,
              },
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }, 1000);
    } else if (
      this.context.authDetails?.userType === USER_TYPE_USER ||
      this.context.authDetails?.userType === USER_TYPE_MANAGER
    ) {
      setTimeout(() => {
        getUserOrders(this.context.authDetails?.token)
          .then((res) => {
            let orders = res.data;
            orders.map((o) => {
              if (o.buying_for === "Group") {
                o.order_id = `${
                  process.env.REACT_APP_COMPANY_SHORT_NAME
                }-G-${o.order_id.toString().padStart(5, "0")}`;
              } else if (!o.buying_for || o.buying_for === "Yourself") {
                o.order_id = `${
                  process.env.REACT_APP_COMPANY_SHORT_NAME
                }-I-${o.order_id.toString().padStart(5, "0")}`;
              } else if (o.buying_for === "Someone") {
                o.order_id = `${
                  process.env.REACT_APP_COMPANY_SHORT_NAME
                }-S-${o.order_id.toString().padStart(5, "0")}`;
              }
            });
            this.setState({
              data: orders,
              categories: res.data,
              pagination: {
                ...params.pagination,
                total: res.data.totalCount,
              },
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }, 1000);
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, queryParamOrderID, selectedUnpaidRowKeys } = this.state;
    const { pagination, columns, data, filterPagination, filterTable } =
      this.state;
    const paginationProperties = {
      position: ["topRight"],
      pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedUnpaidRowKeys: selectedRowKeys });
      },
      getCheckboxProps: (record) => ({
        disabled: record.isPaid,
      }),
    };
    return (
      <div>
        <Col className="dashboard-charts" span={24}>
          <Row style={{ width: "100%" }}>
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <h2>Orders</h2>
              {this.context.authDetails &&
                (this.context.authDetails.userType === USER_TYPE_ADMIN ||
                  this.context.authDetails.userType === USER_TYPE_SALES) && (
                  <Button
                    className="blue-btn "
                    disabled={selectedUnpaidRowKeys.length === 0}
                    onClick={this.sendPurchaseEmail}
                  >
                    Send Cart Reminder{" "}
                    {selectedUnpaidRowKeys.length > 0 && (
                      <Badge
                        style={{ marginLeft: 15 }}
                        count={selectedUnpaidRowKeys.length}
                      />
                    )}
                  </Button>
                )}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={20}
              xl={20}
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <div className="search" style={{ display: "inline-block" }}>
                <Search
                  placeholder="Search Order"
                  allowClear
                  value={this.state.filters.searchText}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      filters: {
                        ...prevState.filters,
                        searchText: e.target.value,
                      },
                    }))
                  }
                  enterButton={<SearchOutlined />}
                  size="large"
                  onSearch={(value) => this.searchValue(value)}
                />
              </div>
              <Button
                style={{ margin: "0 10px 0px" }}
                type="primary"
                icon={<RedoOutlined />}
                size={"large"}
                onClick={() => {
                  this.refreshOrdersData();
                }}
              />
              {!this.props.BDMNumber &&
                this.context.authDetails &&
                this.context.authDetails.userType === USER_TYPE_ADMIN && (
                  <Button
                    style={{ margin: "0 10px 0px" }}
                    type="danger"
                    size={"large"}
                    onClick={() => {
                      this.removeDuplicateOrders();
                    }}
                  >
                    Remove UnPaid Orders
                  </Button>
                )}
            </Col>
          </Row>
          <div className="table-box mt-10">
            <Table
              key={this.state.index}
              rowSelection={
                (this.context?.authDetails?.userType === USER_TYPE_ADMIN ||
                  this.context?.authDetails?.userType === USER_TYPE_SALES) && {
                  type: "checkbox",
                  ...rowSelection,
                }
              }
              className="recent-orders"
              rowClassName={(record) =>
                record._id === queryParamOrderID ? "row-highlight" : ""
              }
              columns={columns}
              rowKey={(record) => record._id}
              // dataSource={data}
              dataSource={filterTable ? filterTable : data}
              pagination={
                filterTable
                  ? { ...filterPagination, ...paginationProperties }
                  : { ...pagination, ...paginationProperties }
              }
              loading={{
                spinning: loading,
                indicator: (
                  <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 34 }} />}
                  />
                ),
              }}
              filterMultiple={true}
              onChange={this.handleTableChange}
            />
          </div>
        </Col>
      </div>
    );
  }
}

export default Orders;
