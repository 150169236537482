import React from "react";
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  Button,
  // notification,
  Select,
  Image,
  Modal,
} from "antd";
import MainContext from "../../../Context/AppContext";
import CKEditor from "ckeditor4-react";
import FileUpload from "../../../components/FileUpload/FileUpload";
// import CKEditor from "ckeditor4-react";
import { FormOutlined } from "@ant-design/icons";
import {
  // getCategoryByID,
  getBlogByID,
  updateBlog,
} from "../../../network/network";
const { TextArea } = Input;

class EditBlog extends React.Component {
  formRef = React.createRef();
  state = {
    blog_details: {},
    detailed_content: "",
    loading: false,
    visible: false,
    categories: [],
    thumbnailImageId: "",
    bannerImageId: "",
    bannerImagePath: "",
    thumbnailImagePath: "",
    ogImageId: "",
    ogImagePath: "",
  };
  static contextType = MainContext;

  handleBlogOGImageID = (blogOGid, blogOGpath) => {
    this.setState({
      ogImageId: blogOGid,
      ogImagePath: blogOGpath,
    });
  };

  getBlogDetails = (blogID) => {
    getBlogByID(blogID, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
        visible: true,
        blog_details: res.data,
        detailed_content: res.data.description,

        thumbnailImageId: res.data.thumbnailImage
          ? res.data.thumbnailImage._id
          : "",

        thumbnailImagePath: res.data.thumbnailImage
          ? res.data.thumbnailImage.path
          : "",
        bannerImageId: res.data.bannerImage ? res.data.bannerImage._id : "",
        bannerImagePath: res.data.bannerImage ? res.data.bannerImage.path : "",
        ogImageId: res.data.ogImage ? res.data.ogImage._id : "",
        ogImagePath: res.data.ogImage ? res.data.ogImage.path : "",
      });

      res.data.categoryName = res.data.category?._id;

      if (res.data) {
        this.formRef.current.setFieldsValue({
          ...res.data,
        });
      }
    });
  };

  onFinish = (values) => {
    const formData = new FormData();
    formData.append("thumbnailImage", this.state.thumbnailImageId);
    formData.append("bannerImage", this.state.bannerImageId);
    formData.append("title", values.title);
    formData.append("ogImage", this.state.ogImageId);
    formData.append("slug", values.slug);
    formData.append("description", this.state.detailed_content);
    formData.append("shortDescription", values.shortDescription);
    formData.append("authorsName", values.authorsName);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);
    formData.append("featured", values.featured);
    formData.append("category", values.categoryName);

    updateBlog(this.props.data_id, formData, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        // notification.success({
        //   message: "Blog Updated",
        //   description: "The Blog has been updated successfully.",
        //   placement: "topRight",
        // });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  handleThumbnailImageID = (thumbnailImageID, thumbnailImagePath) => {
    this.setState({
      thumbnailImageId: thumbnailImageID,
      thumbnailImagePath: thumbnailImagePath,
    });
  };

  handlebannerImageID = (bannerImageID, bannerImagePath) => {
    this.setState({
      bannerImageId: bannerImageID,
      bannerImagePath: bannerImagePath,
    });
  };

  showModal = () => {
    this.getBlogDetails(this.props.data_id);

    this.setState({
      categories: this.props.categories,
      loading: false,
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onEditorChange = (evt) => {
    this.setState({
      detailed_content: evt.editor.getData(),
    });
  };

  //   handleChange( changeEvent ) {
  //     this.setState( {
  //         data: changeEvent.target.value
  //     } );
  // }

  onFinishFailed = (errorInfo) => {};

  render() {
    const { layout, blog_details } = this.state;

    return (
      <div>
        <Button
          onClick={this.showModal}
          type="primary"
          icon={<FormOutlined />}
        />

        <Modal
          title="Edit Blog"
          visible={this.state.visible}
          onOk={this.onFinish}
          onCancel={this.hideModal}
          okText="Update"
          width={2000}
          centered
          cancelText="Cancel"
          footer={[
            <Button
              form={"myForm" + blog_details._id}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Update
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                {...layout}
                ref={this.formRef}
                name="basic"
                id={"myForm" + blog_details._id}
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="slug"
                  label="Slug"
                  rules={[
                    {
                      required: true,
                      message: "Please input slug!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="shortDescription"
                  label="Short Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input short description!",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: false,
                      message: "Please input description!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <CKEditor
                    data={this.state.blog_details.description}
                    onChange={this.onEditorChange}
                  />

                  {/* <TextArea rows={4} /> */}
                </Form.Item>

                <Form.Item label="Thumbnail Image">
                  {this.state.thumbnailImageId ? (
                    <Image width={200} src={this.state.thumbnailImagePath} />
                  ) : (
                    <Image
                      width={200}
                      src={
                        this.state.blog_details &&
                        this.state.blog_details.thumbnailImage
                          ? `${this.state.blog_details.thumbnailImage["path"]}`
                          : "/no-image.png"
                      }
                    />
                  )}
                  <FileUpload
                    onThumbnailImageId={this.handleThumbnailImageID}
                    imageUploadType="thumbnailImage"
                  />
                </Form.Item>

                <Form.Item label="Banner Image">
                  {this.state.bannerImageId ? (
                    <Image width={200} src={this.state.bannerImagePath} />
                  ) : (
                    <Image
                      width={200}
                      src={
                        this.state.blog_details &&
                        this.state.blog_details.bannerImage
                          ? `${this.state.blog_details.bannerImage["path"]}`
                          : "/no-image.png"
                      }
                    />
                  )}
                  <FileUpload
                    onBannerImageId={this.handlebannerImageID}
                    imageUploadType="bannerImage"
                  />
                </Form.Item>

                <Form.Item label="OG Image">
                  {this.state.ogImage ? (
                    <Image width={200} src={this.state.ogImagePath} />
                  ) : (
                    <Image
                      width={200}
                      src={
                        this.state.blog_details &&
                        this.state.blog_details.ogImage
                          ? this.state.blog_details.ogImage["path"]
                          : "/no-image.png"
                      }
                    />
                  )}
                  <FileUpload
                    onBlogOgID={this.handleBlogOGImageID}
                    imageUploadType="blogOGImage"
                    type="image"
                  />
                </Form.Item>

                {this.state.categories.length > 0 && (
                  <Form.Item
                    name="categoryName"
                    label="Category"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select>
                      {this.state.categories?.map((item, index) => (
                        <Select.Option value={item._id} key={index}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  name="authorsName"
                  label="Authors Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input blog authors_name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>

                <div
                  className="ant-modal-title"
                  style={{ marginBottom: "20px" }}
                >
                  Course SEO Details
                </div>

                <Form.Item
                  name="seo_title"
                  label="SEO Title"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO title!",
                      whitespace: true,
                      // max:60,
                      // min:30
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="SEO Description"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO description!",
                      whitespace: true,
                      // max:160,
                      // min:110,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="SEO Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input course SEO Keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="featured"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Blog status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Published</Radio>
                    <Radio value={false}>Unpublished</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default EditBlog;
