import React from "react";
import { message, Table, Button } from "antd";

import MainContext from "../../Context/AppContext";
import { getStatistics } from "../../network/network";
import CourseAssignment from "../../components/CourseAssignment/CourseAssignment";
class ManagerAvailableCourses extends React.Component {
  state = {
    loading: false,
    course_data: [],
    showModal: false,
    active_course: {},

    available_courses_columns: [
      {
        title: "#",
        key: "index",
        width: "5%",
        align: "center",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Course Name",
        width: "20%",
        render: (record) => {
          return <p>{record.courseId?.title}</p>;
        },
      },
      {
        title: "Language",
        width: "10%",
        align: "center",
        render: (record) => {
          return (
            <p>{record.courseId?.language === 1 ? "English" : "Spanish"}</p>
          );
        },
      },
      {
        title: "Quantities",
        width: "15%",
        align: "center",
        render: (record) => {
          return (
            <>
              <p>
                <b>Available: </b>
                {record.quantity - record.assignCount}
              </p>

              <p>
                <b>Total: </b>
                {record.quantity}
              </p>
            </>
          );
        },
      },
      {
        title: !this.props.manager_id ? "Action" : "",
        width: "10%",
        align: "center",
        hidden: this.props.manager_id ? true : false,
        render: (record) => {
          if (!this.props.manager_id) {
            if (
              record.courseId?.portal_type === 1 &&
              record.assignCount < record.quantity
            ) {
              return (
                <Button
                  className="green-btn"
                  style={{ width: "100%" }}
                  onClick={() => this.assignCourseToTrainees(record)}
                >
                  Assign
                </Button>
              );
            } else {
              return (
                <Button
                  className="green-btn"
                  disabled
                  style={{ width: "100%" }}
                >
                  Assign
                </Button>
              );
            }
          }
        },
      },
    ].filter((item) => !item.hidden),
  };

  static contextType = MainContext;

  componentDidMount() {
    if (
      this.context.authDetails.userType === 2 ||
      this.context.authDetails.userType === 1
    ) {
      this.getManagerStats();
    }
  }

  assignCourseToTrainees = (_id) => {
    this.setState({
      showModal: true,
      active_course: _id,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
    this.getManagerStats();
  };

  getManagerStats = () => {
    this.setState({
      loading: true,
    });
    getStatistics(
      this.context.authDetails.token,
      this.context.authDetails.userType,
      this.props.manager_id ? this.props.manager_id : ""
    )
      .then((res) => {
        let purchased_courses = res.data.course_data;
        this.setState({
          course_data: purchased_courses,
        });
      })
      .catch((err) => {
        message.error("Some error has occured while fetching manager stats!");
      })
      .finally(() => {
        this.setState({
          loading: false,
          showModal: false,
          active_course: {},
        });
      });
  };

  render() {
    const { loading, available_courses_columns, course_data } = this.state;
    return (
      <div>
        {this.state.showModal && (
          <CourseAssignment
            course={this.state.active_course}
            callBack={this.closeModal}
          />
        )}

        <h2 className="manager-box-heading">Available Courses</h2>
        <div className="table-box">
          <Table
            className="recent-orders"
            scroll={{ y: 500 }}
            columns={available_courses_columns}
            rowKey={(record) => record._id}
            dataSource={course_data}
            loading={loading}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

export default ManagerAvailableCourses;
