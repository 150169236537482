import Environment from "./baseUrl";
import axios from "axios";

const getHeaders = async (token) => {
  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      // 'Authorization':  APP_NORMAL,
    };
  }
};

const getProfilePictureUploadHeaders = (token) => {
  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    return {
      Authorization: Environment.BasicToken,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  }
};

var profilePictureOptions = {
  method: null,
  data: null,
  headers: null,
};

var authOptions = {
  method: null,
  data: null,
  headers: getHeaders(),
  // timeout: 500
};

// export const doGetFromCloud = async (url) => {
//     authOptions.headers = await getHeaders()
//     authOptions.method = 'GET'
//     // authOptions.data = null
//     // authOptions.username = 'SHYWJUCOI2';
//     // authOptions.password = 'xsUlOeX6p5BpSIdf1O7jHx7KcrfgxHLuCSytt3tp';

//     const auth = {
//         'username':'SHYWJUCOI2',
//         'password' : 'xsUlOeX6p5BpSIdf1O7jHx7KcrfgxHLuCSytt3tp'
//     };

//     return axios("https://cloud.scorm.com/api/v2" + url, authOptions, auth)
// }

export const doGetFromCloud = async (url, data) => {
  authOptions.headers = {
    // 'Content-Type': 'application/json',
    Authorization:
      "Basic NTBMVlVRM0g3TzpvUVJLOFlHS2JrbEJnSGxhc0tacVdWb0JBdGlXTERoNkxLQTlDR1Ny",
  };
  authOptions.data = data;
  authOptions.method = "GET";

  return axios(Environment.SCORM_CLOUD_URL + url, authOptions);
};

export const doDeleteFromCloud = async (url, data) => {
  authOptions.headers = {
    // 'Content-Type': 'application/json',
    Authorization:
      "Basic NTBMVlVRM0g3TzpvUVJLOFlHS2JrbEJnSGxhc0tacVdWb0JBdGlXTERoNkxLQTlDR1Ny",
  };
  authOptions.data = data;
  authOptions.method = "Delete";

  return axios(Environment.SCORM_CLOUD_URL + url, authOptions);
};

export const doPostFromCloud = async (url, data) => {
  authOptions.headers = {
    // 'Content-Type': 'application/json',
    Authorization:
      "Basic NTBMVlVRM0g3TzpvUVJLOFlHS2JrbEJnSGxhc0tacVdWb0JBdGlXTERoNkxLQTlDR1Ny",
  };
  authOptions.data = data;
  authOptions.method = "POST";

  return axios(Environment.SCORM_CLOUD_URL + url, authOptions);
};

export const doPost = async (url, data, token) => {
  authOptions.method = "POST";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token);
  return axios(Environment.API_BASE_URL + url, authOptions);
};
export const doPatch = async (url, data, token) => {
  authOptions.method = "Patch";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token);
  return axios(Environment.API_BASE_URL + url, authOptions);
};

export const doPut = async (url, data, token) => {
  authOptions.method = "PUT";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token);

  return axios(Environment.API_BASE_URL + url, authOptions);
};

export const doGet = async (url, token, data, responseType = null) => {
  authOptions.headers = await getHeaders(token);
  authOptions.method = "GET";
  authOptions.data = null;
  return axios(Environment.API_BASE_URL + url, {
    ...authOptions,
    responseType: responseType ? responseType : undefined,
  });
};

export const doDelete = async (url, data, token) => {
  authOptions.method = "DELETE";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token);
  return axios(Environment.API_BASE_URL + url, authOptions);
};

export const doPostImageUpload = (url, data, token) => {
  profilePictureOptions.method = "POST";
  profilePictureOptions.data = data;
  profilePictureOptions.headers = getProfilePictureUploadHeaders(token);
  return axios(Environment.API_BASE_URL + url, profilePictureOptions);
};
