import React from "react";
import Highlighter from "react-highlight-words";
import { Table, Input, Button, notification, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import CourseAssignment from "../../components/CourseAssignment/CourseAssignment";
import { getManagerCourses } from "../../network/network";

import MainContext from "../../Context/AppContext";

class ManagerCourses extends React.Component {
  state = {
    categories: [],
    searchText: "",
    showModal: false,
    active_course: {},
    searchedColumn: "",
    data: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 10,
    },
  };
  static contextType = MainContext;

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    selectedKeys[0] = selectedKeys[0].trim();
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  updateCourseTable = (data = null) => {
    if (data) {
      this.setState({
        loading: true,
      });
      var newData = [...this.state.data];
      const index = newData.findIndex((item) => item._id === data._id);
      newData[index] = data;

      this.setState({
        data: newData,
        loading: false,
      });
    } else {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }

    notification.success({
      message: "Course Updated",
      description: "The Course has been updated successfully.",
      placement: "topRight",
    });
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  closeModal = () => {
    this.setState({
      showModal: false,
    });
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    getManagerCourses(this.context.authDetails.token)
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  assignCourseToTrainees = (_id) => {
    this.setState({
      showModal: true,
      active_course: _id,
    });
  };

  render() {
    const { loading } = this.state;
    const { data } = this.state;
    const columns = [
      {
        title: "#",
        key: "index",
        width: "2%",
        render: (text, record, index) => {
          return (
            <p>
              {this.state.pagination.pageSize *
                (this.state.pagination.current - 1) +
                (index + 1)}
            </p>
          );
        },
      },

      {
        title: "Course Name",
        width: "40%",
        render: (record) => {
          return <p style={{ textAlign: "left" }}>{record.courseId.title}</p>;
        },
      },

      {
        title: "Duration",
        width: "10%",
        align: "center",
        render: (course) => {
          return (
            <p>
              {course.courseId.hours} hr {course.courseId.minutes} mins
            </p>
          );
        },
      },
      {
        title: "Language",
        width: "10%",
        align: "center",
        render: (record) => {
          return (
            <p>{record.courseId.language === 1 ? "English" : "Spanish"}</p>
          );
        },
      },

      {
        title: "Available Courses",
        width: "10%",
        align: "center",
        render: (record) => {
          let obj = record.quantity - record.assignCount;
          return (
            <>
              {obj === 0 ? (
                <p
                  style={{ color: "red", fontWeight: "700", fontSize: "16px" }}
                >
                  {obj}
                </p>
              ) : (
                <p
                  style={{
                    color: "black",
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                >
                  {obj}
                </p>
              )}
            </>
          );
        },
      },
      {
        title: "Purchased Quantity",
        width: "10%",
        align: "center",
        render: (record) => {
          return (
            <p style={{ fontWeight: "700", fontSize: "16px" }}>
              {record.quantity}
            </p>
          );
        },
      },

      {
        title: "Action",
        width: "20%",
        align: "center",
        render: (record, course) => {
          if (
            record.courseId.portal_type === 1 &&
            record.assignCount < record.quantity
          ) {
            return (
              <>
                <div className="manager-courses-btns-wrapper">
                  <Button
                    className="green-btn"
                    onClick={() => this.assignCourseToTrainees(record)}
                  >
                    Assign
                  </Button>
                  <a
                    className="blue-btn"
                    href={`${process.env.REACT_APP_BASE_URL}/${course.courseId.slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Buy More
                  </a>
                </div>
              </>
            );
          } else {
            return (
              <div className="manager-courses-btns-wrapper">
                <Button className="green-btn" disabled>
                  Assign
                </Button>
                <a
                  className="blue-btn"
                  href={`${process.env.REACT_APP_BASE_URL}/${course.courseId.slug}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Buy More
                </a>
              </div>
            );
          }
        },
      },
    ];

    return (
      <div>
        {this.state.showModal && (
          <CourseAssignment
            course={this.state.active_course}
            callBack={this.closeModal}
          />
        )}

        <div className="dashboard-charts">
          <h2>Recent Courses</h2>
          <div className="table-box">
            <Table
              columns={columns}
              rowKey={(record) => record._id}
              dataSource={data}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ManagerCourses;
