//userTypes
export const USER_TYPE_ADMIN = 1;
export const USER_TYPE_MANAGER = 2;
export const USER_TYPE_USER = 4;

//Course Type OES/NonOES
export const COURSE_IN_LMS = 1;
export const COURSE_NOT_IN_LMS = 2;

// order Success Message type
export const ORDER_SUCCESS_TYPE_EMAIL = 1;
export const ORDER_SUCCESS_TYPE_SMS = 2;

// User Credentials Message type
export const USER_CREDENTIALS_TYPE_EMAIL = 1;
export const USER_CREDENTIALS_TYPE_SMS = 2;

// order Reminder Channel

export const ORDER_REMINDER_TYPE_EMAIL = 1;
export const ORDER_REMINDER_TYPE_SMS = 2;

// customer sales representative

export const USER_TYPE_SALES = 3;
export const USER_TYPE_CSR = 7;
