import React from "react";
import {
  // Tag,
  Table,
  Col,
  message,
  // notification,
  Space,
  Button,
  // Input,
} from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  getManagerHierarchy,
  //  deleteUserByID
} from "../../../network/network";
import AddHierarchy from "./add";
import EditHierarchy from "./edit";

import MainContext from "../../../Context/AppContext";

class Hierarchy extends React.Component {
  state = {
    visible: false,
    course: {},
    columns: [
      {
        title: "#",
        align: "center",
        key: "index",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },
      {
        title: "Label",
        align: "left",
        render: (record) => record.label,
      },
      {
        title: "Campus",
        align: "left",
        render: (record) => record.campus,
      },
      {
        title: "Instructor",
        align: "left",
        render: (record) => record.instructor,
      },
      {
        title: "Action",
        align: "left",
        render: (text, record) => (
          <Space size="middle">
            <EditHierarchy
              data_id={record._id}
              type={"edit"}
              onChildClick={this.updateHierarchyTable.bind(this)}
            />
            <Button type="dashed">
              <Link to={`report/hierarchy/${record._id}`}>
                <BarChartOutlined />
              </Link>
            </Button>
          </Space>
        ),
      },
    ],
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
  };
  static contextType = MainContext;
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "", filterTable: null });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    selectedKeys[0] = selectedKeys[0].trim();
    var filterTable = [];
    var data = this.state.data;
    if (dataIndex === "contact_details") {
      confirm();

      data.map((o) => {
        if (
          o.email.toLowerCase().includes(selectedKeys[0].toLowerCase()) ||
          (o.contactNo &&
            o.contactNo
              .toLowerCase()
              .includes(selectedKeys[0].toLowerCase())) ||
          (o.address &&
            o.address.toLowerCase().includes(selectedKeys[0].toLowerCase())) ||
          (o.city &&
            o.city.toLowerCase().includes(selectedKeys[0].toLowerCase()))
        ) {
          filterTable.push(o);
        }
      });
    }

    if (dataIndex === "fullname") {
      confirm();

      data.map((o) => {
        var fullname =
          o.firstName.toLowerCase() + " " + o.lastName.toLowerCase();
        if (
          fullname.includes(selectedKeys[0].toLowerCase()) ||
          o.firstName.toLowerCase().includes(selectedKeys[0].toLowerCase()) ||
          o.lastName.toLowerCase().includes(selectedKeys[0].toLowerCase())
        ) {
          filterTable.push(o);
        }
      });
    }
    if (dataIndex === "user_credentials") {
      confirm();

      data.map((o) => {
        if (
          (o.userName && o.userName.includes(selectedKeys[0].toLowerCase())) ||
          (o.password && o.password.includes(selectedKeys[0].toLowerCase()))
        ) {
          filterTable.push(o);
        }
      });
    }

    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
      filterTable: filterTable,
    });
  };

  updateHierarchyTable = () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
  };
  // handleTableChange = (pagination, filters, sorter) => {
  //   this.fetch({
  //     sortField: sorter.field,
  //     sortOrder: sorter.order,
  //     pagination,
  //     ...filters,
  //   });
  // };

  // onDelete = (record) => {
  //   this.setState({ loading: true });
  //   deleteUserByID(record._id, this.context.authDetails?.token).then((res) => {
  //     this.setState({
  //       loading: false,
  //     });

  //     notification.success({
  //       message: "User Deleted",
  //       description: "The user has been deleted successfully.",
  //       placement: "topRight",
  //     });

  //     const { pagination } = this.state;
  //     this.fetch({ pagination });
  //   });
  // };

  // getRandomuserParams = (params) => ({
  //   results: params.pagination.pageSize,
  //   page: params.pagination.current,
  //   ...params,
  // });

  // notDeleteUserMessage = () => {
  //   message.info("User is safe");
  // };

  fetch = (params = {}) => {
    this.setState({ loading: true });

    setTimeout(() => {
      if (this.context.authDetails && this.context.authDetails.token) {
        getManagerHierarchy(this.context.authDetails.token)
          .then((res) => {
            this.setState({
              loading: false,
              data: res.data,
              pagination: {
                ...params.pagination,
                total: res.data.totalCount,
              },
            });
          })
          .catch(function (error) {
            if (error && error.response && error.response.status === 401) {
              message.error(error.response.data.message);
            }
          });
      }
    }, 1000);
  };

  render() {
    const { loading, filterTable, columns, data, pagination } = this.state;

    return (
      <div>
        <Col className="dashboard-charts" span={24}>
          <h2>Recent Hierarchy</h2>
          <AddHierarchy onChildClick={this.updateHierarchyTable.bind(this)} />
          <div className="table-box">
            <Table
              columns={columns}
              rowKey={(record) => record._id}
              dataSource={filterTable === null ? data : filterTable}
              pagination={pagination}
              loading={loading}
              // onChange={this.handleTableChange}
            />
          </div>
        </Col>
      </div>
    );
  }
}

export default Hierarchy;
