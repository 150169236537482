import React from "react";
import {
  Table,
  notification,
  Col,
  Row,
  Space,
  Tag,
  // Popconfirm,
  Button,
} from "antd";
import {
  getBlogs,
  getBlogCategories,
  deleteBlog,
} from "../../../network/network";
// import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import MainContext from "../../../Context/AppContext";
import AddBlog from "./add";

import EditBlog from "./edit";
// import { Link } from "react-router-dom";

class Blogs extends React.Component {
  state = {
    categories: [],
    columns: [
      {
        title: "#",
        key: "index",
        width: "2%",
        algin: "center",
        render: (text, record, index) => {
          return <p>{index + 1}</p>;
        },
      },

      {
        title: "Title",
        width: "30%",
        render: (record) => {
          return <p>{record?.title || "-"}</p>;
        },
      },

      {
        title: "Slug",
        width: "30%",
        render: (record) => {
          return (
            <a
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_BASE_URL}/articles/${record.slug}`}
            >
              {record?.slug}
            </a>
          );
        },
      },
      {
        title: "Category",
        width: "10%",
        render: (record) => {
          return <b>{record?.category?.name || "-"}</b>;
        },
      },
      {
        title: "Status",
        width: "10%",
        render: (record) => {
          return (
            <Tag
              color={record.featured === 1 || record.featured ? "green" : "red"}
            >
              {record.featured || record.featured === 1 ? "Published" : "Draft"}
            </Tag>
          );
        },
      },

      {
        title: "Action",
        width: "10%",
        render: (text, record) => (
          <div className="text-center">
            <Space size="middle">
              <EditBlog
                data_id={record._id}
                type={"edit"}
                categories={this.state.categories}
                onChildClick={this.updateBlogsTable.bind(this)}
              />

              {/* <Popconfirm
                title="Are you sure to delete this blog?"
                onConfirm={() => {
                  this.onDelete(record);
                }}
                onCancel={this.notDeleteUserMessage}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />} type="danger" />
              </Popconfirm> */}
              <Button type="dashed">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_BASE_URL}/articles/${record.slug}`}
                >
                  <EyeOutlined />
                </a>
              </Button>
            </Space>
          </div>
        ),
      },
    ],
    blogs: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  };

  static contextType = MainContext;

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  onDelete = (record) => {
    this.setState({ loading: true });
    deleteBlog(record._id, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
      });

      notification.success({
        message: "Blog Deleted",
        description: "The blog has been deleted successfully.",
        placement: "topRight",
      });

      const { pagination } = this.state;
      this.fetch({ pagination });
    });
  };
  updateBlogsTable = () => {
    notification.success({
      message: "Blog Updated",
      description: "The blog has been updated successfully.",
      placement: "topRight",
    });
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });

    getBlogCategories(this.context.authDetails?.token).then((res) => {
      this.setState({
        categories: res.data,
      });
    });

    getBlogs(this.context.authDetails?.token)
      .then((res) => {
        this.setState({
          blogs: res.data,
          pagination: {
            ...params.pagination,
            total: res.data.totalCount,
          },
        });
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { blogs, columns, loading } = this.state;

    return (
      <>
        <Row gutter={30}>
          <Col className="dashboard-charts ind-dashboard" span={24}>
            <h2>My Blogs</h2>

            <Col span={12}>
              <AddBlog
                onChildClick={this.updateBlogsTable.bind(this)}
                categories={this.state.categories}
              />
            </Col>
            <div className="table-box">
              <Table
                className="blogs-table"
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={blogs ? blogs : []}
                loading={loading}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

// };
export default Blogs;
