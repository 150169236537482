import React from "react";
import {
  Modal,
  Button,
  // Space,
  Form,
  Input,
  // Radio,
  Row,
  notification,
  Col,
  // notification,
} from "antd";
import MainContext from "../../../Context/AppContext";
import { PlusOutlined } from "@ant-design/icons";
import { storePage } from "../../../network/network";
// import CKEditor from "ckeditor4-react";
const { TextArea } = Input;
// import { ExclamationCircleOutlined } from '@ant-design/icons';

class AddUser extends React.Component {
  formRef = React.createRef();
  state = {
    visible: false,
    layout: {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    },
    tailLayout: {
      wrapperCol: {
        offset: 3,
        span: 24,
      },
    },
  };
  static contextType = MainContext;
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onFinish = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);
    if (values.og_image_url) {
      formData.append("og_image_url", values.og_image_url);
    }

    storePage(formData, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Page Created",
          description: "The page has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  render() {
    return (
      <>
        <Button
          style={{ float: "right", margin: "10px" }}
          type="primary"
          onClick={this.showModal}
          icon={<PlusOutlined />}
          size="large"
        ></Button>

        <Modal
          width={2000}
          centered
          title="Create Page"
          visible={this.state.visible}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText="Create"
          cancelText="Cancel"
          footer={[
            <Button
              form="myFormAdd"
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Store
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                layout="vertical"
                name="basic"
                id="myFormAdd"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                // onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="title"
                  label="Page Title"
                  rules={[
                    {
                      required: true,
                      message: "Please input page title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_title"
                  label="Meta Title"
                  rules={[
                    {
                      required: false,
                      message: "Please input meta title!",
                      whitespace: true,
                      max: 60,
                      min: 30,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="Meta Description"
                  rules={[
                    {
                      required: false,
                      message: "Please input meta description!",
                      whitespace: true,
                      max: 160,
                      min: 110,
                    },
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="Meta Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input meta keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="og_image_url"
                  label="OG Image URL"
                  rules={[
                    {
                      required: false,
                      message: "This field is required.",
                    },
                    {
                      type: "url",
                      message: "This field must be a valid url.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default AddUser;
