import React from "react";
import {
  Modal,
  Button,
  // Space,
  Form,
  Input,
  Radio,
  Select,
  Row,
  notification,
  Col,
  // notification,
} from "antd";
// import CKEditor from "ckeditor4-react";
// import FileUpload from "../../../components/FileUpload/FileUpload";
import MainContext from "../../../Context/AppContext";
import { PlusOutlined } from "@ant-design/icons";
import { storePromoCode } from "../../../network/network";
// import CKEditor from "ckeditor4-react";
// const { TextArea } = Input;
// import { ExclamationCircleOutlined } from '@ant-design/icons';

class AddPromoCode extends React.Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
  };
  static contextType = MainContext;
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onFinish = (values) => {
    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("code", values.code);
    formData.append("type", values.type);
    formData.append("discount", values.discount);
    formData.append("limit", values.limit);
    formData.append("status", values.status);

    storePromoCode(formData, this.context.authDetails?.token)
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Promo Code Created",
          description: "The Promo Code has been created successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  render() {
    return (
      <>
        <Button
          style={{ float: "right", margin: "10px 0" }}
          type="primary"
          onClick={this.showModal}
          icon={<PlusOutlined />}
          size="large"
        ></Button>

        <Modal
          width={2000}
          centered
          title="Create Promo Code"
          visible={this.state.visible}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText="Create"
          cancelText="Cancel"
          footer={[
            <Button
              form="myFormAdd"
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Store
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                layout="vertical"
                name="basic"
                id="myFormAdd"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                // onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="name"
                  label="Promotion Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input promotion title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="code"
                  label="Promotion Code"
                  rules={[
                    {
                      required: true,
                      message: "Please input promotion title!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="type"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please input promotion type!",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={1}>Percentage</Select.Option>
                    <Select.Option value={2}>Amount</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="discount"
                  label="Discount"
                  rules={[
                    {
                      required: true,
                      message: "Please input promo discount!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item
                  name="limit"
                  label="Limit"
                  rules={[
                    {
                      required: true,
                      message: "Please input promo limit!",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Published</Radio>
                    <Radio value={false}>Draft</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default AddPromoCode;
