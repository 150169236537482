import React from "react";
import { CanadianTime } from "../../../helpers/helpers";
import {
  Col,
  Row,
  Comment,
  Avatar,
  Checkbox,
  Form,
  Input,
  notification,
  Button,
  message,
} from "antd";
import {
  getContactByID,
  updateContactStatusById,
  replyContactByID,
} from "../../../network/network";
import { UserOutlined } from "@ant-design/icons";
import MainContext from "../../../Context/AppContext";
const { TextArea } = Input;
class ContactDetails extends React.Component {
  state = {
    contact_details: false,
    loading: false,
    showReplyEditor: false,
  };
  static contextType = MainContext;
  componentDidMount() {
    if (
      this.context &&
      this.context.authDetails &&
      this.context.authDetails.token
    ) {
      this.getContactDetails();
    }
  }

  updateContactStatus = (contactStatus) => {
    this.setState({ loading: true });

    updateContactStatusById(
      this.props.match.params.contactID,
      { status: contactStatus },
      this.context.authDetails.token
    )
      .then((res) => {
        this.setState({
          contact_details: res.data,
          loading: false,
        });
        notification.success({
          message: "Contact ",
          description: "Contact status updated successfully.",
          placement: "topRight",
        });
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };
  sendReply = (values) => {
    this.setState({ loading: true });

    replyContactByID(
      this.props.match.params.contactID,
      { reply: values.email_reply_message },
      this.context.authDetails.token
    )
      .then((res) => {
        this.getContactDetails();
        notification.success({
          message: "Contact reply send",
          description: "Contact reply has been sent successfully.",
          placement: "topRight",
        });
      })
      .catch((err) => {
        message.error("Some Error has occurred while sending a reply");
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };
  getContactDetails = () => {
    this.setState({
      loading: true,
      contact_details: false,
      showReplyEditor: false,
    });

    getContactByID(
      this.props.match.params.contactID,
      this.context.authDetails.token
    )
      .then((res) => {
        this.setState({
          contact_details: res.data,
          loading: false,
        });

        if (!res.data.status) {
          this.updateContactStatus(true);
        }
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { contact_details, loading } = this.state;
    return (
      <>
        <div className="user-profile">
          <Row>
            <Col md={24} sm={24} className="user-profile-details ind-dashboard">
              {contact_details && (
                <Row gutter="16">
                  <Col span="12">
                    <h2 className="card-heading">Contact Details</h2>
                  </Col>

                  <Col span="12" align="right">
                    {contact_details.createdAt && (
                      <h2 className="card-heading" style={{ fontSize: 14 }}>
                        Date:{" "}
                        <b style={{ color: "#000" }}>
                          {CanadianTime(contact_details.createdAt)}
                        </b>
                      </h2>
                    )}
                  </Col>

                  {contact_details.name && (
                    <Col span="12">
                      <div className="field-wrapper">
                        <label className="label">Full Name</label>
                        <p className="details">{contact_details.name}</p>
                      </div>
                    </Col>
                  )}
                  {contact_details.email && (
                    <Col span="12">
                      <div className="field-wrapper">
                        <label className="label">Email Address</label>
                        <p className="details">{contact_details.email}</p>
                      </div>
                    </Col>
                  )}
                  {contact_details.contactNo && (
                    <Col span="12">
                      <div className="field-wrapper">
                        <label className="label">Contact No</label>
                        <p className="details">{contact_details.contactNo}</p>
                      </div>
                    </Col>
                  )}
                  {contact_details.companyName && (
                    <Col span="12">
                      <div className="field-wrapper">
                        <label className="label">Company Name</label>
                        <p className="details">{contact_details.companyName}</p>
                      </div>
                    </Col>
                  )}
                  {contact_details.message && (
                    <Col span="24">
                      <div className="field-wrapper">
                        <label className="label">Message</label>
                        <p style={{ minHeight: 100 }} className="details">
                          {contact_details.message}
                        </p>
                      </div>
                    </Col>
                  )}
                  {contact_details.replies &&
                    contact_details.replies.length > 0 && (
                      <>
                        <Col span="24">
                          <>
                            {contact_details.replies.map((reply, i) => (
                              <Comment
                                author={"Reply: "}
                                avatar={
                                  <Avatar size={30} icon={<UserOutlined />} />
                                }
                                content={<p>{reply.message}</p>}
                              ></Comment>
                            ))}
                            <hr />
                          </>
                        </Col>
                      </>
                    )}
                  <Col span="12">
                    <Checkbox
                      onChange={(e) =>
                        this.updateContactStatus(!e.target.checked)
                      }
                      checked={!contact_details.status}
                    >
                      Mark as Unread
                    </Checkbox>
                  </Col>
                  <Col span="12" align="right">
                    <Button
                      type="primary"
                      onClick={() => this.setState({ showReplyEditor: true })}
                    >
                      Reply
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
            {this.state.showReplyEditor && (
              <Col
                md={24}
                sm={24}
                className="user-profile-details ind-dashboard"
              >
                <Form onFinish={this.sendReply} layout="vertical">
                  <Form.Item
                    name="email_reply_message"
                    label="Please enter email reply "
                    rules={[
                      {
                        required: true,
                        message: "Reply is required",
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                  <Form.Item>
                    <Button loading={loading} htmlType="submit" type="primary">
                      Send Reply
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }
}

export default ContactDetails;
