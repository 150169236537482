import React from "react";
import Highlighter from "react-highlight-words";
import {
  Tag,
  Table,
  Col,
  message,
  notification,
  Space,
  Button,
  Input,
  Modal,
  Row,
  Spin,
  Tooltip,
} from "antd";
import {
  EyeOutlined,
  SearchOutlined,
  FilterOutlined,
  ImportOutlined,
  TeamOutlined,
  LoadingOutlined,
  ReloadOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  getUsersData,
  deleteUserByID,
  userLogin,
  sendUserCredentialsSuccessNotification,
  duplicateUser,
} from "../../../network/network";
import AddUser from "./add";
import EditUser from "./edit";
import StudentStats from "./StudentStats";
import AddTrainees from "./AddTrainees";
import MainContext from "../../../Context/AppContext";
import {
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  USER_TYPE_USER,
  USER_TYPE_SALES,
  USER_TYPE_CSR,
  USER_CREDENTIALS_TYPE_EMAIL,
  USER_CREDENTIALS_TYPE_SMS,
} from "../../../constants/constants";
import { CanadianTime } from "../../../helpers/helpers";
const confirm = Modal.confirm;
const { Search } = Input;
class Users extends React.Component {
  state = {
    filters: {
      status: null,
      bdm: null,
      role: null,
      searchText: "",
    },
    index: 0,
    searchedColumn: "",
    selected_user_roles: [],
    selected_user_status: [],
    showStatsModal: false,
    visible: false,
    isDataRefreshed: false,
    course: {},
    traineesManager: false,
    isTraineesManagementModalVisible: false,
    data: [],
    filterTable: null,
    pagination: {
      current: 1,
      pageSize: 20,
    },
    filterPagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    flashClass: "",
  };
  static contextType = MainContext;
  componentDidMount() {
    this.fetch();
  }
  sendSuccessNotifications = (type, userID) => {
    let message_type = type === USER_CREDENTIALS_TYPE_EMAIL ? "Email" : "SMS";
    confirm({
      title: `User Credentials ${message_type}`,
      content: `Are you sure want to send user credentials ${message_type}`,
      okText: "OK",
      onOk: () => {
        sendUserCredentialsSuccessNotification(
          type,
          userID,
          this.context.authDetails?.token
        )
          .then((res) => {
            notification.success({
              message: `User Success ${message_type}`,
              description: `${res.data.message}`,
              placement: "topRight",
            });
          })
          .catch((err) => {
            notification.error({
              message: `Error`,
              description: `Some error has occurred while sending ${message_type}`,
              placement: "topRight",
            });
          });
      },
    });
  };

  makeTextHighlight = (text) => {
    return (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.filters.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  };
  handleReset = (clearFilters) => {
    clearFilters();
  };
  refreshUserData = () => {
    this.setState({
      filterTable: null,
      searchedColumn: "",
      filters: {
        status: null,
        bdm: null,
        role: null,
        searchText: "",
      },
      index: this.state.index + 1,
      loading: false,
      data: [],
      isDataRefreshed: true,
      pagination: {
        current: 1,
        pageSize: 20,
      },
      filterPagination: {
        current: 1,
        pageSize: 20,
      },
    });
    const pagination = {
      pageNumber: 1,
      pagesize: 20,
    };
    this.fetch(pagination, true);
  };
  searchValue = (value) => {
    this.setState({ loading: true, filterTable: null });
    // var data = this.state.data;
    value = value.trim();
    const { searchText, bdm, status, role } = this.state.filters;
    let tableFilters = {};
    if (searchText && searchText != "") {
      tableFilters.keyword = searchText;
    }
    if (status) {
      tableFilters.status = status;
    }
    if (bdm) {
      tableFilters.bdm = bdm;
    }
    if (role) {
      tableFilters.role = role;
    }
    if (value) {
      this.filterData({ ...tableFilters });
    } else {
      this.setState({
        filterTable: null,
        filters: {
          status: null,
          bdm: null,
          role: null,
          searchText: "",
        },
        loading: false,
      });
    }
  };

  updateUserTable = (data = null) => {
    if (data) {
      this.setState({
        loading: true,
      });
      var newData = [...this.state.data];
      const index = newData.findIndex((item) => item._id === data._id);
      newData[index] = data;

      this.setState({
        data: newData,
        loading: false,
      });
    } else {
      this.fetch();
    }
  };

  handleTableChange = (tablePagination, filters, sorter) => {
    const { current: pageNumber, pageSize: pagesize } = tablePagination;
    const { searchText } = this.state.filters;
    let tableFilters = {};

    if (searchText && searchText != "") {
      tableFilters.keyword = searchText;
    }
    if (filters.status) {
      tableFilters.status = filters.status;
    }

    if (filters.role) {
      tableFilters.role = filters.role;
    }

    if (filters.bdm) {
      tableFilters.bdm = filters.bdm;
    }
    this.filterData(
      {
        ...tableFilters,
      },
      { pageNumber, pagesize }
    );
  };

  handleShowTraineesManagementModal = (manager) => {
    this.setState({
      traineesManager: manager,
      isTraineesManagementModalVisible: true,
    });
  };
  swtichAccount = (user) => {
    userLogin({ userName: user.userName, password: user.password })
      .then((response) => {
        response.data.user.token = response.data.token;
        this.context.switchAccount(response.data.user);
        this.props.history.push("/");
      })
      .catch((err) => {
        if (
          (err.response && err.response.status === 401) ||
          (err.response && err.response.data && err.response.data.message)
        ) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onDelete = (record) => {
    this.setState({ loading: true });
    deleteUserByID(record._id, this.context.authDetails?.token).then((res) => {
      this.setState({
        loading: false,
      });

      notification.success({
        message: "User Deleted",
        description: "The user has been deleted successfully.",
        placement: "topRight",
      });

      const { pagination } = this.state;
      this.fetch({ pagination });
    });
  };

  getRandomuserParams = (params) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });

  notDeleteUserMessage = () => {
    message.info("User is safe");
  };

  filterData = (filters = {}, params = { pageNumber: 1, pagesize: 20 }) => {
    this.setState({
      loading: true,
      filterTable: null,
      filters: {
        status: null,
        bdm: null,
        searchText: "",
      },
    });

    getUsersData(
      this.context.authDetails?.token,
      { ...filters, ...params },
      this.context.authDetails?.all_data,
      this.context.authDetails?.bdm_no
    )
      .then((res) => {
        this.setState({
          filterTable: res.data.users,
          selected_user_roles: [],
          selected_user_status: [],
          filters: {
            status: filters.status || null,
            bdm: filters.bdm || null,
            role: filters.role || null,
            searchText: filters.keyword || "",
          },
          index: this.state.index++,
          filterPagination: {
            current: params.pageNumber,
            total: res.data.total,
            pageSize: params.pagesize,
          },
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  fetch = (params = { pageNumber: 1, pagesize: 20 }, refreshData = false) => {
    this.setState({
      loading: true,
      filterTable: null,
      filters: {
        status: null,
        bdm: null,
        role: null,
        searchText: "",
      },
    });

    setTimeout(() => {
      if (this.context.authDetails && this.context.authDetails.token) {
        const { token, all_data, userType, bdm_no } = this.context.authDetails;
        getUsersData(token, params, all_data, bdm_no, userType)
          .then((res) => {
            this.setState({
              loading: false,
              data: res.data.users,
              selected_user_roles: [],
              selected_user_status: [],
              searchText: "",
              index: this.state.index++,
              pagination: {
                current: params.pageNumber,
                total: res.data.total,
                pageSize: params.pagesize,
              },
            });
          })
          .catch((error) => {
            if (error && error.response && error.response.status === 401) {
              message.error(error.response.data.message);
            }
          });
      }
    }, 1000);
  };
  copyCodeToClipboard = (val) => {
    const tempInput = document.createElement("input");
    tempInput.value = val;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    message.success("Copied!");
  };

  addDuplicateUser = (userId) => {
    duplicateUser(this.context.authDetails?.token, userId)
      .then((res) => {
        notification.success({
          message: "User Successfully Duplicated",
          placement: "topRight",
        });
        this.refreshUserData();
        this.setState({ flashClass: "highlight-row" });
        setTimeout(() => {
          this.setState({ flashClass: "" });
        }, 3000);
      })
      .catch((err) => {
        notification.error({
          message: `Error`,
          description: `Some error has occurred`,
          placement: "topRight",
        });
      });
  };

  render(text, record, index) {
    const {
      loading,
      filterTable,
      data,
      pagination,
      showStatsModal,
      course,
      filterPagination,
    } = this.state;
    const paginationProperties = {
      position: ["topRight"],
      pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
    };

    const columns = [
      {
        title: "#",
        width: "1%",
        align: "center",
        key: "index",
        render: (text, record, index) => {
          return (
            <p>
              {!filterTable
                ? pagination.pageSize * (pagination.current - 1) + (index + 1)
                : filterPagination.pageSize * (filterPagination.current - 1) +
                  (index + 1)}
            </p>
          );
        },
      },
      {
        title: "Full Name",
        width: "17%",
        dataIndex: "bdm",
        align: "left",
        render: (text, record) => {
          var fullName = `${record.firstName} ${record.lastName}`;
          return (
            <>
              {this.makeTextHighlight(fullName)}

              <Button
                type="primary"
                onClick={() =>
                  this.copyCodeToClipboard(
                    `${process.env.REACT_APP_BASE_URL}/login?loginToken=${record.token}`
                  )
                }
                style={{
                  marginTop: "20px",
                }}
              >
                Copy Login URL
              </Button>
              {!record?.is_non_oes_user && !record?.is_blocked && (
                <>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.sendSuccessNotifications(
                        USER_CREDENTIALS_TYPE_EMAIL,
                        record._id
                      )
                    }
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    Credentials Email
                  </Button>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.sendSuccessNotifications(
                        USER_CREDENTIALS_TYPE_SMS,
                        record._id
                      )
                    }
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    Credentials SMS
                  </Button>
                </>
              )}
            </>
          );
        },
      },
      {
        title: "User Credentials",
        width: "15%",
        align: "left",
        render: (record) => {
          return (
            <>
              <p>
                <b>Username: </b>
                {this.makeTextHighlight(record.userName)}
              </p>
              <p>
                <b>Password: </b> {this.makeTextHighlight(record.password)}
              </p>
            </>
          );
        },
      },
      {
        title: "Contact Details",
        width: "20%",
        align: "left",
        render: (text, record) => {
          return (
            <>
              <p>
                <b>Email: </b> {this.makeTextHighlight(record.email)}
              </p>

              {record.contactNo && (
                <p>
                  <b>Contact: </b>
                  {this.makeTextHighlight(record.contactNo)}
                </p>
              )}
              {record.address && record.city && (
                <p>
                  <b>Address: </b>
                  {record.address && this.makeTextHighlight(record.address)}
                  {record.city && `, `}
                  {this.makeTextHighlight(record.city)}
                  {record.state && `, `}
                  {this.makeTextHighlight(record.state)}
                  {record.country && `, `}
                  {this.makeTextHighlight(record.country)}
                  {record.zipCode && ` - `}
                  {this.makeTextHighlight(record.zipCode)}
                </p>
              )}
            </>
          );
        },
      },
      {
        title: "Courses",
        width: "15%",
        align: "left",
        render: (text, record) => {
          let courses = [];
          if (record.userType === USER_TYPE_USER) {
            record.courses.forEach((element) => {
              if (element.course_assign_status && element.courseId) {
                let status = "";
                if (element.is_failed || element.status === "FAILED") {
                  status = "FAILED";
                } else if (element.completion === "COMPLETED") {
                  status = "COMPELETED";
                } else if (
                  (element.time === "0" || element.time === "00:00:00") &&
                  element.completion !== "COMPLETED"
                ) {
                  status = "NOT STARTED";
                } else if (
                  element.time !== "0" &&
                  element.time !== "00:00:00" &&
                  element.completion !== "COMPLETED"
                ) {
                  status = "IN PROGRESS";
                }

                courses.push({
                  title: element.courseId.title,
                  registration_id: element._id,
                  course_progress: status,
                  created_at: element.createdAt,
                });
              }
            });
          } else if (record.userType === USER_TYPE_MANAGER) {
            record.purchased_courses.forEach((element) => {
              courses.push({
                title:
                  element.courseId && element.courseId.title
                    ? element.courseId.title
                    : "",
                quantity: element.quantity,
              });
            });
          }

          return (
            <div className="parentCourse">
              {record.userType === USER_TYPE_USER && (
                <>
                  {courses.length > 0 ? (
                    courses.map((obj, i) => (
                      <div className="course-report" key={i}>
                        <p
                          className="link-stats"
                          key={i}
                          onClick={() => {
                            this.setState({
                              course: obj,
                              showStatsModal: true,
                            });
                          }}
                        >
                          {i + 1}.<b>{this.makeTextHighlight(obj.title)}</b>
                          {obj.course_progress === "FAILED" && (
                            <span
                              className="st"
                              style={{ color: "red", fontWeight: "600" }}
                            >
                              ({obj.course_progress})
                            </span>
                          )}
                          {obj.course_progress === "COMPELETED" && (
                            <span
                              className="st"
                              style={{ color: "lightgreen", fontWeight: "600" }}
                            >
                              ({obj.course_progress})
                            </span>
                          )}
                          {obj.course_progress === "NOT STARTED" && (
                            <span
                              className="st"
                              style={{ color: "grey", fontWeight: "600" }}
                            >
                              ({obj.course_progress})
                            </span>
                          )}
                          {obj.course_progress === "IN PROGRESS" && (
                            <span
                              className="st"
                              style={{ color: "orange", fontWeight: "600" }}
                            >
                              ({obj.course_progress})
                            </span>
                          )}
                          <span style={{ display: "block", color: "#000" }}>
                            <b>Assigned at: </b>({CanadianTime(obj.created_at)})
                          </span>
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No Courses</p>
                  )}
                </>
              )}
              {record.userType === USER_TYPE_MANAGER && (
                <>
                  {courses.length > 0 ? (
                    courses.map((obj, i) => (
                      <div className="man-course-report" key={i}>
                        <p>
                          {i + 1}. <b>{this.makeTextHighlight(obj.title)}</b>
                          <br />
                          Qty: <b>{this.makeTextHighlight(obj.quantity)}</b>
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No Courses</p>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        title: "Created at",
        width: "15%",
        align: "left",
        render: (record) => {
          return (
            <>
              <p>{CanadianTime(record.createdAt)}</p>
            </>
          );
        },
      },
      {
        title: "Role",
        dataIndex: "role",
        filterIcon: <FilterOutlined style={{ color: "#0083B7" }} />,
        defaultFilteredValue: "",
        width: "10%",
        align: "center",
        filters: [
          {
            text: "Admin",
            value: "admin",
          },
          {
            text: "User",
            value: "user",
          },
          {
            text: "Manager",
            value: "manager",
          },
          {
            text: "Trainee",
            value: "trainee",
          },
          {
            text: "Sales",
            value: "sales",
          },
          {
            text: "CSR",
            value: "csr",
          },
        ],

        render: (text, record) => {
          if (record.userType === USER_TYPE_ADMIN) {
            return <Tag color={"green"}>Admin</Tag>;
          } else if (record.userType === USER_TYPE_MANAGER) {
            return <Tag color={"blue"}>Manager</Tag>;
          } else if (record.userType === USER_TYPE_SALES) {
            return <Tag color={"grey"}>Sales</Tag>;
          } else if (record.userType === USER_TYPE_USER) {
            return <Tag color={"red"}>User</Tag>;
          } else if (record.userType === USER_TYPE_USER) {
            return <Tag color={"red"}>User</Tag>;
          } else if (record.userType === USER_TYPE_CSR) {
            return <Tag color={"yellow"}>CSR</Tag>;
          } else if (record.userType === USER_TYPE_USER) {
            return (
              <>
                <Tag color={"purple"}>Trainee</Tag>
                <Tag style={{ marginTop: "10px" }} color={"blue"}>
                  {record.manager_id.firstName} {record.manager_id.lastName}
                </Tag>
                {record.manager_id && record.manager_id.companyName && (
                  <Tag style={{ marginTop: "10px" }} color={"black"}>
                    {record.manager_id.companyName}
                  </Tag>
                )}
              </>
            );
          }
        },
      },
      {
        title: "Status",
        width: "10%",
        dataIndex: "status",
        filterIcon: <FilterOutlined style={{ color: "#0083B7" }} />,
        defaultFilteredValue: "",
        align: "center",
        filters: [
          {
            text: "Active",
            value: "active",
          },
          {
            text: "Disabled",
            value: "disabled",
          },
        ],
        render: (text, record) => (
          <>
            {!record.is_blocked ? (
              <Tag color={"green"}>Active</Tag>
            ) : (
              <Tag color={"red"}>Disabled</Tag>
            )}
          </>
        ),
      },
      {
        title: "Duplicate User",
        width: "15%",
        align: "center",
        render: (record) => {
          return (
            <>
              {record.is_blocked && (
                <Button
                  type="primary"
                  onClick={() => this.addDuplicateUser(record._id)}
                  style={{
                    marginTop: "20px",
                  }}
                >
                  Add Duplicate User
                </Button>
              )}
            </>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        width: "15%",
        align: "center",
        render: (text, record) => (
          // <span
          //   className={`${this.props.className}-delete`}
          //   onClick={() => { this.onDelete(record); }}
          // >
          //   Delete
          // </span>
          <Space size="middle" className="userActionBtns">
            <EditUser
              onClick={this.showModal}
              data_id={record._id}
              type={"edit"}
              onChildClick={this.updateUserTable.bind(this)}
            />
            {record.userType === USER_TYPE_MANAGER && (
              <Tooltip placement="bottom" title={"Add Trainees in Account"}>
                <Button
                  type="primary"
                  onClick={() => this.handleShowTraineesManagementModal(record)}
                >
                  <TeamOutlined />
                </Button>
              </Tooltip>
            )}
            {this.context.authDetails &&
              this.context.authDetails.userType &&
              this.context.authDetails.userType === USER_TYPE_ADMIN &&
              record.userType !== USER_TYPE_ADMIN &&
              !record.is_non_oes_user && (
                <Tooltip placement="bottom" title={"Switch Account"}>
                  <Button onClick={() => this.swtichAccount(record)}>
                    <UserSwitchOutlined />
                  </Button>
                </Tooltip>
              )}

            {/* <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={() => {
                  this.onDelete(record);
                }}
                onCancel={this.notDeleteUserMessage}
                okText="Yes"
                cancelText="No"
              >
                 <Button icon={<DeleteOutlined/>} type="danger" />
              </Popconfirm> */}
            {record.userType === USER_TYPE_MANAGER && (
              <Tooltip placement="bottom" title={"Add Courses"}>
                <Button type="dashed">
                  <Link to={`/user/${record._id}/courses`}>
                    <ImportOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
            <Tooltip placement="bottom" title={"View Account Details"}>
              <Button type="dashed">
                <Link to={`/user/${record._id}/details`}>
                  <EyeOutlined />
                </Link>
              </Button>
            </Tooltip>
          </Space>
        ),
      },
    ];

    return (
      <>
        {showStatsModal ? (
          <StudentStats
            closeStats={() => this.setState({ showStatsModal: false })}
            course={course}
          />
        ) : (
          ""
        )}

        <div>
          <Col className="users-recent" span={24}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h2>Recent Users</h2>
              </Col>
              <Col xs={12} sm={12} md={12} lg={0} xl={0}>
                <AddUser onChildClick={this.updateUserTable.bind(this)} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Row
                  style={{
                    justifyContent: "space-evenly",
                    marginBottom: "30px",
                  }}
                >
                  <div className="search">
                    <Search
                      placeholder="Search User"
                      value={this.state.filters.searchText}
                      onChange={(e) =>
                        this.setState((prevState) => ({
                          filters: {
                            ...prevState.filters,
                            searchText: e.target.value,
                          },
                        }))
                      }
                      allowClear
                      enterButton={<SearchOutlined />}
                      size="large"
                      onSearch={(value) => this.searchValue(value)}
                    />
                  </div>
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    size="large"
                    onClick={() => {
                      this.refreshUserData();
                    }}
                  ></Button>
                </Row>
              </Col>
              <Col xs={0} sm={0} md={0} lg={4} xl={4}>
                <AddUser onChildClick={this.updateUserTable.bind(this)} />
              </Col>
            </Row>
            <div className="table-box">
              <Table
                key={this.state.index}
                rowClassName={(record, index) =>
                  index === 0 && this.state.flashClass
                }
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={filterTable ? filterTable : data}
                pagination={
                  filterTable
                    ? { ...filterPagination, ...paginationProperties }
                    : { ...pagination, ...paginationProperties }
                }
                loading={{
                  spinning: loading,
                  indicator: (
                    <Spin
                      indicator={<LoadingOutlined style={{ fontSize: 34 }} />}
                    />
                  ),
                }}
                onChange={this.handleTableChange}
              />
            </div>
          </Col>
        </div>
        {this.state.isTraineesManagementModalVisible && (
          <AddTrainees
            traineesManager={this.state.traineesManager}
            closeTrainees={() =>
              this.setState({ isTraineesManagementModalVisible: false })
            }
          />
        )}
      </>
    );
  }
}

export default Users;
