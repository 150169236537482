import {
  doGet,
  doDelete,
  doPost,
  doPatch,
  doPostImageUpload,
  doGetFromCloud,
  doDeleteFromCloud,
  doPostFromCloud,
} from "./config";
import {
  ORDER_SUCCESS_TYPE_SMS,
  ORDER_SUCCESS_TYPE_EMAIL,
  USER_CREDENTIALS_TYPE_EMAIL,
  USER_CREDENTIALS_TYPE_SMS,
} from "../constants/constants";
// IMPORTANT! READ IT
//  If API contains basic token like auth API's or public APIs then dont send any token
//  example:
//  return doPost(`contact-us?lang=${lang}`, data)

// Scorm Cloud APIS start

// const SCORMCLOUD_REGISTRATION_ID = "courses-registration-108556787565672556-12465757-35344";
// const SCORMCLOUD_REGISTRATION_ID = "courses-registration-108556787565672556-12465757-35346";
// const SCORMCLOUD_REGISTRATION_ID = "courses-registration-108556787565672556-12465757-35347";
// const SCORMCLOUD_REGISTRATION_ID = "courses-registration-108556787565672556-12465757-35389";
// const API_type = "SCORM_CLOUD";
// const APP_ID = "SHYWJUCOI2";
// const APP_SECRET_KEY = "xsUlOeX6p5BpSIdf1O7jHx7KcrfgxHLuCSytt3tp";

export const getCoursePreviewURL = (courseID, redirectURL) => {
  let data = {};
  data = {
    expiry: 120,
    redirectOnExitUrl: redirectURL,
    tracking: true,
    // startSco: "string",
    // culture: "string",
    // cssUrl: "string",
    // learnerTags: ["string"],
    // courseTags: ["string"],
    // registrationTags: ["60ae487ab17bfb5293b81df3"],
    // additionalvalues: [
    //   {
    //     item: "string",
    //     value: "string",
    //   },
    // ],
    // launchAuth: {
    //   type: "cookies",
    //   options: {
    //     ipAddress: true,
    //     fingerprint: true,
    //     expiry: 0,
    //     slidingExpiry: 0,
    //   },
    // },
  };
  return doPostFromCloud(`/courses/${courseID}/preview`, data);
};

export const DeleteRegistrations = (reg_id) => {
  return doDeleteFromCloud(`/registrations/${reg_id}`);
};

export const BuildRegistrationLaunchLink = (reg_id, redirectURL) => {
  let data = {
    expiry: 120,
    redirectOnExitUrl: redirectURL,
    tracking: true,
  };

  return doPostFromCloud(`/registrations/${reg_id}/launchLink`, data);
};
export const GetRegistrationProgress = (reg_id) => {
  return doGetFromCloud(`/registrations/${reg_id}`);
};

export const createRegistration = (user_details, course_scorm_id, reg_id) => {
  let data = {
    courseId: course_scorm_id,
    registrationId: reg_id,
    learner: {
      id: user_details._id,
      email: user_details.email,
      firstName: user_details.firstName,
      lastName: user_details.lastName,
    },
  };
  return doPostFromCloud("/registrations", data);
};

export const importAndUploadCourse = (courseID, courseFile) => {
  return doPostFromCloud(
    `/courses/importJobs/upload?courseId=${courseID}`,
    courseFile
  );
};

// Scorm Cloud APIS end

export const saveCourseProgress = (registration_id, data, token) => {
  return doPatch(`/user/courses/registration/${registration_id}`, data, token);
};

export const markCourseRegistrationFailed = (data, token) => {
  return doPatch(`/admin/users/mark-as-failed`, data, token);
};

export const managerReAssignCourses = (data, token) => {
  return doPatch("/manager/trainees/reassign-course", data, token);
};

export const userLogin = (data) => {
  return doPost("/signin", data, "");
};
export const userRegister = (data) => {
  return doPost("/signup", data, "");
};

export const userForgotPassword = (data, token) => {
  return doPost("/forgotPassword", data, token);
};

export const getEmailFromToken = (resetToken) => {
  return doGet(`/reset/${resetToken}`, "");
};

export const updatePassword = (data, token) => {
  return doPatch("/resetPassword", data, "");
};

export const getUsersData = (token, params = null, all_data, bdm_no) => {
  if (!all_data && !bdm_no) {
    params.only_today = 1;
  }
  return doGet(`/admin/users${params ? serialize(params) : ""}`, token);
};

export const getBDM_Orders = (token, BDM_Num) => {
  return doGet(`/admin/orders?bdm_no=${BDM_Num}`, token);
};

export const sendOrderSuccessNotification = (type, order_id, token) => {
  if (type == ORDER_SUCCESS_TYPE_SMS) {
    return doGet(`/admin/orders/send-sms/${order_id} `, token);
  } else if (type == ORDER_SUCCESS_TYPE_EMAIL) {
    return doGet(`/admin/orders/send-email/${order_id} `, token);
  }
};
export const sendUserCredentialsSuccessNotification = (type, userId, token) => {
  if (type === USER_CREDENTIALS_TYPE_EMAIL) {
    return doGet(`/admin/users/credentials-email/${userId} `, token);
  } else if (type === USER_CREDENTIALS_TYPE_SMS) {
    return doGet(`/admin/users/credentials-sms/${userId} `, token);
  }
};
export const sendTraineeCredentialsNotification = (type, traineeId, token) => {
  if (type === USER_CREDENTIALS_TYPE_EMAIL) {
    return doGet(`/manager/trainees/credentials-email/${traineeId} `, token);
  } else if (type === USER_CREDENTIALS_TYPE_SMS) {
    return doGet(`/manager/trainees/credentials-sms/${traineeId} `, token);
  }
};
export const getManagerHierarchy = (token) => {
  return doGet("/manager/hierarchy", token);
};

export const updateFaq = (data, token) => {
  return doPatch(`/admin/faqs/${data._id}`, data, token);
};

export const getAllUsersStatistics = (token) => {
  return doGet("/admin/courses/progress", token);
};
export const getManagerActivityLogs = (token) => {
  return doGet("/manager/stats/logs", token);
};
export const getManagerHierarchyStatistics = (hierarchyID, token) => {
  return doGet(`/manager/hierarchy/${hierarchyID}/progress`, token);
};

export const getManagerTraineesStatistics = (token) => {
  return doGet(`/manager/courses/progress`, token);
};

export const getStatistics = (token, userType, manager_id = "") => {
  if ((userType === 1 || userType === 3) && manager_id === "") {
    return doGet("/admin/stats/new", token);
  } else if (userType === 2 || (userType === 1 && manager_id !== "")) {
    return doGet(`/manager/stats/${manager_id}`, token);
  } else {
    return doGet("/user/stats", token);
  }
};

export const duplicateUser = (token, userId) => {
  return doGet(`/admin/users/replicate-user/${userId}`, token);
};

export const getRegistrationDetailsByRegID = (token, regID) => {
  return doGet(`/user/courses/registration/${regID}`, token);
};

export const getCategories = (token) => {
  return doGet("/admin/categories", token);
};

export const getChildCategories = (token) => {
  return doGet("/admin/categories/child-categories", token);
};

export const getParentCategories = (token) => {
  return doGet("/admin/categories/parent-categories", token);
};

function serialize(obj) {
  let searchParams = new URLSearchParams();

  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      searchParams.append(p, obj[p]);
    }
  }

  return "?" + searchParams.toString();
}

export const getOrders = (token, params = null, all_data, bdm_no) => {
  if (!all_data && !bdm_no) {
    params.only_today = 1;
  }

  return doGet(`/admin/orders${params ? serialize(params) : ""}`, token);
};

export const getUserOrders = (token) => {
  return doGet("/user/orders", token);
};

export const removeOrderDuplication = (token) => {
  return doGet("/admin/orders/remove-unpaid", token);
};

export const getUserOrderById = (user_id, token) => {
  return doGet(`/user/orders/${user_id} `, token);
};

export const deleteOrderByID = (orderID, token) => {
  return doDelete(`/admin/orders/${orderID}`, "", token);
};
export const deleteContactByID = (contactID, token) => {
  return doDelete(`/admin/contact/${contactID}`, "", token);
};

export const getPageByID = (page_id, token) => {
  return doGet(`/admin/pages/${page_id} `, token);
};

export const getBlogByID = (blog_id, token) => {
  return doGet(`/admin/blogs/${blog_id} `, token);
};
export const getBlogCategoryByID = (blog_category_id, token) => {
  return doGet(`/admin/blogCategories/${blog_category_id} `, token);
};

export const getContacts = (token) => {
  return doGet("/admin/contact", token);
};

export const getFaqs = (token) => {
  return doGet("/admin/faqs", token);
};

export const getBlogs = (token) => {
  return doGet("/admin/blogs", token);
};

export const getPromoCodes = (token) => {
  return doGet("/admin/promocodes", token);
};

export const getPromoCodeByID = (promocode, token) => {
  return doGet(`/admin/promocodes/${promocode} `, token);
};

export const storePromoCode = (data, token) => {
  return doPost("/admin/promocodes", data, token);
};

export const sendEmailToUnPaidOrders = (data, token) => {
  return doPost("/admin/orders/send-reminder-email", data, token);
};
export const sendSMSToUnPaidOrders = (data, token) => {
  return doPost("/admin/orders/send-reminder-sms", data, token);
};
export const replyContactByID = (contactId, data, token) => {
  return doPost(`/admin/contact/reply/${contactId}`, data, token);
};
export const exportCourseProgressSheet = (data, token) => {
  var params = new URLSearchParams();
  if (data.filter_start_date) {
    params.append("filter_start_date", data.filter_start_date);
  }
  if (data.filter_end_date) {
    params.append("filter_end_date", data.filter_end_date);
  }
  if (data.course_progress_status) {
    params.append("course_progress_status", data.course_progress_status);
  }
  return doGet(
    "/admin/courses/progress-report-export?" + params.toString(),

    token
  );
};

export const updatePromoCode = (promo_code_id, data, token) => {
  return doPatch(`/admin/promocodes/${promo_code_id}`, data, token);
};

export const deletePromoCode = (promo_code_id, token) => {
  return doDelete(`/admin/promocodes/${promo_code_id}`, "", token);
};

export const getBlogCategories = (token) => {
  return doGet("/admin/blogCategories/", token);
};

export const getCourses = (userType, token) => {
  return doGet(`/${userType}/courses`, token);
};

export const addManagerCourses = (managerID, data, token) => {
  return doPatch(
    `/admin/manager/add-purchased-courses/${managerID}`,
    data,
    token
  );
};

export const getManagerCourses = (token) => {
  return doGet(`/manager/courses`, token);
};
export const getManagerTrainees = (token, params = null) => {
  return doGet(`/manager/trainees${params ? serialize(params) : ""}`, token);
};
export const saveTraineeData = (data, token) => {
  return doPost("/manager/trainees", data, token);
};
export const managerAssignCourses = (data, token) => {
  return doPatch("/manager/trainees/assign-courses", data, token);
};

export const getCanBeTraineesData = (token) => {
  return doGet("/admin/users/can-be-trainees", token);
};
export const addTraineesToManager = (data, token) => {
  return doPost(
    `/admin/users/${data._id}/add-trainees-to-manager`,
    data,
    token
  );
};

export const getProfileMe = (token) => {
  return doGet("/profile", token);
};

export const getContactNotification = (token) => {
  return doGet("/admin/contact/notifications", token);
};

export const storeCategory = (data, token) => {
  return doPost("/admin/categories", data, token);
};

export const getPage = (token) => {
  return doGet("/admin/pages", token);
};

export const storePage = (data, token) => {
  return doPost("/admin/pages", data, token);
};
export const storeBlog = (data, token) => {
  return doPost("/admin/blogs", data, token);
};
export const storeBlogCategories = (data, token) => {
  return doPost("/admin/blogCategories", data, token);
};

export const updatePage = (page_id, data, token) => {
  return doPatch(`/admin/pages/${page_id}`, data, token);
};

export const updateBlog = (blog_id, data, token) => {
  return doPatch(`/admin/blogs/${blog_id}`, data, token);
};

export const updateBlogCategory = (blog_category_id, data, token) => {
  return doPatch(`/admin/blogCategories/${blog_category_id}`, data, token);
};
export const updateBlogCategories = (blog_category_id, data, token) => {
  return doPatch(`/admin/blogCategories/${blog_category_id}`, data, token);
};

export const UpdateCourseDiscount = (type, data, token) => {
  if (type === 1) {
    return doPatch(
      `/admin/courses/updateDiscountedPriceToPercent`,
      data,
      token
    );
  }
  if (type === 2) {
    return doPatch(
      `/admin/courses/updateDiscountedPriceToActualPrice`,
      "",
      token
    );
  }
};

export const storeCourse = (data, token) => {
  return doPost("/admin/courses", data, token);
};

export const generateEmails = (data, token) => {
  return doPost("/admin/sendgrid/bulk-emails-from-sheet", data, token);
};

export const generateCertificateLink = (data, registration_id, token) => {
  return doPatch(
    `/admin/users/course/registration/${registration_id}`,
    data,
    token
  );
};

export const downloadCertificate = (registration_id, token) => {
  return doGet(
    `/user/courses/download-certificate/${registration_id}`,
    token,
    null,
    "blob"
  );
};

export const deleteCategory = (categoryID, token) => {
  return doDelete(`/admin/categories/${categoryID}`, "", token);
};

export const deleteBlog = (blogID, token) => {
  return doDelete(`/admin/blogs/${blogID}`, "", token);
};

export const deleteBlogCategory = (blogID, token) => {
  return doDelete(`/admin/blogCategories/${blogID}`, "", token);
};

export const deleteFaq = (faqID, token) => {
  return doDelete(`/admin/faqs/${faqID}`, "", token);
};

export const getHierarchyByID = (hierarchyID, token) => {
  return doGet(`/manager/hierarchy/${hierarchyID}`, token);
};
export const getUserByID = (role, userID, token) => {
  if (role === "admin") {
    return doGet(`/admin/users/${userID}`, token);
  } else if (role === "manager") {
    return doGet(`/manager/trainees/view/${userID}`, token);
  }
};
export const getUserOrTraineeDetails = (userID, token) => {
  return doGet(`/manager/trainees/view/${userID}`, token);
};

export const getTraineeByID = (traineeID, token) => {
  return doGet(`/manager/trainees/${traineeID}`, token);
};
export const assignCourseToYourselfManager = (data, token) => {
  return doPatch(`/manager/trainees/assign-course-yourself`, data, token);
};

export const getFaqByID = (faqID, token) => {
  return doGet(`/admin/faqs/${faqID}`, token);
};

export const getCategoryByID = (categoryID, token) => {
  return doGet(`/admin/categories/${categoryID}`, token);
};

export const getCourseByID = (couserID, token) => {
  return doGet(`/admin/courses/${couserID}`, token);
};

export const getOrderByID = (orderID, token) => {
  return doGet(`/admin/orders/${orderID}`, token);
};

export const getUserOrderByID = (orderID, token) => {
  return doGet(`/user/orders/${orderID}`, token);
};

export const getContactByID = (contactID, token) => {
  return doGet(`/admin/contact/${contactID}`, token);
};

export const updateContactStatusById = (contactID, data, token) => {
  return doPatch(`/admin/contact/${contactID}`, data, token);
};

export const deleteUserByID = (userID, token) => {
  return doDelete(`/admin/users/${userID}`, "", token);
};
export const deleteCourseByID = (courseID, token) => {
  return doDelete(`/admin/courses/${courseID}`, "", token);
};

export const saveUserData = (data, token) => {
  return doPost("/admin/users", data, token);
};
export const saveHierarchyData = (data, token) => {
  return doPost("/manager/hierarchy", data, token);
};

export const saveFaq = (data, token) => {
  return doPost("/admin/faqs", data, token);
};

export const updateUserDetails = (data, token) => {
  return doPatch(`/admin/users/${data._id}`, data, token);
};

export const updateHierarchyDetails = (data, token) => {
  return doPatch(`/manager/hierarchy/${data._id}`, data, token);
};

export const updateTraineeDetails = (data, token) => {
  return doPatch(`/manager/trainees/${data._id}`, data, token);
};

export const updateProfileInformation = (data, token) => {
  return doPatch("/profile/", data, token);
};

export const getProfileInformation = (token) => {
  return doGet("/profile/", token, "");
};

export const updateCategory = (category_id, data, token) => {
  return doPatch(`/admin/categories/${category_id}`, data, token);
};

export const updateCourse = (courseID, data, token) => {
  return doPatch(`/admin/courses/${courseID}`, data, token);
};

export const assignCourseToUser = (userID, coursedata, token) => {
  var courseArray = {};
  courseArray.courses = coursedata;
  return doPatch(`/admin/users/${userID}/assign-courses`, courseArray, token);
};

export const unAssignCourseToUser = (
  userID,
  courseProgressID,
  token,
  unassign
) => {
  var data = {
    courses_progress_id: courseProgressID,
    unassign: unassign,
  };
  return doPatch(`/admin/users/${userID}/unassign-courses`, data, token);
};

export const saveImage = (data, token) => {
  return doPostImageUpload(`/admin/images`, data, token);
};

export const uploadCourse = (data, token) => {
  return doPostImageUpload(`/admin/courses/upload`, data, token);
};

//ECommerce APIS
export const getAllCourses = (token) => {
  return doGet("/shop/courses");
};
