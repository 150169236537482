import React from "react";
import FileUpload from "../../../components/FileUpload/FileUpload";
import CKEditor from "ckeditor4-react";
import {
  Form,
  Modal,
  Input,
  Row,
  Image,
  Checkbox,
  Radio,
  Col,
  Button,
  Select,
  notification,
} from "antd";
import MainContext from "../../../Context/AppContext";
import { FormOutlined } from "@ant-design/icons";

import {
  getCategoryByID,
  updateCategory,
  getParentCategories,
} from "../../../network/network";

class editCategory extends React.Component {
  formRef = React.createRef();
  state = {
    category_details: {},
    loading: false,
    visible: false,
    categoriesArr: [],
    thumbnailImageId: "",
    ogImageId: "",
    categoryDescription: "",
    bannerImageId: "",
    bannerImagePath: "",
    thumbnailImagePath: "",
    ogImagePath: "",
  };
  static contextType = MainContext;

  componentDidMount() {}

  showModal = () => {
    // var _this = this;
    this.setState({ loading: true });
    this.getCategoryDetails(this.props.data_id);

    if (this.state.category_details.parent_category !== null) {
      getParentCategories(this.context.authDetails.token).then((res) => {
        this.setState({ categoriesArr: res.data, loading: false });
      });
    }
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleUpload = ({ thumbnailImage }) => {
    this.setState({ thumbnailImage });
  };

  onFinish = (values) => {
    const formData = new FormData();
    formData.append("thumbnailImage", this.state.thumbnailImageId);
    formData.append("ogImage", this.state.ogImageId);
    formData.append("bannerImage", this.state.bannerImageId);
    formData.append("name", values.name);
    formData.append("slug", values.slug);
    formData.append("description", this.state.categoryDescription);
    formData.append("recommended", values.recommended ? true : false);
    formData.append("order", values.order);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("seo_keyword", values.seo_keyword);

    formData.append(
      "parent_category",
      values.parent_category ? values.parent_category : ""
    );
    formData.append("status", values.status);

    updateCategory(
      this.props.data_id,
      formData,
      this.context.authDetails?.token
    )
      .then((res) => {
        this.hideModal();
        this.props.onChildClick();
        notification.success({
          message: "Category Updated",
          description: "The category has been updated successfully.",
          placement: "topRight",
        });
        this.formRef.current.resetFields();
      })
      .catch(function (err) {});
  };

  onFinishFailed = (errorInfo) => {};

  handleThumbnailImageID = (thumbnailImageID, thumbnailImagePath) => {
    this.setState({
      thumbnailImageId: thumbnailImageID,
      thumbnailImagePath: thumbnailImagePath,
    });
  };
  handleOGImageID = (ogImageID, ogImagePath) => {
    this.setState({
      ogImageId: ogImageID,
      ogImagePath: ogImagePath,
    });
  };

  handlebannerImageID = (bannerImageID, bannerImagePath) => {
    this.setState({
      bannerImageId: bannerImageID,
      bannerImagePath: bannerImagePath,
    });
  };

  getCategoryDetails = (categoryID) => {
    getCategoryByID(categoryID, this.context.authDetails?.token).then((res) => {
      // let  newCategories = [];
      // var parentCategories = [];

      // if (res.data.parent_category !== null) {
      //   parentCategories = newCategories.filter(function (item) {
      //     return (
      //       item.parent_category === null && item._id !== this?.props?.data_id
      //     );
      //   });
      // }

      // if (res.data.parent_category !== null) {
      //   getParentCategories(this.context.authDetails.token).then((res) => {
      //     newCategories = res.data;
      //     this.setState({categoriesArr : newCategories})
      //   });
      // }

      this.setState({
        loading: false,
        // categoriesArr: newCategories,
        visible: true,
        category_details: res.data,
        categoryDescription: res.data.description ? res.data.description : "",

        thumbnailImageId: res.data.thumbnailImage
          ? res.data.thumbnailImage._id
          : "",

        ogImageId: res.data.ogImage ? res.data.ogImage._id : "",

        thumbnailImagePath: res.data.thumbnailImage
          ? res.data.thumbnailImage.path
          : "",
        ogImagePath: res.data.ogImage ? res.data.ogImage.path : "",
        bannerImageId: res.data.bannerImage ? res.data.bannerImage._id : "",
        bannerImagePath: res.data.bannerImage ? res.data.bannerImage.path : "",
      });

      if (res.data) {
        this.formRef.current.setFieldsValue({
          ...res.data,
        });
      }
    });
  };

  categoryDescriptionupdate = (evt) => {
    this.setState({
      categoryDescription: evt.editor.getData(),
    });
  };
  render() {
    const { category_details } = this.state;
    return (
      <div>
        <Button
          onClick={this.showModal}
          type="primary"
          icon={<FormOutlined />}
        />

        <Modal
          title="Edit Category"
          visible={this.state.visible}
          onOk={this.onFinish}
          width={2000}
          centered
          onCancel={this.hideModal}
          cancelText="Cancel"
          footer={[
            <Button
              form={"myForm" + category_details._id}
              type="primary"
              key="submit"
              htmlType="submit"
            >
              Update
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} offset={0}>
              <Form
                ref={this.formRef}
                layout="vertical"
                id={"myForm" + category_details._id}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="slug"
                  label="Slug"
                  rules={[
                    {
                      required: true,
                      message: "Please input slug!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: false,
                      message: "Please input description!",
                    },
                  ]}
                >
                  {/* <TextArea rows={4} /> */}
                  <CKEditor
                    data={this.state.categoryDescription}
                    onChange={this.categoryDescriptionupdate}
                  />
                </Form.Item>

                <Form.Item label="Thumbnail Image">
                  {this.state.thumbnailImageId ? (
                    <Image width={200} src={this.state.thumbnailImagePath} />
                  ) : (
                    <Image
                      width={200}
                      src={
                        this.state.category_details &&
                        this.state.category_details.thumbnailImage
                          ? `${this.state.category_details.thumbnailImage["path"]}`
                          : "/no-image.png"
                      }
                    />
                  )}
                  <FileUpload
                    onThumbnailImageId={this.handleThumbnailImageID}
                    imageUploadType="thumbnailImage"
                  />
                </Form.Item>

                <Form.Item label="Banner Image">
                  {this.state.bannerImageId ? (
                    <Image width={200} src={this.state.bannerImagePath} />
                  ) : (
                    <Image
                      width={200}
                      src={
                        this.state.category_details &&
                        this.state.category_details.bannerImage
                          ? `${this.state.category_details.bannerImage["path"]}`
                          : "/no-image.png"
                      }
                    />
                  )}
                  <FileUpload
                    onBannerImageId={this.handlebannerImageID}
                    imageUploadType="bannerImage"
                  />
                </Form.Item>

                <Form.Item label="OG Image">
                  {this.state.ogImageId ? (
                    <Image width={200} src={this.state.ogImagePath} />
                  ) : (
                    <Image
                      width={200}
                      src={
                        this.state.category_details &&
                        this.state.category_details.ogImage
                          ? `${this.state.category_details.ogImage["path"]}`
                          : "/no-image.png"
                      }
                    />
                  )}
                  <FileUpload
                    onOGImageId={this.handleOGImageID}
                    imageUploadType="categoryOGImage"
                  />
                </Form.Item>

                {this.state.category_details.parent_category ? (
                  <Form.Item
                    name="parent_category"
                    label="Parent Category"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select>
                      {/* <Select.Option value={""}>No Category</Select.Option> */}
                      {this.state.categoriesArr?.map((item, index) => (
                        <Select.Option value={item._id} key={index}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  ""
                )}
                <Form.Item
                  name="order"
                  label="Ordering"
                  rules={[
                    {
                      required: false,
                      message: "Please input integer value for ordering!",
                    },
                  ]}
                >
                  <Input type="number" min="1" />
                </Form.Item>

                <Form.Item name="recommended" valuePropName="checked">
                  <Checkbox>Mark as Recommended Category</Checkbox>
                </Form.Item>

                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "Page status is required",
                      // whitespace: true,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Published</Radio>
                    <Radio value={false}>Unpublished</Radio>
                  </Radio.Group>
                </Form.Item>

                <div
                  className="ant-modal-title"
                  style={{ marginBottom: "20px" }}
                >
                  Course SEO Details
                </div>

                <Form.Item
                  name="seo_title"
                  label="SEO Title"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO title!",
                      whitespace: true,
                      max: 60,
                      min: 30,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_description"
                  label="SEO Description"
                  rules={[
                    {
                      required: true,
                      // message: "Please input course SEO description!",
                      whitespace: true,
                      max: 160,
                      min: 110,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="seo_keyword"
                  label="SEO Keywords"
                  rules={[
                    {
                      required: false,
                      message: "Please input course SEO Keywords!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default editCategory;
